import { Component, ReactNode } from 'react';
import authStore from '~stores/authStore';

import { ALL_LOCATION, PERMISSION_SUPERADMIN } from '@mgn/common';

interface IProps {
  permission: string;
  locationId?: string;
  unAuthorizedComponent?: ReactNode;
  children?: ReactNode;
}

export class Authorized extends Component<IProps> {
  public static defaultProps = {
    unAuthorizedComponent: null,
  };

  public render() {
    const {
      children,
      unAuthorizedComponent,
      permission,
      locationId,
    } = this.props;

    if (isAuthorized(permission, locationId)) {
      return children;
    }

    return unAuthorizedComponent;
  }
}

export const isAuthorized = (permission, locationId = null) => {
  const { permissions: currentPermission } = authStore;
  const isSu = currentPermission[PERMISSION_SUPERADMIN];
  if (isSu) return true;

  // auth không có location
  if (!locationId && currentPermission[permission]) return true;

  // kiểm tra authored với location
  const arrLocation = currentPermission[permission];
  if (!arrLocation) return false;

  return arrLocation.some(e => e === ALL_LOCATION || e === locationId);
};

export default Authorized;

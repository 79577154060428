import gql from 'graphql-tag';
import warehouse from '../fragments/warehouse';

export const REMOVE_WAREHOUSE_MUTATION = gql`
  mutation RemoveWarehouse($_id: ID!) {
    removeWarehouse(_id: $_id) {
      warehouse {
        ...BasicWarehouse
      }
      error {
        message
      }
    }
  }
  ${warehouse.basic}
`;

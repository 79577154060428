import './AgGridTable.less';

import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { agGridLocale } from '~locales/viVN';

import { Loading } from '../';

LicenseManager.setLicenseKey(
  'NDEwMjMzMzIwMDAwMA==4776ae9eddc069aad222a64b09b9e834'
);

interface IProps extends AgGridReactProps {
  title?: ReactNode;
  height?: string | number;
  loading?: boolean;
}

export const defaultOptions: GridOptions = {
  localeText: agGridLocale,
  defaultColDef: { menuTabs: [], suppressToolPanel: true },
  enableSorting: true,
  animateRows: true,
  rowHeight: 35,
  headerHeight: 35,
  enableColResize: true,
  suppressContextMenu: true,
  suppressRowClickSelection: true,
  masterDetail: true,
  loadingOverlayComponentFramework: Spin,
  suppressMovableColumns: true,
};

@observer
export default class AgGridTable extends Component<IProps> {
  public onGridReady = (event: GridReadyEvent) => {
    const { api } = event;

    const resize = () => {
      api.sizeColumnsToFit();
    };

    api.addEventListener('gridSizeChanged', resize);
    api.addEventListener('firstDataRendered', resize);
    resize();

    const { onGridReady } = this.props;
    if (typeof onGridReady === 'function') {
      onGridReady(event);
    }
  };

  public render() {
    const {
      title,
      height,
      onGridReady,
      loading,
      ...remainingProps
    } = this.props;
    if (loading) return <Loading />;
    return (
      <div className='ag-theme-material' style={{ height: height || 200 }}>
        {title}
        <AgGridReact
          {...defaultOptions}
          {...remainingProps}
          onGridReady={this.onGridReady}
        />
      </div>
    );
  }
}

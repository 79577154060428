import gql from 'graphql-tag';

import location from '../fragments/location';

export const UPDATE_CONTENT_LOCATION_MUTATION = gql`
  mutation UpdateContentLocation(
    $_id: ID!
    $name: String!
    $typeCode: String!
    $address: String!
    $district: String
    $area: String
    $contact: [ContactInput!]!
    $surveyorId: String
    $installerId: String
    $maintainerId: String
  ) {
    updateContentLocation(
      _id: $_id
      name: $name
      typeCode: $typeCode
      address: $address
      district: $district
      area: $area
      contact: $contact
      surveyorId: $surveyorId
      installerId: $installerId
      maintainerId: $maintainerId
    ) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${location.full}
`;

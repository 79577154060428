import { ICellRendererParams } from 'ag-grid-community';
import { Component } from 'react';
import { GOODSRECEIPT } from '@mgn/common';
import {
  GoodsReceiptDetailLocation,
  GoodsIssueDetailLocation
} from '~screens/Routes';

export class GridCodeCellRenderer extends Component<ICellRendererParams> {
  public render() {
    const { value, data } = this.props;
    if (!data) return '';

    if (data.type === GOODSRECEIPT) {
      return value
        ? GoodsReceiptDetailLocation.toLink(value, { code: value })
        : '';
    }
    return value ? GoodsIssueDetailLocation.toLink(value, { code: value }) : '';
  }
}

import { Select } from "antd";
import { action, computed, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";

import client from "~graphql/client";
import {
  FullLocationTypeFragment,
  FindManyLocationTypeQuery
} from "~graphql/types";
import { FIND_MANY_LOCATION_TYPE_QUERY } from "~graphql/queries/findLocationType";
import { LOCATION_TYPE } from "@mgn/common";

const { Option } = Select;

@observer
export default class LocationTypeDropdown extends Component {
  @observable public loading: boolean;
  @observable public data: FullLocationTypeFragment[];

  @computed public get locationTypes() {
    return this.data.filter(t => t.type === LOCATION_TYPE);
  }

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = true;
      this.data = [];
    });
  }

  public componentDidMount() {
    this.loadData().then(
      action("setLoading", () => {
        this.loading = false;
      })
    );
  }

  @action public loadData = async () => {
    this.loading = true;

    const {
      data: { findManyLocationType }
    } = await client.query<FindManyLocationTypeQuery>({
      query: FIND_MANY_LOCATION_TYPE_QUERY
    });

    runInAction(() => {
      this.loading = false;
      this.data = findManyLocationType;
    });
  };

  public render() {
    return (
      <Select
        placeholder="Chọn loại điểm"
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: "100%" }}
        {...this.props}
      >
        {this.locationTypes.map(item => (
          <Option key={item._id} value={item.code}>
            {item.name}
          </Option>
        ))}
      </Select>
    );
  }
}

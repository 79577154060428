import { Checkbox, Divider, Drawer, Form, Input, InputNumber, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ErrorNoti, Loading, SuccessNoti, TableItem } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_PROPOSED_EQUIPMENT_MUTATION,
  PASS_TO_MANAGER_PROPOSED_EQUIPMENT_MUTATION,
  REMOVE_PROPOSED_EQUIPMENT_MUTATION,
  UPDATE_DETAIL_PROPOSED_EQUIPMENT_MUTATION
} from '~graphql/mutations';
import { FIND_MANY_PROPOSED_EQUIPMENT_QUERY } from '~graphql/queries';
import {
  CreateProposedEquipmentMutation,
  CreateProposedEquipmentVariables,
  EquipmentDetailLocationEquipmentDetail,
  FindManyProposedEquipmentQuery,
  FindManyProposedEquipmentVariables,
  FullProposedEquipmentFragment,
  PassToManagerProposedEquipmentMutation,
  PassToManagerProposedEquipmentVariables,
  RemoveProposedEquipmentMutation,
  RemoveProposedEquipmentVariables,
  UpdateDetailProposedEquipmentMutation,
  UpdateDetailProposedEquipmentVariables
} from '~graphql/types';
import HomeScreenStore from '~screens/HomeScreen/store';
import { sortByName, countLocation } from '~utils';

import { AGREED, CREATED, FULFILLING } from '@mgn/common';

import { columnsAdd, columnsRemove } from './ColLayout';
import {
  CreateEquipmentInput,
  CreateFooter,
  CreateFormTypeAndReasonInput,
  CreateMaterialDetail
} from './CreateInputAndTable';
import RemoveSerials from './RemoveSerials';

export enum TypeEnum {
  AddEquipment = 'ADD_EQUIPMENT',
  ReplaceEquipment = 'REPLACE_EQUIPMENT',
  RemoveEquipment = 'REMOVE_EQUIPMENT',
}

interface IProps extends FormComponentProps, SelectProps {
  visible: boolean;
  toggleUpdateDetail: () => void;
  toggleDrawer: () => void;
  locationId: string;
  equipmentDetail: EquipmentDetailLocationEquipmentDetail[];
}

interface IState {
  currentForm: FullProposedEquipmentFragment | null;
  alreadyProposedEquipmentModelId: string[];
  prevFormNotCompleted: boolean;
  currentFormType?: TypeEnum;
  isFormEmpty: boolean;
  firstTimePropose: boolean;
  rmbsoq: boolean;
  loading: boolean;
}

@observer
class ProposedEquipmentDrawer extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      currentForm: null,
      isFormEmpty: true,
      prevFormNotCompleted: false,
      alreadyProposedEquipmentModelId: [],
      firstTimePropose: true,
      rmbsoq: true,
      loading: true,
    };
  }

  public async componentWillMount() {
    const firstTimePropose = isEmpty(this.props.equipmentDetail);
    await this.preparingData();
    this.removeFormHasSerials();
    await this.setState({
      firstTimePropose,
      currentFormType: firstTimePropose
        ? TypeEnum.AddEquipment
        : TypeEnum.ReplaceEquipment,
    });
  }

  public removeFormHasSerials = () => {
    this.setState({
      rmbsoq: this.state.currentForm
        ? this.state.currentForm.equipmentDetail.some(
            item => !isEmpty(item.serials)
          )
        : true,
    });
  };

  public async preparingData() {
    const {
      loading,
      data: { findManyProposedEquipment },
    } = await client.query<
      FindManyProposedEquipmentQuery,
      FindManyProposedEquipmentVariables
    >({
      query: FIND_MANY_PROPOSED_EQUIPMENT_QUERY,
      variables: {
        where: {
          _operators: {
            locationId: { in: [this.props.locationId] },
            status: { in: [FULFILLING, AGREED, CREATED] },
          },
        },
      },
      fetchPolicy: 'network-only',
    });
    this.setState({ loading });

    const currentPropose = findManyProposedEquipment[0] || null;
    const currentStatus = get(currentPropose, 'status', null);

    if (currentStatus === FULFILLING || currentStatus === AGREED) {
      this.setState({ prevFormNotCompleted: true });
    } else if (currentStatus === CREATED) {
      this.setState({
        currentForm: currentPropose,
        alreadyProposedEquipmentModelId: currentPropose.equipmentDetail.map(
          object => (object.quantity > 0 ? object.equipmentModelId : null)
        ),
        isFormEmpty: !currentPropose.equipmentDetail.some(e =>
          Boolean(e.quantity)
        ),
      });
    }
  }

  public updateProposedEquipment = async (
    equipmentDetail,
    dataOnTable,
    proposalReason,
    materialDetail
  ) => {
    const {
      data: { updateDetailProposedEquipment },
    } = await client.mutate<
      UpdateDetailProposedEquipmentMutation,
      UpdateDetailProposedEquipmentVariables
    >({
      mutation: UPDATE_DETAIL_PROPOSED_EQUIPMENT_MUTATION,
      variables: {
        proposalReason,
        materialDetail,
        _id: this.state.currentForm._id,
        type: this.state.currentFormType,
        equipmentDetail: equipmentDetail.concat(dataOnTable),
      },
    });

    const { error, proposedEquipment } = updateDetailProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật');
      this.setState({
        currentForm: proposedEquipment,
        alreadyProposedEquipmentModelId: proposedEquipment.equipmentDetail.map(
          object => (object.quantity > 0 ? object.equipmentModelId : null)
        ),
      });
    }
  };

  public createProposedEquipment = async (
    equipmentDetail,
    dataOnTable,
    proposalReason,
    materialDetail
  ) => {
    const {
      data: { createProposedEquipment },
    } = await client.mutate<
      CreateProposedEquipmentMutation,
      CreateProposedEquipmentVariables
    >({
      mutation: CREATE_PROPOSED_EQUIPMENT_MUTATION,
      variables: {
        proposalReason,
        materialDetail,
        locationId: this.props.locationId,
        type: this.state.currentFormType,
        equipmentDetail: !isEmpty(dataOnTable)
          ? !isEmpty(equipmentDetail)
            ? dataOnTable.concat(equipmentDetail)
            : dataOnTable
          : equipmentDetail,
      },
    });

    const { error, proposedEquipment } = createProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo đề xuất thiết bị mới');
      this.setState({
        currentForm: proposedEquipment,
        alreadyProposedEquipmentModelId: proposedEquipment.equipmentDetail.map(
          object => (object.quantity > 0 ? object.equipmentModelId : null)
        ),
      });
    }
  };

  public handleOK = e => {
    e.preventDefault();
    const { currentForm, rmbsoq } = this.state;
    const { form } = this.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const equipmentDetail = [];
      const dataOnTable = [];
      const quantity = values.quantity ? values.quantity : null;
      if (values.equipment && quantity >= 1) {
        equipmentDetail.push({
          equipmentModelId: values.equipment,
          quantity: values.quantity,
          note: values.note,
        });
      }

      const handleFormData = type => {
        if (values[type] === undefined) {
          values[type] = {};
        }
        Object.keys(values[type]).forEach((v, i) => {
          if (
            values[type][v].quantity > 0 ||
            !isEmpty(values[type][v].serials)
          ) {
            dataOnTable.push({
              quantity:
                type === 'addQuantity'
                  ? values[type][v].quantity
                  : rmbsoq
                  ? values[type][v].serials.length * -1
                  : values[type][v].quantity * -1,
              equipmentModelId: Object.keys(values[type])[i],
              note: values[type][v].note,
              serials: values[type][v].serials || [],
            });
          }
        });
      };
      handleFormData('addQuantity');
      handleFormData('removeQuantity');

      if (currentForm) {
        await this.updateProposedEquipment(
          equipmentDetail,
          dataOnTable,
          values.proposalReason,
          values.materialDetail
        );
      } else {
        await this.createProposedEquipment(
          equipmentDetail,
          dataOnTable,
          values.proposalReason,
          values.materialDetail
        );
      }
      this.setState({
        isFormEmpty: !this.state.currentForm.equipmentDetail.some(ed =>
          Boolean(ed.quantity)
        ),
      });
      form.resetFields();
    });
  };

  public handlePassToManager = async () => {
    const {
      data: { passToManagerProposedEquipment },
    } = await client.mutate<
      PassToManagerProposedEquipmentMutation,
      PassToManagerProposedEquipmentVariables
    >({
      mutation: PASS_TO_MANAGER_PROPOSED_EQUIPMENT_MUTATION,
      variables: { _id: this.state.currentForm._id },
    });

    const { error } = passToManagerProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Giao quản lý');
      HomeScreenStore.update();
      await countLocation(HomeScreenStore.whereFilter).then(values => {
        HomeScreenStore.setCount(values);
      });
      this.props.toggleDrawer();
      this.props.toggleUpdateDetail();
    }
  };

  public handleDelete = async _id => {
    const {
      data: { removeProposedEquipment },
    } = await client.mutate<
      RemoveProposedEquipmentMutation,
      RemoveProposedEquipmentVariables
    >({
      mutation: REMOVE_PROPOSED_EQUIPMENT_MUTATION,
      variables: { _id },
      errorPolicy: 'all',
    });

    const { error } = removeProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Xóa đề xuất');
      await this.setState({
        currentForm: null,
        isFormEmpty: true,
        alreadyProposedEquipmentModelId: [],
        rmbsoq: true,
      });

      this.props.form.resetFields();
      await this.preparingData();
    }
  };

  public getAddTableData = () => {
    const {
      equipmentDetail,
      form: { getFieldDecorator },
    } = this.props;
    const { currentForm } = this.state;

    const getOldQuantity = equipmentModelId =>
      get(
        equipmentDetail.find(
          element => element.equipmentModelId === equipmentModelId
        ),
        'quantity',
        0
      );
    const arr = [];
    if (currentForm) {
      currentForm.equipmentDetail.sort(sortByName).forEach((item, index) => {
        if (item.quantity > 0) {
          arr.push({
            index,
            _id: item.equipmentModelId,
            key: item.equipmentModelId,
            name: item.equipmentModel.name,
            catName: item.equipmentModel.equipmentCategory.name,
            oldQuantity: getOldQuantity(item.equipmentModelId),
            newQuantity: (
              <Form>
                {getFieldDecorator(
                  `addQuantity[${item.equipmentModelId}][quantity]`,
                  { initialValue: item.quantity }
                )(<InputNumber style={{ border: 'none' }} min={0} step={1} />)}
              </Form>
            ),
            note: (
              <Form>
                {getFieldDecorator(
                  `addQuantity[${item.equipmentModelId}][note]`,
                  {
                    initialValue: item.note,
                  }
                )(<Input style={{ border: 'none' }} placeholder='ghi chú' />)}
              </Form>
            ),
          });
        }
      });
    }
    return arr;
  };

  public getRemoveTableData = () => {
    const { equipmentDetail, locationId, form } = this.props;
    const { getFieldDecorator } = form;
    const { currentForm, rmbsoq } = this.state;
    const getNewForm = equipmentModelId => {
      const equipment = currentForm
        ? currentForm.equipmentDetail.find(
            element =>
              element.equipmentModelId === equipmentModelId &&
              element.quantity < 0
          )
        : null;
      return equipment
        ? { qty: equipment.quantity * -1, note: equipment.note }
        : { qty: 0, note: '' };
    };
    const arr = [];
    equipmentDetail.sort(sortByName).forEach((item, index) => {
      const newForm = getNewForm(item.equipmentModelId);
      arr.push({
        index,
        _id: item.equipmentModelId,
        key: item.equipmentModelId,
        name: item.equipmentModel.name,
        catName: item.equipmentModel.equipmentCategory.name,
        quantity: `${newForm.qty} / ${item.quantity}`,
        serialsOrNewQty: rmbsoq ? (
          <Form>
            <RemoveSerials
              form={form}
              formField={`removeQuantity[${item.equipmentModelId}][serials]`}
              equipmentModelId={item.equipmentModelId}
              locationId={locationId}
              currentForm={currentForm}
            />
          </Form>
        ) : (
          <Form>
            {getFieldDecorator(
              `removeQuantity[${item.equipmentModelId}][quantity]`,
              { initialValue: newForm.qty }
            )(
              <InputNumber
                style={{ border: 'none' }}
                min={0}
                step={1}
                max={item.quantity}
              />
            )}
          </Form>
        ),
        note: (
          <Form>
            {getFieldDecorator(
              `removeQuantity[${item.equipmentModelId}][note]`,
              {
                initialValue: newForm.note,
              }
            )(<Input style={{ border: 'none' }} placeholder='ghi chú' />)}
          </Form>
        ),
      });
    });
    return arr;
  };

  public removeBySerialOrQuantity = () => {
    this.setState({ rmbsoq: !this.state.rmbsoq });
  };

  public createEquipmentInputAndTable = () => {
    const { currentFormType, prevFormNotCompleted, rmbsoq } = this.state;
    return (
      <div>
        {!prevFormNotCompleted && (
          <CreateEquipmentInput
            form={this.props.form}
            handleOK={this.handleOK}
            alreadyProposedEquipmentModelId={
              this.state.alreadyProposedEquipmentModelId
            }
          />
        )}
        {!prevFormNotCompleted && (
          <TableItem
            title={() => <b>Bảng thiết bị xuất thêm</b>}
            columns={columnsAdd}
            dataSource={this.getAddTableData()}
            pagination={false}
            style={{ marginTop: 8 }}
          />
        )}
        {!prevFormNotCompleted &&
          currentFormType === TypeEnum.ReplaceEquipment && (
            <TableItem
              title={() => (
                <Row
                  type='flex'
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <b>Bảng thiết bị thu hồi</b>
                  {currentFormType === TypeEnum.ReplaceEquipment && (
                    <Checkbox
                      checked={rmbsoq}
                      onChange={this.removeBySerialOrQuantity}
                      style={{ float: 'right' }}
                    >
                      Nhập serials thu hồi
                    </Checkbox>
                  )}
                </Row>
              )}
              columns={columnsRemove}
              dataSource={this.getRemoveTableData()}
              pagination={false}
            />
          )}
      </div>
    );
  };

  public render() {
    const {
      toggleDrawer: closeProposedEquipment,
      visible: proposedEquipmentVisible,
      form,
    } = this.props;
    const {
      prevFormNotCompleted,
      currentForm,
      currentFormType,
      isFormEmpty,
    } = this.state;
    return (
      <Drawer
        closable={false}
        onClose={closeProposedEquipment}
        visible={proposedEquipmentVisible}
      >
        {this.state.loading ? (
          <Loading>Đang tải dữ liệu ...</Loading>
        ) : (
          <div style={{ marginBottom: '3em' }}>
            <CreateFormTypeAndReasonInput
              form={form}
              currentFormType={currentFormType}
              currentForm={currentForm}
              prevFormNotCompleted={prevFormNotCompleted}
            />

            <Divider orientation='left' dashed={true}>
              Vật tư
            </Divider>

            <CreateMaterialDetail
              form={form}
              currentForm={currentForm}
              prevFormNotCompleted={prevFormNotCompleted}
            />

            <Divider orientation='left' dashed={true}>
              Thiết bị
            </Divider>

            {this.createEquipmentInputAndTable()}
          </div>
        )}
        <CreateFooter
          closeProposedEquipment={closeProposedEquipment}
          currentFormType={currentFormType}
          currentForm={currentForm}
          isFormEmpty={isFormEmpty}
          handleOK={this.handleOK}
          prevFormNotCompleted={prevFormNotCompleted}
          handleDelete={this.handleDelete}
          handlePassToManager={this.handlePassToManager}
        />
      </Drawer>
    );
  }
}

export default Form.create()(ProposedEquipmentDrawer);

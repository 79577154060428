import { Row } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { LocationInfo } from '~components/UI';
import Panda from '~components/UI/Icon/panda';
import { NotFoundScreen } from '~screens/NotFound';
import {
  EquipmentDetailLocation,
  GoodsIssueDetailLocation,
  GoodsReceiptDetailLocation
} from '~screens/Routes';
import { formatPrice } from '~utils';

import store from './store';

const tableStyle = css({
  display: 'table',
  '> div': {
    display: 'table-row',
  },
  '> div > div': {
    display: 'table-cell',
    padding: '8px',
  },
});

@observer
class EquipmentInfo extends Component {
  public render() {
    const { equipment } = store;
    return get(equipment, 'serial') ? (
      <Row style={{ margin: '1em auto' }}>
        <div className={tableStyle} style={{ fontSize: '14px' }}>
          <div>
            <div>Serial:</div>
            <div>
              <b>
                {EquipmentDetailLocation.toLink(get(equipment, 'serial'), {
                  serial: get(equipment, 'serial'),
                })}
              </b>
            </div>
          </div>
          <div>
            <div>Mẫu thiết bị:</div>
            <div>
              <b>{get(equipment, 'equipmentModel.name', '')}</b>
            </div>
          </div>
          <div>
            <div>Loại thiết bị:</div>
            <div>
              <b>{get(equipment, 'equipmentCategory.name', '')}</b>
            </div>
          </div>
          <div>
            <div>Nhà cung cấp:</div>
            <div>
              <b>{get(equipment, 'supplier.name', '')}</b>
            </div>
          </div>
          <div>
            <div>Địa điểm:</div>
            <div>
              <b>{<LocationInfo location={equipment.location} />}</b>
            </div>
          </div>
          <div>
            <div>Mã phiếu nhập:</div>
            <div>
              <b>
                {get(equipment, 'goodsReceiptCode')
                  ? GoodsReceiptDetailLocation.toLink(
                      equipment.goodsReceiptCode,
                      {
                        code: equipment.goodsReceiptCode,
                      }
                    )
                  : ''}
              </b>
            </div>
          </div>
          <div>
            <div>Mã phiếu xuất:</div>
            <div>
              <b>
                {get(equipment, 'goodsIssueCode')
                  ? GoodsIssueDetailLocation.toLink(equipment.goodsIssueCode, {
                      code: equipment.goodsIssueCode,
                    })
                  : ''}
              </b>
            </div>
          </div>
          <div>
            <div>Đơn giá (VNĐ):</div>
            <div>
              <b>{formatPrice(get(equipment, 'unitPrice', ''))}</b>
            </div>
          </div>
          <div>
            <div>Bảo hành (T):</div>
            <div>
              <b>{get(equipment, 'warranty', '')}</b>
            </div>
          </div>
          <div>
            <div>Trạng thái:</div>
            <div>
              <b>{get(equipment, 'status', '')}</b>
            </div>
          </div>
          <div>
            <div>Ghi chú:</div>
            <div>
              <b>{get(equipment, 'note', '')}</b>
            </div>
          </div>
        </div>
      </Row>
    ) : (
      <div style={{ textAlign: 'center', margin: '1em auto' }}>
        <Panda fontSize='128px' />
      </div>
    );
  }
}

export default EquipmentInfo;

import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { Button, Col, Icon, Modal, Row, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import XLSX from 'xlsx';

import EquipmentTable from '~components/Equipment/EquipmentTable';
import SearchEquipment from '~components/SearchEquipment/SearchEquipment';
import { ErrorNoti, UIButton } from '~components/UI';

import excelTemplateFile from './GoodsIssueExcelTemplate.xlsx';
import { GridPreTempActionCellRenderer } from './GridPreTempActionCellRenderer';
import { GridPreTempSerialCellRenderer } from './GridPreTempSerialCellRenderer';
import store from './store';

@observer
export default class FormEquipmentTemp extends Component {
  private actionColDef: ColDef = {
    colId: 'action',
    field: 'serial',
    headerName: '',
    minWidth: 60,
    maxWidth: 60,
    pinned: 'left',
    cellRendererFramework: GridPreTempActionCellRenderer,
  };

  public handleFile = async (file: UploadFile) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    return new Promise(resolve => {
      reader.onload = e => {
        const bstr = (e.target as any).result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        return resolve(
          data
            .slice(1)
            .map(([serial]) => serial)
            .filter(Boolean)
        );
      };
      if (rABS) reader.readAsBinaryString(file.originFileObj);
      else reader.readAsArrayBuffer(file.originFileObj);
    });
  };

  public handleBeforeUpload = () => false;

  public handleFileChange = async (info: UploadChangeParam) => {
    const serials = await Promise.all(info.fileList.map(this.handleFile))
      .then(allData => {
        return Array.prototype.concat([], ...allData);
      })
      .catch(err => {
        console.error(err);
        ErrorNoti('Không đọc được file excel');
      });

    if (!serials || !serials.length) return;

    const { handleAddPreTemps } = store;
    handleAddPreTemps(...serials);
  };

  public handleGridReady = (event: GridReadyEvent) => {
    const serialCol = event.api.getColumnDef('serial');
    serialCol.cellRendererFramework = GridPreTempSerialCellRenderer;
  };

  public render() {
    const {
      selectedItem,
      preTempVisible: formVisible,
      toggleForm,
      equipmentPreTemps,
      handleAddPreTemps,
      handleCreateEquipmentTemps,
    } = store;

    const footer = (
      <Row>
        <Button
          icon='plus'
          type='primary'
          disabled={!equipmentPreTemps.length}
          onClick={handleCreateEquipmentTemps}
        >
          Tạo thiết bị
        </Button>

        <Button
          type='danger'
          style={{ marginLeft: 8 }}
          onClick={() => toggleForm()}
        >
          Đóng
        </Button>
      </Row>
    );

    return (
      <Modal closable={false} visible={formVisible} footer={footer}>
        <SearchEquipment
          locationId={selectedItem.fromLocationId}
          handleSelect={handleAddPreTemps}
        />

        <div style={{ marginTop: 8 }}>
          <Upload
            fileList={[]}
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            beforeUpload={this.handleBeforeUpload}
            onChange={this.handleFileChange}
          >
            <UIButton type='info' icon='file-excel'>
              Excel
            </UIButton>{' '}
            hoặc{' '}
            <a href={excelTemplateFile} target='_blank'>
              <Icon type='download' style={{ marginRight: 8 }} />
              Tải file excel mẫu
            </a>
          </Upload>
        </div>

        <div style={{ height: 250, marginTop: 8 }}>
          <EquipmentTable
            data={equipmentPreTemps}
            title={null}
            actionColDef={this.actionColDef}
            gridOptions={{ onGridReady: this.handleGridReady }}
          />
        </div>
      </Modal>
    );
  }
}

export { FormEquipmentTemp };

import { Button, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Query } from 'react-apollo';

import { FIND_MANY_EQUIPMENT_QUERY } from '~graphql/queries';
import {
  FindManyEquipmentQuery,
  FindManyEquipmentVariables,
} from '~graphql/types';

import EquipmentTable from '../Equipment/EquipmentTable';

interface IProps {
  locationId: string;
  visible: boolean;
  toggleModal: (filter?) => void;
  categoryIdFilter?: string;
  modelIdFilter?: string;
}

@observer
class EquipmentModal extends Component<IProps> {
  public render() {
    const {
      locationId,
      categoryIdFilter,
      modelIdFilter,
      visible,
      toggleModal,
    } = this.props;

    const footer = (
      <Row>
        <Button type='danger' onClick={() => toggleModal()}>
          Đóng
        </Button>
      </Row>
    );

    return (
      <Modal
        className='biggerModal'
        visible={visible}
        closable={false}
        footer={footer}
        onCancel={toggleModal}
      >
        <Query<FindManyEquipmentQuery, FindManyEquipmentVariables>
          query={FIND_MANY_EQUIPMENT_QUERY}
          variables={{ where: { locationId } }}
          fetchPolicy='cache-and-network'
        >
          {({ error, data: { findManyEquipment } }) => {
            if (error) return `ERROR: ${error.message}`;
            return (
              <div style={{ height: 300 }}>
                <EquipmentTable
                  data={findManyEquipment}
                  categoryId={categoryIdFilter}
                  modelId={modelIdFilter}
                />
              </div>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

export default EquipmentModal;

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { LocationTableServer } from '~components/Location/LocationTableServer';
import { Contact, EmployeeInfo, LocationInfo } from '~components/UI';
import { GridCellStatusRenderer } from '~components/UI/AgGridTable/CellStatusRenderer';
import { LocationBaseFilter } from '~graphql/types';

import { GridCellActionRenderer } from './GridCellActionRenderer';
import store from './store';
import { GridCellDateRenderer } from '~components/UI/AgGridTable/CellDateRenderer';

interface IProps {
  whereFilter: LocationBaseFilter;
  reset?: boolean;
}

@observer
class HomeScreenTable extends Component<IProps> {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      maxWidth: 60,
      pinned: 'left',
      minWidth: 60,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      headerName: 'Tên địa điểm',
      pinned: 'left',
      headerClass: 'center',
      field: 'name',
      minWidth: 380,
      cellRendererFramework: ({ data }) => <LocationInfo location={data} />,
    },
    {
      headerName: 'Xem thông tin chi tiết',
      headerClass: 'center',
      children: [
        {
          headerName: 'Liên hệ',
          field: 'contact',
          headerClass: 'center',
          cellRendererFramework: ({ value }) => <Contact contact={value} />,
          suppressSorting: true,
          minWidth: 250,
        },
        {
          headerName: 'NV khảo sát',
          headerClass: 'center',
          cellStyle: { 'text-align': 'center' },
          columnGroupShow: 'open',
          colId: 'surveyorId',
          field: 'surveyor',
          minWidth: 60,
          cellRendererFramework: ({ value }) => (
            <EmployeeInfo employee={value} />
          ),
        },
        {
          headerName: 'NV thi công',
          colId: 'installerId',
          headerClass: 'center',
          field: 'installer',
          cellStyle: { 'text-align': 'center' },
          minWidth: 60,
          columnGroupShow: 'open',
          cellRendererFramework: ({ value }) => (
            <EmployeeInfo employee={value} />
          ),
        },
        {
          headerName: 'NV vận hành',
          headerClass: 'center',
          cellStyle: { 'text-align': 'center' },
          colId: 'maintainerId',
          field: 'maintainer',
          columnGroupShow: 'open',
          minWidth: 60,
          cellRendererFramework: ({ value }) => (
            <EmployeeInfo employee={value} />
          ),
        },
      ],
    },
    {
      headerName: 'Ngày tạo',
      cellStyle: { 'text-align': 'center' },
      headerClass: 'center',
      columnGroupShow: 'open',
      field: 'createdAt',
      minWidth: 60,
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Ngày cập nhật',
      cellStyle: { 'text-align': 'center' },
      headerClass: 'center',
      columnGroupShow: 'open',
      field: 'updatedAt',
      minWidth: 60,
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Trạng thái',
      cellStyle: { 'text-align': 'center' },
      headerClass: 'center',
      field: 'status',
      minWidth: 60,
      cellRendererFramework: GridCellStatusRenderer,
    },
  ];
  public reload = () => undefined;
  public componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.whereFilter) !==
      JSON.stringify(nextProps.whereFilter)
    ) {
      this.reload();
    }
  }

  public render() {
    const { handleGridReady } = store;
    const { whereFilter, reset } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <LocationTableServer
          reset={reset}
          columnsDefs={this.columnDefs}
          where={whereFilter}
          onGridReady={handleGridReady}
          colResizeDefault='shift'
        />
      </div>
    );
  }
}

export default HomeScreenTable;

import { Button, Col, Input, Row, Tree } from 'antd';
import classname from 'classname';
import { css } from 'emotion';
import React from 'react';

import { UIButton } from '~components/UI';

const treeNodeStyle = css({
  alignItems: 'baseline',

  '> .treenode--actions, > .treenode--input': {
    display: 'none',
  },

  '> .ant-tree-treenode-selected + .treenode--input + .treenode--actions': {
    display: 'initial',
  },

  '.treenode--input': {
    padding: '4px 0',
  },
});

const treeNodeEditingStyle = css({
  '> .treenode--input, > .treenode--actions': {
    display: 'initial',
  },

  '> li': {
    display: 'none',
  },
});

export default class TreeNode extends Tree.TreeNode {
  public toggleEditing = (editing?: any) => {
    const nextState = {
      editing: typeof editing === 'boolean' ? editing : !this.state.editing,
      title: this.state.title,
    };
    if (nextState.editing) {
      nextState.title = this.props.title
        .toString()
        .split(' ')
        .slice(0, -1)
        .join(' ');
    }

    this.setState(nextState);
  };

  public handleUpdate = () => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(this.state.title, this.props.pos);
    }

    this.toggleEditing(false);
  };

  public handleDelete = () => {
    const { onDelete } = this.props;
    if (typeof onDelete === 'function') {
      onDelete(this.props.pos);
    }
  };

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.target.value });
  };

  public render() {
    const { editing, title } = this.state;

    const className = classname(treeNodeStyle, editing && treeNodeEditingStyle);

    return (
      <Row type='flex' gutter={8} className={className}>
        {super.render.call(this)}
        <Col className='treenode--input' style={{ marginLeft: 30 }}>
          <Input size='small' value={title} onChange={this.handleChange} />
        </Col>
        <Col className='treenode--actions'>
          <Button.Group>
            {editing ? (
              <UIButton
                icon='check'
                type='primary'
                size='small'
                outline='none'
                title='Cập nhật'
                onClick={this.handleUpdate}
              />
            ) : (
              <UIButton
                icon='edit'
                type='primary'
                size='small'
                outline='none'
                title='Sửa'
                onClick={this.toggleEditing}
              />
            )}
            <UIButton
              icon='delete'
              type='danger'
              size='small'
              outline='none'
              title='Xoá'
              onClick={this.handleDelete}
            />
          </Button.Group>
        </Col>
      </Row>
    );
  }
}

import gql from 'graphql-tag';
import employee from '../fragments/employee';

export const FIND_MANY_EMPLOYEE_QUERY = gql`
  query FindManyEmployee(
    $skip: Int
    $take: Int
    $where: EmployeeBaseFilter
    $order: EmployeeBaseSort
  ) {
    findManyEmployee(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullEmployee
    }
  }
  ${employee.full}
`;

import gql from 'graphql-tag';

import equipmentModel from './equipmentModel';

const basic = gql`
  fragment BasicEquipmentDetail on EquipmentDetail {
    equipmentModelId
    quantity
    note
  }
  ${equipmentModel.full}
`;

const full = gql`
  fragment FullEquipmentDetail on EquipmentDetail {
    equipmentModelId
    equipmentModel {
      ...FullEquipmentModel
    }
    quantity
    note
  }
  ${equipmentModel.full}
`;

export default { basic, full };

import { Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import { css } from 'emotion';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { FIND_MANY_EQUIPMENT_QUERY } from '~graphql/queries';
import {
  FindManyEquipmentQuery,
  FindManyEquipmentVariables,
  FullProposedEquipmentFragment,
} from '~graphql/types';

interface IProps extends SelectProps, FormComponentProps {
  formField: string;
  locationId: string;
  currentForm: FullProposedEquipmentFragment | null;
  equipmentModelId: string;
}

const selectBorder = css`
  & > div {
    border: none;
  }
`;
export default class RemoveSerials extends Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      form: { getFieldDecorator },
      formField,
      locationId,
      equipmentModelId,
      currentForm,
    } = this.props;
    return (
      <Query<FindManyEquipmentQuery, FindManyEquipmentVariables>
        query={FIND_MANY_EQUIPMENT_QUERY}
        variables={{ where: { locationId } }}
        fetchPolicy='cache-and-network'
      >
        {({ error, data: { findManyEquipment } }) => {
          if (error) return `ERROR: ${error.message}`;
          if (!findManyEquipment) {
            return <h3>Đang tải...</h3>;
          }
          let serials = [];
          if (currentForm) {
            serials = get(
              currentForm.equipmentDetail.filter(
                item =>
                  item.equipmentModelId === equipmentModelId &&
                  item.quantity < 0
              )[0],
              'serials'
            );
          }
          return getFieldDecorator(formField, {
            initialValue: serials,
          })(
            <Select
              className={selectBorder}
              mode='multiple'
              allowClear={true}
              placeholder='Chọn thiết bị'
              style={{ width: '12em' }}
            >
              {findManyEquipment
                .filter(item => item.equipmentModelId === equipmentModelId)
                .map(item => {
                  return (
                    <Select.Option key={item.serial}>
                      {item.serial}
                    </Select.Option>
                  );
                })}
            </Select>
          );
        }}
      </Query>
    );
  }
}

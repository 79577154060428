import React from 'react';
import { Badge } from 'antd';

const TabName = ({ title, count = null }) => (
  <div>
    {title} <Badge count={count} />
  </div>
);

export default TabName;

import Bluebird from 'bluebird';

type ConditionType<T> = (value: T) => boolean;
type ActionType<T> = (value: T) => T | Promise<T>;

export const promiseWhile: <T>(
  condition: ConditionType<T>,
  action: ActionType<T>,
  initialValue: T
) => Bluebird<T> = Bluebird.method((condition, action, initValue) =>
  action(initValue).then(value => {
    if (!condition(value)) return value;
    return promiseWhile(condition, action, value);
  })
);

import gql from 'graphql-tag';
import goodsReceipt from '../fragments/goodsReceipt';

export const UPDATE_GOODS_RECEIPT_MUTATION = gql`
  mutation UpdateGoodsReceiptByCode(
    $code: String!
    $record: UpdateGoodsReceiptInput!
  ) {
    updateGoodsReceiptByCode(code: $code, record: $record) {
      goodsReceipt {
        ...FullGoodsReceipt
      }
      error {
        message
      }
    }
  }
  ${goodsReceipt.full}
`;

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import client from '~graphql/client';
import { FIND_MANY_EQUIPMENT_CATEGORY_QUERY } from '~graphql/queries';
import {
  FindManyEquipmentCategoryQuery,
  FindManyEquipmentCategoryVariables,
  FullEquipmentCategoryFragment,
} from '~graphql/types';

const { Option } = Select;

@observer
export default class EquipmentCategoryDropdown extends Component<SelectProps> {
  @observable public loading: boolean;
  @observable public data: FullEquipmentCategoryFragment[];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = true;
      this.data = [];
    });
  }

  public componentDidMount() {
    this.loadData().then(
      action('setLoading', () => {
        this.loading = false;
      })
    );
  }

  @action public loadData = async () => {
    this.loading = true;

    const {
      data: { findManyEquipmentCategory },
    } = await client.query<
      FindManyEquipmentCategoryQuery,
      FindManyEquipmentCategoryVariables
    >({
      query: FIND_MANY_EQUIPMENT_CATEGORY_QUERY,
      fetchPolicy: 'cache-first',
    });

    runInAction(() => {
      this.data = findManyEquipmentCategory || [];
    });
  };

  public render() {
    return (
      <Select
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        allowClear={true}
        style={{ width: '100%' }}
        {...this.props}
        loading={this.loading}
        placeholder='Chọn loại thiết bị'
      >
        {this.data.map((e, index) => {
          return (
            <Option key={String(index)} value={e._id}>
              {e.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { get, uniqBy } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import client from '~graphql/client';
import { FIND_MANY_ROLE_MAPPING_QUERY } from '~graphql/queries';
import {
  FindManyRoleMappingQuery,
  FindManyRoleMappingVariables,
  FullEmployeeFragment,
  FullPermissionFragment,
  FullRoleMappingFragment,
} from '~graphql/types';

import { CREATED } from '@mgn/common';

const { Option } = Select;

interface IProps extends SelectProps {
  permission?: string;
}

export const findByPermissions = (
  roleMapping: FullRoleMappingFragment,
  requiredPermission: string
) =>
  get(roleMapping, 'role.permissions').some(
    (p: FullPermissionFragment) => p.name === requiredPermission
  );

export type EmployeeDropdownProps = IProps;

@observer
export default class EmployeeDropdown extends Component<IProps> {
  @observable private loading: boolean;
  @observable.shallow private data: FullRoleMappingFragment[];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = true;
      this.data = [];
    });
  }

  @computed public get employees(): FullEmployeeFragment[] {
    const { permission } = this.props;

    const filteredEmployee = uniqBy(
      this.data.filter(roleMapping => {
        if (!permission) return true;
        return (
          findByPermissions(roleMapping, permission) &&
          get(roleMapping, 'employee.status') === CREATED
        );
      }),
      'employeeId'
    )
      .map(item => item.employee)
      .filter(Boolean);
    return filteredEmployee;
  }

  public componentDidMount() {
    this.fetchEmployee().finally(
      action(() => {
        this.loading = false;
      })
    );
  }

  public render() {
    const { permission, ...props } = this.props;

    return (
      <Select
        {...props}
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        loading={this.loading}
        notFoundContent='Không tìm thấy!'
      >
        {this.employees.map(e => (
          <Option key={e._id} value={e._id}>
            {e.fullName}
          </Option>
        ))}
      </Select>
    );
  }

  @action private fetchEmployee = async () => {
    const {
      data: { findManyRoleMapping },
    } = await client.query<
      FindManyRoleMappingQuery,
      FindManyRoleMappingVariables
    >({
      query: FIND_MANY_ROLE_MAPPING_QUERY,
    });

    runInAction(() => {
      this.data = findManyRoleMapping.filter(
        item => item.roleId !== 'SUPERADMIN'
      );
    });
  };
}

import gql from 'graphql-tag';
import employee from './employee';
import role from './role';
import location from './location';

export const basic = gql`
  fragment BasicRoleMapping on RoleMapping {
    _id
    employeeId
    roleId
    locationId
    createdAt
    updatedAt
  }
`;

export const full = gql`
  fragment FullRoleMapping on RoleMapping {
    ...BasicRoleMapping
    employee {
      ...FullEmployee
    }
    role {
      ...FullRole
    }
    location {
      ...BasicLocation
    }
  }
  ${basic}
  ${employee.full}
  ${role.full}
  ${location.basic}
`;

export default { basic, full };

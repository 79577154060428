import gql from 'graphql-tag';
import equipmentTemp from '../fragments/equipmentTemp';

export const ADD_EQUIPMENT_TEMP_GOODS_ISSUE_MUTATION = gql`
  mutation AddEquipmentTempGoodsIssue(
    $goodsIssueCode: String!
    $equipmentTemps: [EquipmentInputType!]!
  ) {
    addEquipmentTempGoodsIssue(
      goodsIssueCode: $goodsIssueCode
      equipmentTemps: $equipmentTemps
    ) {
      error {
        message
      }
      equipmentTemps {
        ...FullEquipmentTemp
      }
      invalidEquipmentTemps {
        serial
        message
      }
    }
  }
  ${equipmentTemp.full}
`;

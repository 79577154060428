import gql from 'graphql-tag';
import goodsReceipt from '../fragments/goodsReceipt';

export const REMOVE_GOODS_RECEIPT_MUTATION = gql`
  mutation RemoveGoodsReceiptByCode($code: String!) {
    removeGoodsReceiptByCode(code: $code) {
      goodsReceipt {
        ...BasicGoodsReceipt
      }
      error {
        message
      }
    }
  }
  ${goodsReceipt.basic}
`;

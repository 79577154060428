import client from '~graphql/client';
import { COUNT_PROPOSEDEQUIPMENT_QUERY } from '~graphql/queries';
import { CountProposedEquipmentQuery } from '~graphql/types';

export const countProposed = async status => {
  const {
    data: { countProposedEquipment },
  } = await client.query<CountProposedEquipmentQuery>({
    query: COUNT_PROPOSEDEQUIPMENT_QUERY,
    variables: { where: { status } },
    fetchPolicy: 'network-only',
  });
  return countProposedEquipment;
};

import { Popover, Table, Tag } from 'antd';
import { get, uniq } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { EmployeeInfo, UIButton } from '~components/UI';

import ExpandedRow from './GridExpandedRow';
import store from './MenuStore';

@observer
class PermissionTable extends Component {
  private columns = [
    {
      title: '',
      dataIndex: '_id',
      width: 50,
      render: (_value, record) => (
        <UIButton
          size='small'
          type='primary'
          icon='edit'
          onClick={() => store.toggleModal(record)}
        />
      ),
    },
    {
      title: 'Nhân viên',
      width: 200,
      dataIndex: 'employee.fullName',
      sorter: (a, b) => a.employee.fullName.localeCompare(b.employee.fullName),
      render: (_value, record) => (
        <EmployeeInfo employee={get(record, 'employee')} />
      ),
    },
    {
      title: 'Danh mục',
      width: 200,
      dataIndex: 'menu.name',
    },
    {
      title: 'Quyền',
      dataIndex: 'role',
      render: record =>
        record &&
        uniq(record).map((item, index) => {
          return (
            <Popover
              key={index}
              title={null}
              content={get(item, 'description')}
            >
              <Tag>{get(item, 'display')}</Tag>
            </Popover>
          );
        }),
    },
  ];

  public render() {
    const { menuAndRoleMappingErrors, loading, menuAndRoleMapping } = store;

    return (
      <Fragment>
        <h2 style={{ marginTop: 8 }}>Danh sách quyền hạn</h2>
        <Table
          size='small'
          loading={loading}
          locale={{
            emptyText: menuAndRoleMappingErrors
              ? `Error!: ${menuAndRoleMappingErrors}`
              : 'Chưa có dữ liệu',
          }}
          expandedRowRender={record => (
            <ExpandedRow
              employeeId={record.employeeId}
              roleId={record.roleId}
            />
          )}
          columns={this.columns}
          rowKey={(_record, index) => String(index)}
          dataSource={menuAndRoleMapping}
          pagination={false}
          scroll={{ y: 'calc(100vh - 100px)' }}
        />
      </Fragment>
    );
  }
}

export default PermissionTable;

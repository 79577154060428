import supplier from '../fragments/supplier';
import gql from 'graphql-tag';

export const UPDATE_SUPPLIER_BY_ID_MUTATION = gql`
  mutation UpdateSupplierById($_id: ID!, $record: SupplierInput!) {
    updateSupplierById(_id: $_id, record: $record) {
      payload {
        ...FullSupplier
      }
      error {
        message
      }
    }
  }
  ${supplier.full}
`;

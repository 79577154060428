import React, { Component } from 'react';
import { Formik, FormikProps, Form, Field, FormikActions } from 'formik';
import { Button } from 'antd';
import { UIField } from '~components/UI/UIField';
import store from './store';
import * as Yup from 'yup';

interface IFormValues {
  phone: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  phone: Yup.string()
    .min(10, 'Số điện thoại không hợp lệ')
    .max(11, 'Số điện thoại không hợp lệ')
    .required('Bắt buộc nhập'),
});

export default class UpdatePhoneForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleUpdatePhone } = store;
    const { resetForm, setSubmitting } = actions;

    await handleUpdatePhone(values);

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting } = props;

      return (
        <Form id='updatePhoneFm'>
          <Field
            name='phone'
            component={UIField}
            fieldProps={{ placeholder: 'Nhập số điện thoại' }}
          />
          <Button
            style={{ width: '100%' }}
            htmlType='submit'
            form='updatePhoneFm'
            disabled={!isValid}
            loading={isSubmitting}
            type='primary'
          >
            Cập nhật
          </Button>
        </Form>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

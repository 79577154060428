import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { get, identity, pickBy } from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Query } from "react-apollo";
import { FIND_MANY_SEARCH_LOCATION_QUERY } from "~graphql/queries";
import {
  FindManyBasicLocationQuery,
  FindManyBasicLocationVariables,
  LocationBaseFilter
} from "~graphql/types";
import { acronymArea, acronymDistrict } from "~utils/acronymLocation";

import { IOrderArgs, SortDirection, LOCATION_TYPE } from "@mgn/common";

const { Option } = Select;

interface IProps extends SelectProps {
  status?: string[];
  area?: string;
  district?: string;
  statusFilter?: string;
  locationId?: string;
  typeCode?: string;
  employeeId: string;
}

export type LocationSearchProps = IProps;

@observer
export default class LocationSearch extends Component<IProps> {
  public render() {
    const {
      status,
      district,
      statusFilter,
      area,
      locationId,
      employeeId,
      typeCode,
      placeholder,
      ...props
    } = this.props;

    const object = pickBy(
      { area, district, typeCode, _id: locationId, status: statusFilter },
      identity
    );

    const filter: LocationBaseFilter = {
      locationType: LOCATION_TYPE,
      _operators: {
        status: { in: status }
      }
    };
    filter.AND = [object];

    if (employeeId) {
      filter.OR = [
        { installerId: employeeId },
        { surveyorId: employeeId },
        { maintainerId: employeeId }
      ];
    }
    const order: IOrderArgs = { createdAt: SortDirection.DESC };
    return (
      <Query<FindManyBasicLocationQuery, FindManyBasicLocationVariables>
        query={FIND_MANY_SEARCH_LOCATION_QUERY}
        variables={{
          order,
          where: {
            ...filter
          }
        }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data: { findManyLocation } }) => {
          if (error) return `ERROR: ${error.message}`;

          return (
            <Select
              {...props}
              loading={loading}
              showSearch={true}
              placeholder={placeholder}
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {findManyLocation &&
                findManyLocation.map((location, index) => (
                  <Option key={String(index)} value={location._id}>
                    {get(location, "type.display")} -{" "}
                    {acronymArea(get(location, "area"))}
                    {acronymDistrict(
                      get(location, "area"),
                      get(location, "district")
                    )}
                    {get(location, "name")}
                  </Option>
                ))}
            </Select>
          );
        }}
      </Query>
    );
  }
}

export const layout = {
  largeCol: {
    md: { span: 24, offset: 0 },
    lg: { span: 16, offset: 0 },
  },
  smallCol: {
    md: { span: 24, offset: 0 },
    lg: { span: 6, offset: 0 },
  },
};

export const columnsAdd = [
  {
    title: 'Tên',
    dataIndex: 'name',
  },
  {
    title: 'Loại',
    dataIndex: 'catName',
  },
  {
    title: 'Số lượng',
    children: [
      {
        title: 'Hiện có',
        dataIndex: 'oldQuantity',
      },
      {
        title: 'Xuất thêm',
        dataIndex: 'newQuantity',
      },
    ],
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
  },
];

export const columnsRemove = [
  {
    title: 'Tên',
    dataIndex: 'name',
  },
  {
    title: 'Loại',
    dataIndex: 'catName',
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
  },
  {
    title: 'Thu về',
    dataIndex: 'serialsOrNewQty',
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
  },
];

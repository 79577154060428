import gql from 'graphql-tag';
import equipmentTemp from '../fragments/equipmentTemp';

export const FIND_MANY_EQUIPMENT_TEMP_QUERY = gql`
  query FindManyEquipmentTemp(
    $skip: Int
    $take: Int
    $where: EquipmentTempBaseFilter
    $order: EquipmentTempBaseSort
  ) {
    findManyEquipmentTemp(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullEquipmentTemp
    }
  }
  ${equipmentTemp.full}
`;

import gql from 'graphql-tag';

const full = gql`
  fragment FullEquipmentCategory on EquipmentCategory {
    _id
    name
    description
    createdAt
    updatedAt
  }
`;

export default { full };

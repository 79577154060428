import gql from 'graphql-tag';
import locationType from '../fragments/locationType';

export const REMOVE_LOCATION_TYPE_BY_ID_MUTATION = gql`
  mutation RemoveLocationTypeById($_id: ID!) {
    removeLocationTypeById(_id: $_id) {
      payload {
        ...FullLocationType
      }
      error {
        message
      }
    }
  }
  ${locationType.full}
`;

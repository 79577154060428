import { Modal, Row, Button } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { UIField } from '~components/UI/UIField';
import { SupplierInput } from '~graphql/types';

import store from './store';

interface IFormValues extends SupplierInput {
  _id: string;
}
const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),

  name: Yup.string().required('Bắt buộc nhập'),

  code: Yup.string().required('Bắt buộc nhập'),

  phone: Yup.string()
    .min(10, 'SĐT không hợp lệ')
    .max(11, 'SĐT không hợp lệ')
    .required('Bắt buộc nhập'),

  email: Yup.string().email('Email không hợp lệ'),

  address: Yup.string().required('Bắt buộc nhập'),
});

@observer
class SupplierForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }
    resetForm();
  };

  public render() {
    const { visible, toggleModal, selectedItem } = store;

    const formInitialValues = {
      _id: get(selectedItem, '_id'),
      name: get(selectedItem, 'name'),
      code: get(selectedItem, 'code'),
      phone: get(selectedItem, 'phone'),
      email: get(selectedItem, 'email', ''),
      address: get(selectedItem, 'address'),
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            type='primary'
            htmlType='submit'
            form='supplierFm'
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='supplierFm'>
            <Field
              name='name'
              label='Nhà cung cấp'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập tên NCC' }}
            />
            <Field
              name='code'
              label='Mã'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập mã NCC' }}
            />
            <Field
              name='phone'
              label='Số điện thoại'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập số điện thoại NCC' }}
            />
            <Field
              name='email'
              type='email'
              label='Email '
              component={UIField}
              fieldProps={{ placeholder: 'Nhập email NCC' }}
            />
            <Field
              name='address'
              label='Địa chỉ'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập địa chỉ NCC' }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default SupplierForm;

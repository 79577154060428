import { ICellRendererParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import store from './store';
import {
  WAITING_AGREEMENT,
  AGREED,
  FULFILLED,
  CANCELLED,
  FULFILLING,
  PERMISSION_EMPLOYEE_FREEWIFI_MANAGER,
  PERMISSION_EMPLOYEE_WAREHOUSE_MANAGER
} from '@mgn/common';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { isAuthorized } from '~components/Authorized/Authorized';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const { toggleView } = store;

    if (
      get(data, 'status') === FULFILLING ||
      get(data, 'status') === FULFILLED ||
      get(data, 'status') === CANCELLED
    ) {
      return (
        <Link to={`/proposedEquipment/${get(data, '_id')}`}>
          <Button size='small' type='primary' icon='info' />
        </Link>
      );
    }

    return (
      <Fragment>
        {(get(data, 'status') === WAITING_AGREEMENT ||
          get(data, 'status') === AGREED ||
          isAuthorized(PERMISSION_EMPLOYEE_WAREHOUSE_MANAGER) ||
          isAuthorized(PERMISSION_EMPLOYEE_FREEWIFI_MANAGER)) && (
          <Button
            size='small'
            type='primary'
            onClick={() => toggleView(data)}
            icon='edit'
          />
        )}
      </Fragment>
    );
  }
}

import gql from 'graphql-tag';
import location from './location';
import employee from './employee';
import proposedEquipment from './proposedEquipment';

const basic = gql`
  fragment BasicGoodsIssue on GoodsIssue {
    _id
    code
    fromLocationId
    toLocationId
    proposedEquipmentId
    approverId
    approvedAt
    status
    note
    description
    createdEmployeeId
    createdAt
    updatedAt
    totalQuantity
    totalPrice
  }
`;

const full = gql`
  fragment FullGoodsIssue on GoodsIssue {
    ...BasicGoodsIssue
    fromLocation {
      ...BasicLocation
    }
    toLocation {
      ...BasicLocation
    }
    proposedEquipment {
      ...FullProposedEquipment
    }
    approver {
      ...FullEmployee
    }
    createdEmployee {
      ...FullEmployee
    }
  }
  ${basic}
  ${location.basic}
  ${proposedEquipment.full}
  ${employee.full}
`;

export default { basic, full };

import { UPDATE_PHONE_EMPLOYEE_MUTATION } from './../../graphql/mutations/updatePhoneEmployee';
import { UPDATE_PASSWORD_EMPLOYEE_MUTATION } from '~graphql/mutations/updatePasswordEmployee';
import { action, observable, runInAction } from 'mobx';
import client from '~graphql/client';
import { ErrorNoti, SuccessNoti } from '~components/UI';
import {
  UpdatePasswordEmployeeMutation,
  UpdatePasswordEmployeeVariables,
  UpdatePhoneEmployeeMutation,
  UpdatePhoneEmployeeVariables
} from '~graphql/types';
import authStore from '~stores/authStore';

class UpdateEquipmentStore {
  @observable public type: string;

  constructor() {
    runInAction(() => {
      this.type = null;
    });
  }

  @action public handleSelect = value => {
    this.type = value;
  };

  @action
  public handleUpdatePassword = async record => {
    const {
      data: { updatePasswordEmployee },
    } = await client.mutate<
      UpdatePasswordEmployeeMutation,
      UpdatePasswordEmployeeVariables
    >({
      mutation: UPDATE_PASSWORD_EMPLOYEE_MUTATION,
      variables: record,
    });

    const { error } = updatePasswordEmployee;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Đổi mật khẩu');
      runInAction(() => {
        this.type = null;
      });
    }
  };

  @action
  public handleUpdatePhone = async record => {
    const { data } = await client.mutate<
      UpdatePhoneEmployeeMutation,
      UpdatePhoneEmployeeVariables
    >({
      mutation: UPDATE_PHONE_EMPLOYEE_MUTATION,
      variables: record,
    });

    const { error } = data.updatePhoneEmployee;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Đổi SĐT');
      authStore.updateCurrentEmployee();
      runInAction(() => {
        this.type = null;
      });
    }
  };
}

export default new UpdateEquipmentStore();

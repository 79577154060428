import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { ErrorNoti } from '~components/UI';
import commonStore from '~stores/commonStore';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const authLink = setContext((_, { headers }) => {
  const reqHeaders = {
    ...headers,
  };
  if (commonStore.token) {
    reqHeaders.authorization = commonStore.token;
  }
  return { headers: reqHeaders };
});

const httpLinkWithAuthToken = authLink.concat(httpLink);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );

          ErrorNoti(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        ErrorNoti(`[Network error]:${networkError}`);
      }
    }),
    httpLinkWithAuthToken,
  ]),
  cache: new InMemoryCache({}),
});

(window as any).__APOLLO_CLIENT__ = client;

export default client;

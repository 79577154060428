import { Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Loading } from '~components/UI';

import CardEquipmentHistory from './CardEquipmentHistory';
import EquipmentHistoryTable from './Grid';
import store from './store';

@observer
class EquipmentHistory extends Component<RouteComponentProps> {
  public componentDidMount = async () => {
    const { serial } = this.props.match.params as any;
    store.setSerial(serial);
  };

  public componentWillReceiveProps = async nextProps => {
    const { serial } = this.props.match.params as any;
    if (serial !== nextProps.match.params.serial) {
      store.setSerial(nextProps.match.params.serial);
    }
  };

  public render() {
    const { loading, selectedItem } = store;
    if (loading) return <Loading />;

    return (
      <Row
        type='flex'
        style={{
          flexFlow: 'column',
          height: '100vh',
        }}
      >
        <div style={{ flex: 0 }}>
          <CardEquipmentHistory data={selectedItem} />
        </div>
        <div style={{ flex: 1, marginTop: 8 }}>
          <EquipmentHistoryTable />
        </div>
      </Row>
    );
  }
}

export default EquipmentHistory;

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Button, Col, Input, Popconfirm, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { ChangeEvent, Component, Fragment } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Authorized from '~components/Authorized/Authorized';
import ConfirmModal from '~components/ConfirmModal/ConfirmModal';
import { AgGridTable, Loading, LocationInfo, UIButton } from '~components/UI';
import { GridCellStatusRenderer } from '~components/UI/AgGridTable/CellStatusRenderer';

import {
  AGREED,
  CANCELLED,
  CREATED,
  FULFILLING,
  GOODSRECEIPT,
  PERMISSION_GOODS_ISSUE_CREATE,
  PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_WAREHOUSE,
  PERMISSION_PROPOSED_EQUIPMENT_PASS_TO_FULFILLED,
  WAITING_AGREEMENT,
  WAITING_SURVEYOR,
} from '@mgn/common';

import GoodsReceiptIssueForm from './FormGRI';
import { GridCellActionRenderer } from './GridCellActionRenderer';
import { GridCodeCellRenderer } from './GridCellCodeActionRenderer';
import store from './store';
import { TypeEnum } from '~components/ProposedEquipmentDrawer/ProposedEquipmentDrawer';

@observer
class GoodsReceiptIssueTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 90,
      maxWidth: 90,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      headerName: 'Mã',
      field: 'code',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: GridCodeCellRenderer,
    },
    {
      headerName: 'Loại',
      field: 'type',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: ({ value }) =>
        value === GOODSRECEIPT ? 'Phiếu nhập' : 'Phiếu xuất',
    },
    {
      headerName: 'Kho nguồn',
      field: 'fromLocation',
      minWidth: 100,
      suppressSorting: true,
      cellRendererFramework: ({ value }) => <LocationInfo location={value} />,
    },
    {
      headerName: 'Kho đích',
      field: 'toLocation',
      minWidth: 100,
      suppressSorting: true,
      cellRendererFramework: ({ value }) => <LocationInfo location={value} />,
    },
    {
      headerName: 'Mô tả',
      field: 'description',
      minWidth: 50,
      suppressSorting: true,
    },
    {
      headerName: 'Trạng thái',
      field: 'status',
      minWidth: 130,
      maxWidth: 130,
      cellRendererFramework: GridCellStatusRenderer,
    },
  ];

  public handleQuickFilter = (event: ChangeEvent<HTMLInputElement>) => {
    store.setQuickFilter(event.target.value);
  };

  public render() {
    const {
      loading,
      quickFilter,
      toggleModal,
      selectedItem: pe,
      goodsIssue,
      goodsReceipt,
      visible,
      visibleConfirmModal,
      toggleConfirmModal,
      handleConfirmFromWarehouse,
      handlePassToFulfilled,
    } = store;
    const data = [...goodsReceipt, ...goodsIssue];

    if (loading) return <Loading />;

    return (
      <Fragment>
        <Row type='flex' style={{ flexFlow: 'column', height: '100%' }}>
          <h2>Danh sách phiếu nhập xuất</h2>
          <Row type='flex'>
            <Col span={24} md={{ span: 6 }} style={{ maxWidth: 300 }}>
              <Input
                type='text'
                onChange={this.handleQuickFilter}
                placeholder='Tìm kiếm ...'
              />
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              {get(pe, 'status') === FULFILLING && (
                <Fragment>
                  {isEmpty(data) &&
                    get(pe, 'type') !== TypeEnum.RemoveEquipment && (
                      <Authorized
                        permission={
                          PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_WAREHOUSE
                        }
                      >
                        <UIButton
                          type='warning'
                          onClick={toggleConfirmModal}
                          style={{ marginRight: 8 }}
                        >
                          Trả đề xuất
                        </UIButton>
                        <ConfirmModal
                          visible={visibleConfirmModal}
                          toggleModal={toggleConfirmModal}
                          handleConfirm={handleConfirmFromWarehouse}
                          values={[
                            {
                              text: 'TRẢ LẠI',
                              value: WAITING_SURVEYOR,
                              color: 'blue',
                            },
                            { text: 'HỦY', value: CANCELLED, color: 'red' },
                          ]}
                        />
                      </Authorized>
                    )}
                  {data.some(i => i.status === AGREED) && (
                    <Authorized
                      permission={
                        PERMISSION_PROPOSED_EQUIPMENT_PASS_TO_FULFILLED
                      }
                    >
                      <Popconfirm
                        title='Chuyển sang hoàn thành?'
                        okText='Chuyển'
                        cancelText='Không'
                        onConfirm={handlePassToFulfilled}
                      >
                        <UIButton
                          icon='check'
                          type='warning'
                          style={{ marginRight: 8 }}
                        >
                          Hoàn thành
                        </UIButton>
                      </Popconfirm>
                    </Authorized>
                  )}
                  {!data.some(
                    i => i.status === CREATED || i.status === WAITING_AGREEMENT
                  ) && (
                    <Authorized permission={PERMISSION_GOODS_ISSUE_CREATE}>
                      <Button
                        type='primary'
                        icon='plus'
                        onClick={() => toggleModal()}
                      >
                        Tạo phiếu
                      </Button>
                    </Authorized>
                  )}
                </Fragment>
              )}
            </Col>
          </Row>

          <div style={{ flex: 1, marginTop: 8 }}>
            <ReactResizeDetector handleHeight={true}>
              {(_width, height) => (
                <AgGridTable
                  height={height - 8}
                  rowData={data}
                  columnDefs={this.columnDefs}
                  quickFilterText={quickFilter}
                />
              )}
            </ReactResizeDetector>
          </div>
        </Row>
        <GoodsReceiptIssueForm visible={visible} />
      </Fragment>
    );
  }
}

export default GoodsReceiptIssueTable;

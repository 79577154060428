import { ColDef, ColGroupDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { AgGridTable, Loading } from '~components/UI';

import { GridCellActionRenderer } from './GridCellActionRenderer';
import store from './store';

@observer
class EquipmentCategoryTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      maxWidth: 90,
      minWidth: 90,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      headerName: 'Loại thiết bị',
      field: 'name',
      minWidth: 150,
    },
    {
      headerName: 'Mô tả',
      field: 'description',
      suppressSorting: true,
      minWidth: 400,
    },
  ];

  public render() {
    const { loading, equipmentCategory, quickFilter } = store;
    if (loading) return <Loading />;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height: number) => (
            <AgGridTable
              context={this}
              columnDefs={this.columnDefs}
              height={height - 8}
              rowData={equipmentCategory}
              quickFilterText={quickFilter}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

export default EquipmentCategoryTable;

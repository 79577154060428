import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Button, Col, Input, Row, Tag } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { ChangeEvent, Component, Fragment } from 'react';
import Authorized from '~components/Authorized/Authorized';
import { AgGridTable, Loading } from '~components/UI';
import { FullLocationFragment, FullRoleMappingFragment } from '~graphql/types';
import { Without } from '~utils';

import { PERMISSION_ROLE_MAPPING_CREATE } from '@mgn/common';

import { GridCellActionRenderer } from './GridCellActionRenderer';
import RoleMappingForm from './RoleMappingForm';
import { RoleStore } from './RoleStore';
import Header from '~screens/Employee/Header';

interface IProps {
  employeeId: string;
  roleId: string;
}

export interface IRowMapping
  extends Without<FullRoleMappingFragment, 'location' | 'locationId'> {
  location: FullLocationFragment[];
  locationId: string[];
}

@observer
class ExpandedRow extends Component<IProps> {
  private roleStore = new RoleStore();

  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 100,
      maxWidth: 100,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
      cellRendererParams: { store: this.roleStore },
    },
    {
      headerName: 'Chức danh',
      field: 'role.display',
      minWidth: 200,
      maxWidth: 200,
    },
    {
      headerName: 'Địa điểm',
      field: 'location',
      autoHeight: true,
      cellClass: 'cell-wrap-text',
      suppressSorting: true,
      cellRendererFramework: ({ value }) =>
        value &&
        value.map((item, index) => (
          <Tag key={index}>{get(item, 'name', 'Tất cả điểm')}</Tag>
        )),
    },
  ];

  public componentWillMount() {
    this.roleStore.onRowExpanded(this.props.employeeId);
  }

  public handleQuickFilter = (event: ChangeEvent<HTMLInputElement>) => {
    this.roleStore.setQuickFilterText(event.target.value);
  };
  public render() {
    const { employeeId, roleId } = this.props;
    const {
      quickFilter,
      roleMappings,
      toggleModal,
      visible,
      loading,
    } = this.roleStore;

    if (loading) return <Loading />;

    return (
      <Fragment>
        <Row>
          <Header
            text='quyền'
            filter={quickFilter}
            toggleModal={toggleModal}
            permission={PERMISSION_ROLE_MAPPING_CREATE}
            handeFilter={this.handleQuickFilter}
          />
          <div style={{ marginTop: 8 }}>
            <AgGridTable
              rowData={roleMappings}
              columnDefs={this.columnDefs}
              quickFilterText={quickFilter}
              rowHeight={70}
            />
          </div>
        </Row>
        <RoleMappingForm
          store={this.roleStore}
          visible={visible}
          employeeId={employeeId}
          roleId={roleId}
        />
      </Fragment>
    );
  }
}

export default ExpandedRow;

import { Col, Collapse, Divider, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import * as Yup from 'yup';
import NetworkTable from '~components/NetworkTable/NetworkTable';
import { UIField } from '~components/UI/UIField';
import { InspectionResultInput, NetworkInput } from '~graphql/types';
import { removeTypename } from '~utils';

import store from './store';

const { Panel } = Collapse;

const validationSchema = Yup.object().shape<InspectionResultInput>({
  area: Yup.string().required('Bắt buộc nhập'),
  capacity: Yup.string().required('Bắt buộc nhập'),
  description: Yup.string().nullable(true),
  network: Yup.array<NetworkInput>().min(1, ''),
});

@observer
class UpdateDetailForm extends Component {
  public handleSubmit = async (
    values: InspectionResultInput,
    actions: FormikActions<InspectionResultInput>
  ) => {
    const { setSubmitting } = actions;
    const { handleUpdateDetail } = store;

    handleUpdateDetail(values);

    setSubmitting(false);
  };

  public render() {
    const { selectedItem } = store;

    const formInitialValues = {
      area: get(selectedItem, 'inspectionResult.area'),
      capacity: get(selectedItem, 'inspectionResult.capacity'),
      network: removeTypename(
        get(selectedItem, 'inspectionResult.network', [])
      ),
      description: get(selectedItem, 'inspectionResult.description'),
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (props: FormikProps<InspectionResultInput>) => {
    const { isValid, isSubmitting, values, setFieldValue } = props;
    const { validateForm } = store;

    validateForm(isValid, isSubmitting);

    const handleNetworkSubmit = (network: NetworkInput[]) => {
      setFieldValue('network', network);
    };

    return (
      <Fragment>
        <Form id='updateDetailFm'>
          <Row gutter={8}>
            <Col md={12}>
              <Field
                name='area'
                component={UIField}
                fieldProps={{ placeholder: 'Diện tích' }}
              />
            </Col>
            <Col md={12}>
              <Field
                name='capacity'
                component={UIField}
                fieldProps={{ placeholder: 'Sức chứa' }}
              />
            </Col>
          </Row>
          <Field
            name='description'
            component={UIField}
            fieldComponent={TextArea}
            fieldProps={{
              placeholder: 'VD: quán đông, không gian hẹp...',
              autosize: { minRows: 2, maxRows: 4 },
            }}
          />
        </Form>
        <Divider orientation='left'>Thông tin nhà mạng</Divider>
        <NetworkTable value={values.network} onChange={handleNetworkSubmit} />
      </Fragment>
    );
  };
}
export default UpdateDetailForm;

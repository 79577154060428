import gql from 'graphql-tag';
import roleMapping from '../fragments/roleMapping';

export const FIND_MANY_ROLE_MAPPING_QUERY = gql`
  query FindManyRoleMapping(
    $skip: Int
    $take: Int
    $where: RoleMappingBaseFilter
    $order: RoleMappingBaseSort
  ) {
    findManyRoleMapping(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullRoleMapping
    }
  }
  ${roleMapping.full}
`;

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import client from '~graphql/client';
import { FIND_MANY_PERMISSION_QUERY } from '~graphql/queries';
import {
  FindManyPermissionQuery,
  FindManyPermissionVariables,
  FullPermissionFragment,
} from '~graphql/types';
import { PERMISSION_SUPERADMIN } from '@mgn/common';

const { Option } = Select;

@observer
export class PermissionSelect extends Component<SelectProps> {
  @observable public loading: boolean;
  @observable public data: FullPermissionFragment[];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = false;
      this.data = [];
    });
  }

  public componentDidMount() {
    this.loadData();
  }

  @computed public get roles() {
    return this.data.filter(item => {
      if (item.name === PERMISSION_SUPERADMIN || item.name[0] === '$') {
        return false;
      }
      return true;
    });
  }

  @action public loadData = async () => {
    this.loading = true;

    const { data } = await client.query<
      FindManyPermissionQuery,
      FindManyPermissionVariables
    >({
      query: FIND_MANY_PERMISSION_QUERY,
    });

    runInAction(() => {
      this.loading = false;
      this.data = data.findManyPermission;
    });
  };

  public render() {
    return (
      <Select
        mode='multiple'
        allowClear={true}
        placeholder='Chọn quyền'
        style={{ width: '100%' }}
        {...this.props}
      >
        {this.roles.map((item, index) => (
          <Option key={String(index)} value={item.name}>
            {item.description}
          </Option>
        ))}
      </Select>
    );
  }
}

import gql from 'graphql-tag';
import equipmentCategory from '../fragments/equipmentCategory';

export const FIND_MANY_EQUIPMENT_CATEGORY_QUERY = gql`
  query FindManyEquipmentCategory(
    $skip: Int
    $take: Int
    $where: EquipmentCategoryBaseFilter
    $order: EquipmentCategoryBaseSort
  ) {
    findManyEquipmentCategory(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullEquipmentCategory
    }
  }
  ${equipmentCategory.full}
`;

export const FIND_ONE_EQUIPMENT_CATEGORY = gql`
  query FindOneEquipmentCategory(
    $where: EquipmentCategoryBaseFilter
    $order: EquipmentCategoryBaseSort
  ) {
    findOneEquipmentCategory(where: $where, order: $order) {
      ...FullEquipmentCategory
    }
  }
  ${equipmentCategory.full}
`;

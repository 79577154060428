import React, { Component } from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import { ApolloError } from 'apollo-client';

interface IProps extends TableProps<{}> {
  error?: ApolloError;
  caption?: string | null;
}

export default class TableItem extends Component<IProps> {
  public render() {
    const { error, caption, ...props } = this.props;

    return (
      <div
        style={{
          marginBottom: 7,
          verticalAlign: 'middle',
        }}
      >
        <Table
          {...props}
          scroll={{ x: true }}
          locale={{ emptyText: error || 'Chưa có dữ liệu' }}
          size='small'
          rowKey={(_record, index) => String(index)}
        />
        {caption && (
          <p
            style={{
              marginRight: 8,
              display: 'inline-block',
              color: '#808080',
              fontStyle: 'italic',
            }}
          >
            {caption}
          </p>
        )}
      </div>
    );
  }
}

import { Button, Input, Modal, Row } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { UIField } from '~components/UI/UIField';
import {
  CreateRoleVariables,
  FullPermissionFragment,
  FullRoleFragment
} from '~graphql/types';

import { PermissionSelect } from './PermissionSelect';
import store from './store';

const { TextArea } = Input;

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),
  name: Yup.string().required('Bắt buộc chọn'),
  display: Yup.string().required('Bắt buộc chọn'),
  permissions: Yup.array(Yup.string()).required('Bắt buộc chọn'),
  description: Yup.string(),
});

interface IFormValues extends CreateRoleVariables {
  _id: string;
}

@observer
class RoleForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { setSubmitting, resetForm } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { selectedItem, visible, toggleModal } = store;

    const initialValues: IFormValues = {
      _id: get(selectedItem, '_id'),
      name: get(selectedItem, 'name'),
      display: get(selectedItem, 'display'),
      permissions: get<
        FullRoleFragment,
        'permissions',
        FullPermissionFragment[]
      >(selectedItem, 'permissions', []).map(item => item.name),
      description: get(selectedItem, 'description'),
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            htmlType='submit'
            form='roleFm'
            disabled={!isValid}
            loading={isSubmitting}
            type='primary'
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='roleFm'>
            <Field
              name='name'
              label='Tên chức danh'
              component={UIField}
              fieldComponent={Input}
              fieldProps={{
                autosize: { minRows: 2, maxRows: 6 },
                placeholder: 'Nhập tên chức danh',
              }}
            />
            <Field
              name='display'
              label='Tên hiển thị'
              component={UIField}
              fieldComponent={Input}
              fieldProps={{
                autosize: { minRows: 2, maxRows: 6 },
                placeholder: 'Nhập tên hiển thị',
              }}
            />
            <Field
              name='description'
              label='Mô tả'
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                autosize: { minRows: 2, maxRows: 6 },
                placeholder: 'Nhập mô tả',
              }}
            />
            <Field
              name='permissions'
              label='Danh sách quyền'
              component={UIField}
              fieldComponent={PermissionSelect}
              fieldProps={{
                placeholder: 'Chọn quyền',
              }}
              nativeInput={false}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default RoleForm;

import { FormComponentProps } from 'antd/lib/form';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import EquipmentTableServer from '~components/Equipment/EquipmentTableServer';

interface IProps extends FormComponentProps, RouteComponentProps {}

class Equipment extends Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <div style={{ height: '100vh', paddingTop: 8 }}>
        <EquipmentTableServer
          extraColumn={['unitPrice', 'issueInvoiceDay', 'warranty', 'location']}
          rowGroup={true}
        />
      </div>
    );
  }
}

export default Equipment;

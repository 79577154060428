import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { observer } from "mobx-react";
import React, { Component } from "react";

const { Option } = Select;

interface IProps extends SelectProps {
  permission?: string;
}

const typeData = {
  WAREHOUSE: "Kho",
  LOCATION: "Địa điểm"
};

export type TypeDropdownProps = IProps;

@observer
export default class TypeDropdown extends Component<IProps> {
  public render() {
    const { permission, ...props } = this.props;
    return (
      <Select
        {...props}
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {Object.keys(typeData).map(s => (
          <Option key={s} value={s}>
            {typeData[s]}
          </Option>
        ))}
      </Select>
    );
  }
}

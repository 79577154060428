import { ColDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { columnDefs } from '~components/Equipment/EquipmentTable';
import { AgGridTable } from '~components/UI';

import ActionCellRenderer from './GridPreTempActionCellRenderer';
import SerialCellRenderer from './GridPreTempSerialCellRenderer';
import store from './store';

@observer
export default class GridEquipmentPreTemp extends Component {
  private columnDefs: ColDef[] = [
    {
      colId: 'action',
      headerName: '',
      field: '_id',
      minWidth: 60,
      maxWidth: 60,
      cellRendererFramework: ActionCellRenderer,
    },
    columnDefs.idGroup,
    {
      ...columnDefs.serial,
      cellRenderer: undefined,
      cellRendererFramework: SerialCellRenderer,
    },
    {
      ...columnDefs.equipmentModelCode,
      rowGroup: true,
      hide: true,
    },
    columnDefs.supplierCode,
    columnDefs.unitPrice,
    columnDefs.warranty,
  ];

  public render() {
    const { equipmentPreTemps } = store;

    return (
      <AgGridTable
        height={200}
        columnDefs={this.columnDefs}
        groupUseEntireRow={true}
        suppressAggFuncInHeader={true}
        rememberGroupStateWhenNewData={true}
        rowData={equipmentPreTemps}
      />
    );
  }
}

export { GridEquipmentPreTemp };

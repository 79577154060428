import gql from 'graphql-tag';
import employeeDevice from '../fragments/employeeDevice';

export const FIND_MANY_EMPLOYEE_DEVICE_QUERY = gql`
  query FindManyEmployeeDevice(
    $skip: Int
    $take: Int
    $where: EmployeeDeviceBaseFilter
    $order: EmployeeDeviceBaseSort
  ) {
    findManyEmployeeDevice(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullEmployeeDevice
    }
  }
  ${employeeDevice.full}
`;

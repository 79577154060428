import { Button, Modal, Row } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { UIField } from '~components/UI/UIField';
import { EmployeeInput } from '~graphql/types';

import store from './store';

interface IFormValues extends EmployeeInput {
  _id?: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),

  fullName: Yup.string()
    .min(2, 'Tên quá ngắn')
    .max(50, 'Tên quá dài')
    .required('Bắt buộc nhập'),

  phone: Yup.string()
    .min(10, 'Số điện thoại không hợp lệ')
    .max(11, 'Số điện thoại không hợp lệ')
    .required('Bắt buộc nhập'),

  email: Yup.string()
    .email('Email không hợp lệ')
    .required('Bắt buộc nhập'),

  status: Yup.string(),
});

@observer
class EmployeeForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm, setSubmitting } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { visible, toggleModal, selectedItem } = store;

    const formInitialValues = {
      _id: get(selectedItem, '_id'),
      fullName: get(selectedItem, 'fullName'),
      email: get(selectedItem, 'email'),
      phone: get(selectedItem, 'phone'),
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            htmlType='submit'
            form='empFm'
            disabled={!isValid}
            loading={isSubmitting}
            type='primary'
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='empFm'>
            <Field
              name='fullName'
              label='Họ tên'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập họ tên' }}
            />
            <Field
              name='phone'
              label='Số điện thoại'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập số điện thoại' }}
            />
            <Field
              name='email'
              type='email'
              label='Email'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập email' }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default EmployeeForm;

import { Button, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { UIField } from '~components/UI/UIField';
import { MaterialInput } from '~graphql/types';

import store from './store';

interface IFormValues extends MaterialInput {
  _id: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),

  name: Yup.string().required('Bắt buộc nhập'),

  code: Yup.string().required('Bắt buộc nhập'),

  unit: Yup.string().required('Bắt buộc nhập'),

  description: Yup.string(),

  equipmentCategoryId: Yup.string().required('Bắt buộc nhập'),

  isRecoved: Yup.boolean().required('Bắt buộc nhập'),
});

@observer
class MaterialForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }
    resetForm();
  };

  public render() {
    const { visible, toggleModal, selectedItem } = store;

    const formInitialValues = {
      _id: get(selectedItem, '_id'),
      name: get(selectedItem, 'name'),
      code: get(selectedItem, 'code'),
      unit: get(selectedItem, 'unit'),
      description: get(selectedItem, 'description'),
      equipmentCategoryId: get(selectedItem, 'equipmentCategoryId'),
      isRecoved: get(selectedItem, 'isRecove'),
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            type='primary'
            htmlType='submit'
            form='eqipCateFm'
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='eqipCateFm'>
            <Field name='name' label='Tên vật tư' component={UIField} />
            <Field name='code' label='Mã vật tư' component={UIField} />
            <Field name='unit' label='Đơn vị tính' component={UIField} />
            <Field
              name='description'
              label='Mô tả'
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{ autosize: { minRows: 2, maxRows: 6 } }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}
export { MaterialForm };

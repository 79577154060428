import moment from 'moment';
import 'moment/locale/vi';

const locale = {
  weekdays: 'Chủ nhật_Thứ hai_Thứ ba_Thứ tư_Thứ năm_Thứ sáu_Thứ bảy'.split('_'),
  weekdaysShort: 'CN_T2_T3_T4_T5_T6_T7'.split('_'),
  weekdaysMin: 'CN_T2_T3_T4_T5_T6_T7'.split('_'),
  months: 'Tháng một_Tháng hai_Tháng ba_Tháng bốn_Tháng năm_Tháng sáu_Tháng bảy_Tháng tám_Tháng chín_Tháng mười_Tháng 11_Tháng 12'.split(
    '_'
  ),

  relativeTime: {
    future: 'trong %s',
    past: '%s trước',
    s: 'vài giây',
    ss: 'vừa xong',
    m: 'một phút',
    mm: '%d phút',
    h: 'một tiếng',
    hh: '%d tiếng',
    d: 'một ngày',
    dd: '%d Ngày',
    M: 'một tháng',
    MM: '%d tháng',
    y: 'một năm',
    yy: '%d năm',
  },
};
moment.updateLocale('vi', locale);
moment.locale('vi');

export default locale;

import { Component, ReactNode } from 'react';
import authStore from '~stores/authStore';

import {
  IEmployee,
  PERMISSION_EMPLOYEE_FREEWIFI_ADMIN,
  PERMISSION_SUPERADMIN,
} from '@mgn/common';

interface IProps {
  employee: IEmployee;
  unAuthorizedComponent?: ReactNode;
  extraPermission?: string;
}

export class CurrentUser extends Component<IProps> {
  public static defaultProps = {
    unAuthorizedComponent: null,
  };

  public render() {
    const { currentEmployee, permissions: currentPermission } = authStore;
    const {
      children,
      unAuthorizedComponent,
      employee,
      extraPermission,
    } = this.props;

    if (!employee) return unAuthorizedComponent;

    const authorized =
      currentPermission[PERMISSION_SUPERADMIN] ||
      currentPermission[extraPermission] ||
      (currentEmployee && employee._id === currentEmployee._id);

    return authorized ? children : unAuthorizedComponent;
  }
}

export const isCurrentUser = (employee: IEmployee) => {
  const { currentEmployee, permissions: currentPermission } = authStore;

  return (
    currentPermission[PERMISSION_SUPERADMIN] ||
    (currentEmployee && employee._id === currentEmployee._id)
  );
};

export default CurrentUser;

import gql from 'graphql-tag';
import equipmentDetail from '~graphql/fragments/equipmentDetail';

import equipment from '../fragments/equipment';

export const SEARCH_EQUIPMENT_QUERY = gql`
  query SearchEquipment($skip: Int, $take: Int, $where: FindEquipmentWhere) {
    searchEquipment(skip: $skip, take: $take, where: $where) {
      ...CommonEquipment
    }
  }
  ${equipment.common}
`;

export const FIND_ONE_EQUIPMENT_QUERY = gql`
  query FindOneEquipment(
    $where: EquipmentBaseFilter
    $order: EquipmentBaseSort
  ) {
    findOneEquipment(where: $where, order: $order) {
      ...FullEquipment
    }
  }
  ${equipment.full}
`;

export const FIND_MANY_EQUIPMENT_QUERY = gql`
  query FindManyEquipment(
    $skip: Int
    $take: Int
    $where: EquipmentBaseFilter
    $order: EquipmentBaseSort
  ) {
    findManyEquipment(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullEquipment
    }
  }
  ${equipment.full}
`;

export const FIND_MANY_EQUIPMENT_GOODS_RI_QUERY = gql`
  query FindEquipmentByGoodsRICode($code: String!) {
    findEquipmentByGoodsRICode(code: $code) {
      ...SharedEquipment
    }
  }
  ${equipment.shared}
`;

export const FIND_EQUIPMENT_DETAIL_QUERY = gql`
  query FindEquipmentDetail(
    $skip: Int
    $take: Int
    $where: FindEquipmentDetailWhere
  ) {
    findEquipmentDetail(skip: $skip, take: $take, where: $where) {
      ...FullEquipmentDetail
    }
  }
  ${equipmentDetail.full}
`;

import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { action, computed, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import client from "~graphql/client";
import { FIND_MANY_SEARCH_LOCATION_QUERY } from "~graphql/queries";
import {
  BasicLocationFragment,
  FindManyBasicLocationQuery,
  FindManyBasicLocationVariables
} from "~graphql/types";
import authStore from "~stores/authStore";

import { ALL_LOCATION } from "@mgn/common";

const { Option } = Select;

interface IProps extends SelectProps {
  typeCode?: string;
  locationType?: string;
  allLocation: boolean;
  permission?: string;
}

export type LocationDropdownProps = IProps;

@observer
export default class LocationDropdown extends Component<IProps> {
  @observable private loading: boolean;
  @observable private data: BasicLocationFragment[];

  constructor(props) {
    super(props);

    runInAction(() => {
      this.loading = true;
      this.data = [];
    });
  }

  @computed public get locations(): BasicLocationFragment[] {
    const { permission } = this.props;
    const { isSuperadmin, permissions } = authStore;

    if (isSuperadmin || !permission) return this.data;

    const permissionLocations = permissions[permission];
    return this.data.filter(({ _id }) => permissionLocations.indexOf(_id) > -1);
  }

  public componentDidMount() {
    const { locationType, typeCode } = this.props;
    this.fetchLocation(locationType, typeCode).then(
      action("setLoading", () => {
        this.loading = false;
      })
    );
  }

  public componentWillReceiveProps(nextProps) {
    const { locationType, typeCode } = this.props;

    if (
      locationType !== nextProps.locationType ||
      typeCode !== nextProps.typeCode
    ) {
      this.fetchLocation(nextProps.locationType, nextProps.typeCode).then(
        action("setLoading", () => {
          this.loading = false;
        })
      );
    }
  }

  public render() {
    const { permission, typeCode, allLocation, ...props } = this.props;

    return (
      <Select
        {...props}
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        loading={this.loading}
      >
        {allLocation && <Option value={ALL_LOCATION}>Tất cả điểm</Option>}
        {this.locations.map((e, index) => {
          return (
            <Option key={String(index)} value={e._id}>
              {e.name}
            </Option>
          );
        })}
      </Select>
    );
  }

  @action private fetchLocation = async (
    locationType: string,
    typeCode: string
  ) => {
    const {
      data: { findManyLocation }
    } = await client.query<
      FindManyBasicLocationQuery,
      FindManyBasicLocationVariables
    >({
      query: FIND_MANY_SEARCH_LOCATION_QUERY,
      variables: {
        where: {
          locationType,
          typeCode
        }
      },
      fetchPolicy: "network-only"
    });

    runInAction(() => {
      this.data = findManyLocation || [];
    });
  };
}

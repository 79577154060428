import location from '../fragments/location';
import gql from 'graphql-tag';

export const PASS_TO_INSTALLER_LOCATION_MUTATION = gql`
  mutation PassToInstallerLocation($_id: ID!) {
    passToInstallerLocation(_id: $_id) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${location.full}
`;

import { ICellRendererParams } from 'ag-grid-community';
import { Popconfirm, Button } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import Authorized from '~components/Authorized/Authorized';
import { UIButton } from '~components/UI';
import {
  PERMISSION_ROLE_MAPPING_REMOVE,
  PERMISSION_ROLE_MAPPING_UPDATE
} from '@mgn/common';

import { RoleStore } from './RoleStore';

interface IProps extends ICellRendererParams {
  store: RoleStore;
}

@observer
export class GridCellActionRenderer extends Component<IProps> {
  public render() {
    const { data, store } = this.props;
    const { toggleModal, handleDelete } = store;

    return (
      <Button.Group>
        <Authorized permission={PERMISSION_ROLE_MAPPING_UPDATE}>
          <UIButton
            size='small'
            type='primary'
            icon='edit'
            onClick={() => toggleModal(data)}
          />
        </Authorized>
        <Authorized permission={PERMISSION_ROLE_MAPPING_REMOVE}>
          <Popconfirm
            title='Xóa?'
            onConfirm={() => handleDelete(data.roleId, data.employeeId)}
          >
            <UIButton size='small' type='danger' icon='delete' />
          </Popconfirm>
        </Authorized>
      </Button.Group>
    );
  }
}

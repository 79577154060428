import gql from 'graphql-tag';
import reportInventory from '~graphql/fragments/reportInventory';

export const GET_REPORT_INVENTORY_QUERY = gql`
  query GetReportInventory($where: ReportInventoryBaseFilter) {
    getReportInventory(where: $where) {
      ...BasicReportInventory
    }
  }
  ${reportInventory.basic}
`;

export function omitDeep<T extends {} = {}>(input: T, excludes: string[]): T {
  return Object.entries(input).reduce<T>(
    (nextInput, [key, value]) => {
      const shouldExclude = excludes.includes(key);
      if (shouldExclude) return nextInput;

      if (Array.isArray(value)) {
        const arrValue = value;
        const nextValue = arrValue.map(arrItem => {
          if (typeof arrItem === 'object') {
            return omitDeep(arrItem, excludes);
          }
          return arrItem;
        });
        nextInput[key] = nextValue;
        return nextInput;
      }
      if (typeof value === 'object' && value !== null) {
        nextInput[key] = omitDeep(value, excludes);
        return nextInput;
      }

      nextInput[key] = value;

      return nextInput;
    },
    // tslint:disable-next-line:no-object-literal-type-assertion
    {} as T
  );
}

import role from '../fragments/role';
import gql from 'graphql-tag';

export const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole(
    $name: String!
    $display: String!
    $description: String
    $permissions: [String!]!
  ) {
    createRole(
      record: {
        name: $name
        display: $display
        description: $description
        permissions: $permissions
      }
    ) {
      payload {
        ...FullRole
      }
      error {
        message
      }
    }
  }
  ${role.full}
`;

import gql from 'graphql-tag';
import errorLog from '../fragments/errorLog';

export const CREATE_ERROR_LOG_MUTATION = gql`
  mutation CreateErrorLog($record: ErrorLogInput!) {
    createErrorLog(record: $record) {
      payload {
        ...FullErrorLog
      }
      error {
        message
      }
    }
  }
  ${errorLog.full}
`;

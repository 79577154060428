import { ErrorNoti, SuccessNoti } from '~components/UI';
import {
  CREATE_MENU_MUTATION,
  UPDATE_MENU_BY_ID_MUTATION,
  REMOVE_MENU_BY_ID_MUTATION
} from '~graphql/mutations';
import { action, observable, runInAction } from 'mobx';

import client from '~graphql/client';
import { FIND_MANY_MENU_QUERY } from '~graphql/queries';
import {
  FindManyMenuQuery,
  FindManyMenuVariables,
  FullMenuFragment,
  MenuChildInput,
  MenuInput,
  CreateMenuMutation,
  CreateMenuVariables,
  UpdateMenuByIdMutation,
  UpdateMenuByIdVariables,
  RemoveMenuByIdMutation,
  RemoveMenuByIdVariables
} from '~graphql/types';

const clone = (menu: MenuChildInput[]) => {
  return !menu
    ? []
    : menu.map(({ name, icon, route, children }) => {
        const obj: MenuChildInput = { name, icon, route };
        if (children && children.length) {
          obj.children = clone(children);
        }
        return obj;
      });
};

class MenuStore {
  @observable public loading: boolean = true;
  @observable public visible: boolean = false;
  @observable public quickFilter: string = null;
  @observable.shallow public data: FullMenuFragment[] = [];
  @observable public selectedItem: FullMenuFragment = null;
  @observable public menuTree: MenuChildInput[] = [];

  private initialized: boolean = false;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.visible = false;
      this.quickFilter = null;
      this.data = [];
      this.selectedItem = null;
      this.menuTree = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public handleShow = item => {
    this.selectedItem = item;
    this.visible = true;

    this.menuTree = item ? clone(this.selectedItem.menu) : [];
  };

  @action public handleClose = () => {
    this.visible = false;
  };

  @action public setQuickFilter = quickFilter => {
    this.quickFilter = quickFilter;
  };

  @action public handleCreate = async (record: MenuInput) => {
    const {
      data: { createMenu },
    } = await client.mutate<CreateMenuMutation, CreateMenuVariables>({
      mutation: CREATE_MENU_MUTATION,
      variables: {
        record,
      },
    });

    const { error, payload } = createMenu;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo danh mục mới');
      this.updateData(null, payload);
      this.handleClose();
    }
  };

  @action public handleUpdate = async (_id: string, record: MenuInput) => {
    const {
      data: { updateMenuById },
    } = await client.mutate<UpdateMenuByIdMutation, UpdateMenuByIdVariables>({
      mutation: UPDATE_MENU_BY_ID_MUTATION,
      variables: {
        _id,
        record,
      },
    });

    const { error, payload } = updateMenuById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật menu');
      this.updateData(_id, payload);
      this.handleClose();
    }
  };

  @action public handleDelete = async (_id: string) => {
    const {
      data: { removeMenuById },
    } = await client.mutate<RemoveMenuByIdMutation, RemoveMenuByIdVariables>({
      mutation: REMOVE_MENU_BY_ID_MUTATION,
      variables: {
        _id,
      },
    });

    const { error } = removeMenuById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Xoá menu');
      this.updateData(_id);
    }
  };

  @action private fetchData = async () => {
    const { data } = await client.query<
      FindManyMenuQuery,
      FindManyMenuVariables
    >({
      query: FIND_MANY_MENU_QUERY,
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.data = data.findManyMenu;
    });
  };

  @action private updateData = (_id: string, record?: FullMenuFragment) => {
    if (!_id) {
      this.data = this.data.concat(record);
    } else {
      const index = this.data.findIndex(item => item._id === _id);

      this.data = [
        ...this.data.slice(0, index),
        record,
        ...this.data.slice(index + 1),
      ].filter(Boolean);
    }
  };
}

export default new MenuStore();

import gql from 'graphql-tag';

const full = gql`
  fragment FullEmployee on Employee {
    _id
    fullName
    email
    phone
    status
    createdAt
    updatedAt
  }
`;

export default { full };

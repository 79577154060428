// https://stackoverflow.com/questions/35272533/flattening-deeply-nested-array-of-objects
export const flatten = array => {
  let result = [];
  array.forEach(a => {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flatten(a.children));
    }
  });
  return result;
};

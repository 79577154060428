import { get, some } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, ComponentType } from 'react';
import Location from 'react-app-location';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch
} from 'react-router-dom';
import * as Yup from 'yup';
import PrintGoodsIssue from '~components/Print/PrintGoodsIssue';
import PrintGoodsReceipt from '~components/Print/PrintGoodsReceipt';
import { ErrorBoundary } from '~components/UI';
import authStore from '~stores/authStore';

import { IMenuItem } from '@mgn/common';

import Employee from './Employee/Employee';
import EmployeeDevice from './EmployeeDevice/EmployeeDevice';
import Equipment from './Equipment/Equipment';
import EquipmentCategory from './EquipmentCategory/EquipmentCategory';
import EquipmentHistory from './EquipmentHistory/EquipmentHistory';
import EquipmentModel from './EquipmentModel/EquipmentModel';
import GoodsIssue from './GoodsIssue/GoodsIssue';
import GoodsIssueDetail from './GoodsIssueDetail/GoodsIssueDetail';
import GoodsReceipt from './GoodsReceipt/GoodsReceipt';
import GoodsReceiptDetail from './GoodsReceiptDetail/GoodsReceiptDetail';
import Home from './HomeScreen/HomeScreen';
import Info from './Info/Info';
import IOInventory from './IOInventory/IOInventory';
import LocationType from './LocationType/LocationType';
import Login from './Login/LoginForm';
import Material from './Material/Material';
import Menu from './Menu/Menu';
import Permission from './Permission/Permission';
import ProposedEquipment from './ProposedEquipment/ProposedEquipment';
import ProposedEquipmentDetail from './ProposedEquipmentDetail/ProposedEquipmentDetail';
import Role from './Role/Role';
import UpdateEquipment from './UpdateEquipment/UpdateEquipment';
import Supplier from './Supplier/Supplier';
import Warehouse from './Warehouse/Warehouse';
import WarehouseDetail from './WarehouseDetail/WarehouseDetail';

type IProps = RouteProps;

interface IRoute extends IMenuItem {
  exact?: boolean;
  menu?: boolean;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

export const DashboardLocation = new Location('/');
export const UserInfoLocation = new Location('/info');
export const EmployeeLocation = new Location('/employee');
export const RoleLocation = new Location('/role');
export const MenuLocation = new Location('/menu');
export const PermissionLocation = new Location('/permission');
export const EquipmentCategoryLocation = new Location('/equipmentCategory');
export const EquipmentModelLocation = new Location('/equipmentModel');
export const SupplierLocation = new Location('/supplier');
export const MaterialLocation = new Location('/material');
export const EquipmentLocation = new Location('/equipment');
export const EquipmentDetailLocation = new Location('/equipment/:serial', {
  serial: Yup.string().required(),
});
export const ProposedEquipmentLocation = new Location('/proposedEquipment');
export const ProposedEquipmentDetailLocation = new Location(
  '/proposedEquipment/:id',
  { id: Yup.string().required() }
);
export const LocationTypeLocation = new Location('/locationType');
export const WarehouseLocation = new Location('/warehouse');
export const WarehouseDetailLocation = new Location('/warehouse/:id', {
  id: Yup.string().required(),
});
export const GoodsReceiptLocation = new Location('/goodsReceipt');
export const GoodsReceiptDetailLocation = new Location('/goodsReceipt/:code', {
  code: Yup.string().required(),
});
export const GoodsReceiptPrintLocation = new Location(
  '/goodsReceipt/:code/print',
  {
    code: Yup.string().required(),
  }
);
export const GoodsIssueLocation = new Location('/goodsIssue');
export const GoodsIssueDetailLocation = new Location('/goodsIssue/:code', {
  code: Yup.string().required(),
});
export const GoodsIssuePrintLocation = new Location('/goodsIssue/:code/print', {
  code: Yup.string().required(),
});
export const UpdateEquipmentLocation = new Location('/updateEquipment');
export const ReportInventoryLocation = new Location('/inventory');
export const EmployeeDeviceLocation = new Location('/employeeDevice');

export const routes: IRoute[] = [
  {
    icon: 'dashboard',
    name: 'Bảng chung',
    route: DashboardLocation.path,
    component: Home,
    exact: true,
    menu: true,
  },
  {
    icon: 'star',
    name: 'Loại địa điểm',
    route: LocationTypeLocation.path,
    component: LocationType,
    exact: true,
    menu: true,
  },
  {
    icon: 'user',
    name: 'Người dùng',
    route: UserInfoLocation.path,
    component: Info,
    exact: true,
  },
  {
    icon: 'team',
    name: 'Nhân viên',
    route: EmployeeLocation.path,
    component: Employee,
    exact: true,
    menu: true,
  },
  {
    icon: 'credit-card',
    name: 'Chức danh',
    route: RoleLocation.path,
    component: Role,
    exact: true,
    menu: true,
  },
  {
    icon: 'ordered-list',
    name: 'Menu',
    route: MenuLocation.path,
    component: Menu,
    exact: true,
    menu: true,
  },
  {
    icon: 'solution',
    name: 'Phân quyền',
    route: PermissionLocation.path,
    component: Permission,
    exact: true,
    menu: true,
  },
  {
    icon: 'cluster',
    name: 'Loại thiết bị',
    route: EquipmentCategoryLocation.path,
    component: EquipmentCategory,
    exact: true,
    menu: true,
  },
  {
    icon: 'cluster',
    name: 'Loại vật tư',
    route: MaterialLocation.path,
    component: Material,
  },
  {
    icon: 'barcode',
    name: 'Mẫu thiết bị',
    route: EquipmentModelLocation.path,
    component: EquipmentModel,
    exact: true,
    menu: true,
  },
  {
    icon: 'idcard',
    name: 'Nhà cung cấp',
    route: SupplierLocation.path,
    component: Supplier,
    exact: true,
    menu: true,
  },
  {
    icon: 'reconciliation',
    name: 'Thiết bị nhân viên',
    route: EmployeeDeviceLocation.path,
    component: EmployeeDevice,
    exact: true,
    menu: true,
  },
  {
    icon: 'hdd',
    name: 'Thiết bị',
    route: EquipmentLocation.path,
    component: Equipment,
    exact: true,
    menu: true,
  },
  {
    icon: 'info-circle',
    name: 'Chi tiết thiết bị',
    route: EquipmentDetailLocation.path,
    component: EquipmentHistory,
    exact: true,
  },
  {
    icon: 'file',
    name: 'Phiếu đề xuất',
    route: ProposedEquipmentLocation.path,
    component: ProposedEquipment,
    exact: true,
    menu: true,
  },
  {
    icon: 'info-circle',
    name: 'Chi tiết phiếu đề xuất',
    route: ProposedEquipmentDetailLocation.path,
    component: ProposedEquipmentDetail,
    exact: true,
  },
  {
    icon: 'home',
    name: 'Kho',
    route: WarehouseLocation.path,
    component: Warehouse,
    exact: true,
    menu: true,
  },
  {
    icon: 'info-circle',
    name: 'Chi tiết kho',
    route: WarehouseDetailLocation.path,
    component: WarehouseDetail,
    exact: true,
  },
  {
    icon: 'file',
    name: 'Phiếu nhập kho',
    route: GoodsReceiptLocation.path,
    component: GoodsReceipt,
    exact: true,
    menu: true,
  },
  {
    icon: 'info-circle',
    name: 'Chi tiết phiếu nhập kho',
    route: GoodsReceiptDetailLocation.path,
    component: GoodsReceiptDetail,
    exact: true,
  },
  {
    icon: 'printer',
    name: 'In Phiếu Nhập',
    route: GoodsReceiptPrintLocation.path,
    component: PrintGoodsReceipt,
    exact: true,
  },
  {
    icon: 'file',
    name: 'Phiếu xuất kho',
    route: GoodsIssueLocation.path,
    component: GoodsIssue,
    exact: true,
    menu: true,
  },
  {
    icon: 'info-circle',
    name: 'Chi tiết phiếu xuất kho',
    route: GoodsIssueDetailLocation.path,
    component: GoodsIssueDetail,
    exact: true,
  },
  {
    icon: 'printer',
    name: 'In Phiếu Xuất',
    route: GoodsIssuePrintLocation.path,
    component: PrintGoodsIssue,
    exact: true,
  },
  {
    icon: 'file',
    name: 'Xuất nhập tồn',
    route: ReportInventoryLocation.path,
    component: IOInventory,
    exact: true,
    menu: true,
  },
  {
    icon: 'edit',
    name: 'Cập nhật thiết bị',
    route: UpdateEquipmentLocation.path,
    component: UpdateEquipment,
    exact: true,
    menu: true,
  },
];

@observer
class PrivateRoute extends Component<IProps> {
  public render() {
    const { component, path, exact } = this.props;
    const { isAuthenticated, flattedMenu } = authStore;

    if (!isAuthenticated) {
      return <Redirect to='/login' />;
    }

    if (
      path === '/info' ||
      some(
        flattedMenu,
        (m: any) => path.includes(m.route) || path.includes('/print')
      )
    ) {
      return (
        <ErrorBoundary>
          <Route path={path} component={component} exact={exact} />
        </ErrorBoundary>
      );
    }
    return <Redirect to={get(flattedMenu, '[0].route', '/info')} />;
  }
}

const Routes = () => (
  <Switch>
    <Route path='/login' component={Login} />
    {routes.map((r, index) => (
      <PrivateRoute
        key={index}
        path={r.route}
        component={r.component}
        exact={r.exact}
      />
    ))}
  </Switch>
);

export default Routes;

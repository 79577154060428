import { ICellRendererParams } from 'ag-grid-community';
import { Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { isAuthorized } from '~components/Authorized/Authorized';
import { UIButton } from '~components/UI';
import {
  CREATED,
  PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_REMOVE
} from '@mgn/common';

import store from './store';

@observer
export default class GridEquipmentTempActionCellRenderer extends Component<
  ICellRendererParams
> {
  public onClick = () => {
    const { value } = this.props;
    const { handleDeleteEquipmentTemp } = store;

    handleDeleteEquipmentTemp(value);
  };

  public render() {
    const { value, data, node } = this.props;
    if (node.group) return value;

    const { selectedItem } = store;
    return (
      <Popconfirm title='Xóa?' onConfirm={this.onClick}>
        <UIButton
          icon='delete'
          size='small'
          type='danger'
          disabled={
            selectedItem.status !== CREATED ||
            !isAuthorized(
              PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_REMOVE,
              data.locationId
            )
          }
        />
      </Popconfirm>
    );
  }
}

export { GridEquipmentTempActionCellRenderer };

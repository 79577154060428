import { Button, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import EmployeeDropdown from '~components/Dropdown/EmployeeDropdown';
import { MacInput } from '~components/UI/MacInput';
import { UIField } from '~components/UI/UIField';
import { EmployeeDeviceInput } from '~graphql/types';

import { PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER } from '@mgn/common';

import store from './store';

interface IFormValues extends EmployeeDeviceInput {
  _id: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),

  mac: Yup.string()
    .required('Bắt buộc nhập')
    .matches(/^([A-F0-9]{2}:){5}([A-F0-9]{2})$/, {
      message: 'Vui lòng nhập đúng định dạng MAC',
      excludeEmptyString: true,
    }),

  device: Yup.string().required('Bắt buộc nhập'),

  employeeId: Yup.string().required('Bắt buộc nhập'),

  description: Yup.string(),
});

@observer
class EmployeeDeviceForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm, setSubmitting } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { visible, toggleModal, selectedItem } = store;

    const formInitialValues = {
      _id: get(selectedItem, '_id'),
      mac: get(selectedItem, 'mac'),
      device: get(selectedItem, 'device'),
      employeeId: get(selectedItem, 'employeeId'),
      description: get(selectedItem, 'description'),
    };

    console.error(formInitialValues);

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            type='primary'
            htmlType='submit'
            form='employeeDeviceFm'
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='employeeDeviceFm'>
            <Field
              name='employeeId'
              label='Nhân viên'
              component={UIField}
              fieldComponent={EmployeeDropdown}
              fieldProps={{
                placeholder: 'Chọn nhân viên',
                permission: PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER,
              }}
              nativeInput={false}
            />
            <Field
              name='mac'
              label='MAC'
              component={UIField}
              fieldComponent={MacInput}
              fieldProps={{
                placeholder: 'Nhập MAC của thiết bị',
              }}
            />
            <Field
              name='device'
              label='Loại thiết bị'
              component={UIField}
              fieldProps={{
                placeholder: 'Nhập tên thiết bị',
              }}
            />
            <Field
              name='description'
              label='Mô tả'
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                autosize: { minRows: 2, maxRows: 6 },
                placeholder: 'Nhập mô tả',
              }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}
export default EmployeeDeviceForm;

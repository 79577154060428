import { css } from 'emotion';

export const printContainer = css({
  fontSize: '12px',
  flexFlow: 'column',
  padding: '30px',
  minHeight: '100vh',
  maxWidth: 'calc(842px + 60px)',
  margin: '30px auto',
  boxShadow: '0px 0px 4px 1px #ccc',
  position: 'relative',

  p: {
    lineHeight: '20px',
    marginBottom: '0.5em',
  },

  '.header, .footer': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '.header': {
    '.header--right': {
      textAlign: 'right',
    },
  },

  '.footer > div': {
    padding: '0 20px',
  },

  '@media print': {
    height: 'auto',
    boxShadow: 'none',
  },

  '@page': {
    size: 'A4 landscape',
    margin: '0',
  },

  // '@page :first': {
  //   margin: '0 0 1cm 0',
  // },
});

export const printButton = css({
  position: 'fixed',
  right: '2em',
  bottom: '2em',
  zIndex: 1,

  '@media print': {
    display: 'none',
  },
});

export const textFullRowWithDot = css({
  display: 'flex',
  'span:last-child': {
    flex: '1',
    borderBottom: 'dashed 1px',
  },
});

export const h1Title = css`
  font-size: 16px;
  font-weight: bold;
`;

export const h2Title = css`
  font-size: 14px;
  font-weight: bold;
`;

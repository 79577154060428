import { Icon, Modal, Popconfirm, Row, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile as File } from 'antd/lib/upload/interface';
import { findIndex, isEmpty } from 'lodash';
import React, { Component } from 'react';

import { ErrorNoti, SuccessNoti, UIButton } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_UPLOAD_LINK_LOCATION_MUTATION,
  REMOVE_FILE_LOCATION_MUTATION,
  UPDATE_DETAIL_LOCATION_MUTATION,
} from '~graphql/mutations';
import {
  CreateUploadLinkLocationMutation,
  CreateUploadLinkLocationVariables,
  FullLocationFragment,
  RemoveFileLocationMutation,
  RemoveFileLocationVariables,
  UpdateDetailLocationMutation,
  UpdateDetailLocationVariables,
} from '~graphql/types';
import { IFile, RECORD_FILE } from '@mgn/common';

interface IProps {
  fileList: IFile[];
  locationId: string;
  typeFile: string;
  disable: boolean;
  onUploadChange?: (location: FullLocationFragment) => void;
}

interface IState {
  previewVisible: boolean;
  uploading: boolean;
  previewImage?: IPreviewImage;
  fileList?: File[];
}

interface IPreviewImage {
  name: string;
  url: string;
}

class UploadFile extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: null,
      fileList: [],
      uploading: false,
    };
  }

  public componentWillMount = () => {
    const { fileList } = this.props;

    this.updateFileList(fileList);
  };

  public componentWillReceiveProps(nextProps) {
    if (this.props.fileList !== nextProps.fileList) {
      this.updateFileList(nextProps.fileList);
    }
  }

  public updateFileList(fileList) {
    const mappedFileList = isEmpty(fileList)
      ? []
      : fileList.map(f => {
          return {
            uid: f.id,
            name: f.name,
            status: 'done',
            url: f.url,
          };
        });

    this.setState({ fileList: mappedFileList });
  }

  public togglePreview = (file = null) => {
    this.setState({
      previewVisible: !this.state.previewVisible,
      previewImage: file,
    });
  };

  public handleRemove = async file => {
    const { locationId, typeFile, onUploadChange } = this.props;
    const { fileList } = this.state;

    const {
      data: { removeFileLocation },
    } = await client.mutate<
      RemoveFileLocationMutation,
      RemoveFileLocationVariables
    >({
      mutation: REMOVE_FILE_LOCATION_MUTATION,
      variables: { id: file.uid },
    });

    if (removeFileLocation) {
      const index = findIndex(fileList, f => f.uid === removeFileLocation.id);
      fileList.splice(index, 1);
      this.setState({ fileList });

      const mappedFiles = fileList.map(f => {
        return { id: f.uid, name: f.name };
      });

      const {
        data: { updateDetailLocation },
      } = await client.mutate<
        UpdateDetailLocationMutation,
        UpdateDetailLocationVariables
      >({
        mutation: UPDATE_DETAIL_LOCATION_MUTATION,
        variables: {
          _id: locationId,
          [typeFile]: [...mappedFiles],
        },
      });

      const { error, location } = updateDetailLocation;
      if (error) {
        ErrorNoti(error.message);
      } else {
        this.updateFileList(location[typeFile]);
        onUploadChange(location);
        this.togglePreview();
        SuccessNoti('Xóa file');
      }
    }
  };

  public handleUpload = async ({ file }) => {
    const { locationId, typeFile, onUploadChange } = this.props;
    const { fileList } = this.state;

    this.setState({
      uploading: true,
    });

    const {
      data: { createUploadLinkLocation },
    } = await client.mutate<
      CreateUploadLinkLocationMutation,
      CreateUploadLinkLocationVariables
    >({
      mutation: CREATE_UPLOAD_LINK_LOCATION_MUTATION,
      variables: { id: file.uid, name: file.name },
    });

    const { path, id } = createUploadLinkLocation;

    const mappedFiles = await fileList.map(f => {
      return { id: f.uid, name: f.name };
    });

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', path, true);
    xhr.send(file);
    xhr.onload = async () => {
      if (xhr.status === 200) {
        const {
          data: { updateDetailLocation },
        } = await client.mutate<
          UpdateDetailLocationMutation,
          UpdateDetailLocationVariables
        >({
          mutation: UPDATE_DETAIL_LOCATION_MUTATION,
          variables: {
            _id: locationId,
            [typeFile]: [...mappedFiles, { id, name: file.name }],
          },
        });

        const { error, location } = updateDetailLocation;
        if (error) {
          ErrorNoti(error.message);
        } else {
          this.updateFileList(location[typeFile]);
          onUploadChange(location);
          SuccessNoti('Tải file lên');
        }
      } else {
        ErrorNoti('Tải file lên thất bại!');
      }

      this.setState({
        uploading: false,
      });
    };
  };

  public handleChange = ({ fileList }) => this.setState({ fileList });

  public render() {
    const { disable, typeFile } = this.props;
    const { previewVisible, previewImage, fileList } = this.state;

    let ext = '';
    if (!isEmpty(previewImage)) {
      ext = previewImage.name.slice(
        previewImage.name.lastIndexOf('.'),
        previewImage.name.length
      );
    }

    const props: UploadProps = {
      fileList,
      listType: 'picture-card',
      onPreview: file => {
        this.togglePreview(file);
      },
      customRequest: option => {
        this.handleUpload(option);
      },
      onChange: info => {
        this.handleChange(info);
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: false,
      },
      accept:
        typeFile === RECORD_FILE ? '*' : 'image/x-png,image/jpg,image/jpeg',
    };

    const uploadButton = (
      <div>
        <Icon type='plus' />
        <p>Tải lên</p>
      </div>
    );

    const footer = (
      <Row>
        <Popconfirm
          title='Bạn có muốn xóa file?'
          onConfirm={() => this.handleRemove(previewImage)}
        >
          <UIButton type='danger'>Xóa</UIButton>
        </Popconfirm>
      </Row>
    );

    return (
      <div className='clearfix'>
        <Upload {...props}>{!disable && uploadButton}</Upload>
        {previewImage && (
          <Modal
            title={previewImage.name}
            visible={previewVisible}
            footer={!disable ? footer : null}
            onCancel={() => this.togglePreview()}
          >
            {ext.toLowerCase() === '.jpg' ||
            ext.toLowerCase() === '.jpeg' ||
            ext.toLowerCase() === '.png' ? (
              <img
                alt='image'
                src={previewImage.url}
                style={{ width: '100%', marginTop: 32 }}
              />
            ) : (
              <a href={previewImage.url} target='_blank'>
                Tải về
              </a>
            )}
          </Modal>
        )}
      </div>
    );
  }
}

export default UploadFile;

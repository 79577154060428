import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Popover, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import EquipmentModal from '~components/EquipmentModal/EquipmentModal';
import ProposedEquipmentHistory from '~components/ProposedEquipmentHistory/ProposedEquipmentHistory';
import { AgGridTable, DescriptionItem, UIButton } from '~components/UI';
import {
  DetailedLocationEquipmentDetail,
  FullProposedEquipmentFragment,
} from '~graphql/types';
import { mergeEquipment } from '~utils';

import store from './store';

interface IProps {
  locationId: string;
  equipmentDetail: DetailedLocationEquipmentDetail[];
  proposedEquipment?: FullProposedEquipmentFragment | null;
}

@observer
class EquipmentViewDrawer extends Component<IProps> {
  public columns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'STT',
      field: '_id',
      pinned: 'left',
      minWidth: 60,
      maxWidth: 60,
      suppressSorting: true,
      cellRendererFramework: ({ rowIndex }) => rowIndex + 1,
    },
    {
      headerName: 'Loại thiết bị',
      minWidth: 100,
      field: 'equipmentModel.equipmentCategory.name',
      cellRendererFramework: ({ value, data }) => (
        <span
          style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}
          onClick={() =>
            store.toggleEquipmentModal({
              categoryIdFilter: get(data, 'equipmentModel.equipmentCategoryId'),
            })
          }
        >
          {value}
        </span>
      ),
    },
    {
      headerName: 'Tên thiết bị',
      field: 'equipmentModel.name',
      minWidth: 100,
      cellRendererFramework: ({ value, data }) => (
        <span
          style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}
          onClick={() =>
            store.toggleEquipmentModal({
              modelIdFilter: get(data, 'equipmentModelId'),
            })
          }
        >
          {value}
        </span>
      ),
    },
    {
      headerName: 'Số lượng',
      suppressSorting: true,
      field: 'quantity',
      minWidth: 100,
    },
  ];

  public equipmentModelColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Loại thiết bị',
      field: 'equipmentModel.equipmentCategory.name',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      headerName: 'Tên',
      field: 'equipmentModel.name',
      minWidth: 80,
    },
    {
      headerName: 'Hiện tại',
      suppressSorting: true,
      field: 'oldQty',
      minWidth: 75,
      maxWidth: 75,
    },
    {
      headerName: 'Thêm',
      suppressSorting: true,
      field: 'receiveQty',
      minWidth: 65,
      maxWidth: 65,
    },
    {
      headerName: 'Thu',
      suppressSorting: true,
      field: 'issueQty',
      minWidth: 60,
      maxWidth: 60,
    },
    {
      headerName: 'Tổng',
      suppressSorting: true,
      field: 'totalQty',
      minWidth: 60,
      maxWidth: 60,
    },
    {
      headerName: 'Serials',
      field: 'serials',
      minWidth: 120,
      suppressSorting: true,
      valueFormatter: ({ value }) => (value ? value.join(', ') : null),
      autoHeight: true,
      cellClass: 'cell-wrap-text',
    },
  ];

  public getRowHeight = ({ data }) => {
    const flattenMenuData = get(data, 'serials', []);
    if (!flattenMenuData) return 38;
    const heightWithPadding = flattenMenuData.length * 32 + 6;
    const heightWhenNoData = 35;
    return Math.max(heightWhenNoData, heightWithPadding);
  };

  public render() {
    const { locationId, equipmentDetail, proposedEquipment } = this.props;
    const {
      toggleEquipmentModal,
      toggleProposedEquipmentHistory,
      proposedEquipmentHistoryVisible,
      equipmentModalVisible,
      categoryIdFilter,
      modelIdFilter,
    } = store;

    let title = 'Thiết bị hiện tại';

    if (proposedEquipment) {
      switch (get(proposedEquipment, 'type')) {
        case 'ADD_EQUIPMENT':
          title = 'Đề xuất thiết bị';
          break;
        case 'REPLACE_EQUIPMENT':
          title = 'Thay thế thiết bị';
          break;
      }
    }

    const isProposedEquipment = (
      <Fragment>
        <Row
          type='flex'
          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <h2>{title}</h2>
          <UIButton
            type='info'
            onClick={() => toggleProposedEquipmentHistory()}
            style={{ marginLeft: 8, float: 'right' }}
          >
            Lịch sử thay đổi
          </UIButton>
        </Row>

        <DescriptionItem
          title='Lý do'
          content={get(proposedEquipment, 'proposalReason')}
        />
        <DescriptionItem
          title={'Yêu cầu vật tư'}
          content={
            <Popover
              content={
                <span style={{ whiteSpace: 'pre-wrap' }}>
                  {get(proposedEquipment, 'materialDetail')}
                </span>}
            >
              <span style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}>
                Xem chi tiết
              </span>
            </Popover>
          }
        />

        <AgGridTable
          columnDefs={this.equipmentModelColumns}
          rowData={mergeEquipment(
            equipmentDetail,
            get(proposedEquipment, 'equipmentDetail', [])
          )}
          rowClassRules={{
            redRow: 'data.oldQty > data.totalQty',
            greenRow: 'data.oldQty < data.totalQty',
          }}
          getRowHeight={this.getRowHeight}
        />
      </Fragment>
    );

    const notProposedEquipment = (
      <Fragment>
        <Row
          type='flex'
          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <h2>
            <span
              style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}
              onClick={() => toggleEquipmentModal(true)}
            >
              {title}
            </span>
          </h2>
          <h2>
            <UIButton
              type='info'
              onClick={() => toggleProposedEquipmentHistory()}
              style={{ marginLeft: 8, float: 'right' }}
            >
              Lịch sử thay đổi
            </UIButton>
          </h2>
        </Row>
        <Row>
          <AgGridTable columnDefs={this.columns} rowData={equipmentDetail} />
        </Row>
      </Fragment>
    );

    return (
      <Fragment>
        {proposedEquipment ? isProposedEquipment : notProposedEquipment}

        <ProposedEquipmentHistory
          locationId={locationId}
          visible={proposedEquipmentHistoryVisible}
          toggleModal={toggleProposedEquipmentHistory}
        />

        <EquipmentModal
          locationId={locationId}
          visible={equipmentModalVisible}
          toggleModal={toggleEquipmentModal}
          categoryIdFilter={categoryIdFilter}
          modelIdFilter={modelIdFilter}
        />
      </Fragment>
    );
  }
}

export default EquipmentViewDrawer;

import { Row, Tabs } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { TabName } from '~components/UI';
import { FullGoodsReceiptFragment } from '~graphql/types';
import Header from '~screens/GoodsIssue/Hearder';

import {
  AGREED,
  CANCELLED,
  CREATED,
  PERMISSION_GOODS_RECEIPT_CREATE,
  WAITING_AGREEMENT,
} from '@mgn/common';

import GoodsReceiptForm from './Form';
import GoodsReceiptTable from './Grid';
import store from './store';

const { TabPane } = Tabs;

interface IProps {
  data: FullGoodsReceiptFragment;
}

@observer
class GoodsReceipt extends Component<IProps> {
  public componentWillMount() {
    store.setNotification();
  }

  public handleTabChanged = activeKey => {
    store.setStatus(activeKey);
  };

  public render() {
    const { status, noti, toggleModal, setFilter, filter } = store;

    const tabs = (
      <Tabs onChange={this.handleTabChanged} activeKey={status}>
        <TabPane
          key={CREATED}
          tab={<TabName title='Mới' count={get(noti, 'createdTab', 0)} />}
        />
        <TabPane
          key={WAITING_AGREEMENT}
          tab={
            <TabName
              title='Chờ duyệt'
              count={get(noti, 'waitingAgreementTab', 0)}
            />
          }
        />
        <TabPane
          key={AGREED}
          tab={<TabName title='Đã duyệt' count={get(noti, 'agreedTab', 0)} />}
        />
        <TabPane
          key={CANCELLED}
          tab={<TabName title='Huỷ' count={get(noti, 'cancelledTab', 0)} />}
        />
        <TabPane
          key=''
          tab={<TabName title='Tất cả' count={get(noti, 'allTab', 0)} />}
        />
      </Tabs>
    );

    return (
      <Row
        type='flex'
        style={{
          flexFlow: 'column',
          height: '100vh',
        }}
      >
        <h2 style={{ marginTop: 8 }}>Danh sách phiếu nhập</h2>
        <Row style={{ flex: 0 }}>{tabs}</Row>
        <Header
          text='phiếu nhập'
          handeFilter={setFilter}
          toggleModal={toggleModal}
          permission={PERMISSION_GOODS_RECEIPT_CREATE}
          status={status}
          filter={filter}
        />
        <div style={{ flex: 1, marginTop: 8 }}>
          <GoodsReceiptTable />
        </div>
        <GoodsReceiptForm />
      </Row>
    );
  }
}

export default GoodsReceipt;

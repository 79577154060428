import { isEmpty, set } from 'lodash';
import { action, observable, runInAction } from 'mobx';

import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_ROLE_MAPPING_MUTATION,
  REMOVE_ROLE_MAPPING_MUTATION,
} from '~graphql/mutations';
import { FIND_MANY_ROLE_MAPPING_QUERY } from '~graphql/queries';
import {
  CreateRoleMappingMutation,
  CreateRoleMappingVariables,
  FindManyRoleMappingQuery,
  FullRoleMappingFragment,
  RemoveRoleMappingMutation,
  RemoveRoleMappingVariables,
} from '~graphql/types';

import { IRowMapping } from './GridExpandedRow';
import menuStore from './MenuStore';

class RoleStore {
  @observable public selectedRoleMapping: IRowMapping;
  @observable public visible: boolean;
  @observable public loading: boolean;
  @observable public quickFilter: string;
  @observable.shallow public roleMappings: FullRoleMappingFragment[];
  public employeeId: string = null;

  constructor() {
    runInAction(() => {
      this.selectedRoleMapping = null;
      this.visible = false;
      this.loading = true;
      this.quickFilter = null;
      this.roleMappings = [];
    });
  }

  @action public onRowExpanded(employeeId: string) {
    this.loading = true;
    this.selectedRoleMapping = null;
    this.roleMappings = [];
    this.employeeId = employeeId;

    this.fetchRoleMapping(employeeId).then(
      action(() => {
        this.loading = false;
      })
    );
  }

  @action public toggleModal = (record = null) => {
    this.visible = !this.visible;
    this.selectedRoleMapping = record;
  };

  @action public setQuickFilterText = filter => {
    this.quickFilter = filter;
  };

  @action public handleRoleMappingCreate = async (
    record: CreateRoleMappingVariables
  ) => {
    const { locationId, roleId, employeeId } = record;

    const {
      data: { createRoleMapping },
    } = await client.mutate<
      CreateRoleMappingMutation,
      CreateRoleMappingVariables
    >({
      mutation: CREATE_ROLE_MAPPING_MUTATION,
      variables: { locationId, roleId, employeeId },
      refetchQueries: [
        {
          query: FIND_MANY_ROLE_MAPPING_QUERY,
        },
        {
          query: FIND_MANY_ROLE_MAPPING_QUERY,
          variables: { where: { employeeId } },
        },
      ],
      errorPolicy: 'all',
    });

    const { error, roleMapping } = createRoleMapping;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo quyền');
      this.updateData(roleId, roleMapping);
      this.toggleModal();
    }
  };

  @action public handleDelete = async (roleId: string, employeeId: string) => {
    const {
      data: { removeRoleMapping },
    } = await client.mutate<
      RemoveRoleMappingMutation,
      RemoveRoleMappingVariables
    >({
      mutation: REMOVE_ROLE_MAPPING_MUTATION,
      variables: { roleId, employeeId },
      refetchQueries: [
        {
          query: FIND_MANY_ROLE_MAPPING_QUERY,
        },
        {
          query: FIND_MANY_ROLE_MAPPING_QUERY,
          variables: { where: { employeeId } },
        },
      ],
      errorPolicy: 'all',
    });

    const { error } = removeRoleMapping;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Xóa chức danh');
      this.updateData(roleId);
    }
  };

  @action public fetchRoleMapping = async (employeeId: string) => {
    const {
      data: { findManyRoleMapping },
    } = await client.query<FindManyRoleMappingQuery>({
      query: FIND_MANY_ROLE_MAPPING_QUERY,
      fetchPolicy: 'network-only',
      variables: { where: { employeeId } },
    });
    if (!isEmpty(findManyRoleMapping)) {
      const groupedData = findManyRoleMapping.reduce((acc, cur) => {
        if (!acc[cur.roleId]) {
          acc[cur.roleId] = {
            _id: cur.roleId,
            roleId: cur.roleId,
            role: cur.role,
            employee: cur.employee,
            employeeId: cur.employeeId,
            locationId: [],
            location: [],
          };
        }
        acc[cur.roleId].locationId.push(cur.locationId);
        acc[cur.roleId].location.push(cur.location);
        return acc;
      }, {});

      runInAction(() => {
        const a = Object.keys(groupedData).map(e => ({
          ...groupedData[e],
        }));
        this.roleMappings = a;
      });
    }
  };

  @action private updateData = (_id: string | string[], record?) => {
    const { updateRoleData } = menuStore;
    const index = this.roleMappings.findIndex(item => item.roleId === _id);
    if (!record) {
      this.roleMappings = [
        ...this.roleMappings.slice(0, index),
        ...this.roleMappings.slice(index + 1),
      ];
      updateRoleData(_id as any, this.employeeId, record);
    } else {
      const groupedData = record.reduce((acc, cur) => {
        if (!acc[cur.roleId]) {
          acc[cur.roleId] = {
            _id: cur.roleId,
            roleId: cur.roleId,
            role: cur.role,
            employee: cur.employee,
            employeeId: cur.employeeId,
            locationId: [],
            location: [],
          };
        }
        acc[cur.roleId].locationId.push(cur.locationId);
        acc[cur.roleId].location.push(cur.location);
        return acc;
      }, {});
      const data: any = Object.keys(groupedData).map(e => ({
        ...groupedData[e],
      }));

      this.roleMappings =
        index > -1
          ? [
              ...this.roleMappings.slice(0, index),
              ...data,
              ...this.roleMappings.slice(index + 1),
            ].filter(Boolean)
          : [...this.roleMappings, ...data];
      updateRoleData(_id as any, null, record);
    }
  };
}

export { RoleStore };

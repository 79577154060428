import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import client from '~graphql/client';
import { FIND_MANY_ROLE_QUERY } from '~graphql/queries';
import {
  FindManyRoleQuery,
  FindManyRoleVariables,
  FullRoleFragment,
} from '~graphql/types';

const { Option } = Select;

interface IRoleDropdownProps extends SelectProps {
  roleId: string[];
}

@observer
export default class RoleDropdown extends Component<IRoleDropdownProps> {
  @observable public loading: boolean;
  @observable public data: FullRoleFragment[];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = true;
      this.data = [];
    });
  }

  public componentDidMount() {
    this.loadData().then(
      action('setLoading', () => {
        this.loading = false;
      })
    );
  }

  @action public loadData = async () => {
    this.loading = true;
    const { data } = await client.query<
      FindManyRoleQuery,
      FindManyRoleVariables
    >({
      query: FIND_MANY_ROLE_QUERY,
      fetchPolicy: 'network-only',
    });
    runInAction(() => {
      this.loading = false;
      const dataAfterFilter = data.findManyRole.filter(
        e => e.name !== 'SUPERADMIN'
      );
      this.data =
        dataAfterFilter.filter(i => !this.props.roleId.includes(i.name)) || [];
    });
  };

  public render() {
    return (
      <Select
        allowClear={true}
        style={{ width: '100%' }}
        {...this.props}
        loading={this.loading}
      >
        {this.data.map(item => {
          return (
            <Option key={item._id} value={item.name}>
              {item.display}
            </Option>
          );
        })}
      </Select>
    );
  }
}

import { Button, Input, Modal, Row, Col, Icon } from "antd";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { get } from "lodash";
import { observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import * as Yup from "yup";
import LocationDropdown from "~components/Dropdown/LocationDropdown";
import { UIDatePicker } from "~components/UI/UIDatePicker";
import { UIField } from "~components/UI/UIField";
import { CreateGoodsReceiptVariables } from "~graphql/types";

import { WAREHOUSE_TYPE } from "@mgn/common";

import store from "./store";

const { TextArea } = Input;

const validationSchema = Yup.object().shape<IFormValues>({
  code: Yup.string(),
  issueInvoiceDay: Yup.string().nullable(true),
  toLocationId: Yup.string().required("Bắt buộc chọn")
});

interface IFormValues extends CreateGoodsReceiptVariables {
  code: string;
}

@observer
class GoodsReceiptForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { setSubmitting, resetForm } = actions;

    const { code, ...record } = values;

    if (code) {
      await handleUpdate(code, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { selectedItem, visible, toggleModal } = store;

    const initialValues: IFormValues = {
      code: get(selectedItem, "code"),
      issueInvoiceDay: get(selectedItem, "issueInvoiceDay"),
      toLocationId: get(selectedItem, "toLocationId"),
      description: get(selectedItem, "description"),
      fromLocationId: get(selectedItem, "fromLocationId")
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            type="primary"
            htmlType="submit"
            form="goodsReceiptFm"
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values.code ? "Cập nhật" : "Tạo mới"}
          </Button>
          <Button type="danger" onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id="goodsReceiptFm">
            {get(selectedItem, "proposedEquipmentId") ? (
              <Fragment>
                <Row gutter={8} type="flex">
                  <Col span={24} md={{ span: 10 }}>
                    <Field
                      name="fromLocationId"
                      label="Kho nguồn"
                      component={UIField}
                      fieldComponent={LocationDropdown}
                      fieldProps={{
                        disabled: true
                      }}
                      nativeInput={false}
                    />
                  </Col>
                  <Col
                    span={4}
                    style={{
                      alignSelf: "center",
                      fontSize: "2em",
                      textAlign: "center"
                    }}
                  >
                    <Icon type="arrow-right" />
                  </Col>
                  <Col span={24} md={{ span: 10 }}>
                    <Field
                      name="toLocationId"
                      label="Kho đích"
                      fieldComponent={LocationDropdown}
                      fieldProps={{
                        disabled: true
                      }}
                      component={UIField}
                      nativeInput={false}
                    />
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Fragment>
                <Field
                  name="toLocationId"
                  label="Kho đích"
                  component={UIField}
                  fieldComponent={LocationDropdown}
                  fieldProps={{
                    locationType: WAREHOUSE_TYPE,
                    disabled: Boolean(selectedItem && selectedItem.code),
                    placeholder: "Chọn kho đích"
                  }}
                  nativeInput={false}
                />
                <Field
                  name="issueInvoiceDay"
                  label="Ngày xuất hóa đơn"
                  component={UIField}
                  fieldComponent={UIDatePicker}
                  nativeInput={false}
                />
              </Fragment>
            )}

            <Field
              name="description"
              label="Mô tả"
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                autosize: { minRows: 2, maxRows: 6 },
                placeholder: "Nhập mô tả"
              }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default GoodsReceiptForm;

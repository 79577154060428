import gql from 'graphql-tag';
import menu from '../fragments/menu';

export const FIND_MANY_MENU_QUERY = gql`
  query FindManyMenu(
    $skip: Int
    $take: Int
    $where: MenuBaseFilter
    $order: MenuBaseSort
  ) {
    findManyMenu(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullMenu
    }
  }
  ${menu.full}
`;

import { Button, Col, Icon, Input, Modal, Row } from "antd";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { get } from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import * as Yup from "yup";
import LocationDropdown from "~components/Dropdown/LocationDropdown";
import { UIField } from "~components/UI/UIField";
import { CreateGoodsIssueVariables } from "~graphql/types";

import { WAREHOUSE_TYPE } from "@mgn/common";

import store from "./store";

const { TextArea } = Input;

const validationSchema = Yup.object().shape<IFormValues>({
  code: Yup.string(),
  fromLocationId: Yup.string().required("Bắt buộc chọn"),
  toLocationId: Yup.string().required("Bắt buộc chọn")
});

interface IFormValues extends CreateGoodsIssueVariables {
  code: string;
}

@observer
class GoodsIssueForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { setSubmitting, resetForm } = actions;

    const { code, ...record } = values;

    if (code) {
      await handleUpdate(code, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { selectedItem, visible, toggleModal } = store;

    const initialValues: IFormValues = {
      code: get(selectedItem, "code"),
      fromLocationId: get(selectedItem, "fromLocationId"),
      toLocationId: get(selectedItem, "toLocationId"),
      description: get(selectedItem, "description")
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values, setFieldValue } = props;

      const footer = (
        <Row>
          <Button
            type="primary"
            htmlType="submit"
            form="goodsIssueFm"
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values.code ? "Cập nhật" : "Tạo mới"}
          </Button>
          <Button type="danger" onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      function onChange(field: string, value: any) {
        setFieldValue(field, value);
      }

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id="goodsIssueFm">
            <Row gutter={8} type="flex">
              <Col span={24} md={{ span: 10 }}>
                <Field
                  name="fromLocationId"
                  label="Kho nguồn"
                  component={UIField}
                  fieldComponent={LocationDropdown}
                  nativeInput={false}
                  fieldProps={{
                    locationType: WAREHOUSE_TYPE,
                    onChange: onChange.bind(this, "fromLocationId"),
                    disabled: Boolean(get(selectedItem, "_id")),
                    placeholder: "Chọn kho nguồn"
                  }}
                />
              </Col>
              <Col
                span={4}
                style={{
                  alignSelf: "center",
                  fontSize: "2em",
                  textAlign: "center"
                }}
              >
                <Icon type="arrow-right" />
              </Col>
              <Col span={24} md={{ span: 10 }}>
                <Field
                  name="toLocationId"
                  label="Kho đích"
                  component={UIField}
                  fieldComponent={LocationDropdown}
                  nativeInput={false}
                  fieldProps={{
                    onChange: onChange.bind(this, "toLocationId"),
                    disabled: Boolean(get(selectedItem, "_id")),
                    placeholder: "Chọn kho đích",
                    locationType:
                      !get(selectedItem, "proposedEquipmentId") &&
                      WAREHOUSE_TYPE
                  }}
                />
              </Col>
            </Row>
            <Field
              name="description"
              label="Mô tả"
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                autosize: { minRows: 2, maxRows: 6 },
                placeholder: "Nhập mô tả"
              }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default GoodsIssueForm;

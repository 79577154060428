import { Row, Tabs } from 'antd';
import { get } from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { TabName } from '~components/UI';
import UpdateContentLocation from '~components/UpdateContentLocation/UpdateContentLocation';
import UpdateDetailLocationForm from '~components/UpdateLocationForm/UpdateDetailLocation';
import ViewDrawer from '~components/ViewDrawer/ViewDrawer';
import authStore from '~stores/authStore';
import { countLocation } from '~utils/countLocation';

import {
  AGREED,
  CREATED,
  INSTALLED,
  PERMISSION_EMPLOYEE_FREEWIFI_ADMIN,
  PERMISSION_EMPLOYEE_FREEWIFI_INSTALLER,
  PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER,
  PERMISSION_EMPLOYEE_FREEWIFI_MANAGER,
  PERMISSION_EMPLOYEE_FREEWIFI_SURVEYOR,
  STOPPED,
  WAITING_AGREEMENT,
  WAITING_INSTALLER,
  WAITING_MAINTAINER,
  WAITING_SURVEYOR
} from '@mgn/common';

import LocationFilter from './Filter';
import HomeScreenTable from './Grid';
import store from './store';

const { TabPane } = Tabs;

const getDefaultActiveKey = currentPermission => {
  if (currentPermission[PERMISSION_EMPLOYEE_FREEWIFI_ADMIN]) return CREATED;
  if (currentPermission[PERMISSION_EMPLOYEE_FREEWIFI_SURVEYOR]) {
    return WAITING_SURVEYOR;
  }
  if (currentPermission[PERMISSION_EMPLOYEE_FREEWIFI_MANAGER]) {
    return WAITING_AGREEMENT;
  }
  if (currentPermission[PERMISSION_EMPLOYEE_FREEWIFI_INSTALLER]) {
    return WAITING_INSTALLER;
  }
  if (currentPermission[PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER]) {
    return WAITING_MAINTAINER;
  }
  return CREATED;
};

@observer
class HomeScreenTabs extends Component {
  public async componentWillMount() {
    store.setNotification();
    await countLocation(store.whereFilter).then(value => {
      runInAction(() => {
        store.setCount(value);
      });
    });
  }

  public onTabChanged = async activeKey => {
    store.onTabChanged(activeKey);
    await countLocation(store.whereFilter).then(value => {
      runInAction(() => {
        store.setCount(value);
      });
    });
  };

  public onFilterChange = async values => {
    store.setFilter(values);
    await countLocation(store.whereFilter).then(value => {
      runInAction(() => {
        store.setCount(value);
      });
    });
  };

  public updateCount = async () => {
    await countLocation(store.whereFilter).then(value => {
      runInAction(() => {
        store.setCount(value);
      });
    });
  };

  public render() {
    const { permissions: currentPermission } = authStore;
    const {
      noti,
      status,
      toggleUpdateContent,
      selectedItem,
      updateContentVisible,
      onChange,
      viewVisible,
      updateDetailVisible,
      toggleView,
      toggleUpdateDetail,
      whereFilter,
      reset,
      count,
    } = store;

    const tabs = (
      <Tabs
        size='small'
        onChange={this.onTabChanged}
        activeKey={status[0]}
        defaultActiveKey={getDefaultActiveKey(currentPermission)}
      >
        <TabPane
          key={CREATED}
          tab={<TabName title='Mới tạo' count={get(noti, 'created', 0)} />}
        />
        <TabPane
          key={WAITING_SURVEYOR}
          tab={
            <TabName title='Chờ khảo sát' count={get(noti, 'surveyor', 0)} />}
        />
        <TabPane
          key={WAITING_AGREEMENT}
          tab={<TabName title='Chờ duyệt' count={get(noti, 'manager', 0)} />}
        />
        <TabPane
          key={AGREED}
          tab={<TabName title='Kết quả duyệt' count={get(noti, 'result', 0)} />}
        />
        <TabPane
          key={WAITING_INSTALLER}
          tab={
            <TabName title='Chờ thi công' count={get(noti, 'installer', 0)} />}
        />
        <TabPane
          key={INSTALLED}
          tab={<TabName title='Hoàn công' count={get(noti, 'record', 0)} />}
        />
        <TabPane
          key={WAITING_MAINTAINER}
          tab={<TabName title='Hoạt động' count={get(noti, 'maintainer', 0)} />}
        />
        <TabPane key={STOPPED} tab={<TabName title='Ngưng hoạt động' />} />
      </Tabs>
    );
    return (
      <Fragment>
        <Row
          type='flex'
          style={{
            flexFlow: 'column',
            height: '100vh',
          }}
        >
          <Row>{tabs}</Row>
          <Row gutter={8}>
            <LocationFilter
              onChange={this.onFilterChange}
              count={count}
              reset={reset}
            />
          </Row>
          <div style={{ flex: 1, marginTop: 8 }}>
            <HomeScreenTable whereFilter={whereFilter} reset={reset} />
          </div>
        </Row>
        <UpdateContentLocation
          location={selectedItem}
          visible={updateContentVisible}
          toggleModal={toggleUpdateContent}
          onChange={onChange}
        />
        {!status.some(() => status.includes(CREATED)) && (
          <Fragment>
            <ViewDrawer
              visible={viewVisible}
              toggleDrawer={toggleView}
              locationId={get(selectedItem, '_id', '')}
            />
            <UpdateDetailLocationForm
              visible={updateDetailVisible}
              toggleDrawer={toggleUpdateDetail}
              locationId={get(selectedItem, '_id', '')}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default HomeScreenTabs;

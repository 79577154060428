import { action, computed, observable, runInAction } from 'mobx';

import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  REMOVE_EMPLOYEE_DEVICE_BY_ID_MUTATION,
  CREATE_EMPLOYEE_DEVICE_MUTATION,
  UPDATE_EMPLOYEE_DEVICE_MUTATION
} from '~graphql/mutations';
import { FIND_MANY_EMPLOYEE_DEVICE_QUERY } from '~graphql/queries';
import {
  FullEmployeeDeviceFragment,
  RemoveEmployeeDeviceByIdMutation,
  RemoveEmployeeDeviceByIdVariables,
  CreateEmployeeDeviceVariables,
  CreateEmployeeDeviceMutation,
  FindManyEmployeeDeviceQuery,
  EmployeeDeviceInput,
  UpdateEmployeeDeviceByIdMutation,
  UpdateEmployeeDeviceByIdVariables
} from '~graphql/types.d';
import { CREATED } from '@mgn/common';
import { removeTypename } from '~utils';

class EmployeeDeviceStore {
  @computed public get employeeDevice() {
    return this.data;
  }

  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public status: string;
  @observable public selectedItem: FullEmployeeDeviceFragment;
  @observable public quickFilter: string;

  @observable.shallow private data: FullEmployeeDeviceFragment[];

  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.visible = false;
      this.status = CREATED;
      this.selectedItem = null;
      this.quickFilter = null;
      this.data = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public handleDelete = async (_id: string) => {
    await client.mutate<
      RemoveEmployeeDeviceByIdMutation,
      RemoveEmployeeDeviceByIdVariables
    >({
      mutation: REMOVE_EMPLOYEE_DEVICE_BY_ID_MUTATION,
      variables: { _id },
      errorPolicy: 'all',
      update: (_cache, { data: { removeEmployeeDeviceById } }) => {
        const { error } = removeEmployeeDeviceById;
        if (error) {
          ErrorNoti(error.message);
        } else {
          SuccessNoti('Xóa loại vật tư thành công!');
          this.updateData(_id);
        }
      },
    });
  };

  @action public handleCreate = async (record: EmployeeDeviceInput) => {
    const {
      data: { createEmployeeDevice },
    } = await client.mutate<
      CreateEmployeeDeviceMutation,
      CreateEmployeeDeviceVariables
    >({
      mutation: CREATE_EMPLOYEE_DEVICE_MUTATION,
      variables: { record },
      errorPolicy: 'all',
    });

    const { error, payload } = createEmployeeDevice;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo loại vật tư thành công!');
      this.updateData(null, payload);
      this.toggleModal();
    }
  };

  @action public handleUpdate = async (
    _id: string,
    record: EmployeeDeviceInput
  ) => {
    const {
      data: { updateEmployeeDeviceById },
    } = await client.mutate<
      UpdateEmployeeDeviceByIdMutation,
      UpdateEmployeeDeviceByIdVariables
    >({
      mutation: UPDATE_EMPLOYEE_DEVICE_MUTATION,
      variables: { _id, record },
      errorPolicy: 'all',
    });

    const { error, payload } = updateEmployeeDeviceById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật loại vật tư thành công!');
      this.updateData(_id, payload);
      this.toggleModal();
    }
  };

  @action public toggleModal = (item = null) => {
    this.selectedItem = item;
    this.visible = !this.visible;
  };

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action private fetchData = async () => {
    const {
      data: { findManyEmployeeDevice },
    } = await client.query<FindManyEmployeeDeviceQuery>({
      query: FIND_MANY_EMPLOYEE_DEVICE_QUERY,
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.data = removeTypename(findManyEmployeeDevice);
    });
  };

  @action private updateData = (
    _id: string,
    record?: FullEmployeeDeviceFragment
  ) => {
    if (!_id) {
      this.data = this.data.concat(record);
    } else {
      const index = this.data.findIndex(item => item._id === _id);

      this.data = [
        ...this.data.slice(0, index),
        record,
        ...this.data.slice(index + 1),
      ].filter(Boolean);
    }
  };
}

export default new EmployeeDeviceStore();

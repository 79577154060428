import { GridReadyEvent } from "ag-grid-community";
import { pickBy, identity } from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { ErrorNoti, SuccessNoti } from "~components/UI";
import client from "~graphql/client";
import {
  CONFIRM_FROM_INSTALLER_LOCATION_MUTATION,
  CONFIRM_FROM_MAINTAINER_LOCATION_MUTATION,
  CONFIRM_FROM_SURVEYOR_LOCATION_MUTATION
} from "~graphql/mutations";
import {
  ConfirmFromInstallerLocationMutation,
  ConfirmFromInstallerLocationVariables,
  ConfirmFromMaintainerLocationMutation,
  ConfirmFromMaintainerLocationVariables,
  ConfirmFromSurveyorLocationMutation,
  ConfirmFromSurveyorLocationVariables,
  FullLocationFragment,
  LocationBaseFilter
} from "~graphql/types";
import authStore from "~stores/authStore";
import { countLocation } from "~utils/countLocation";

import {
  AGREED,
  CANCELLED,
  CREATED,
  INSTALLED,
  INSTALLING,
  PERMISSION_EMPLOYEE_FREEWIFI_ADMIN,
  STOPPED,
  SURVEYING,
  WAITING_AGREEMENT,
  WAITING_INSTALLER,
  WAITING_MAINTAINER,
  WAITING_SURVEYOR,
  WORKING,
  LOCATION_TYPE
} from "@mgn/common";

import { FilterValue } from "./Filter";
import { async } from "q";

interface INotification {
  created?: number;
  surveyor?: number;
  manager?: number;
  result?: number;
  installer?: number;
  record?: number;
  maintainer?: number;
}

class HomeScreenStore {
  @observable public status: string[];
  @observable public loading: boolean;
  @observable public viewVisible: boolean;
  @observable public updateDetailVisible: boolean;
  @observable public noti: INotification;
  @observable public updateContentVisible: boolean;
  @observable.ref public selectedItem: FullLocationFragment;
  @observable public filter: FilterValue;
  @observable public reset: boolean;
  @observable public count: number;
  @observable public locationIdFilter: string;

  constructor() {
    runInAction(() => {
      this.status = [CREATED];
      this.loading = true;
      this.viewVisible = false;
      this.updateDetailVisible = false;
      this.noti = null;
      this.updateContentVisible = false;
      this.selectedItem = null;
      this.filter = null;
      this.reset = false;
      this.count = 0;
      this.locationIdFilter = null;
    });
  }

  @computed public get whereFilter() {
    const filter: LocationBaseFilter = {
      locationType: LOCATION_TYPE,
      _operators: {
        status: { in: this.status }
      }
    };

    if (this.filter) {
      const {
        area,
        district,
        typeCode,
        employeeId,
        locationId,
        statusFilter
      } = this.filter;
      const object = pickBy(
        {
          area,
          district,
          typeCode,
          _id: locationId,
          status: statusFilter
        },
        identity
      );
      filter.AND = [object];
      if (employeeId) {
        filter.OR = [
          { installerId: employeeId },
          { surveyorId: employeeId },
          { maintainerId: employeeId }
        ];
      }
    }
    return filter;
  }

  @action public resetFilter = async () => {
    this.filter = null;
    await countLocation(this.whereFilter).then(values => {
      this.setCount(values);
    });
  };

  @action public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action public async setNotification() {
    const { currentEmployee, permissions: currentPermission } = authStore;

    const noti: INotification = {
      created: currentPermission.hasOwnProperty(
        PERMISSION_EMPLOYEE_FREEWIFI_ADMIN
      )
        ? await countLocation({
            locationType: LOCATION_TYPE,
            _operators: {
              status: { in: [CREATED] }
            }
          })
        : 0,
      surveyor: await countLocation({
        locationType: LOCATION_TYPE,
        _operators: {
          status: { in: [WAITING_SURVEYOR, SURVEYING] },
          surveyorId: { in: [currentEmployee._id.toString()] }
        }
      }),
      manager: currentPermission.hasOwnProperty(
        PERMISSION_EMPLOYEE_FREEWIFI_ADMIN
      )
        ? await countLocation({
            locationType: LOCATION_TYPE,
            _operators: {
              status: { in: [WAITING_AGREEMENT] }
            }
          })
        : 0,
      result: currentPermission.hasOwnProperty(
        PERMISSION_EMPLOYEE_FREEWIFI_ADMIN
      )
        ? await countLocation({
            locationType: LOCATION_TYPE,
            _operators: {
              status: { in: [AGREED, CANCELLED] }
            }
          })
        : 0,
      installer: await countLocation({
        locationType: LOCATION_TYPE,
        _operators: {
          status: { in: [WAITING_INSTALLER, INSTALLING] },
          installerId: { in: [currentEmployee._id.toString()] }
        }
      }),
      record: currentPermission.hasOwnProperty(
        PERMISSION_EMPLOYEE_FREEWIFI_ADMIN
      )
        ? await countLocation({
            locationType: LOCATION_TYPE,
            _operators: {
              status: { in: [INSTALLED] }
            }
          })
        : 0,
      maintainer: await countLocation({
        locationType: LOCATION_TYPE,
        _operators: {
          status: { in: [WAITING_MAINTAINER, WORKING] },
          maintainerId: { in: [currentEmployee._id.toString()] }
        }
      })
    };
    runInAction(() => {
      this.noti = noti;
    });
  }

  @action public update() {
    runInAction(() => {
      this.setNotification();
      this.reset = !this.reset;
    });
  }
  @action public onTabChanged = activeKey => {
    runInAction(() => {
      if (activeKey === WAITING_SURVEYOR) {
        this.status = [activeKey, SURVEYING];
      }
      if (activeKey === WAITING_INSTALLER) {
        this.status = [activeKey, INSTALLING];
      }
      if (activeKey === WAITING_MAINTAINER) {
        this.status = [activeKey, WORKING];
      }
      if (activeKey === AGREED) {
        this.status = [activeKey, CANCELLED];
      }
      if (
        activeKey === CREATED ||
        activeKey === WAITING_AGREEMENT ||
        activeKey === INSTALLED
      ) {
        this.status = [activeKey];
      }

      if (activeKey === STOPPED) {
        this.status = [activeKey];
      }
      runInAction(() => {
        this.setNotification();
        this.filter = {};
      });
    });
  };

  @action public setFilter = filter => {
    this.filter = filter;
  };

  @action public setCount = count => {
    runInAction(() => {
      this.count = count;
    });
  };

  @action public toggleUpdateContent = (record = null) => {
    this.updateContentVisible = !this.updateContentVisible;
    this.selectedItem = record;
  };

  @action public onChange = (location: FullLocationFragment) => {
    this.selectedItem = location;
  };

  @action public handleConfirmSurveyor = async ({ _id }) => {
    const {
      data: { confirmFromSurveyorLocation }
    } = await client.mutate<
      ConfirmFromSurveyorLocationMutation,
      ConfirmFromSurveyorLocationVariables
    >({
      mutation: CONFIRM_FROM_SURVEYOR_LOCATION_MUTATION,
      variables: { _id }
    });

    const { error } = confirmFromSurveyorLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Xác nhận khảo sát");
      this.update();
      await countLocation(this.whereFilter).then(values => {
        this.setCount(values);
      });
    }
  };

  @action public toggleView = (record = null) => {
    this.viewVisible = !this.viewVisible;
    this.selectedItem = record;
  };

  @action public toggleUpdateDetail = (record = null) => {
    this.updateDetailVisible = !this.updateDetailVisible;
    this.selectedItem = record;
  };

  @action public handleConfirmFromInstaller = async ({ _id }) => {
    const {
      data: { confirmFromInstallerLocation }
    } = await client.mutate<
      ConfirmFromInstallerLocationMutation,
      ConfirmFromInstallerLocationVariables
    >({
      mutation: CONFIRM_FROM_INSTALLER_LOCATION_MUTATION,
      variables: { _id }
    });

    const { error } = confirmFromInstallerLocation;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Nhận thi công");
      await countLocation(this.whereFilter).then(values => {
        this.setCount(values);
      });
      this.update();
    }
  };

  @action public handleConfirmFromMaintainer = async ({ _id }) => {
    const {
      data: { confirmFromMaintainerLocation }
    } = await client.mutate<
      ConfirmFromMaintainerLocationMutation,
      ConfirmFromMaintainerLocationVariables
    >({
      mutation: CONFIRM_FROM_MAINTAINER_LOCATION_MUTATION,
      variables: { _id }
    });

    const { error } = confirmFromMaintainerLocation;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Đã xác nhận vận hành");
      await countLocation(this.whereFilter).then(values => {
        this.setCount(values);
      });
      this.update();
    }
  };

  @action private reload = () => undefined;
}

export default new HomeScreenStore();

import gql from 'graphql-tag';

import equipmentModel from '../fragments/equipmentModel';

export const UPDATE_EQUIPMENT_MODEL_MUTATION = gql`
  mutation UpdateEquipmentModelById($_id: ID!, $record: EquipmentModelInput!) {
    updateEquipmentModelById(_id: $_id, record: $record) {
      payload {
        ...FullEquipmentModel
      }
      error {
        message
      }
    }
  }
  ${equipmentModel.full}
`;

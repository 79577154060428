import { Button, Col, Collapse, Drawer, Row, Tag } from 'antd';
import { get, pick } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import Authorized from '~components/Authorized/Authorized';
import EquipmentViewDrawer from '~components/EquipmentViewDrawer/EquipmentViewDrawer';
import {
  Contact,
  DescriptionItem,
  Loading,
  LocationEmployee,
  LocationInfo,
  UIButton
} from '~components/UI';
import UpdateContentLocation from '~components/UpdateContentLocation/UpdateContentLocation';
import UploadFile from '~components/UploadFile/UploadFile';
import {
  FullLocationNetwork,
  FullProposedEquipmentFragment
} from '~graphql/types';

import {
  DRAWING_FILE,
  PERMISSION_EMPLOYEE_FREEWIFI_ADMIN,
  PICTURE_FILE,
  RECORD_FILE,
  PERMISSION_LOCATION_UPDATE_INSTALLER,
  PERMISSION_LOCATION_UPDATE_CONTENT
} from '@mgn/common';

import store from './store';

const { Panel } = Collapse;

interface IProps {
  visible: boolean;
  toggleDrawer: () => void;
  locationId: string;
  disableDrawingPaths?: boolean;
  disablePicturePaths?: boolean;
  disableRecordPaths?: boolean;
  proposedEquipment?: FullProposedEquipmentFragment;
}

@observer
class ViewDrawer extends Component<IProps> {
  public static defaultProps: {
    disableDrawingPaths: boolean;
    disablePicturePaths: boolean;
    disableRecordPaths: boolean;
    proposedEquipemnt: any;
  };

  public componentDidMount = () => {
    store.setLocationId(this.props.locationId);
  };

  public componentWillReceiveProps = nextProps => {
    if (this.props.locationId !== nextProps.locationId) {
      store.setLocationId(nextProps.locationId);
    }
  };

  public render() {
    const { visible, toggleDrawer } = this.props;
    const { loading, selectedItem } = store;

    return (
      <Drawer closable={false} visible={visible} onClose={() => toggleDrawer()}>
        {loading || !selectedItem ? this.renderLoading() : this.renderDrawer()}
      </Drawer>
    );
  }

  public renderLoading() {
    const { loading } = store;
    return loading ? <Loading /> : '';
  }

  public renderDrawer() {
    const {
      toggleDrawer,
      disableDrawingPaths,
      disablePicturePaths,
      disableRecordPaths,
      proposedEquipment,
      children,
    } = this.props;
    const {
      selectedItem,
      toggleUpdateContentModal,
      updateContentVisible,
      setLocation,
    } = store;

    return (
      <Fragment>
        <div style={{ marginBottom: '3em' }}>
          <Row>
            <DescriptionItem
              title='Tên địa điểm'
              content={<LocationInfo location={selectedItem} />}
            />
          </Row>
          <Row>
            <DescriptionItem
              title='Người liên hệ'
              content={<Contact contact={get(selectedItem, 'contact') || []} />}
            />
          </Row>
          <Collapse bordered={false} defaultActiveKey={['1', '2']}>
            <Panel header='Kết quả khảo sát' key='1'>
              <Row>
                <Col span={12}>
                  <DescriptionItem
                    title='Diện tích'
                    content={
                      get(selectedItem, 'inspectionResult.area') || 'Chưa có'
                    }
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title='Sức chứa'
                    content={
                      get(selectedItem, 'inspectionResult.capacity') ||
                      'Chưa có'
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DescriptionItem
                    title='Mô tả'
                    content={get(selectedItem, 'inspectionResult.description')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DescriptionItem
                    title='Đường truyền'
                    content={
                      get(selectedItem, 'inspectionResult.network')
                        ? get(selectedItem, 'inspectionResult.network', []).map(
                            (n: FullLocationNetwork, index) => {
                              const allowed = ['carrier', 'speed', 'note'];
                              return (
                                <Tag key={index}>
                                  {Object.values(pick(n, allowed))
                                    .filter(Boolean)
                                    .join(' - ')}
                                </Tag>
                              );
                            }
                          )
                        : 'Chưa có'
                    }
                  />
                </Col>
              </Row>
            </Panel>
            <Panel header='Thông tin thiết bị' key='2'>
              <EquipmentViewDrawer
                locationId={get(selectedItem, '_id')}
                proposedEquipment={proposedEquipment}
                equipmentDetail={get(selectedItem, 'equipmentDetail', [])}
              />
            </Panel>
            <Panel header='Hình ảnh & tài liệu' key='3'>
              <DescriptionItem
                title='Hình ảnh bản vẽ'
                content={
                  <UploadFile
                    fileList={get(selectedItem, 'drawingPaths')}
                    locationId={get(selectedItem, '_id')}
                    typeFile={DRAWING_FILE}
                    disable={disableDrawingPaths}
                  />
                }
              />
              <DescriptionItem
                title='Hình ảnh thực tế'
                content={
                  <UploadFile
                    fileList={get(selectedItem, 'picturePaths')}
                    locationId={get(selectedItem, '_id')}
                    typeFile={PICTURE_FILE}
                    disable={disablePicturePaths}
                  />
                }
              />
              <DescriptionItem
                title='Văn bản hành chính'
                content={
                  <UploadFile
                    fileList={get(selectedItem, 'recordPaths')}
                    locationId={get(selectedItem, '_id')}
                    typeFile={RECORD_FILE}
                    disable={disableRecordPaths}
                  />
                }
              />
            </Panel>
            <Panel header='Thông tin nhân viên' key='4'>
              <LocationEmployee location={selectedItem} />
            </Panel>
          </Collapse>
        </div>
        <div className='drawer-footer'>
          <Authorized permission={PERMISSION_LOCATION_UPDATE_CONTENT}>
            <UIButton
              type='info'
              onClick={() => toggleUpdateContentModal()}
              style={{ marginRight: 8 }}
            >
              Cập nhật thông tin
            </UIButton>

            <UpdateContentLocation
              visible={updateContentVisible}
              location={selectedItem}
              toggleModal={toggleUpdateContentModal}
              toggleViewDrawer={toggleDrawer}
              onChange={setLocation}
            />
          </Authorized>

          {children}

          <Button
            type='danger'
            onClick={() => toggleDrawer()}
            style={{ marginLeft: 8 }}
          >
            Đóng
          </Button>
        </div>
      </Fragment>
    );
  }
}

ViewDrawer.defaultProps = {
  disableDrawingPaths: true,
  disablePicturePaths: true,
  disableRecordPaths: true,
  proposedEquipemnt: null,
};

export default ViewDrawer;

import gql from 'graphql-tag';
import employeeFragments from '../fragments/employee';

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      authToken
      employee {
        ...FullEmployee
      }
      error {
        message
      }
    }
  }
  ${employeeFragments.full}
`;

import { Alert, Row } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

import EquipmentTableServer from '~components/Equipment/EquipmentTableServer';
import { Loading } from '~components/UI';
import store from './store';

import { WarehouseCardDetail } from './CardDetail';

@observer
class WarehouseDetail extends Component<RouteComponentProps> {
  public componentWillMount = async () => {
    const { id } = this.props.match.params as any;
    store.setName(id);
  };

  public render() {
    const { loading, selectedItem: warehouse } = store;

    if (loading) return <Loading />;
    if (!warehouse) {
      return (
        <Alert
          style={{ marginTop: 8 }}
          message='Error'
          description={'Không có kho !'}
          type='error'
          showIcon={true}
        />
      );
    }

    return (
      <Row
        type='flex'
        style={{
          flexFlow: 'column',
          height: '100vh',
        }}
      >
        <div style={{ flex: 0 }}>
          <WarehouseCardDetail />
        </div>

        <div style={{ flex: 1, marginTop: 8 }}>
          <EquipmentTableServer
            rowGroup={true}
            locationId={warehouse._id}
            extraColumn={['unitPrice', 'issueInvoiceDay', 'warranty', 'note']}
          />
        </div>
      </Row>
    );
  }
}

export default WarehouseDetail;

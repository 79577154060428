import equipmentModel from '../fragments/equipmentModel';
import gql from 'graphql-tag';

export const CREATE_EQUIPMENT_MODEL_MUTATION = gql`
  mutation CreateEquipmentModel($record: EquipmentModelInput!) {
    createEquipmentModel(record: $record) {
      payload {
        ...FullEquipmentModel
      }
      error {
        message
      }
    }
  }
  ${equipmentModel.full}
`;

import gql from 'graphql-tag';
import equipmentCategory from '../fragments/equipmentCategory';

export const UPDATE_EQUIPMENT_CATEGORY_BY_ID_MUTATION = gql`
  mutation UpdateEquipmentCategoryById(
    $_id: ID!
    $record: EquipmentCategoryInput!
  ) {
    updateEquipmentCategoryById(_id: $_id, record: $record) {
      payload {
        ...FullEquipmentCategory
      }
      error {
        message
      }
    }
  }
  ${equipmentCategory.full}
`;

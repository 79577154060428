import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import { isAuthorized } from '~components/Authorized/Authorized';
import { UIButton } from '~components/UI';
import {
  PERMISSION_EQUIPMENT_MODEL_REMOVE,
  PERMISSION_EQUIPMENT_MODEL_UPDATE
} from '@mgn/common';

import store from './store';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const { toggleModal, handleRemove } = store;
    return (
      <Fragment>
        <Button.Group>
          <UIButton
            icon='edit'
            type='primary'
            size='small'
            onClick={() => toggleModal(data)}
            disabled={!isAuthorized(PERMISSION_EQUIPMENT_MODEL_UPDATE)}
          />
          <Popconfirm
            title='Bạn có chắc muốn xóa không?'
            onConfirm={() => handleRemove(data._id)}
          >
            <UIButton
              icon='delete'
              type='danger'
              size='small'
              disabled={!isAuthorized(PERMISSION_EQUIPMENT_MODEL_REMOVE)}
            />
          </Popconfirm>
        </Button.Group>
      </Fragment>
    );
  }
}

import { Button, Collapse, Drawer, Popconfirm, Row, Col } from 'antd';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import Authorized from '~components/Authorized/Authorized';
import EquipmentViewDrawer from '~components/EquipmentViewDrawer/EquipmentViewDrawer';
import ProposedEquipmentDrawer from '~components/ProposedEquipmentDrawer/ProposedEquipmentDrawer';
import {
  Contact,
  DescriptionItem,
  Loading,
  LocationEmployee,
  LocationInfo,
  UIButton
} from '~components/UI';
import UpdateContentLocation from '~components/UpdateContentLocation/UpdateContentLocation';
import UploadFile from '~components/UploadFile/UploadFile';

import {
  DRAWING_FILE,
  INSTALLING,
  PERMISSION_LOCATION_CONFIRM_ASBUILT,
  PICTURE_FILE,
  RECORD_FILE,
  PERMISSION_LOCATION_UPDATE_CONTENT
} from '@mgn/common';

import UpdateDetailForm from './Form';
import store from './store';

const { Panel } = Collapse;

interface IProps {
  toggleDrawer?: () => void;
  locationId?: string;
  visible?: boolean;
}

@observer
export default class UpdateDetailLocation extends Component<IProps> {
  public componentWillMount = () => {
    store.setLocationId(this.props.locationId);
  };

  public componentWillReceiveProps = nextProps => {
    if (this.props.locationId !== nextProps.locationId) {
      store.setLocationId(nextProps.locationId);
    }
  };

  public render() {
    const { visible, toggleDrawer } = this.props;
    const { loading, selectedItem } = store;

    return (
      <Drawer closable={false} visible={visible} onClose={() => toggleDrawer()}>
        {loading || !selectedItem ? this.renderLoading() : this.renderDrawer()}
      </Drawer>
    );
  }

  public renderLoading() {
    const { loading } = store;
    return loading ? <Loading /> : '';
  }

  public renderDrawer() {
    const { toggleDrawer, children } = this.props;
    const {
      locationId,
      selectedItem,
      proposedEquipmentDrawerVisible,
      toggleProposedEquipmentDrawer,
      handleConfirmSuccessAfterInstalledLocation,
      updateContentVisible,
      toggleUpdateContentModal,
      setLocation,
      isValid,
      isSubmitting,
    } = store;

    return (
      <Fragment>
        <div style={{ marginBottom: '3em' }}>
          <Row>
            <DescriptionItem
              title='Tên địa điểm'
              content={<LocationInfo location={selectedItem} />}
            />
          </Row>
          <Row>
            <DescriptionItem
              title='Người liên hệ'
              content={<Contact contact={get(selectedItem, 'contact') || []} />}
            />
          </Row>
          <Collapse bordered={false} defaultActiveKey={['1', '2']}>
            <Panel
              header='Kết quả khảo sát'
              key='1'
              extra={
                <Button
                  type='primary'
                  htmlType='submit'
                  form='updateDetailFm'
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  Cập nhật
                </Button>
              }
            >
              <UpdateDetailForm />
            </Panel>
            <Panel header='Thông tin thiết bị' key='2'>
              <EquipmentViewDrawer
                locationId={get(selectedItem, '_id')}
                equipmentDetail={get(selectedItem, 'equipmentDetail', [])}
              />
            </Panel>
            <Panel header='Hình ảnh & tài liệu' key='3'>
              <DescriptionItem
                title='Hình ảnh bản vẽ'
                content={
                  <UploadFile
                    locationId={get(selectedItem, '_id')}
                    fileList={get(selectedItem, 'drawingPaths')}
                    typeFile={DRAWING_FILE}
                    disable={false}
                    onUploadChange={setLocation}
                  />
                }
              />
              <DescriptionItem
                title='Hình ảnh thực tế'
                content={
                  <UploadFile
                    locationId={get(selectedItem, '_id')}
                    fileList={get(selectedItem, 'picturePaths')}
                    typeFile={PICTURE_FILE}
                    disable={false}
                    onUploadChange={setLocation}
                  />
                }
              />
              <DescriptionItem
                title='Văn bản hành chính'
                content={
                  <UploadFile
                    locationId={get(selectedItem, '_id')}
                    fileList={get(selectedItem, 'recordPaths')}
                    typeFile={RECORD_FILE}
                    disable={true}
                  />
                }
              />
            </Panel>
            <Panel header='Thông tin nhân viên' key='4'>
              <LocationEmployee location={selectedItem} />
            </Panel>
          </Collapse>
        </div>
        <div className='drawer-footer'>
          <Row
            type='flex'
            gutter={8}
            style={{
              justifyContent: 'flex-end',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Col>
              <Authorized permission={PERMISSION_LOCATION_UPDATE_CONTENT}>
                <UIButton
                  type='info'
                  onClick={() => toggleUpdateContentModal()}
                  style={{ width: '100%' }}
                >
                  Cập nhật
                </UIButton>

                <UpdateContentLocation
                  visible={updateContentVisible}
                  location={selectedItem}
                  toggleModal={toggleUpdateContentModal}
                  toggleViewDrawer={toggleProposedEquipmentDrawer}
                  toggleUpdateDetail={toggleDrawer}
                  onChange={setLocation}
                />
              </Authorized>
            </Col>

            {get(selectedItem, 'status') === INSTALLING && (
              <Col>
                <Authorized permission={PERMISSION_LOCATION_CONFIRM_ASBUILT}>
                  <Popconfirm
                    title='Hoàn công?'
                    okText='Đồng ý'
                    cancelText='Không'
                    onConfirm={() =>
                      handleConfirmSuccessAfterInstalledLocation()
                    }
                  >
                    <UIButton type='success' style={{ width: '100%' }}>
                      Hoàn công
                    </UIButton>
                  </Popconfirm>
                </Authorized>
              </Col>
            )}

            {get(selectedItem, 'inspectionResult.area') &&
              get(selectedItem, 'inspectionResult.capacity') &&
              !isEmpty(get(selectedItem, 'inspectionResult.network')) &&
              !isEmpty(get(selectedItem, 'drawingPaths')) && (
                <Col>
                  <UIButton
                    type='warning'
                    onClick={toggleProposedEquipmentDrawer}
                    style={{ width: '100%' }}
                  >
                    Đề xuất
                  </UIButton>
                  <ProposedEquipmentDrawer
                    visible={proposedEquipmentDrawerVisible}
                    toggleDrawer={toggleProposedEquipmentDrawer}
                    locationId={locationId}
                    equipmentDetail={get(selectedItem, 'equipmentDetail')}
                    toggleUpdateDetail={toggleDrawer}
                    key={locationId}
                  />
                </Col>
              )}
            <Col>
              <Button
                type='danger'
                onClick={() => toggleDrawer()}
                style={{ width: '100%' }}
              >
                Đóng
              </Button>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

import gql from 'graphql-tag';
import material from '../fragments/material';

export const UPDATE_MATERIAL_BY_ID_MUTATION = gql`
  mutation UpdateMaterialById($_id: ID!, $record: MaterialInput!) {
    updateMaterialById(_id: $_id, record: $record) {
      payload {
        ...FullMaterial
      }
      error {
        message
      }
    }
  }
  ${material.full}
`;

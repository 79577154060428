import { ICellRendererParams } from 'ag-grid-community';
import { Tag, Tooltip } from 'antd';
import React, { Component } from 'react';

export class GridCellRoleRenderer extends Component<ICellRendererParams> {
  public render() {
    const { value } = this.props;

    return value.map((v, index) => (
      <Tag key={index}>
        <Tooltip placement='right'>{v.description}</Tooltip>
      </Tag>
    ));
  }
}

import gql from 'graphql-tag';
import material from '../fragments/material';

export const REMOVE_MATERIAL_BY_ID_MUTATION = gql`
  mutation RemoveMaterialById($_id: ID!) {
    removeMaterialById(_id: $_id) {
      payload {
        ...FullMaterial
      }
      error {
        message
      }
    }
  }
  ${material.full}
`;

import gql from 'graphql-tag';
import equipmentTemp from '../fragments/equipmentTemp';

export const REMOVE_EQUIPMENT_TEMP_GOODS_ISSUE_MUTATION = gql`
  mutation RemoveEquipmentTempGoodsIssue($_id: ID!) {
    removeEquipmentTempGoodsIssue(_id: $_id) {
      error {
        message
      }
      equipmentTemps {
        ...BasicEquipmentTemp
      }
    }
  }
  ${equipmentTemp.basic}
`;

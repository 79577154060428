import { Select } from 'antd';
import { debounce } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import client from '~graphql/client';
import { SEARCH_EQUIPMENT_QUERY } from '~graphql/queries/findEquipment';
import {
  CommonEquipmentFragment,
  SearchEquipmentQuery,
  SearchEquipmentVariables,
} from '~graphql/types';

const { Option } = Select;

interface IProps {
  locationId?: string;
  ignoreSerials?: string[];
  handleSelect: (serial: string) => void | Promise<void>;
}

@observer
class SearchEquipment extends Component<IProps> {
  @observable public loading: boolean;
  @observable private value: string;
  @observable.shallow private data: CommonEquipmentFragment[];

  private fetchEquipment = debounce(
    async (value: string = null) => {
      const { locationId } = this.props;
      if (!value || value.length < 3) return;

      runInAction(() => {
        this.loading = true;
        this.data = [];
      });

      const { data } = await client.query<
        SearchEquipmentQuery,
        SearchEquipmentVariables
      >({
        query: SEARCH_EQUIPMENT_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          take: 5,
          where: {
            locationId,
            serial: value,
          },
        },
      });

      runInAction(() => {
        this.loading = false;
        this.data = data.searchEquipment;
      });
    },
    200,
    { leading: false, trailing: true }
  );

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = false;
      this.data = [];
    });
  }

  @action public onChange = (value: string) => {
    const { handleSelect } = this.props;
    handleSelect(value);

    this.value = undefined;
    this.data = [];
  };

  public render() {
    return (
      <Select
        value={this.value}
        placeholder='Nhập SERIAL/MAC'
        onSearch={this.fetchEquipment}
        onChange={this.onChange}
        style={{ width: '100%' }}
        showSearch={true}
        showArrow={false}
        allowClear={true}
      >
        {this.data.map((equipment) => (
          <Option key={equipment._id} value={equipment.serial}>
            {equipment.serial} - {equipment.equipmentModelCode}
          </Option>
        ))}
      </Select>
    );
  }
}

export default SearchEquipment;

import { Tree } from 'antd';
import { AntTreeNodeDropEvent } from 'antd/lib/tree/Tree';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import { ErrorNoti } from '~components/UI';
import { MenuChildInput } from '~graphql/types';
import { routes } from '~screens/Routes';
import { flatten } from '~utils';

import FormMenuInput from './FormMenuInput';
import { renderMenuTree } from './GridMenuTreeCellRenderer';
import TreeNode from './TreeNode';

interface IProps {
  value: MenuChildInput[];
  onChange: (value: MenuChildInput[]) => any;
  onBlur?: () => any;
}

@observer
export default class MenuTree extends Component<IProps> {
  public render() {
    const { value } = this.props;
    const treeNodeProps = {
      onChange: this.onChangeMenuItem,
      onDelete: this.onDeleteMenuItem,
    };

    return (
      <Fragment>
        <FormMenuInput
          data={this.availableMenuItems}
          handleSubmit={this.onAddMenuItem}
        />
        <Tree
          showIcon={true}
          defaultExpandAll={true}
          draggable={true}
          selectable={true}
          onDrop={this.onDrop}
          defaultSelectedKeys={[]}
        >
          {renderMenuTree(value, TreeNode, treeNodeProps)}
        </Tree>
      </Fragment>
    );
  }

  public onDrop = (info: AntTreeNodeDropEvent) => {
    const { node, dragNode, dropPosition, dropToGap } = info;

    const dropPaths = node.props.pos.split('-').map(item => parseInt(item, 10));
    let dropItem: MenuChildInput;
    if (dropToGap) {
      const lastPos = dropPaths.pop();
      dropPaths.push(Math.max(lastPos, dropPosition));
      dropItem = this.getMenuItem(dropPaths);
      if (!dropItem) {
        dropPaths.pop();
        dropPaths.push(lastPos);
      }
    }
    dropItem = dropItem || this.getMenuItem(dropPaths);

    const dragPaths = dragNode.props.pos
      .split('-')
      .map(item => parseInt(item, 10));
    const dragItem = this.getMenuItem(dragPaths);

    const dragItemHasChild = dragNode.props.children ? true : false;
    const dropItemIsChild = dropPaths.length > 2;

    const changingPosition = () => {
      this.handleMoveMenuItem(dragPaths, dropItem, dropToGap);
    };

    if (!dragItem.route && (dropItemIsChild || !dropToGap)) {
      return ErrorNoti('Menu con phải có đường dẫn');
    }
    if (dropToGap) {
      return changingPosition();
    }
    if (dragItemHasChild || dropItemIsChild) {
      return ErrorNoti('Không được tạo menu hơn 2 cấp');
    }
    changingPosition();
  };

  public onChange = () => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(this.props.value);
    }
  };

  public onBlur = () => {
    const { onBlur } = this.props;
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  public onAddMenuItem = (item: MenuChildInput) => {
    this.handleAddMenuItem(item);
    this.onChange();
  };

  public onChangeMenuItem = (name: string, pos: string) => {
    const paths = pos.split('-').map(item => parseInt(item, 10));
    this.handleUpdateMenuItem(name, paths);
    this.onChange();
  };

  public onDeleteMenuItem = (pos: string) => {
    const paths = pos.split('-').map(item => parseInt(item, 10));
    this.handleDeleteMenuItem(paths);
    this.onChange();
  };

  @computed public get availableMenuItems(): MenuChildInput[] {
    const selected = flatten(this.props.value)
      .filter(item => item.route)
      .reduce((all, item) => {
        all[item.route] = true;
        return all;
      }, {});

    return routes.filter(item => item.menu && !selected[item.route]);
  }

  @action public getMenuItem = (
    paths: number[],
    data: MenuChildInput[] = this.props.value,
    index = 1
  ): MenuChildInput => {
    const pos = paths[index];
    if (index >= paths.length - 1) {
      return data[pos];
    }
    return this.getMenuItem(paths, data[pos].children, index + 1);
  };

  @action public getPathOfMenuItem = (
    item: MenuChildInput,
    paths: number[] = [0],
    data: MenuChildInput[] = this.props.value,
    i = 0
  ): boolean => {
    return data.reduce((all, e, index) => {
      if (all) return all;

      if (e === item) {
        paths.push(index);
        return true;
      }

      if (e.children) {
        paths.push(index);
        const found = this.getPathOfMenuItem(item, paths, e.children, i + 1);
        if (!found) {
          paths.pop();
        }
        return found;
      }
    }, false);
  };

  @action public handleAddMenuItem = (
    item: MenuChildInput,
    paths: number[] = [0, this.props.value.length],
    data: MenuChildInput[] = this.props.value,
    index: number = 1
  ) => {
    if (!item) return;
    const pos = paths[index];
    if (index >= paths.length - 1) {
      data.splice(pos, 0, item);
    } else {
      this.handleAddMenuItem(
        item,
        paths,
        this.props.value[pos].children,
        index + 1
      );
    }
  };

  @action public handleUpdateMenuItem = (
    value: string,
    paths: number[],
    data: MenuChildInput[] = this.props.value,
    index: number = 1
  ) => {
    if (!value) return;
    const pos = paths[index];
    if (index >= paths.length - 1) {
      data[pos].name = value;
    } else {
      this.handleUpdateMenuItem(
        value,
        paths,
        this.props.value[pos].children,
        index + 1
      );
    }
  };

  @action public handleMoveMenuItem = (
    dragPaths: number[],
    dropItem: MenuChildInput,
    dropToGap: boolean
  ) => {
    const dragItem = this.handleDeleteMenuItem(dragPaths);
    const dropPaths = [0];
    this.getPathOfMenuItem(dropItem, dropPaths);
    if (!dropToGap) {
      dropItem.children = dropItem.children || [];
      dropPaths.push(0);
    }
    this.handleAddMenuItem(dragItem, dropPaths);
  };

  @action public handleDeleteMenuItem = (
    paths: number[],
    data: MenuChildInput[] = this.props.value,
    index: number = 1
  ): MenuChildInput => {
    const pos = paths[index];
    if (index >= paths.length - 1) {
      return data.splice(pos, 1).pop();
    }
    return this.handleDeleteMenuItem(
      paths,
      this.props.value[pos].children,
      index + 1
    );
  };
}

export const CREATED = 'CREATED';
export const WAITING_SURVEYOR = 'WAITING_SURVEYOR';
export const SURVEYING = 'SURVEYING';
export const WAITING_AGREEMENT = 'WAITING_AGREEMENT';
export const AGREED = 'AGREED';
export const CANCELLED = 'CANCELLED';
export const WAITING_INSTALLER = 'WAITING_INSTALLER';
export const INSTALLING = 'INSTALLING';
export const INSTALLED = 'INSTALLED';
export const WAITING_MAINTAINER = 'WAITING_MAINTAINER';
export const WORKING = 'WORKING';
export const FULFILLING = 'FULFILLING';
export const FULFILLED = 'FULFILLED';
export const DELETED = 'DELETED';
export const STOPPED = 'STOPPED';

export const LocationStatus = [
  CREATED,
  WAITING_SURVEYOR,
  SURVEYING,
  WAITING_AGREEMENT,
  AGREED,
  CANCELLED,
  WAITING_INSTALLER,
  INSTALLING,
  INSTALLED,
  WAITING_MAINTAINER,
  WORKING,
  DELETED,
  STOPPED,
];

export const ProposedEquipmentStatus = [
  CREATED,
  WAITING_AGREEMENT,
  AGREED,
  CANCELLED,
  FULFILLING,
  FULFILLED,
];

export const GoodsReceiptStatus = [
  CREATED,
  WAITING_AGREEMENT,
  AGREED,
  CANCELLED,
];

export const GoodsIssueStatus = [CREATED, WAITING_AGREEMENT, AGREED, CANCELLED];

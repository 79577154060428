import gql from 'graphql-tag';
import employee from '../fragments/employee';

export const UPDATE_PHONE_EMPLOYEE_MUTATION = gql`
  mutation UpdatePhoneEmployee($phone: String!) {
    updatePhoneEmployee(phone: $phone) {
      employee {
        ...FullEmployee
      }
      error {
        message
      }
    }
  }
  ${employee.full}
`;

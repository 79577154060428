import gql from 'graphql-tag';
import proposedEquipment from '../fragments/proposedEquipment';

export const FIND_ONE_PROPOSED_EQUIPMENT_QUERY = gql`
  query FindOneProposedEquipment(
    $where: ProposedEquipmentBaseFilter
    $order: ProposedEquipmentBaseSort
  ) {
    findOneProposedEquipment(where: $where, order: $order) {
      ...FullProposedEquipment
    }
  }
  ${proposedEquipment.full}
`;

export const FIND_MANY_PROPOSED_EQUIPMENT_QUERY = gql`
  query FindManyProposedEquipment(
    $skip: Int
    $take: Int
    $where: ProposedEquipmentBaseFilter
    $order: ProposedEquipmentBaseSort
  ) {
    findManyProposedEquipment(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullProposedEquipment
    }
  }
  ${proposedEquipment.full}
`;

import { GridReadyEvent } from 'ag-grid-community';
import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_GOODS_ISSUE_MUTATION,
  REMOVE_GOODS_ISSUE_MUTATION,
  UPDATE_GOODS_ISSUE_MUTATION,
} from '~graphql/mutations';
import {
  CreateGoodsIssueMutation,
  CreateGoodsIssueVariables,
  FullGoodsIssueFragment,
  GoodsIssueBaseFilter,
  RemoveGoodsIssueByCodeMutation,
  RemoveGoodsIssueByCodeVariables,
  UpdateGoodsIssueByCodeMutation,
  UpdateGoodsIssueByCodeVariables,
  UpdateGoodsIssueInput,
} from '~graphql/types';
import { FilterValue } from '~screens/GoodsIssue/Filter';
import { countGoodsIssue } from '~utils/countGoodsIssue';

import { AGREED, CANCELLED, CREATED, WAITING_AGREEMENT } from '@mgn/common';

interface INotification {
  createdTab?: number;
  waitingAgreementTab?: number;
  agreedTab?: number;
  cancelledTab?: number;
  allTab?: number;
}

class GoodIssuesStore {
  @observable public filter: FilterValue;
  @observable public noti: INotification;
  @observable public status: string;
  @observable public visible: boolean;
  @observable public selectedItem: FullGoodsIssueFragment;

  constructor() {
    runInAction(() => {
      this.filter = null;
      this.noti = null;
      this.status = CREATED;
      this.visible = false;
      this.selectedItem = null;
    });
  }

  @computed public get whereFilter() {
    const filter: GoodsIssueBaseFilter = { status: this.status };
    if (this.filter) {
      const { locationId, createdAt, code } = this.filter;
      if (locationId) {
        filter.OR = [
          { fromLocationId: locationId },
          { toLocationId: locationId },
        ];
      }
      if (Array.isArray(createdAt) && createdAt.length) {
        const [from, to] = createdAt;
        filter._operators = {
          createdAt: {
            ...(from && {
              gte: moment(from)
                .startOf('day')
                .toISOString(),
            }),
            ...(to && {
              lte: moment(to)
                .endOf('day')
                .toISOString(),
            }),
          },
        };
      }
      if (code) {
        filter.code = code;
      }
    }

    return filter;
  }

  @action public setNotification = async () => {
    const notification: INotification = {};
    Object.assign(notification, {
      createdTab: await countGoodsIssue(CREATED),
      waitingAgreementTab: await countGoodsIssue(WAITING_AGREEMENT),
      agreedTab: await countGoodsIssue(AGREED),
      cancelledTab: await countGoodsIssue(CANCELLED),
      allTab: await countGoodsIssue(),
    });

    runInAction(() => {
      this.noti = notification;
    });
  };

  @action public setFilter = (filter: FilterValue) => {
    this.filter = filter;
  };

  @action public setStatus = (status: string) => {
    this.status = status;
  };

  @action public toggleModal = (record = null) => {
    this.visible = !this.visible;
    this.selectedItem = record;
  };

  @action public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action public handleDelete = async (code: string) => {
    await client.mutate<
      RemoveGoodsIssueByCodeMutation,
      RemoveGoodsIssueByCodeVariables
    >({
      mutation: REMOVE_GOODS_ISSUE_MUTATION,
      variables: { code },
      update: (_cache, { data: { removeGoodsIssueByCode } }) => {
        const { error } = removeGoodsIssueByCode;
        if (error) {
          ErrorNoti(error.message);
        } else {
          SuccessNoti('Xóa phiếu nhập kho');
          this.setNotification();
          this.reload();
        }
      },
    });
  };

  @action public handleCreate = async (record: CreateGoodsIssueVariables) => {
    const {
      data: { createGoodsIssue },
    } = await client.mutate<
      CreateGoodsIssueMutation,
      CreateGoodsIssueVariables
    >({
      mutation: CREATE_GOODS_ISSUE_MUTATION,
      variables: record,
      errorPolicy: 'all',
    });

    const { error } = createGoodsIssue;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo phiếu nhập kho');
      this.toggleModal();
      this.setNotification();
      this.reload();
    }
  };

  @action public handleUpdate = async (
    code: string,
    record: UpdateGoodsIssueInput
  ) => {
    const {
      data: { updateGoodsIssueByCode },
    } = await client.mutate<
      UpdateGoodsIssueByCodeMutation,
      UpdateGoodsIssueByCodeVariables
    >({
      mutation: UPDATE_GOODS_ISSUE_MUTATION,
      variables: {
        code,
        record,
      },
      errorPolicy: 'all',
    });

    const { error } = updateGoodsIssueByCode;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật phiếu nhập kho');
      this.toggleModal();
      this.setNotification();
      this.reload();
    }
  };

  @action private reload = () => undefined;
}

export default new GoodIssuesStore();

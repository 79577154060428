import './assets/styles/all.less';
import * as locale from '~locales/viVN';

import { ConfigProvider, Empty, LocaleProvider } from 'antd';
import { Provider as MobxProvider } from 'mobx-react';
import { shim } from 'promise.prototype.finally';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import whyDidYouUpdate from 'why-did-you-update';

import client from '~graphql/client';

import Screens from './screens';
import * as serviceWorker from './serviceWorker';
import stores from './stores';

// if (process.env.NODE_ENV !== 'production') {
//   whyDidYouUpdate(React);
// }
shim();

ReactDOM.render(
  <ApolloProvider client={client}>
    <MobxProvider {...stores}>
      <ConfigProvider
        renderEmpty={() => <Empty description='Không có dữ liệu' />}
      >
        <BrowserRouter>
          <LocaleProvider locale={locale.antdLocale}>
            <Screens />
          </LocaleProvider>
        </BrowserRouter>
      </ConfigProvider>
    </MobxProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

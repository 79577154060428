import role from '../fragments/role';
import gql from 'graphql-tag';

export const REMOVE_ROLE_MUTATION = gql`
  mutation RemoveRoleById($_id: ID!) {
    removeRoleById(_id: $_id) {
      payload {
        ...BasicRole
      }
      error {
        message
      }
    }
  }
  ${role.basic}
`;

import { merge } from 'lodash';
import viVN from 'antd/lib/locale-provider/vi_VN';

export default merge(viVN, {
  Table: {
    filterTitle: 'Bộ lọc',
    filterConfirm: 'OK',
    filterReset: 'Đặt lại',
    emptyText: 'Chưa có dữ liệu',
    selectAll: 'Chọn tất cả',
    selectInvert: 'Chọn ngược lại',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Huỷ',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Huỷ',
  },
  Transfer: {
    notFoundContent: 'Không tìm thấy',
    searchPlaceholder: 'Tìm ở đây',
    itemUnit: 'mục',
    itemsUnit: 'mục',
  },
  Select: {
    notFoundContent: 'Không tìm thấy',
  },
  Upload: {
    uploading: 'Đang tải lên...',
    removeFile: 'Gỡ bỏ tập tin',
    uploadError: 'Lỗi tải lên',
    previewFile: 'Xem thử tập tin',
  },
});

import gql from 'graphql-tag';

const CREATE_UPLOAD_LINK_LOCATION_MUTATION = gql`
  mutation CreateUploadLinkLocation($id: String!, $name: String!) {
    createUploadLinkLocation(id: $id, name: $name) {
      id
      path
    }
  }
`;

export { CREATE_UPLOAD_LINK_LOCATION_MUTATION };

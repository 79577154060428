import gql from 'graphql-tag';
import goodsIssue from '../fragments/goodsIssue';

export const CREATE_GOODS_ISSUE_MUTATION = gql`
  mutation CreateGoodsIssue(
    $fromLocationId: String!
    $toLocationId: String!
    $proposedEquipmentId: String
    $description: String
  ) {
    createGoodsIssue(
      fromLocationId: $fromLocationId
      toLocationId: $toLocationId
      proposedEquipmentId: $proposedEquipmentId
      description: $description
    ) {
      goodsIssue {
        ...FullGoodsIssue
      }
      error {
        message
      }
    }
  }

  ${goodsIssue.full}
`;

import { GraphQLError } from 'graphql';
import { get, groupBy, isEmpty, set } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import { UPDATE_MENU_MAPPING_MUTATION } from '~graphql/mutations';
import { FIND_MANY_PERMISSION_MAPPING_QUERY } from '~graphql/queries';
import {
    FindManyPermissionMappingQuery, UpdateMenuMappingMutation, UpdateMenuMappingVariables
} from '~graphql/types';

import { DELETED } from '@mgn/common';

class MenuStore {
  @observable public visible: boolean;
  @observable public loading: boolean;
  @observable public selectedMenuMapping;
  @observable.shallow public menuAndRoleMapping;
  @observable public menuAndRoleMappingErrors: GraphQLError[];

  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.visible = false;
      this.loading = true;
      this.selectedMenuMapping = null;
      this.menuAndRoleMapping = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchMenuAndRoleMapping().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public toggleModal = (record = null) => {
    this.selectedMenuMapping = record;
    this.visible = !this.visible;
  };

  @action public handleMenuMappingUpdate = async (_id, menuId: string) => {
    const {
      data: { updateMenuMapping },
    } = await client.mutate<
      UpdateMenuMappingMutation,
      UpdateMenuMappingVariables
    >({
      mutation: UPDATE_MENU_MAPPING_MUTATION,
      variables: { menuId, _id },
      errorPolicy: 'all',
    });

    const { error, menuMapping } = updateMenuMapping;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật menu');
      this.updateMenuData(_id, menuMapping);
      this.toggleModal();
    }
  };

  @action public updateMenuData = (_id: string, record?: any) => {
    const index = this.menuAndRoleMapping.findIndex(item => item._id === _id);

    set(record, 'role', this.selectedMenuMapping.role);
    set(record, 'roleId', this.selectedMenuMapping.roleId);

    this.menuAndRoleMapping = [
      ...this.menuAndRoleMapping.slice(0, index),
      record,
      ...this.menuAndRoleMapping.slice(index + 1),
    ].filter(Boolean);
  };

  @action public updateRoleData = (
    _id: string,
    employeeId: string,
    record?: any
  ) => {
    if (!record) {
      const index = this.menuAndRoleMapping.findIndex(
        m => m.employeeId === employeeId
      );
      const item = this.menuAndRoleMapping.find(
        m => m.employeeId === employeeId
      );
      const roleDeleting = item.role.splice(
        item.role.findIndex(i => i._id === _id),
        1
      );
      item.roleId.splice(
        item.roleId.findIndex(i => roleDeleting.name === i),
        1
      );
      this.menuAndRoleMapping = [
        ...this.menuAndRoleMapping.slice(0, index),
        item,
        ...this.menuAndRoleMapping.slice(index + 1),
      ];
    } else {
      const recordEditing = this.menuAndRoleMapping.find(
        item => item.employeeId === record[0].employeeId
      );

      const index = this.menuAndRoleMapping.findIndex(
        item => item.employeeId === record[0].employeeId
      );

      if (
        recordEditing.role.findIndex(i => i.name === record[0].role.name) < 0
      ) {
        recordEditing.role.push(record[0].role);
        recordEditing.roleId.push(record[0].roleId);
        this.menuAndRoleMapping = [
          ...this.menuAndRoleMapping.slice(0, index),
          recordEditing,
          ...this.menuAndRoleMapping.slice(index + 1),
        ].filter(Boolean);
      } else return;
    }
  };

  @action private fetchMenuAndRoleMapping = async () => {
    const {
      data: { findManyMenuMapping, findManyRoleMapping },
    } = await client.query<FindManyPermissionMappingQuery>({
      query: FIND_MANY_PERMISSION_MAPPING_QUERY,
      variables: {
        where: {
          _operators: {
            status: { nin: DELETED },
            email: { nin: 'su@meganet.com.vn' },
          },
        },
      },
      fetchPolicy: 'network-only',
    });
    let mapMenuMapping = [];
    if (!isEmpty(findManyRoleMapping)) {
      const groupRoleMapping = groupBy(findManyRoleMapping, 'employeeId');

      mapMenuMapping = findManyMenuMapping.map(item => {
        return {
          ...item,
          roleId: groupRoleMapping[item.employeeId]
            ? groupRoleMapping[item.employeeId].map(item2 => item2.roleId)
            : [],
          role: groupRoleMapping[item.employeeId]
            ? groupRoleMapping[item.employeeId].map(item2 => item2.role)
            : [],
        };
      });
    } else {
      mapMenuMapping = findManyMenuMapping;
    }

    runInAction(() => {
      this.menuAndRoleMapping = mapMenuMapping.filter(
        item => get(item, 'employee.fullName') !== 'Superadmin'
      );
    });
  };
}

export default new MenuStore();

import { Select } from 'antd';
import { action, observable, runInAction, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import client from '~graphql/client';
import {
  FullSupplierFragment,
  FindManySupplierQuery,
  FindManySupplierVariables,
} from '~graphql/types';
import { FIND_MANY_SUPPLIER_QUERY } from '~graphql/queries';
import { SelectProps } from 'antd/lib/select';

const { Option } = Select;
interface IProps extends SelectProps {
  useCode?: boolean;
}

@observer
export default class SupplierDropdown extends Component<IProps> {
  @observable public loading;
  @observable public data: FullSupplierFragment[];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = true;
      this.data = [];
    });
  }

  public componentDidMount() {
    this.loadData().then(
      action('setLoading', () => {
        this.loading = false;
      })
    );
  }

  @action public loadData = async () => {
    this.loading = true;

    const { data } = await client.query<
      FindManySupplierQuery,
      FindManySupplierVariables
    >({
      query: FIND_MANY_SUPPLIER_QUERY,
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.data = data.findManySupplier;
    });
  };

  public render() {
    const { useCode = false, ...props } = this.props;
    return (
      <Select
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        allowClear={true}
        placeholder='Chọn nhà cung cấp'
        style={{ width: '100%' }}
        {...props}
      >
        {this.data.map(item => {
          return (
            <Option key={item._id} value={useCode ? item.code : item._id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

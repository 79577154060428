import gql from 'graphql-tag';
import proposedEquipment from '../fragments/proposedEquipment';

export const PASS_TO_FULFILLED_PROPOSED_EQUIPMENT_MUTATION = gql`
  mutation PassToFulfilledProposedEquipment($_id: ID!) {
    passToFulfilledProposedEquipment(_id: $_id) {
      proposedEquipment {
        ...FullProposedEquipment
      }
      error {
        message
      }
    }
  }
  ${proposedEquipment.full}
`;

import gql from 'graphql-tag';
import goodsIssue from '../fragments/goodsIssue';

export const REMOVE_GOODS_ISSUE_MUTATION = gql`
  mutation RemoveGoodsIssueByCode($code: String!) {
    removeGoodsIssueByCode(code: $code) {
      goodsIssue {
        ...BasicGoodsIssue
      }
      error {
        message
      }
    }
  }
  ${goodsIssue.basic}
`;

import React, { Component } from 'react';
import { Row, Col, Alert } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import EquipmentDetail from '~components/EquipmentDetail/EquipmentDetail';
import ProposedEquipmentCardDetail from './CardDetail';
import GoodsReceiptIssueTable from './GridGRI';
import store from './store';
import { Loading } from '~components/UI';
import { observer } from 'mobx-react';

@observer
class ProposedEquipmentDetail extends Component<RouteComponentProps> {
  public componentWillMount = async () => {
    const { id } = this.props.match.params as any;
    store.setId(id);
  };

  public render() {
    const { selectedItem: proposedEquipment, loading } = store;
    if (loading) return <Loading />;

    if (!proposedEquipment) {
      return (
        <Alert
          style={{ marginTop: 8 }}
          message='Error'
          description={`Không có phiếu đề xuất ${proposedEquipment._id}!`}
          type='error'
          showIcon={true}
        />
      );
    }

    return (
      <Row
        type='flex'
        style={{
          flexFlow: 'column',
          height: '100vh',
        }}
      >
        <ProposedEquipmentCardDetail />
        <Row
          type='flex'
          style={{
            flex: 1,
            marginTop: 8,
          }}
          gutter={16}
        >
          <Col span={16}>
            <GoodsReceiptIssueTable />
          </Col>
          <Col span={8}>
            <EquipmentDetail pe={proposedEquipment} />
          </Col>
        </Row>
      </Row>
    );
  }
}

export default ProposedEquipmentDetail;

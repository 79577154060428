// namlx 17/10/2018
// sort table

// so sánh 2 nhân viên với nhân viên hiện tại employee.
export function compareEmployees(employeeIdA, employeeIdB, currentEmployeeId) {
  return (
    (employeeIdA === currentEmployeeId && employeeIdB === currentEmployeeId) ||
    (employeeIdA !== currentEmployeeId && employeeIdB !== currentEmployeeId)
  );
}

// Sắp xếp theo trạng thái
export function sortStatus(a, b, status) {
  if (a.status !== status && b.status === status) {
    return -1;
  }
  if (a.status === status && b.status !== status) {
    return 1;
  }
}
// Sắp xếp theo nhân viên hiện tại
export function sortEmployee(employeeIdA, employeeIdB, currentEmployeeId) {
  if (employeeIdA === currentEmployeeId && employeeIdB !== currentEmployeeId) {
    return 1;
  }
  if (employeeIdA !== currentEmployeeId && employeeIdB === currentEmployeeId) {
    return -1;
  }
  return 0;
}

// Kiểm tra có nhân viên hhay không để sắp xếp
export function checkEmployees(employeeIdA, employeeIdB) {
  return employeeIdA && employeeIdB;
}

// Sắp xếp theo có nhân viên hay không
export function sortHaveEmployee(employeeIdA, employeeIdB) {
  if (employeeIdA && !employeeIdB) {
    return -1;
  }
  if (!employeeIdA && employeeIdB) {
    return 1;
  }
  return 0;
}

// Sắp xếp theo thời gian
export function sortTime(timeA, timeB) {
  if (Date.parse(timeA) < Date.parse(timeB)) {
    return -1;
  }
  if (Date.parse(timeA) > Date.parse(timeB)) {
    return 1;
  }
  return 0;
}

// Sắp xếp theo type location
export function sortType(typeA, typeB, type) {
  if (typeA === type && typeB !== type) {
    return 1;
  }
  if (typeA !== type && typeB === type) {
    return -1;
  }
  return 0;
}

// xài trong proposedEquipmentDrawer, đéo biết để làm gì
export const sortByName = (a, b) =>
  a.equipmentModel.equipmentCategory.name >
  b.equipmentModel.equipmentCategory.name
    ? 1
    : b.equipmentModel.equipmentCategory.name >
      a.equipmentModel.equipmentCategory.name
    ? -1
    : 0;

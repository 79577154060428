import { Col, Collapse, Form, Row, Button, Icon } from 'antd';
import { css } from 'emotion';
import { Field, Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Authorized } from '~components/Authorized/Authorized';
import AreaDropdown from '~components/Dropdown/AreaDropdown';
import DistrictDropdown from '~components/Dropdown/DistrictDropdown';
import StatusDropdown from '~components/Dropdown/StatusDropdown';
import LocationSearch from '~components/Location/LocationSearch';
import { UIField } from '~components/UI/UIField';
import LocationTypeDropdown from '~components/Dropdown/LocationTypeDropdown';
import { trimObject } from '~utils';

import { CREATED, PERMISSION_LOCATION_CREATE } from '@mgn/common';

import store from './store';
import EmployeeSearch from '~components/Dropdown/EmployeeSearch';

interface IFormValues {
  status?: string[];
  area?: string;
  district?: string;
  locationId?: string;
  employeeId?: string;
  statusFilter?: string;
  typeCode?: string;
}

const headerCol = css({
  '.ant-collapse-item > .ant-collapse-header': {
    color: '#1890ff',
  },
});

export type FilterValue = IFormValues;

interface IProps<T> {
  onChange: (values: T) => any;
  reset?: boolean;
  count?: number;
}

const { Panel } = Collapse;

@observer
export default class LocationFilter extends Component<IProps<IFormValues>> {
  public onSubmit = (values: IFormValues) => {
    const { onChange } = this.props;
    trimObject(values);

    if (typeof onChange === 'function') {
      onChange(values);
    }
  };

  public render() {
    const { status, filter } = store;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          status,
          ...filter,
        }}
        onSubmit={this.onSubmit}
        validate={this.onSubmit}
        validateOnBlur={false}
        render={this.renderForm}
      />
    );
  }
  private renderForm = (props: FormikProps<IFormValues>) => {
    const { values, setFieldValue, setTouched } = props;
    const { status, area, district, employeeId, statusFilter } = values;
    const { toggleUpdateContent, resetFilter } = store;

    const onAreaChange = () => {
      setFieldValue('district', undefined, false);
      setTouched({ district: false });
    };

    return (
      <Form>
        <Row>
          <Col md={8}>
            <Field
              name='locationId'
              component={UIField}
              nativeInput={false}
              fieldComponent={LocationSearch}
              fieldProps={{
                status,
                statusFilter,
                district,
                area,
                employeeId,
                placeholder: 'Tìm tên điểm...',
                allowClear: true,
              }}
            />
          </Col>

          {status.includes(CREATED) && (
            <Col
              md={4}
              offset={12}
              style={{ marginTop: 4, textAlign: 'right' }}
            >
              <Authorized permission={PERMISSION_LOCATION_CREATE}>
                <Button
                  type='primary'
                  icon='plus'
                  onClick={() => toggleUpdateContent()}
                >
                  Tạo điểm mới
                </Button>
              </Authorized>
            </Col>
          )}
        </Row>
        <Collapse bordered={false} className={headerCol}>
          <Panel header={`Bộ lọc (SL: ${this.props.count})`} key='1'>
            <Row gutter={8}>
              <Col md={5}>
                <Field
                  name='area'
                  component={UIField}
                  nativeInput={false}
                  fieldComponent={AreaDropdown}
                  fieldProps={{
                    placeholder: 'Tỉnh/TP',
                    allowClear: true,
                    onChange: onAreaChange,
                  }}
                />
              </Col>
              <Col md={5}>
                <Field
                  name='district'
                  component={UIField}
                  fieldComponent={DistrictDropdown}
                  fieldProps={{
                    allowClear: true,
                    placeholder: 'Quận/Huyện',
                    selectedArea: values.area,
                    disabled: values.area ? false : true,
                  }}
                  nativeInput={false}
                />
              </Col>
              <Col md={5}>
                <Field
                  name='employeeId'
                  component={UIField}
                  fieldComponent={EmployeeSearch}
                  fieldProps={{
                    allowClear: true,
                    placeholder: 'Nhân viên',
                  }}
                  nativeInput={false}
                />
              </Col>
              <Col md={4}>
                <Field
                  name='typeCode'
                  component={UIField}
                  nativeInput={false}
                  fieldComponent={LocationTypeDropdown}
                  fieldProps={{
                    status,
                    placeholder: 'Loại địa điểm',
                    allowClear: true,
                  }}
                />
              </Col>
              <Col md={4}>
                <Field
                  name='statusFilter'
                  component={UIField}
                  nativeInput={false}
                  fieldComponent={StatusDropdown}
                  fieldProps={{
                    status,
                    placeholder: 'Trạng thái',
                    allowClear: true,
                  }}
                />
              </Col>
              <Col md={1}>
                <Button
                  style={{ marginTop: 4 }}
                  type='danger'
                  onClick={event => {
                    event.stopPropagation();
                    resetFilter();
                  }}
                >
                  <Icon type='reload' />
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Form>
    );
  };
}

import { ICellRendererParams } from 'ag-grid-community';
import { Component } from 'react';
import { get } from 'lodash';

import {
  GoodsReceiptDetailLocation,
  GoodsIssueDetailLocation
} from '~screens/Routes';

export class GridCellLinkRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const code = get(data, 'goodsReceiptCode') || get(data, 'goodsIssueCode');

    if (get(data, 'goodsReceiptCode')) {
      return GoodsReceiptDetailLocation.toLink(code, {
        code,
      });
    }

    return GoodsIssueDetailLocation.toLink(code, {
      code,
    });
  }
}

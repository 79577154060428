import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import store from './store';
import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import { isAuthorized } from '~components/Authorized/Authorized';
import { UIButton } from '~components/UI';
import { get } from 'lodash';
import {
  PERMISSION_EQUIPMENT_CATEGORY_UPDATE,
  PERMISSION_EQUIPMENT_CATEGORY_REMOVE
} from '@mgn/common';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const { toggleModal, handleDelete } = store;

    return (
      <Fragment>
        <Button.Group>
          <UIButton
            icon='edit'
            size='small'
            type='primary'
            onClick={() => toggleModal(data)}
            disabled={!isAuthorized(PERMISSION_EQUIPMENT_CATEGORY_UPDATE)}
          />
          <Popconfirm
            title='Xoá ?'
            onConfirm={() => handleDelete(get(data, '_id'))}
          >
            <UIButton
              icon='delete'
              size='small'
              type='danger'
              disabled={!isAuthorized(PERMISSION_EQUIPMENT_CATEGORY_REMOVE)}
            />
          </Popconfirm>
        </Button.Group>
      </Fragment>
    );
  }
}

import { Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Loading } from '~components/UI';

import IOInventoryFilter from './Filter';
import IOInventoryTable from './Grid';
import store from './store';

@observer
class IOInventory extends Component {
  public componentWillMount() {
    store.init();
  }

  public onFilterChange = values => {
    store.setFilter(values);
  };

  public render() {
    const { loading } = store;

    if (loading) return <Loading />;

    return (
      <Row type='flex' style={{ flexFlow: 'column', height: '100vh' }}>
        <IOInventoryFilter onChange={this.onFilterChange} />
        <div style={{ flex: 1, marginTop: 8 }}>
          <IOInventoryTable />
        </div>
      </Row>
    );
  }
}

export default IOInventory;

import React from 'react';

const DescriptionItem = ({ title, content, contentStyle = null }) => (
  <div style={{ lineHeight: '2em' }}>
    <span
      style={{
        display: 'inline-block',
        color: 'rgba(0,0,0,0.85)',
      }}
    >
      {title}
    </span>
    : <span style={contentStyle}>{content}</span>
  </div>
);

export default DescriptionItem;

import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import Authorized from '~components/Authorized/Authorized';
import { UIButton } from '~components/UI';
import { PERMISSION_ROLE_REMOVE, PERMISSION_ROLE_UPDATE } from '@mgn/common';

import store from './store';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const { toggleModal, handleDelete } = store;
    return (
      <Fragment>
        <Button.Group>
          <Authorized permission={PERMISSION_ROLE_UPDATE}>
            <UIButton
              onClick={() => toggleModal(data)}
              icon='edit'
              size='small'
              type='primary'
            />
          </Authorized>
          <Authorized permission={PERMISSION_ROLE_REMOVE}>
            <Popconfirm
              title='Bạn có chắc muốn xóa không?'
              onConfirm={() => handleDelete(get(data, '_id'))}
            >
              <UIButton icon='delete' size='small' type='danger' />
            </Popconfirm>
          </Authorized>
        </Button.Group>
      </Fragment>
    );
  }
}

import { Button, Input, Modal, Radio, Row } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { UIField } from '~components/UI/UIField';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const TextArea = Input.TextArea;

interface IValue {
  text: string;
  value: string;
  color: string;
}

interface IProps {
  toggleModal: () => void;
  handleConfirm: (confirm, note) => void;
  visible: boolean;
  values: IValue[];
}

interface IFormValues {
  confirm: string;
  note?: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  confirm: Yup.string().required('Bắt buộc chọn'),
  note: Yup.string(),
});

export default class ConfirmModal extends Component<IProps> {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleConfirm } = this.props;
    const { resetForm, setSubmitting } = actions;

    const { confirm, note } = values;

    await handleConfirm(confirm, note);

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { visible, toggleModal } = this.props;

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting } = props;

      const footer = (
        <Row>
          <Button
            type='primary'
            htmlType='submit'
            form='confirmFm'
            disabled={!isValid}
            loading={isSubmitting}
          >
            Xác nhận
          </Button>

          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='confirmFm'>
            <Field
              name='confirm'
              component={UIField}
              fieldComponent={RadioGroup}
              fieldProps={{
                buttonStyle: 'solid',
                style: { width: '100%', marginBottom: '0.5em' },
                children: this.props.values.map((v, index) => (
                  <RadioButton
                    key={index}
                    value={v.value}
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: v.color,
                      width: `calc(100% / ${this.props.values.length})`,
                    }}
                  >
                    {v.text}
                  </RadioButton>
                )),
              }}
            />
            <Field
              name='note'
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                placeholder: 'Ghi chú',
                autosize: { minRows: 4, maxRows: 6 },
              }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

import equipmentModel from '../fragments/equipmentModel';
import gql from 'graphql-tag';

export const REMOVE_EQUIPMENT_MODEL_BY_ID_MUTATION = gql`
  mutation RemoveEquipmentModelById($_id: ID!) {
    removeEquipmentModelById(_id: $_id) {
      payload {
        ...BasicEquipmentModel
      }
      error {
        message
      }
    }
  }
  ${equipmentModel.basic}
`;

import { omit } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import { UPDATE_CONTENT_EQUIPMENT } from '~graphql/mutations/updateContentEquipment';
import { UPDATE_SERIAL_EQUIPMENT } from '~graphql/mutations/updateSerialEquipment';
import { FIND_ONE_EQUIPMENT_QUERY } from '~graphql/queries';
import {
  FindOneEquipmentQuery,
  FindOneEquipmentVariables,
  FullEquipmentFragment,
  UpdateContentEquipmentMutation,
  UpdateContentEquipmentVariables,
  UpdateSerialEquipmentMutation,
  UpdateSerialEquipmentVariables
} from '~graphql/types';

class UpdateEquipmentStore {
  @observable public loading: boolean;
  @observable public option: string;
  @observable public visible: boolean;
  @observable.shallow public equipment: FullEquipmentFragment;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.option = null;
      this.visible = false;
      this.equipment = null;
    });
  }

  @action public toggleModal = () => {
    this.visible = !this.visible;
  };

  @action public setOption = (value: string) => {
    this.option = value;
    this.toggleModal();
  };

  @action public fetchEquipment = async (serial: string) => {
    const {
      data: { findOneEquipment },
    } = await client.query<FindOneEquipmentQuery, FindOneEquipmentVariables>({
      query: FIND_ONE_EQUIPMENT_QUERY,
      variables: { where: { serial } },
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.equipment = omit(findOneEquipment, ['__typename']);
    });
  };

  @action public updateContentEquipment = async (
    params: UpdateContentEquipmentVariables
  ) => {
    const {
      data: { updateContentEquipment },
    } = await client.mutate<
      UpdateContentEquipmentMutation,
      UpdateContentEquipmentVariables
    >({
      mutation: UPDATE_CONTENT_EQUIPMENT,
      variables: params,
      errorPolicy: 'all',
    });

    const { error } = updateContentEquipment;

    if (error) {
      ErrorNoti(error.message);
    } else {
      this.fetchEquipment(this.equipment.serial);
      SuccessNoti('Cập nhật thiết bị');
      this.toggleModal();
    }
  };

  @action public updateSerialEquipment = async (
    params: UpdateSerialEquipmentVariables
  ) => {
    const {
      data: { updateSerialEquipment },
    } = await client.mutate<
      UpdateSerialEquipmentMutation,
      UpdateContentEquipmentVariables
    >({
      mutation: UPDATE_SERIAL_EQUIPMENT,
      variables: params,
      errorPolicy: 'all',
    });

    const { error } = updateSerialEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      this.fetchEquipment(params.newSerial);
      SuccessNoti('Cập nhật thiết bị');
      this.toggleModal();
    }
  };
}

export default new UpdateEquipmentStore();

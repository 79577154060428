import gql from 'graphql-tag';
import proposedEquipment from '../fragments/proposedEquipment';

export const CONFIRM_FROM_WAREHOUSE_PROPOSED_EQUIPMENT_MUTATION = gql`
  mutation ConfirmFromWarehouseProposedEquipment(
    $_id: ID!
    $confirm: ConfirmProposedEquipmentEnum!
    $note: String
  ) {
    confirmFromWarehouseProposedEquipment(
      _id: $_id
      confirm: $confirm
      note: $note
    ) {
      proposedEquipment {
        ...FullProposedEquipment
      }
      error {
        message
      }
    }
  }
  ${proposedEquipment.full}
`;

const loadDataFromServer = (props, query) => {
  return props.client.query({
    query,
    refetchQueries: [{ query }],
  });
};

const loadDataFromServerFilterStatus = (props, query, filter) => {
  return props.client.query({
    query,
    variables: { status: filter },
  });
};

export { loadDataFromServer, loadDataFromServerFilterStatus };

export const GOODSRECEIPT = "GOODSRECEIPT";
export const GOODSISSUE = "GOODSISSUE";
export const LOCATION = "LOCATION";
export const LOCATION_TYPE = "location";
export const WAREHOUSE = "WAREHOUSE";
export const WAREHOUSE_TYPE = "warehouse";
export const SERIAL = "SERIAL";
export const OTHER = "OTHER";
export const PICTURE_FILE = "picturePaths";
export const DRAWING_FILE = "drawingPaths";
export const RECORD_FILE = "recordPaths";
export const TECHNICAL = "TECHNICAL";
export const CONTACT = "CONTACT";

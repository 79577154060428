import gql from 'graphql-tag';
import employee from '../fragments/employee';

export const RESET_PASSWORD_EMPLOYEE = gql`
  mutation ResetPasswordEmployee($_id: ID!) {
    resetPasswordEmployee(_id: $_id) {
      error {
        message
      }
      employee {
        ...FullEmployee
      }
    }
  }
  ${employee.full}
`;

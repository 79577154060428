import client from '~graphql/client';
import { CountGoodsReceiptQuery } from '~graphql/types';
import { COUNT_GOODSRECEIPT_QUERY } from '~graphql/queries/countGoodsReceipt';

export const countGoodsReceipt = async (status = null) => {
  const { data } = await client.query<CountGoodsReceiptQuery>({
    query: COUNT_GOODSRECEIPT_QUERY,
    variables: { where: { status } },
    fetchPolicy: 'network-only',
  });
  const count = await data.countGoodsReceipt;
  return count;
};

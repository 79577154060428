import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row, Card, Col } from 'antd';
import store from './store';
import SearchEquipment from '~components/SearchEquipment/SearchEquipment';
import EquipmentInfo from './EquipmentInfo';
import SelectOption from './Form';
import UpdateEquipmentActions from './Actions';
import { get } from 'lodash';
import { NotFoundScreen } from '~screens/NotFound';

@observer
export default class UpdateEquipment extends Component {
  public render() {
    const { fetchEquipment, visible, equipment } = store;

    return (
      <Row>
        <Col lg={{ span: 10, offset: 6 }} sm={{ span: 14, offset: 5 }}>
          <Card
            title='Cập nhật thiết bị'
            style={{ marginTop: '1em' }}
            headStyle={{ textAlign: 'center' }}
          >
            <SearchEquipment handleSelect={fetchEquipment} />
            <SelectOption visible={visible} />
            <EquipmentInfo />
            {get(equipment, 'serial') ? (
              <UpdateEquipmentActions />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <NotFoundScreen />
              </div>
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}

import gql from 'graphql-tag';
import location from '../fragments/location';

export const FIND_ONE_LOCATION_QUERY = gql`
  query FindOneLocation($where: LocationBaseFilter, $order: LocationBaseSort) {
    findOneLocation(where: $where, order: $order) {
      ...FullLocation
    }
  }
  ${location.full}
`;

export const FIND_ONE_LOCATION_EQUIPMENT_DETAIL_QUERY = gql`
  query FindOneLocationEquipmentDetail(
    $where: LocationBaseFilter
    $order: LocationBaseSort
  ) {
    findOneLocation(where: $where, order: $order) {
      ...EquipmentDetailLocation
    }
  }
  ${location.equipment}
`;

export const FIND_MANY_LOCATION_QUERY = gql`
  query FindManyLocation(
    $skip: Int
    $take: Int
    $where: LocationBaseFilter
    $order: LocationBaseSort
  ) {
    findManyLocation(skip: $skip, take: $take, where: $where, order: $order) {
      ...DetailedLocation
    }
  }
  ${location.detailed}
`;

export const FIND_MANY_BASIC_LOCATION_QUERY = gql`
  query FindManyBasicLocation(
    $skip: Int
    $take: Int
    $where: LocationBaseFilter
    $order: LocationBaseSort
  ) {
    findManyLocation(skip: $skip, take: $take, where: $where, order: $order) {
      ...BasicLocation
    }
  }
  ${location.basic}
`;

export const FIND_MANY_SEARCH_LOCATION_QUERY = gql`
  query FindManySearchLocation(
    $skip: Int
    $take: Int
    $where: LocationBaseFilter
    $order: LocationBaseSort
  ) {
    findManyLocation(skip: $skip, take: $take, where: $where, order: $order) {
      ...SearchLocation
    }
  }
  ${location.search}
`;

import { ICellRendererParams } from 'ag-grid-community';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { isAuthorized } from '~components/Authorized/Authorized';
import {
  PERMISSION_EMPLOYEE_REMOVE,
  PERMISSION_EMPLOYEE_RESET_PASSWORD,
  PERMISSION_EMPLOYEE_UPDATE
} from '@mgn/common';

import store from './store';
import { UIButton } from '~components/UI';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const { toggleModal, handleDelete, handleResetPassword } = store;

    const moreActions = (
      <Menu>
        <Menu.Item key='1'>
          <Popconfirm
            title='Xóa?'
            onConfirm={() => handleDelete(get(data, '_id'))}
          >
            <UIButton
              icon='delete'
              type='danger'
              size='small'
              outline='none'
              disabled={!isAuthorized(PERMISSION_EMPLOYEE_REMOVE)}
            >
              Xoá
            </UIButton>
          </Popconfirm>
        </Menu.Item>

        <Menu.Item key='2'>
          <Popconfirm
            title='Khôi phục mật khẩu?'
            onConfirm={() => handleResetPassword(get(data, '_id'))}
          >
            <UIButton
              icon='undo'
              type='info'
              outline='none'
              disabled={!isAuthorized(PERMISSION_EMPLOYEE_RESET_PASSWORD)}
            >
              Khôi phục mật khẩu
            </UIButton>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <Fragment>
        <Button.Group>
          <UIButton
            icon='edit'
            type='primary'
            size='small'
            disabled={!isAuthorized(PERMISSION_EMPLOYEE_UPDATE)}
            onClick={() => toggleModal(data)}
          />
          <Dropdown overlay={moreActions}>
            <Button icon='ellipsis' size='small' type='default' />
          </Dropdown>
        </Button.Group>
      </Fragment>
    );
  }
}

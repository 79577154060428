import { ColDef } from 'ag-grid-community';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { AgGridTable } from '~components/UI';
import { flatten } from '~utils';

import ActionCellRenderer from './GridActionCellRenderer';
import MenuTreeCellRenderer from './GridMenuTreeCellRenderer';
import store from './store';

@observer
export default class MenuGrid extends Component {
  private columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 90,
      maxWidth: 90,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: ActionCellRenderer,
    },
    {
      headerName: 'Tên',
      field: 'name',
      minWidth: 200,
      maxWidth: 200,
    },
    {
      headerName: 'Menu',
      field: 'menu',
      minWidth: 600,
      suppressSorting: true,
      valueGetter: ({ data }) => {
        return flatten(get(data, 'menu', [])).map(item => item.name);
      },
      cellRendererFramework: MenuTreeCellRenderer,
    },
  ];

  public getRowHeight = ({ data }) => {
    const flattenMenuData = flatten(get(data, 'menu', []));
    const heightWithPadding = flattenMenuData.length * 32 + 6;
    const heightWhenNoData = 35;
    return Math.max(heightWhenNoData, heightWithPadding);
  };

  public render() {
    const { data, quickFilter } = store;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height) => (
            <AgGridTable
              height={height}
              rowData={data}
              columnDefs={this.columnDefs}
              getRowHeight={this.getRowHeight}
              quickFilterText={quickFilter}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

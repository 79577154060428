import { action, observable, runInAction } from 'mobx';
import client from '~graphql/client';
import { FIND_ONE_LOCATION_QUERY } from '~graphql/queries';
import {
  FindOneLocationQuery,
  FindOneLocationVariables,
  FullLocationFragment
} from '~graphql/types';

class ViewDrawerStore {
  @observable.ref public selectedItem: FullLocationFragment;
  @observable public locationId: string;
  @observable public loading: boolean;
  @observable public updateContentVisible: boolean;

  constructor() {
    runInAction(() => {
      this.selectedItem = null;
      this.locationId = null;
      this.loading = true;
      this.updateContentVisible = false;
    });
  }

  @action public setLocationId = (locationId: string) => {
    this.locationId = locationId;

    if (locationId) {
      this.fetchData();
    } else {
      this.selectedItem = null;
    }

    this.loading = false;
  };

  @action public setLocation = (location: FullLocationFragment) => {
    this.selectedItem = location;
  };

  @action public toggleUpdateContentModal = () => {
    this.updateContentVisible = !this.updateContentVisible;
  };

  @action public fetchData = async () => {
    const _id = this.locationId;

    if (_id) {
      const {
        data: { findOneLocation },
      } = await client.query<FindOneLocationQuery, FindOneLocationVariables>({
        query: FIND_ONE_LOCATION_QUERY,
        fetchPolicy: 'network-only',
        variables: { where: { _id } },
      });

      runInAction(() => {
        this.selectedItem = findOneLocation;
      });
    }
  };
}

export default new ViewDrawerStore();

import { ColDef, GridOptions } from 'ag-grid-community';
import { Col, Icon, Input, Row } from 'antd';
import { get, Omit } from 'lodash';
import React, { ChangeEvent, Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { AgGridTable, LocationInfo } from '~components/UI';
import { GridCellStatusRenderer } from '~components/UI/AgGridTable/CellStatusRenderer';
import { SharedEquipmentFragment } from '~graphql/types';
import { formatEquipmentData, formatPrice, formatTime } from '~utils';

import { GridSerialCellRenderer } from './GridSerialCellRenderer';

export type EquipmentExtraColumn =
  | 'supplier'
  | 'unitPrice'
  | 'issueInvoiceDay'
  | 'warranty'
  | 'note'
  | 'location';

interface IColumnDef {
  [key: string]: ColDef;
}

export const columnDefs: IColumnDef = {
  id: {
    headerName: 'STT',
    field: '_id',
    minWidth: 60,
    maxWidth: 60,
    pinned: 'left',
    suppressSorting: true,
    cellRenderer: ({ rowIndex }) => rowIndex + 1,
  },
  idGroup: {
    headerName: 'STT',
    field: '_id',
    minWidth: 60,
    maxWidth: 60,
    suppressSorting: true,
    cellRenderer: ({ rowIndex, node }) => {
      if (node.group) return null;
      return (
        (!isNaN(node.childIndex) ? node.childIndex : rowIndex) + 1
      ).toString();
    },
  },
  serial: {
    headerName: 'Serial',
    field: 'serial',
    cellRendererFramework: GridSerialCellRenderer,
    minWidth: 200,
  },
  equipmentCategoryId: {
    colId: 'equipmentCategoryId',
    headerName: 'Loại thiết bị',
    field: 'equipmentCategory.name',
    minWidth: 100,
    maxWidth: 100,
  },
  equipmentModelId: {
    colId: 'equipmentModelId',
    headerName: 'Tên thiết bị',
    field: 'equipmentModelId',
    sort: 'asc',
    valueGetter: ({ data, node }) =>
      node.group
        ? get(data, 'equipmentModelId')
        : get(data, 'equipmentModel.name'),
    minWidth: 120,
    maxWidth: 120,
  },
  equipmentModelCode: {
    headerName: 'Mã thiết bị',
    field: 'equipmentModelCode',
    sort: 'asc',
    minWidth: 140,
    maxWidth: 140,
  },
  status: {
    headerName: 'Trạng thái',
    field: 'status',
    suppressSorting: true,
    cellRendererFramework: GridCellStatusRenderer,
    minWidth: 110,
    maxWidth: 110,
  },
  supplier: {
    colId: 'supplierId',
    headerName: 'Nhà cung cấp',
    field: 'supplier.name',
    minWidth: 150,
    maxWidth: 150,
  },
  supplierCode: {
    headerName: 'Mã NCC',
    field: 'supplierCode',
    minWidth: 120,
    maxWidth: 120,
  },
  issueInvoiceDay: {
    headerName: 'Xuất hóa đơn',
    field: 'issueInvoiceDay',
    cellRenderer: ({ value }) => value && formatTime(value),
    minWidth: 150,
    maxWidth: 150,
  },
  unitPrice: {
    headerName: 'ĐG (VNĐ)',
    field: 'unitPrice',
    aggFunc: 'sum',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'right',
    cellRenderer: ({ value }) => formatPrice(value),
  },
  warranty: {
    headerName: 'BH (T)',
    field: 'warranty',
    minWidth: 80,
    maxWidth: 80,
  },
  note: {
    headerName: 'Ghi chú',
    field: 'note',
    minWidth: 200,
  },
  location: {
    colId: 'locationId',
    headerName: 'Địa điểm hiện tại',
    field: 'location',
    cellRendererFramework: ({ value }) => <LocationInfo location={value} />,
    minWidth: 200,
  },
};

export type Equipment = Omit<SharedEquipmentFragment, '__typename'>;

interface IProps {
  data: Equipment[];
  categoryId?: string;
  modelId?: string;
  title?: string;
  actionComponent?: React.ReactNode;
  actionColDef?: ColDef;
  extraColumn?: EquipmentExtraColumn[];
  rowGroup?: boolean;
  gridOptions?: GridOptions;
}

interface IState {
  quickFilter?: string;
}

export class EquipmentTable extends Component<IProps, IState> {
  public state: IState = {};

  public handleQuickFilter = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ quickFilter: event.target.value });
  };

  public render() {
    const {
      data,
      extraColumn = [],
      categoryId: categoryIdFilter,
      modelId: modelIdFilter,
      title = 'Danh sách thiết bị',
      actionComponent: ActionComponent = null,
      actionColDef,
      rowGroup = true,
      gridOptions = {},
    } = this.props;

    const columns = [
      actionColDef,
      ...(rowGroup ? [columnDefs.idGroup] : [columnDefs.id]),
      ...[columnDefs.serial, columnDefs.equipmentCategoryId],
      {
        ...columnDefs.equipmentModelId,
        ...(rowGroup && { rowGroup, hide: true }),
      },
      ...extraColumn.map(col => columnDefs[col]),
      columnDefs.status,
    ].filter(Boolean);

    return (
      <Row
        type='flex'
        style={{
          flexFlow: 'column',
          height: '100%',
        }}
      >
        {title && <h2>{title}</h2>}
        <Row style={{ flex: 0 }}>
          <Col
            span={24}
            md={{ span: 6 }}
            style={{ minWidth: 200, maxWidth: 350 }}
          >
            <Input
              type='text'
              prefix={<Icon type='search' />}
              onChange={this.handleQuickFilter}
              placeholder='Tìm kiếm...'
            />
          </Col>
          <Col style={{ flex: 1, textAlign: 'right' }}>{ActionComponent}</Col>
        </Row>

        <div style={{ flex: 1, paddingTop: 8 }}>
          <ReactResizeDetector handleHeight={true}>
            {(_width, height) => (
              <AgGridTable
                {...gridOptions}
                height={height - 8}
                columnDefs={columns}
                groupUseEntireRow={true}
                suppressAggFuncInHeader={true}
                rememberGroupStateWhenNewData={true}
                quickFilterText={this.state.quickFilter}
                rowData={formatEquipmentData(
                  data,
                  categoryIdFilter,
                  modelIdFilter
                )}
              />
            )}
          </ReactResizeDetector>
        </div>
      </Row>
    );
  }
}

export default EquipmentTable;

import { Button, Col, Input, Row } from "antd";
import React, { Component, Fragment } from "react";
import Authorized from "~components/Authorized/Authorized";

interface IProps {
  text: string;
  handeFilter: (values) => void;
  filter: string;
  toggleModal: () => void;
  permission: string;
}

export default class Header extends Component<IProps> {
  public render() {
    const { text, handeFilter, toggleModal, permission, filter } = this.props;
    return (
      <Fragment>
        <h2 style={{ marginTop: 8 }}>{`Danh sách ${text}`}</h2>
        <Row gutter={8}>
          <Col md={8}>
            <Input
              type="text"
              onChange={handeFilter}
              placeholder="Tìm kiếm..."
              style={{ width: "100%" }}
              defaultValue={filter}
              allowClear={true}
            />
          </Col>
          <Col md={{ span: 4, offset: 12 }} style={{ float: "right" }}>
            <Authorized permission={permission}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                icon="plus"
                onClick={() => toggleModal()}
              >
                {`Tạo ${text}`}
              </Button>
            </Authorized>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

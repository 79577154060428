import gql from 'graphql-tag';
import employee from '../fragments/employee';

export const REMOVE_EMPLOYEE_MUTATION = gql`
  mutation RemoveEmployeeById($_id: ID!) {
    removeEmployeeById(_id: $_id) {
      employee {
        ...FullEmployee
      }
      error {
        message
      }
    }
  }
  ${employee.full}
`;

import { Button, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { UIField } from '~components/UI/UIField';
import { EquipmentCategoryInput } from '~graphql/types';

import store from './store';

interface IFormValues extends EquipmentCategoryInput {
  _id: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),

  name: Yup.string().required('Bắt buộc nhập'),

  description: Yup.string(),
});

@observer
class EquipmentCategoryForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm, setSubmitting } = actions;
    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { visible, toggleModal, selectedItem } = store;

    const formInitialValues = {
      _id: get(selectedItem, '_id'),
      name: get(selectedItem, 'name'),
      description: get(selectedItem, 'description'),
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            type='primary'
            htmlType='submit'
            form='eqipmentCategoryFm'
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='eqipmentCategoryFm'>
            <Field
              name='name'
              label='Tên loại thiết bị'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập tên loại thiết bị' }}
            />
            <Field
              name='description'
              label='Mô tả'
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                placeholder: 'Nhập mô tả',
                autosize: { minRows: 2, maxRows: 6 },
              }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}
export default EquipmentCategoryForm;

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import client from '~graphql/client';
import { FIND_MANY_EMPLOYEE_QUERY } from '~graphql/queries';
import {
  FindManyEmployeeQuery,
  FindManyEmployeeVariables,
  FullEmployeeFragment
} from '~graphql/types';

const { Option } = Select;

@observer
export default class EmployeeSearch extends Component<SelectProps> {
  @observable private loading: boolean;
  @observable private data: FullEmployeeFragment[];

  constructor(props) {
    super(props);

    runInAction(() => {
      this.loading = false;
      this.data = [];
    });
  }

  @computed public get employees(): FullEmployeeFragment[] {
    return this.data;
  }

  public componentDidMount() {
    this.fetchEmployee().finally(
      action(() => {
        this.loading = false;
      })
    );
  }

  public render() {
    const { ...props } = this.props;

    return (
      <Select
        {...props}
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        loading={this.loading}
      >
        {this.employees.map((e, index) => {
          return (
            <Option key={String(index)} value={e._id}>
              {e.fullName}
            </Option>
          );
        })}
      </Select>
    );
  }

  @action private fetchEmployee = async () => {
    const {
      data: { findManyEmployee },
    } = await client.query<FindManyEmployeeQuery, FindManyEmployeeVariables>({
      query: FIND_MANY_EMPLOYEE_QUERY,
    });

    runInAction(() => {
      this.data = findManyEmployee.filter(
        e => e.fullName.toUpperCase() !== 'SUPERADMIN'
      );
    });
  };
}

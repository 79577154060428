import {
  FULFILLED,
  FULFILLING,
  CREATED,
  WAITING_SURVEYOR,
  SURVEYING,
  WAITING_AGREEMENT,
  AGREED,
  CANCELLED,
  WAITING_INSTALLER,
  INSTALLING,
  INSTALLED,
  WAITING_MAINTAINER,
  WORKING,
  DELETED,
  STOPPED
} from '@mgn/common';

export const formatStatus = (status: string) => {
  switch (status) {
    case CREATED:
      return 'Mới tạo';
    case WAITING_SURVEYOR:
      return 'Chờ khảo sát';
    case SURVEYING:
      return 'Đang khảo sát';
    case WAITING_INSTALLER:
      return 'Chờ thi công';
    case INSTALLING:
      return 'Đang thi công';
    case INSTALLED:
      return 'Hoàn tất thi công';
    case WAITING_MAINTAINER:
      return 'Chờ vận hành';
    case WORKING:
      return 'Đang hoạt động';
    case WAITING_AGREEMENT:
      return 'Chờ duyệt';
    case AGREED:
      return 'Duyệt thành công';
    case CANCELLED:
      return 'Hủy';
    case FULFILLING:
      return 'Đang xuất thiết bị';
    case FULFILLED:
      return 'Hoàn tất xuất thiết bị';
    case DELETED:
      return 'Đã xóa';
    case STOPPED:
      return 'Ngưng hoạt động';
    default:
      return status;
  }
};

import { Button, Modal, Row } from "antd";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { get } from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import * as Yup from "yup";
import AreaDropdown from "~components/Dropdown/AreaDropdown";
import DistrictDropdown from "~components/Dropdown/DistrictDropdown";
import EmployeeDropdown from "~components/Dropdown/EmployeeDropdown";
import { UIField } from "~components/UI/UIField";
import { WarehouseContentInput } from "~graphql/types";

import { PERMISSION_EMPLOYEE_WAREHOUSE_EMPLOYEE } from "@mgn/common";

import store from "./store";

interface IProps {
  visible: boolean;
}

interface IFormValues extends WarehouseContentInput {
  _id: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),
  name: Yup.string().required("Bắt buộc nhập"),
  address: Yup.string()
    .matches(/., ./, "Sai định dạng !!!")
    .required("Bắt buộc nhập"),
  maintainerId: Yup.string().required("Bắt buộc nhập"),
  area: Yup.string().required("Bắt buộc chọn"),
  district: Yup.string().required("Bắt buộc chọn"),
  typeCode: Yup.string().required("Bắt buộc chọn")
});

@observer
class WarehouseForm extends Component<IProps> {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { setSubmitting, resetForm } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { selectedItem, type } = store;

    const formInitialValues = {
      typeCode: type,
      _id: get(selectedItem, "_id"),
      name: get(selectedItem, "name"),
      address: get(selectedItem, "address"),
      area: get(selectedItem, "area"),
      district: get(selectedItem, "district"),
      maintainerId: get(selectedItem, "maintainerId")
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (props: FormikProps<IFormValues>) => {
    const { visible } = this.props;
    const { toggleModal } = store;
    const { isValid, isSubmitting, values, setFieldValue, setTouched } = props;

    const footer = (
      <Row>
        <Button
          type="primary"
          htmlType="submit"
          form="warehouseFm"
          disabled={!isValid}
          loading={isSubmitting}
        >
          {values._id ? "Cập nhật" : "Tạo mới"}
        </Button>
        <Button type="danger" onClick={() => toggleModal()}>
          Đóng
        </Button>
      </Row>
    );
    const onAreaChange = () => {
      setFieldValue("district", undefined, false);
      setTouched({ district: false });
    };
    return (
      <Modal closable={false} visible={visible} footer={footer}>
        <Form id="warehouseFm">
          <Field
            name="name"
            label="Tên kho"
            component={UIField}
            fieldProps={{
              placeholder: "Nhập tên kho"
            }}
          />
          <Field
            name="address"
            label="Địa chỉ kho"
            component={UIField}
            fieldProps={{
              placeholder:
                "Nhập địa chỉ theo dạng: số nhà[dấu phẩy][dấu cách]tên đường"
            }}
          />
          <Field
            name="area"
            label="Tỉnh/Thành phố"
            component={UIField}
            fieldComponent={AreaDropdown}
            fieldProps={{
              placeholder: "Chọn Tỉnh/Thành phố",
              allowClear: true,
              onChange: onAreaChange
            }}
            nativeInput={false}
          />
          <Field
            name="district"
            label="Quận/Huyện"
            component={UIField}
            fieldComponent={DistrictDropdown}
            fieldProps={{
              allowClear: true,
              placeholder: "Chọn Quận/Huyện",
              selectedArea: get(values, "area"),
              disabled: get(values, "area") ? false : true
            }}
            nativeInput={false}
          />
          <Field
            name="maintainerId"
            label="Nhân viên kho"
            component={UIField}
            fieldComponent={EmployeeDropdown}
            fieldProps={{
              placeholder: "Chọn nhân viên",
              permission: PERMISSION_EMPLOYEE_WAREHOUSE_EMPLOYEE
            }}
            nativeInput={false}
          />
        </Form>
      </Modal>
    );
  };
}
export default WarehouseForm;

import { get } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { ErrorNoti, InfoNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  ADD_EQUIPMENT_TEMP_GOODS_ISSUE_MUTATION,
  CONFIRM_FROM_MANAGER_GOODS_ISSUE,
  PASS_TO_MANAGER_GOODS_ISSUE,
  REMOVE_EQUIPMENT_TEMP_GOODS_ISSUE_MUTATION,
} from '~graphql/mutations';
import {
  FIND_MANY_EQUIPMENT_GOODS_RI_QUERY,
  FIND_MANY_EQUIPMENT_HISTORY_EQUIPMENT_ONLY_QUERY,
  FIND_MANY_EQUIPMENT_QUERY,
  FIND_MANY_EQUIPMENT_TEMP_QUERY,
  FIND_ONE_GOODS_ISSUE_QUERY,
} from '~graphql/queries';
import {
  AddEquipmentTempGoodsIssueMutation,
  AddEquipmentTempGoodsIssueVariables,
  ConfirmEnum,
  ConfirmFromManagerGoodsIssueMutation,
  ConfirmFromManagerGoodsIssueVariables,
  FindEquipmentByGoodsRiCodeQuery,
  FindEquipmentByGoodsRiCodeVariables,
  FindManyEquipmentHistoryEquipmentOnlyQuery,
  FindManyEquipmentHistoryEquipmentOnlyVariables,
  FindManyEquipmentQuery,
  FindManyEquipmentTempQuery,
  FindManyEquipmentTempVariables,
  FindManyEquipmentVariables,
  FindOneGoodsIssueQuery,
  FindOneGoodsIssueVariables,
  FullEquipmentTempFragment,
  FullGoodsIssueFragment,
  PassToManagerGoodsIssueMutation,
  PassToManagerGoodsIssueVariables,
  RemoveEquipmentTempGoodsIssueMutation,
  RemoveEquipmentTempGoodsIssueVariables,
  SharedEquipmentFragment,
} from '~graphql/types';

import { AGREED, CANCELLED, mapReduce } from '@mgn/common';

interface IEquipmentPreTemp extends SharedEquipmentFragment {
  error?: string;
}

class GoodsIssueDetailStore {
  @observable public loading: boolean;
  @observable public preTempVisible: boolean;
  @observable public confirmVisible: boolean;
  @observable.ref public selectedItem: FullGoodsIssueFragment;
  @observable.shallow
  public equipments: SharedEquipmentFragment[];
  @observable
  public equipmentTemps: FullEquipmentTempFragment[];
  @observable.ref
  public equipmentPreTemps: IEquipmentPreTemp[];

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.preTempVisible = false;
      this.confirmVisible = false;
      this.selectedItem = null;
      this.equipments = [];
      this.equipmentPreTemps = [];
      this.equipmentTemps = [];
    });
  }

  @computed
  private get equipmentPreTempSerials() {
    return this.equipmentPreTemps.reduce((all, item) => {
      all[item.serial] = true;
      return all;
    }, {});
  }

  @action public setCode(code: string) {
    this.loading = true;
    this.selectedItem = null;
    this.equipments = [];
    this.equipmentTemps = [];
    this.equipmentPreTemps = [];

    this.fetchData(code).then(
      action(() => {
        this.loading = false;
      })
    );
  }

  @action public toggleForm = () => {
    this.preTempVisible = !this.preTempVisible;
  };

  @action public toggleConfirm = () => {
    this.confirmVisible = !this.confirmVisible;
  };

  @action public handleAddPreTemps = async (...serials: string[]) => {
    const newSerials = serials.filter(serial => {
      return !this.equipmentPreTempSerials[serial];
    });
    if (!newSerials.length) {
      return InfoNoti('SERIAL đã được thêm');
    }

    const {
      data: { findManyEquipment },
    } = await client.query<FindManyEquipmentQuery, FindManyEquipmentVariables>({
      query: FIND_MANY_EQUIPMENT_QUERY,
      variables: {
        where: {
          locationId: this.selectedItem.fromLocationId,
          _operators: { serial: { in: newSerials } },
        },
      },
    });

    if (findManyEquipment.length) {
      runInAction(() => {
        this.equipmentPreTemps = this.equipmentPreTemps.concat(
          findManyEquipment
        );
      });
    } else {
      return ErrorNoti('Không tìm thấy SERIAL nào trong kho');
    }
  };

  @action public handleDeletePreTemps = async (serial: string) => {
    this.equipmentPreTemps = this.equipmentPreTemps.filter(
      item => item.serial !== serial
    );
  };

  @action public handleCreateEquipmentTemps = async () => {
    const createEquipmentTemps = this.equipmentPreTemps.map(
      ({ serial, equipmentModelCode }) => ({
        serial,
        equipmentModelCode,
      })
    );

    const {
      data: { addEquipmentTempGoodsIssue },
    } = await client.mutate<
      AddEquipmentTempGoodsIssueMutation,
      AddEquipmentTempGoodsIssueVariables
    >({
      mutation: ADD_EQUIPMENT_TEMP_GOODS_ISSUE_MUTATION,
      variables: {
        equipmentTemps: createEquipmentTemps,
        goodsIssueCode: this.selectedItem.code,
      },
      errorPolicy: 'all',
    });

    const {
      error,
      equipmentTemps,
      invalidEquipmentTemps,
    } = addEquipmentTempGoodsIssue;

    if (error) {
      ErrorNoti(error.message);
    } else {
      const invalidEquipmentTempSerials = mapReduce(
        invalidEquipmentTemps,
        'serial',
        ({ message }) => message
      );

      if (equipmentTemps.length) {
        this.fetchEquipmentTemp(this.selectedItem.code);
      }

      runInAction(() => {
        this.equipmentPreTemps = this.equipmentPreTemps
          .filter(item => invalidEquipmentTempSerials[item.serial])
          .map(item => ({
            ...item,
            error: invalidEquipmentTempSerials[item.serial],
          }));

        if (this.equipmentPreTemps.length) {
          ErrorNoti(`Không thể xuất ${this.equipmentPreTemps.length} SERIAL`);
        } else {
          SuccessNoti('Tạo thiết bị vào phiếu');
          this.toggleForm();
        }
      });
    }
  };

  @action public handleDeleteEquipmentTemp = async (_id: string) => {
    const {
      data: { removeEquipmentTempGoodsIssue },
    } = await client.mutate<
      RemoveEquipmentTempGoodsIssueMutation,
      RemoveEquipmentTempGoodsIssueVariables
    >({
      mutation: REMOVE_EQUIPMENT_TEMP_GOODS_ISSUE_MUTATION,
      variables: { _id },
    });

    const { error } = removeEquipmentTempGoodsIssue;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Xóa thiết bị');
      this.updateEquipmentTemp(_id);
    }
  };

  @action public handlePassToManager = async () => {
    await client.mutate<
      PassToManagerGoodsIssueMutation,
      PassToManagerGoodsIssueVariables
    >({
      mutation: PASS_TO_MANAGER_GOODS_ISSUE,
      variables: { goodsIssueCode: this.selectedItem.code },
      update: (_cache, { data: { passToManagerGoodsIssue } }) => {
        const { error, goodsIssue } = passToManagerGoodsIssue;

        if (error) {
          ErrorNoti(error.message);
        } else {
          runInAction(() => {
            this.selectedItem = goodsIssue;
          });
          SuccessNoti('Trình duyệt');
        }
      },
    });
  };

  @action public handleConfirmFromManager = async (
    confirm: ConfirmEnum,
    note: string
  ) => {
    const {
      data: { confirmFromManagerGoodsIssue },
    } = await client.mutate<
      ConfirmFromManagerGoodsIssueMutation,
      ConfirmFromManagerGoodsIssueVariables
    >({
      mutation: CONFIRM_FROM_MANAGER_GOODS_ISSUE,
      variables: { confirm, note, goodsIssueCode: this.selectedItem.code },
    });

    const { error, goodsIssue } = confirmFromManagerGoodsIssue;
    if (error) {
      ErrorNoti(error.message);
    } else {
      runInAction(() => {
        this.selectedItem = goodsIssue;
      });
      if (goodsIssue.status === AGREED || goodsIssue.status === CANCELLED) {
        this.fetchEquipmentHistory(goodsIssue.code);
      }
      this.toggleConfirm();
      SuccessNoti('Duyệt');
    }
  };

  @action private async fetchData(code: string) {
    const {
      data: { findOneGoodsIssue },
    } = await client.query<FindOneGoodsIssueQuery, FindOneGoodsIssueVariables>({
      query: FIND_ONE_GOODS_ISSUE_QUERY,
      fetchPolicy: 'network-only',
      variables: { where: { code } },
    });

    runInAction(() => {
      this.selectedItem = findOneGoodsIssue;
    });

    get(findOneGoodsIssue, 'status') === AGREED ||
    get(findOneGoodsIssue, 'status') === CANCELLED
      ? this.fetchEquipmentHistory(code)
      : this.fetchEquipmentTemp(code);
  }

  @action private async fetchEquipmentTemp(code: string) {
    const {
      data: { findManyEquipmentTemp },
    } = await client.query<
      FindManyEquipmentTempQuery,
      FindManyEquipmentTempVariables
    >({
      query: FIND_MANY_EQUIPMENT_TEMP_QUERY,
      fetchPolicy: 'network-only',
      variables: { where: { code } },
    });

    runInAction(() => {
      this.equipmentTemps = findManyEquipmentTemp || [];
    });
  }

  // @action private async fetchEquipmentHistory(code: string) {
  //   const {
  //     data: { findManyEquipmentHistory },
  //   } = await client.query<
  //     FindManyEquipmentHistoryEquipmentOnlyQuery,
  //     FindManyEquipmentHistoryEquipmentOnlyVariables
  //   >({
  //     query: FIND_MANY_EQUIPMENT_HISTORY_EQUIPMENT_ONLY_QUERY,
  //     fetchPolicy: 'network-only',
  //     variables: { where: { goodsIssueCode: code } },
  //   });

  //   runInAction(() => {
  //     this.equipments = (findManyEquipmentHistory || []).map(
  //       item => item.equipment
  //     );
  //   });
  // }

  @action private async fetchEquipmentHistory(code: string) {
    const {
      data: { findEquipmentByGoodsRICode },
    } = await client.query<
      FindEquipmentByGoodsRiCodeQuery,
      FindEquipmentByGoodsRiCodeVariables
    >({
      query: FIND_MANY_EQUIPMENT_GOODS_RI_QUERY,
      fetchPolicy: 'network-only',
      variables: { code },
    });

    runInAction(() => {
      this.equipments = findEquipmentByGoodsRICode || [];
    });
  }

  @action private updateEquipmentTemp = (
    _id: string,
    record?: FullEquipmentTempFragment
  ) => {
    if (!_id) {
      this.equipmentTemps.push(record);
    } else {
      const index = this.equipmentTemps.findIndex(item => item._id === _id);
      if (!record) {
        this.equipmentTemps.splice(index, 1);
      } else {
        this.equipmentTemps.splice(index, 1, record);
      }
    }
  };
}

export default new GoodsIssueDetailStore();

import { action, observable, runInAction } from 'mobx';

import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_ROLE_MUTATION,
  REMOVE_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION
} from '~graphql/mutations';
import { FIND_MANY_ROLE_QUERY } from '~graphql/queries';
import {
  CreateRoleMutation,
  CreateRoleVariables,
  FindManyRoleQuery,
  FullRoleFragment,
  RemoveRoleByIdMutation,
  RemoveRoleByIdVariables,
  UpdateRoleByIdMutation,
  RoleInput,
  UpdateRoleByIdVariables
} from '~graphql/types';
import { removeTypename } from '~utils';

class RolesStore {
  @observable public loading: boolean;
  @observable public quickFilter: string;
  @observable public visible: boolean;
  @observable public selectedItem: FullRoleFragment;

  @observable.shallow public role: FullRoleFragment[];

  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.quickFilter = null;
      this.visible = false;
      this.selectedItem = null;
      this.role = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action public toggleModal = (item = null) => {
    this.selectedItem = item;
    this.visible = !this.visible;
  };

  @action public handleDelete = async (_id: string) => {
    const {
      data: { removeRoleById },
    } = await client.mutate<RemoveRoleByIdMutation, RemoveRoleByIdVariables>({
      mutation: REMOVE_ROLE_MUTATION,
      variables: { _id },
    });
    const { error } = removeRoleById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Xóa chức danh');
      this.updateData(_id);
    }
  };

  @action public handleCreate = async (record: CreateRoleVariables) => {
    const {
      data: { createRole },
    } = await client.mutate<CreateRoleMutation, CreateRoleVariables>({
      mutation: CREATE_ROLE_MUTATION,
      variables: record,
      errorPolicy: 'all',
    });

    const { error, payload } = createRole;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo chức danh');
      this.updateData(null, payload);
      this.toggleModal();
    }
  };

  @action public handleUpdate = async (_id: string, record: RoleInput) => {
    const {
      data: { updateRoleById },
    } = await client.mutate<UpdateRoleByIdMutation, UpdateRoleByIdVariables>({
      mutation: UPDATE_ROLE_MUTATION,
      variables: {
        _id,
        record,
      },
      errorPolicy: 'all',
    });
    const { error, payload } = updateRoleById;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật chức danh');
      this.updateData(_id, payload);
      this.toggleModal();
    }
  };

  @action private fetchData = async () => {
    const {
      data: { findManyRole },
    } = await client.query<FindManyRoleQuery>({
      query: FIND_MANY_ROLE_QUERY,
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.role = removeTypename(findManyRole);
    });
  };

  @action private updateData = (_id: string, record?: FullRoleFragment) => {
    if (!_id) {
      this.role = this.role.concat(record);
    } else {
      const index = this.role.findIndex(item => item._id === _id);

      this.role = [
        ...this.role.slice(0, index),
        record,
        ...this.role.slice(index + 1),
      ].filter(Boolean);
    }
  };
}

export default new RolesStore();

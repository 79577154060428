import { ICellRendererParams } from 'ag-grid-community';
import React, { Component } from 'react';

import { formatNumber } from '~utils';

interface IProps extends ICellRendererParams {
  decimal?: number;
}

export class GridCellNumberRenderer extends Component<IProps> {
  public render() {
    const { value, decimal = 2 } = this.props;

    return !isNaN(value) ? formatNumber(value, decimal) : '';
  }
}

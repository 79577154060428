import gql from 'graphql-tag';
import material from '../fragments/material';

export const FIND_MANY_MATERIAL_QUERY = gql`
  query FindManyMaterial(
    $skip: Int
    $take: Int
    $where: MaterialBaseFilter
    $order: MaterialBaseSort
  ) {
    findManyMaterial(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullMaterial
    }
  }
  ${material.full}
`;

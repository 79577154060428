import React, { Component } from 'react';
import { Formik, FormikProps, Form, Field, FormikActions } from 'formik';
import { Button } from 'antd';
import { UIField } from '~components/UI/UIField';
import store from './store';
import * as Yup from 'yup';

interface IFormValues {
  oldPassword: string;
  newPassword: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  oldPassword: Yup.string().required('Bắt buộc nhập'),

  newPassword: Yup.string().required('Bắt buộc nhập'),
});

export default class UpdatePasswordForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleUpdatePassword } = store;
    const { resetForm, setSubmitting } = actions;

    await handleUpdatePassword(values);

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting } = props;

      return (
        <Form id='updatePasswordFm'>
          <Field
            name='oldPassword'
            component={UIField}
            fieldProps={{
              placeholder: 'Nhập mật khẩu hiện tại',
              type: 'password',
            }}
          />
          <Field
            name='newPassword'
            component={UIField}
            fieldProps={{ placeholder: 'Nhập mật khẩu mới', type: 'password' }}
          />
          <Button
            style={{ width: '100%' }}
            htmlType='submit'
            form='updatePasswordFm'
            disabled={!isValid}
            loading={isSubmitting}
            type='primary'
          >
            Cập nhật
          </Button>
        </Form>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

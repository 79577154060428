import gql from "graphql-tag";

import warehouse from "../fragments/warehouse";

export const CREATE_WAREHOUSE_MUTATION = gql`
  mutation CreateWarehouse(
    $name: String!
    $address: String!
    $maintainerId: String!
    $area: String!
    $district: String!
    $typeCode: String!
  ) {
    createWarehouse(
      name: $name
      address: $address
      maintainerId: $maintainerId
      area: $area
      district: $district
      typeCode: $typeCode
    ) {
      warehouse {
        ...FullWarehouse
      }
      error {
        message
      }
    }
  }
  ${warehouse.full}
`;

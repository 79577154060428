import { get } from 'lodash';
import { action, runInAction } from 'mobx';
import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_LOCATION_MUTATION,
  PASS_TO_INSTALLER_LOCATION_MUTATION,
  PASS_TO_MAINTAINER_LOCATION_MUTATION,
  PASS_TO_SURVEYOR_LOCATION_MUTATION,
  REMOVE_BEFORE_AGREED_LOCATION_MUTATION,
  UPDATE_CONTENT_LOCATION_MUTATION,
} from '~graphql/mutations';
import { CANCELLED_LOCATION } from '~graphql/mutations/cancelLocation';
import { STOPPED_LOCATION } from '~graphql/mutations/stopLocation';
import { WORKING_LOCATION } from '~graphql/mutations/workLocation';
import { FIND_ONE_LOCATION_QUERY } from '~graphql/queries';
import {
  CancelledLocationMutation,
  CancelledLocationVariables,
  CreateLocationMutation,
  CreateLocationVariables,
  FullLocationFragment,
  PassToInstallerLocationMutation,
  PassToInstallerLocationVariables,
  PassToMaintainerLocationMutation,
  PassToMaintainerLocationVariables,
  PassToSurveyorLocationMutation,
  PassToSurveyorLocationVariables,
  RemoveBeforeAgreedLocationMutation,
  RemoveBeforeAgreedLocationVariables,
  StoppedLocationMutation,
  StoppedLocationVariables,
  UpdateContentLocationMutation,
  UpdateContentLocationVariables,
  WorkingLocationMutation,
  WorkingLocationVariables,
} from '~graphql/types';
import HomeScreenStore from '~screens/HomeScreen/store';
import { countLocation } from '~utils/countLocation';

import { UpdateContentLocationProps } from './UpdateContentLocation';

class UpdateContentLocationStore {
  private props: UpdateContentLocationProps;

  constructor(props: UpdateContentLocationProps) {
    this.props = props;
  }

  @action public setProps(props: UpdateContentLocationProps) {
    this.props = props;
  }

  @action public handleUpdate = async (
    record: UpdateContentLocationVariables
  ) => {
    const {
      data: { updateContentLocation },
    } = await client.mutate<
      UpdateContentLocationMutation,
      UpdateContentLocationVariables
    >({
      mutation: UPDATE_CONTENT_LOCATION_MUTATION,
      variables: record,
      refetchQueries: [
        {
          query: FIND_ONE_LOCATION_QUERY,
          variables: {
            where: {
              _id: this.props.location._id,
            },
          },
        },
      ],
    });
    const { location, error } = updateContentLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật địa điểm');
      this.onChange(location);
    }
  };

  @action public handleCreate = async (record: CreateLocationVariables) => {
    const {
      data: { createLocation },
    } = await client.mutate<CreateLocationMutation, CreateLocationVariables>({
      mutation: CREATE_LOCATION_MUTATION,
      variables: record,
      errorPolicy: 'all',
    });

    const { error, location } = createLocation;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo điểm mới');
      this.onChange(location);
    }
  };

  @action public handleDelete = async () => {
    const {
      data: { removeBeforeAgreedLocation },
    } = await client.mutate<
      RemoveBeforeAgreedLocationMutation,
      RemoveBeforeAgreedLocationVariables
    >({
      mutation: REMOVE_BEFORE_AGREED_LOCATION_MUTATION,
      variables: { _id: get(this.props.location, '_id') },
      errorPolicy: 'all',
    });

    const { error } = removeBeforeAgreedLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Xóa địa điểm');
      this.onChange();
      this.props.toggleModal();
    }
  };

  @action public passToSurveyor = async () => {
    const {
      data: { passToSurveyorLocation },
    } = await client.mutate<
      PassToSurveyorLocationMutation,
      PassToSurveyorLocationVariables
    >({
      mutation: PASS_TO_SURVEYOR_LOCATION_MUTATION,
      variables: { _id: this.props.location._id },
      errorPolicy: 'all',
    });

    const { error, location } = passToSurveyorLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Chuyển cho khảo sát');
      this.onChange(location);
      this.props.toggleModal();
      this.props.toggleViewDrawer();
    }
  };

  @action public passToInstaller = async () => {
    const {
      data: { passToInstallerLocation },
    } = await client.mutate<
      PassToInstallerLocationMutation,
      PassToInstallerLocationVariables
    >({
      mutation: PASS_TO_INSTALLER_LOCATION_MUTATION,
      variables: { _id: this.props.location._id },
      errorPolicy: 'all',
    });

    const { error, location } = passToInstallerLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Chuyển cho thi công');
      this.onChange(location);
      this.props.toggleModal();
      this.props.toggleViewDrawer();
    }
  };

  @action public stoppedLocation = async () => {
    const {
      data: { stoppedLocation },
    } = await client.mutate<StoppedLocationMutation, StoppedLocationVariables>({
      mutation: STOPPED_LOCATION,
      variables: { _id: this.props.location._id },
      errorPolicy: 'all',
    });

    const { error, location } = stoppedLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Ngưng hoạt động');
      this.onChange(location);
      this.props.toggleModal();
      this.props.toggleUpdateDetail();
    }
  };

  @action public workingLocation = async () => {
    const {
      data: { workingLocation },
    } = await client.mutate<WorkingLocationMutation, WorkingLocationVariables>({
      mutation: WORKING_LOCATION,
      variables: { _id: this.props.location._id },
      errorPolicy: 'all',
    });

    const { error, location } = workingLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Quay lại hoạt động');
      this.onChange(location);
      this.props.toggleModal();
      this.props.toggleUpdateDetail();
    }
  };

  @action public cancelledLocation = async () => {
    const {
      data: { cancelledLocation },
    } = await client.mutate<
      CancelledLocationMutation,
      CancelledLocationVariables
    >({
      mutation: CANCELLED_LOCATION,
      variables: { _id: this.props.location._id },
      errorPolicy: 'all',
    });

    const { error, location } = cancelledLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Huỷ điểm');
      this.onChange(location);
      this.props.toggleModal();
      this.props.toggleViewDrawer();
    }
  };

  @action public passToMaintainer = async () => {
    const {
      data: { passToMaintainerLocation },
    } = await client.mutate<
      PassToMaintainerLocationMutation,
      PassToMaintainerLocationVariables
    >({
      mutation: PASS_TO_MAINTAINER_LOCATION_MUTATION,
      variables: { _id: this.props.location._id },
      errorPolicy: 'all',
    });

    const { error, location } = passToMaintainerLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Chuyển cho vận hành');
      this.onChange(location);
      this.props.toggleModal();
      this.props.toggleUpdateDetail();
    }
  };

  @action private async onChange(location?: FullLocationFragment) {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(location);
      HomeScreenStore.update();
      await countLocation(HomeScreenStore.whereFilter).then(value => {
        runInAction(() => {
          HomeScreenStore.setCount(value);
        });
      });
    }
  }
}

export { UpdateContentLocationStore };

import { ICellRendererParams } from 'ag-grid-community';
import { Component } from 'react';

export class GridCellTypeProposedEquipmentRenderer extends Component<
  ICellRendererParams
> {
  public render() {
    const { value } = this.props;
    let title = '';
    switch (value) {
      case 'ADD_EQUIPMENT':
        title = 'Đề xuất thiết bị';
        break;
      case 'REPLACE_EQUIPMENT':
        title = 'Thay thế thiết bị';
        break;
      case 'REMOVE_EQUIPMENT':
        title = 'Thu hồi thiết bị';
        break;
    }
    return title;
  }
}

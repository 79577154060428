import { GridReadyEvent } from "ag-grid-community";
import { action, computed, observable, runInAction } from "mobx";
import { ErrorNoti, SuccessNoti } from "~components/UI";
import client from "~graphql/client";
import {
  CREATE_WAREHOUSE_MUTATION,
  REMOVE_WAREHOUSE_MUTATION,
  UPDATE_CONTENT_WAREHOUSE_MUTATION
} from "~graphql/mutations";
import {
  CreateWarehouseMutation,
  CreateWarehouseVariables,
  FullWarehouseFragment,
  LocationBaseFilter,
  RemoveWarehouseMutation,
  RemoveWarehouseVariables,
  StringOperatorArgs,
  UpdateContentWarehouseMutation,
  UpdateContentWarehouseVariables,
  WarehouseContentInput
} from "~graphql/types";

import { WAREHOUSE_TYPE, LOCATION_TYPE, CANCELLED, DELETED } from "@mgn/common";

import { FilterValue } from "./Filter";

interface IFilter {
  typeCode?: StringOperatorArgs;
}

class WarehouseStore {
  @observable public type: string;
  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public filter: FilterValue;
  @observable public selectedItem: FullWarehouseFragment;

  constructor() {
    runInAction(() => {
      this.type = WAREHOUSE_TYPE;
      this.loading = false;
      this.visible = false;
      this.filter = null;
      this.selectedItem = null;
    });
  }

  @computed public get whereFilter() {
    let filterArgs: IFilter = {};

    if (this.type !== LOCATION_TYPE) {
      filterArgs = { typeCode: { in: [this.type] } };
    }

    const filter: LocationBaseFilter = {
      locationType:
        this.type !== LOCATION_TYPE ? WAREHOUSE_TYPE : LOCATION_TYPE,
      _operators: {
        ...filterArgs,
        status: { nin: [CANCELLED, DELETED] }
      }
    };

    if (this.filter) {
      const { locationId } = this.filter;
      if (locationId) {
        filter.OR = [{ _id: locationId }];
      }
    }

    return filter;
  }

  @action public setType = activeKey => {
    this.type = activeKey;
    this.filter = null;
  };

  @action public setFilter = filter => {
    this.filter = filter;
  };

  @action public toggleModal = (item: FullWarehouseFragment = null) => {
    this.selectedItem = item;
    this.visible = !this.visible;
  };

  @action public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action public handleDelete = async (_id: string) => {
    const {
      data: { removeWarehouse }
    } = await client.mutate<RemoveWarehouseMutation, RemoveWarehouseVariables>({
      mutation: REMOVE_WAREHOUSE_MUTATION,
      variables: { _id }
    });

    const { error } = removeWarehouse;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Xóa kho");
      this.reload();
    }
  };

  @action public handleCreate = async (record: CreateWarehouseVariables) => {
    const {
      data: { createWarehouse }
    } = await client.mutate<CreateWarehouseMutation, CreateWarehouseVariables>({
      mutation: CREATE_WAREHOUSE_MUTATION,
      variables: record,
      errorPolicy: "all"
    });

    const { error } = createWarehouse;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Tạo kho");
      this.toggleModal();
      this.reload();
    }
  };

  @action public handleUpdate = async (
    _id: string,
    record: WarehouseContentInput
  ) => {
    const {
      data: { updateContentWarehouse }
    } = await client.mutate<
      UpdateContentWarehouseMutation,
      UpdateContentWarehouseVariables
    >({
      mutation: UPDATE_CONTENT_WAREHOUSE_MUTATION,
      variables: {
        _id,
        record
      },
      errorPolicy: "all"
    });

    const { error } = updateContentWarehouse;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Cập nhật kho");
      this.toggleModal();
      this.reload();
    }
  };

  @action private reload = () => undefined;
}

export default new WarehouseStore();

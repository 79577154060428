import { Button, Popconfirm } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import Authorized, { isAuthorized } from '~components/Authorized/Authorized';
import CurrentUser from '~components/Authorized/CurrentUser';
import { FullLocationFragment } from '~graphql/types';

import {
  AGREED,
  CREATED,
  INSTALLED,
  INSTALLING,
  PERMISSION_EMPLOYEE_FREEWIFI_ADMIN,
  STOPPED,
  SURVEYING,
  WAITING_AGREEMENT,
  WAITING_INSTALLER,
  WAITING_MAINTAINER,
  WAITING_SURVEYOR,
  WORKING,
  CANCELLED,
  PERMISSION_PROPOSED_EQUIPMENT_UPDATE_ALL,
} from '@mgn/common';

import store from './store';

interface IProps {
  data: FullLocationFragment;
}

@observer
export class GridCellActionRenderer extends Component<IProps> {
  public created = data => (
    <Authorized permission={PERMISSION_EMPLOYEE_FREEWIFI_ADMIN}>
      <Button
        type='primary'
        onClick={() => store.toggleUpdateContent(data)}
        ghost={true}
        icon='edit'
        size='small'
      />
    </Authorized>
  );

  public surveyor = data => (
    <CurrentUser
      employee={get(data, 'surveyor', '')}
      extraPermission={PERMISSION_PROPOSED_EQUIPMENT_UPDATE_ALL}
      unAuthorizedComponent={
        <Button
          size='small'
          type='primary'
          onClick={() => store.toggleView(data)}
          icon='info'
          ghost={get(data, 'status') === WAITING_SURVEYOR ? true : false}
        />
      }
    >
      {data && get(data, 'status', '') === SURVEYING ? (
        <Button
          type='primary'
          onClick={() => store.toggleUpdateDetail(data)}
          ghost={true}
          icon='edit'
          size='small'
        />
      ) : (
        <Popconfirm
          title='Xác nhận khảo sát?'
          okText='OK'
          cancelText='Không'
          onConfirm={() => store.handleConfirmSurveyor(data)}
        >
          <Button size='small' type='primary' icon='check' />
        </Popconfirm>
      )}
    </CurrentUser>
  );

  public view = data => (
    <Button
      size='small'
      type={get(data, 'status') === CANCELLED ? 'danger' : 'primary'}
      onClick={() => store.toggleView(data)}
      icon={get(data, 'status') === CANCELLED ? 'close' : 'info'}
      ghost={true}
    />
  );

  public result = data => (
    <Button
      size='small'
      type={get(data, 'status') === CANCELLED ? 'danger' : 'primary'}
      onClick={() => store.toggleView(data)}
      icon={get(data, 'status') === CANCELLED ? 'close' : 'info'}
      ghost={true}
    />
  );

  public installer = data => (
    <CurrentUser
      employee={get(data, 'installer', '')}
      extraPermission={PERMISSION_PROPOSED_EQUIPMENT_UPDATE_ALL}
      unAuthorizedComponent={
        <Button
          size='small'
          type='primary'
          onClick={() => store.toggleView(data)}
          icon='info'
          ghost={get(data, 'status') === WAITING_INSTALLER ? true : false}
        />
      }
    >
      {data && get(data, 'status', '') === INSTALLING ? (
        <Button
          type='primary'
          onClick={() => store.toggleUpdateDetail(data)}
          ghost={true}
          icon='edit'
          size='small'
        />
      ) : (
        <Popconfirm
          title='Xác nhận thi công?'
          okText='OK'
          cancelText='Không'
          onConfirm={() => store.handleConfirmFromInstaller(data)}
        >
          <Button size='small' type='primary' icon='check' />
        </Popconfirm>
      )}
    </CurrentUser>
  );

  public record = data => (
    <Button
      size='small'
      type='primary'
      onClick={() => store.toggleView(data)}
      icon={isAuthorized(PERMISSION_EMPLOYEE_FREEWIFI_ADMIN) ? 'edit' : 'info'}
      ghost={true}
    />
  );

  public maintainer = data => (
    <Fragment>
      <CurrentUser
        employee={get(data, 'maintainer', '')}
        extraPermission={PERMISSION_PROPOSED_EQUIPMENT_UPDATE_ALL}
        unAuthorizedComponent={
          <Button
            size='small'
            type='primary'
            onClick={() => store.toggleView(data)}
            icon='info'
            ghost={get(data, 'status') === WAITING_MAINTAINER ? true : false}
          />
        }
      >
        {(data && get(data, 'status', '') === WORKING) ||
        get(data, 'status', '') === STOPPED ? (
          <Button
            size='small'
            type='primary'
            ghost={true}
            onClick={() => store.toggleUpdateDetail(data)}
            icon='edit'
          />
        ) : (
          <Popconfirm
            title='Xác nhận vận hành?'
            okText='OK'
            cancelText='Không'
            onConfirm={() => store.handleConfirmFromMaintainer(data)}
          >
            <Button size='small' type='primary' icon='check' />
          </Popconfirm>
        )}
      </CurrentUser>
    </Fragment>
  );

  public stopped = data => (
    <Button
      size='small'
      type='danger'
      onClick={() => store.toggleView(data)}
      icon='close'
      ghost={true}
    />
  );

  public render() {
    const { status } = store;
    const { data } = this.props;
    return (
      <Fragment>
        {status.includes(CREATED) && this.created(data)}
        {status.includes(WAITING_SURVEYOR) && this.surveyor(data)}
        {status.includes(WAITING_AGREEMENT) && this.view(data)}
        {status.includes(AGREED) && this.result(data)}
        {status.includes(WAITING_INSTALLER) && this.installer(data)}
        {status.includes(INSTALLED) && this.record(data)}
        {status.includes(WAITING_MAINTAINER) && this.maintainer(data)}
        {status.includes(STOPPED) && this.stopped(data)}
      </Fragment>
    );
  }
}

import gql from 'graphql-tag';

const full = gql`
  fragment FullErrorLog on ErrorLog {
    _id
    employeeId
    error
    createdAt
    updatedAt
  }
`;

export default { full };

import { Input, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import React, { Component } from 'react';
import client from '~graphql/client';
import {
  FIND_MANY_EQUIPMENT_CATEGORY_QUERY,
  FIND_MANY_EQUIPMENT_MODEL_QUERY,
} from '~graphql/queries';
import {
  FindManyEquipmentCategoryQuery,
  FindManyEquipmentModelQuery,
  FullEquipmentCategoryFragment,
} from '~graphql/types';

const { Option } = Select;
const { Group: InputGroup } = Input;

interface IProps extends SelectProps, FormComponentProps {
  alreadyProposedEquipmentModelId: string[];
  formField: string;
}

interface IState {
  equipmentsNotChosenYet: any;
  equipmentCategories: FullEquipmentCategoryFragment[];
  data: any;
  selectedEquipment: string;
  selectedCategory: string;
}

class EquipmentDropdown extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      equipmentsNotChosenYet: [],
      equipmentCategories: [],
      data: [],
      selectedEquipment: null,
      selectedCategory: null,
    };
  }

  public updateData = async alreadyProposedEquipmentModelId => {
    let {
      data: { findManyEquipmentModel },
    } = await client.query<FindManyEquipmentModelQuery>({
      query: FIND_MANY_EQUIPMENT_MODEL_QUERY,
    });

    let {
      data: { findManyEquipmentCategory },
    } = await client.query<FindManyEquipmentCategoryQuery>({
      query: FIND_MANY_EQUIPMENT_CATEGORY_QUERY,
    });

    findManyEquipmentModel = findManyEquipmentModel.filter(
      e => alreadyProposedEquipmentModelId.indexOf(e._id) === -1
    );
    const data = {};
    findManyEquipmentCategory.forEach(
      item =>
        (data[item.name] = findManyEquipmentModel
          .filter(e => item._id === e.equipmentCategoryId)
          .map(e => {
            return [e.name, e._id];
          }))
    );
    Object.keys(data).forEach(key => {
      // filter non-proposed equipment list that is empty, after all equipment in list is proposed
      if (data[key].length === 0) {
        delete data[key];
        findManyEquipmentCategory = findManyEquipmentCategory.filter(e => {
          return e.name !== key;
        });
      }
    });
    this.setState({
      data,
      equipmentCategories: findManyEquipmentCategory,
    });
  };

  public async componentWillMount() {
    this.updateData(this.props.alreadyProposedEquipmentModelId);
  }

  public async componentWillUpdate(nextProps) {
    if (
      this.props.alreadyProposedEquipmentModelId !==
      nextProps.alreadyProposedEquipmentModelId
    ) {
      await this.updateData(nextProps.alreadyProposedEquipmentModelId);
      await this.resetChoicesAfterUpdateProposed();
    }
  }

  public resetChoicesAfterUpdateProposed = async () => {
    const { data, selectedCategory, equipmentCategories } = this.state;
    const temp = data[selectedCategory];
    await this.setState({
      selectedEquipment: null,
      equipmentsNotChosenYet: temp
        ? temp
        : equipmentCategories[0]
        ? data[equipmentCategories[0].name]
        : null,
      selectedCategory: temp
        ? selectedCategory
        : equipmentCategories[0]
        ? equipmentCategories[0].name
        : null,
    });
  };

  public handleCategoryChange = async value => {
    await this.setState({
      equipmentsNotChosenYet: this.state.data[value],
      selectedCategory: value,
      selectedEquipment: this.state.data[value][0][1],
    });
  };

  public render() {
    const { form, formField } = this.props;
    const {
      equipmentCategories,
      equipmentsNotChosenYet,
      selectedEquipment,
      selectedCategory,
    } = this.state;
    const { getFieldDecorator } = form;
    return (
      <InputGroup compact={true}>
        {getFieldDecorator('equipmentCategory', {
          initialValue: selectedCategory || undefined,
        })(
          <Select
            placeholder='Chọn loại thiết bị'
            style={{ width: '50%' }}
            onChange={this.handleCategoryChange}
          >
            {!equipmentCategories.length
              ? null
              : equipmentCategories.map(category => (
                  <Option key={category.name}>{category.name}</Option>
                ))}
          </Select>
        )}

        {getFieldDecorator(formField, {
          initialValue: selectedEquipment || undefined,
        })(
          <Select placeholder='Chọn mẫu thiết bị' style={{ width: '50%' }}>
            {!equipmentsNotChosenYet
              ? null
              : equipmentsNotChosenYet.map(e => (
                  <Option key={e[1]}>{e[0]}</Option>
                ))}
          </Select>
        )}
      </InputGroup>
    );
  }
}
export default EquipmentDropdown;

import { action, computed, observable, runInAction } from 'mobx';

import client from '~graphql/client';
import { FIND_ONE_PROPOSED_EQUIPMENT_QUERY } from '~graphql/queries';
import {
    FindOneProposedEquipmentQuery, FindOneProposedEquipmentVariables, FullProposedEquipmentFragment
} from '~graphql/types';

class EquipmentDetailStore {
  @observable public loading: boolean = true;
  @observable public selectedItem: FullProposedEquipmentFragment = null;
  @observable public quickFilter: string = null;

  @action public setId(_id: string) {
    this.loading = true;
    this.selectedItem = null;

    this.fetchData(_id).then(
      action(() => {
        this.loading = false;
      })
    );
  }

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action private async fetchData(_id: string) {
    this.loading = true;
    const { data } = await client.query<
      FindOneProposedEquipmentQuery,
      FindOneProposedEquipmentVariables
    >({
      query: FIND_ONE_PROPOSED_EQUIPMENT_QUERY,
      variables: { where: { _id } },
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.selectedItem = data.findOneProposedEquipment;
    });
  }
}

export default new EquipmentDetailStore();

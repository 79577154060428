import { ICellRendererParams } from 'ag-grid-community';
import React, { Component } from 'react';

import { formatTime } from '~utils';

export class GridCellDateRenderer extends Component<ICellRendererParams> {
  public render() {
    const { value } = this.props;

    return value ? formatTime(value) : '';
  }
}

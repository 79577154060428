import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { IProvince, provinces } from '~documents/province';

const { Option } = Select;

interface IProps extends SelectProps {
  permission?: string;
  selectedArea?: string;
}

export type DistrictDropdownProps = IProps;

@observer
export default class DistrictDropdown extends Component<IProps> {
  // @observable private loading: boolean;
  @observable private data: IProvince[];

  constructor(props) {
    super(props);
    runInAction(() => {
      // this.loading = false;
      this.data = provinces;
    });
  }

  @computed public get district(): string[] {
    const { selectedArea } = this.props;
    const district =
      selectedArea && this.data.find(i => i.value === selectedArea);
    return district && district.meta.district;
  }

  public render() {
    const { permission, selectedArea, ...props } = this.props;

    return (
      <Select
        {...props}
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        // loading={this.loading}
      >
        {selectedArea
          ? this.district &&
            this.district.map((e, index) => {
              return (
                <Option key={String(index)} value={e}>
                  {e}
                </Option>
              );
            })
          : null}
      </Select>
    );
  }

  @action private getDistrict = async () => {
    runInAction(() => {
      this.data = provinces;
    });
  };
}

import gql from 'graphql-tag';
import locationType from '../fragments/locationType';

export const CREATE_LOCATION_TYPE_MUTATION = gql`
  mutation CreateLocationType($record: LocationTypeInput!) {
    createLocationType(record: $record) {
      payload {
        ...FullLocationType
      }
      error {
        message
      }
    }
  }
  ${locationType.full}
`;

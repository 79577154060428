import gql from 'graphql-tag';

import equipment from '../fragments/equipment';

export const UPDATE_SERIAL_EQUIPMENT = gql`
  mutation UpdateSerialEquipment($serial: String!, $newSerial: String!) {
    updateSerialEquipment(serial: $serial, newSerial: $newSerial) {
      equipment {
        ...FullEquipment
      }
      error {
        message
      }
    }
  }
  ${equipment.full}
`;

import gql from 'graphql-tag';
import employee from '../fragments/employee';

export const UPDATE_PASSWORD_EMPLOYEE_MUTATION = gql`
  mutation UpdatePasswordEmployee(
    $oldPassword: String!
    $newPassword: String!
  ) {
    updatePasswordEmployee(
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      employee {
        ...FullEmployee
      }
      error {
        message
      }
    }
  }
  ${employee.full}
`;

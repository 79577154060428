import gql from 'graphql-tag';
import equipmentModel from '../fragments/equipmentModel';

export const FIND_MANY_EQUIPMENT_MODEL_QUERY = gql`
  query FindManyEquipmentModel(
    $skip: Int
    $take: Int
    $where: EquipmentModelBaseFilter
    $order: EquipmentModelBaseSort
  ) {
    findManyEquipmentModel(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullEquipmentModel
    }
  }
  ${equipmentModel.full}
`;

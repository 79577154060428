import { AgGridTable } from '~components/UI';
import { FIND_MANY_PROPOSED_EQUIPMENT_QUERY } from '~graphql/queries';
import { FindManyProposedEquipmentQuery } from '~graphql/types';
import { FULFILLED } from '@mgn/common';
import { formatTime } from '~utils';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import { Button, Modal, Row } from 'antd';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { defaultOptions } from '~components/UI/AgGridTable/AgGridTable';

interface IProps {
  locationId: string;
  visible: boolean;
  toggleModal: () => void;
}

class ProposedEquipmentHistory extends Component<IProps> {
  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Ngày duyệt',
      field: 'updatedAt',
      cellRenderer: 'agGroupCellRenderer',
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      headerName: 'Người tạo',
      field: 'createdEmployee.fullName',
    },
  ];

  public detailCellRendererParams: any = {
    detailGridOptions: {
      ...defaultOptions,
      onGridReady: (event: GridReadyEvent) => {
        const { api } = event;

        api.sizeColumnsToFit();
      },
      columnDefs: [
        {
          headerName: 'STT',
          field: '_id',
          minWidth: 80,
          maxWidth: 80,
          spinned: 'left',
          suppressSorting: true,
          cellRendererFramework: ({ rowIndex }) => rowIndex + 1,
        },
        {
          headerName: 'Loại thiết bị',
          field: 'equipmentModel.equipmentCategory.name',
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: 'Tên thiết bị',
          field: 'equipmentModel.name',
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: 'SL',
          field: 'quantity',
          minWidth: 80,
          maxWidth: 80,
        },
        {
          headerName: 'SL đã xuất',
          field: 'issuedQuantity',
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: 'SL đã thu',
          field: 'issuedRecovery',
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: 'Ghi chú',
          field: 'note',
          suppressSorting: true,
        },
      ],
    },
    getDetailRowData: params => {
      params.successCallback(params.data.equipmentDetail);
    },
  };

  public constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  public render() {
    const { locationId, toggleModal, visible } = this.props;

    const footer = (
      <Row>
        <Button type='danger' onClick={() => toggleModal()}>
          Đóng
        </Button>
      </Row>
    );

    return (
      <Modal
        className='biggerModal'
        visible={visible}
        onCancel={toggleModal}
        closable={false}
        footer={footer}
      >
        <h2>Lịch sử thiết bị</h2>
        <Query<FindManyProposedEquipmentQuery>
          query={FIND_MANY_PROPOSED_EQUIPMENT_QUERY}
          variables={{
            where: { locationId, _operators: { status: { in: [FULFILLED] } } },
          }}
          fetchPolicy='network-only'
        >
          {({ error, data: { findManyProposedEquipment } }) => {
            if (error) return `Error, ${error.message}`;
            return (
              <AgGridTable
                rowData={findManyProposedEquipment}
                columnDefs={this.columnDefs}
                detailCellRendererParams={this.detailCellRendererParams}
                masterDetail={true}
                height={350}
              />
            );
          }}
        </Query>
      </Modal>
    );
  }
}

export default ProposedEquipmentHistory;

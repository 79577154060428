export const removeAccents = str => {
  if (!str) return null;
  const AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < AccentsMap.length; i += 1) {
    // tslint:disable-next-line:prefer-template
    const re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
    const char = AccentsMap[i][0];
    // tslint:disable-next-line:no-parameter-reassignment
    str = str.replace(re, char);
  }
  return str;
};

export const acronymArea = area => {
  if (!area) {
    return null;
  }
  let areaStr = null;
  if (area === 'Thành phố Hồ Chí Minh') {
    return 'HCM | ';
  }
  if (area === 'Thành phố Hà Nội') {
    return 'HNI | ';
  }
  if (area === 'Thành phố Hải Phòng') {
    return 'HPG | ';
  }
  if (area === 'Thành phố Đà Nẵng') {
    return 'DNG | ';
  }
  if (area === 'Thành phố Cần Thơ') {
    return 'CTO | ';
  }
  if (area === 'Bà Rịa - Vũng Tàu') {
    return 'VTU | ';
  }
  if (area === 'Thừa Thiên Huế') {
    return 'HUE | ';
  }
  const strAreaArr = area.split(' ');
  if (strAreaArr.length === 2) {
    strAreaArr[0] = strAreaArr[0].charAt(0);
    strAreaArr[1] = `${strAreaArr[1].charAt(0)}${strAreaArr[1].slice(-1)}`;
  }
  areaStr = removeAccents(strAreaArr.join(''));
  return `${areaStr.toUpperCase()} | `;
};

export const acronymDistrict = (area, district) => {
  if (!area || !district) return null;
  const distArr = district.split(' ');
  let distStr = null;
  if (area === 'Thành phố Hà Nội' || area === 'Thành phố Hồ Chí Minh') {
    if (isNaN(+distArr[1])) {
      if (distArr.length === 3) {
        distStr =
          distArr[1].charAt(0) + distArr[2].charAt(0) + distArr[2].slice(-1);
      }
      if (distArr.length === 4) {
        distStr =
          distArr[1].charAt(0) + distArr[2].charAt(0) + distArr[3].charAt(0);
      }
      const result = distStr && distStr.toUpperCase();
      return `${removeAccents(result)} - `;
    }
    return `Q${distArr[1]} - `;
  }
};

// export const acronymDistrict = (area, district) => {
//   if (!area || !district) {
//     return null;
//   }
//   const stringArr = district.split(' ');
//   let strDistrict = null;
//   if (area === 'Ninh Thuận' && district === 'Thành phố Phan Rang-Tháp Chàm') {
//     return 'TP.PRTC - ';
//   }
//   if (
//     (area === 'Gia Lai' && stringArr[0] === 'Huyện') ||
//     (area === 'Đắk Lắk' && stringArr[0] === 'Huyện')
//   ) {
//     // tslint:disable-next-line:prefer-template
//     stringArr[0] = 'H.';
//     strDistrict = removeAccents(stringArr.join(''));
//   }

//   if (area === 'Thành phố Hà Nội') {
//     strDistrict = `Q.${removeAccents(stringArr.join(''))}`;
//   }

//   if (stringArr[0] === 'Huyện' || stringArr[0] === 'Quận') {
// stringArr[0] = `${stringArr[0].slice(0, 1)}.`;
// if (stringArr.length === 3) {
//   stringArr[1] = stringArr[1].slice(0, 1);
// }
// if (stringArr.length === 4) {
//   stringArr[1] = stringArr[1].slice(0, 1);
//   stringArr[2] = stringArr[2].slice(0, 1);
// }
// strDistrict = removeAccents(stringArr.join(''));
//   }

//   if (stringArr[0] === 'Thị' || stringArr[0] === 'Thành') {
//     stringArr[1] = stringArr[1] === 'xã' ? 'TX.' : 'TP.';
//     stringArr.shift();
//     if (stringArr.length === 3) {
//       stringArr[1] = stringArr[1].slice(0, 1);
//     }
//     if (stringArr.length === 4) {
//       stringArr[1] = stringArr[1].slice(0, 1);
//       stringArr[2] = stringArr[2].slice(0, 1);
//     }
//     strDistrict = removeAccents(stringArr.join(''));
//   }

//   return `${strDistrict.toUpperCase()} - `;
// };

import gql from 'graphql-tag';
import equipmentTemp from '../fragments/equipmentTemp';

export const REMOVE_EQUIPMENT_TEMP_GOODS_RECEIPT_MUTATION = gql`
  mutation RemoveEquipmentTempGoodsReceipt($_id: ID!) {
    removeEquipmentTempGoodsReceipt(_id: $_id) {
      error {
        message
      }
      equipmentTemps {
        ...BasicEquipmentTemp
      }
    }
  }
  ${equipmentTemp.basic}
`;

export const removeAccents = str => {
  const AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < AccentsMap.length; i += 1) {
    // tslint:disable-next-line:prefer-template
    const re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
    const char = AccentsMap[i][0];
    // tslint:disable-next-line:no-parameter-reassignment
    str = str.replace(re, char);
  }
  return str;
};

export const acronymArea = area => {
  if (!area) {
    return null;
  }
  let areaStr = null;
  if (area === 'Thành phố Hồ Chí Minh') {
    return 'HCM | ';
  }
  if (area === 'Thành phố Hà Nội') {
    return 'HNI | ';
  }
  if (area === 'Thành phố Hải Phòng') {
    return 'HPG | ';
  }
  if (area === 'Thành phố Đà Nẵng') {
    return 'DNG | ';
  }
  if (area === 'Thành phố Cần Thơ') {
    return 'CTO | ';
  }
  if (area === 'Bà Rịa - Vũng Tàu') {
    return 'VTU | ';
  }
  if (area === 'Thừa Thiên Huế') {
    return 'HUE | ';
  }
  const strAreaArr = area.split(' ');
  if (strAreaArr.length === 2) {
    strAreaArr[0] = strAreaArr[0].charAt(0);
    strAreaArr[1] = `${strAreaArr[1].charAt(0)}${strAreaArr[1].slice(-1)}`;
  }
  areaStr = removeAccents(strAreaArr.join(''));
  return `${areaStr.toUpperCase()} | `;
};

export const acronymDistrict = (area, district) => {
  if (!area || !district) return '';
  const distArr = district.split(' ');
  let distStr = '';
  if (area === 'Thành phố Hà Nội' || area === 'Thành phố Hồ Chí Minh') {
    if (isNaN(+distArr[1])) {
      if (distArr.length === 3) {
        distStr =
          distArr[1].charAt(0) + distArr[2].charAt(0) + distArr[2].slice(-1);
      }
      if (distArr.length === 4) {
        distStr =
          distArr[1].charAt(0) + distArr[2].charAt(0) + distArr[3].charAt(0);
      }
      const result = distStr && distStr.toUpperCase();
      return `${removeAccents(result)} - `;
    }
    return `Q${distArr[1]} - `;
  }
  return '';
};

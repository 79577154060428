import { Button, Modal } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import MenuDropdown from '~components/Dropdown/MenuDropdown';
import { UIField } from '~components/UI/UIField';
import { UpdateMenuMappingVariables } from '~graphql/types';
import { trimObject } from '~utils';

import store from './MenuStore';

interface IFormValues extends UpdateMenuMappingVariables {
  fullName: string;
}
const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),
  fullName: Yup.string(),
  menuId: Yup.string().required('Bắt buộc chọn'),
});

@observer
class MenuMappingForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleMenuMappingUpdate } = store;
    const { setSubmitting, resetForm } = actions;
    trimObject(values);

    const { _id, menuId } = values;

    await handleMenuMappingUpdate(_id, menuId);
    setSubmitting(false);
    resetForm();
  };

  public renderForm = (props: FormikProps<IFormValues>) => {
    const { isValid, isSubmitting } = props;
    const { visible, toggleModal } = store;

    const footer = (
      <div className='modal-footer'>
        <Button
          type='primary'
          htmlType='submit'
          form='menuMapFm'
          disabled={!isValid}
          loading={isSubmitting}
        >
          Cập nhật
        </Button>
        <Button type='danger' onClick={() => toggleModal()}>
          Đóng
        </Button>
      </div>
    );

    return (
      <Modal closable={false} visible={visible} footer={footer}>
        <Form id='menuMapFm'>
          <Field
            label='Tên nhân viên'
            name='fullName'
            component={UIField}
            fieldProps={{ disabled: true }}
          />
          <Field
            label='Danh sách MENU'
            name='menuId'
            component={UIField}
            fieldComponent={MenuDropdown}
            nativeInput={false}
          />
        </Form>
      </Modal>
    );
  };

  public render() {
    const { selectedMenuMapping } = store;

    const formInitialValues = {
      _id: get(selectedMenuMapping, '_id'),
      fullName: get(selectedMenuMapping, 'employee.fullName'),
      menuId: get(selectedMenuMapping, 'menuId'),
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default MenuMappingForm;

import gql from 'graphql-tag';
import equipmentHistory from '~graphql/fragments/equipmentHistory';

export const FIND_MANY_EQUIPMENT_HISTORY_QUERY = gql`
  query FindManyEquipmentHistory(
    $skip: Int
    $take: Int
    $where: EquipmentHistoryBaseFilter
    $order: EquipmentHistoryBaseSort
  ) {
    findManyEquipmentHistory(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullEquipmentHistory
    }
  }
  ${equipmentHistory.full}
`;

export const FIND_MANY_EQUIPMENT_HISTORY_EQUIPMENT_ONLY_QUERY = gql`
  query FindManyEquipmentHistoryEquipmentOnly(
    $skip: Int
    $take: Int
    $where: EquipmentHistoryBaseFilter
    $order: EquipmentHistoryBaseSort
  ) {
    findManyEquipmentHistory(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...EquipmentOnlyEquipmentHistory
    }
  }
  ${equipmentHistory.equipmentOnly}
`;

export const FIND_MANY_EQUIPMENT_HISTORY_HISTORY_ONLY_QUERY = gql`
  query FindManyEquipmentHistoryHistoryOnly(
    $skip: Int
    $take: Int
    $where: EquipmentHistoryBaseFilter
    $order: EquipmentHistoryBaseSort
  ) {
    findManyEquipmentHistory(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...HistoryOnlyEquipmentHistory
    }
  }
  ${equipmentHistory.historyOnly}
`;

import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Sidebar } from '../components/Layout';
import { Loading } from '../components/UI';
import authStore from '../stores/authStore';
import commonStore from '../stores/commonStore';
import Routes from './Routes';

const { Content } = Layout;

@observer
class Screens extends Component<RouteComponentProps> {
  public componentWillMount() {
    if (!commonStore.token) {
      commonStore.setAppLoaded();
    }
  }

  public componentDidMount() {
    if (commonStore.token) {
      authStore.updateCurrentEmployee().finally(() => {
        commonStore.setAppLoaded();
      });
    }
  }

  public render() {
    if (!commonStore.appLoaded) {
      return <Loading />;
    }

    return (
      <Layout style={{ background: 'transparent' }}>
        {authStore.isAuthenticated && <Sidebar />}
        <Content
          style={{
            padding: '0em 1em',
            height: '100vh',
          }}
        >
          <Routes />
        </Content>
      </Layout>
    );
  }
}

export default withRouter<RouteComponentProps>(Screens);

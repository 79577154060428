import { Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ChangeEvent } from 'react';

import EmployeeDeviceForm from './Form';
import EmployeeDeviceTable from './Grid';
import store from './store';
import Header from '~screens/Employee/Header';
import { PERMISSION_EMPLOYEE_DEVICE_CREATE } from '@mgn/common';
import { Loading } from '~components/UI';

@observer
class EmployeeDevice extends Component {
  public componentWillMount() {
    store.init();
  }

  public handleQuickFilter = (event: ChangeEvent<HTMLInputElement>) => {
    store.setQuickFilter(event.target.value);
  };

  public render() {
    const { toggleModal, loading, quickFilter } = store;

    if (loading) return <Loading />;

    return (
      <Fragment>
        <Row
          type='flex'
          style={{
            flexFlow: 'column',
            height: '100vh',
          }}
        >
          <Header
            text='thiết bị nhân viên'
            filter={quickFilter}
            handeFilter={this.handleQuickFilter}
            toggleModal={toggleModal}
            permission={PERMISSION_EMPLOYEE_DEVICE_CREATE}
          />
          <div style={{ flex: 1, marginTop: 8 }}>
            <EmployeeDeviceTable />
          </div>
        </Row>
        <EmployeeDeviceForm />
      </Fragment>
    );
  }
}
export default EmployeeDevice;

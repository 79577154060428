import gql from 'graphql-tag';
import roleMapping from '../fragments/roleMapping';

export const REMOVE_ROLE_MAPPING_MUTATION = gql`
  mutation RemoveRoleMapping($roleId: String!, $employeeId: String!) {
    removeRoleMapping(roleId: $roleId, employeeId: $employeeId) {
      roleMapping {
        ...BasicRoleMapping
      }
      error {
        message
      }
    }
  }
  ${roleMapping.basic}
`;

import { action, computed, observable, runInAction } from "mobx";

import { ErrorNoti, SuccessNoti } from "~components/UI";
import client from "~graphql/client";
import {
  CREATE_LOCATION_TYPE_MUTATION,
  UPDATE_LOCATION_TYPE_BY_ID_MUTATION
} from "~graphql/mutations";
import { REMOVE_LOCATION_TYPE_BY_ID_MUTATION } from "~graphql/mutations/removeLocationType";
import { FIND_MANY_LOCATION_TYPE_QUERY } from "~graphql/queries";
import {
  CreateLocationTypeMutation,
  CreateLocationTypeVariables,
  FindManyLocationTypeQuery,
  FullLocationTypeFragment,
  LocationTypeInput,
  RemoveLocationTypeByIdMutation,
  RemoveLocationTypeByIdVariables,
  UpdateLocationTypeByIdMutation,
  UpdateLocationTypeByIdVariables,
  StringOperatorArgs
} from "~graphql/types.d";
import { CREATED, PERMISSION_SUPERADMIN, WAREHOUSE_TYPE } from "@mgn/common";
import { removeTypename } from "~utils";
import { isAuthorized } from "~components/Authorized/Authorized";

interface IFilter {
  type: StringOperatorArgs;
}

class LocationTypesStore {
  @computed public get locationtype() {
    return this.data;
  }

  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public status: string;
  @observable public selectedItem: FullLocationTypeFragment;
  @observable public quickFilter: string;

  @observable.shallow private data: FullLocationTypeFragment[];

  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.visible = false;
      this.status = CREATED;
      this.selectedItem = null;
      this.quickFilter = null;
      this.data = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public handleDelete = async (_id: string) => {
    const {
      data: { removeLocationTypeById }
    } = await client.mutate<
      RemoveLocationTypeByIdMutation,
      RemoveLocationTypeByIdVariables
    >({
      mutation: REMOVE_LOCATION_TYPE_BY_ID_MUTATION,
      variables: { _id },
      errorPolicy: "all"
    });

    const { error } = removeLocationTypeById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Xóa loại địa điểm");
      this.updateData(_id);
    }
  };

  @action public handleCreate = async (record: LocationTypeInput) => {
    const {
      data: { createLocationType }
    } = await client.mutate<
      CreateLocationTypeMutation,
      CreateLocationTypeVariables
    >({
      mutation: CREATE_LOCATION_TYPE_MUTATION,
      variables: { record },
      errorPolicy: "all"
    });

    const { error, payload } = createLocationType;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Tạo loại thiết bị");
      this.updateData(null, payload);
      this.toggleModal();
    }
  };

  @action public handleUpdate = async (
    _id: string,
    record: LocationTypeInput
  ) => {
    const {
      data: { updateLocationTypeById }
    } = await client.mutate<
      UpdateLocationTypeByIdMutation,
      UpdateLocationTypeByIdVariables
    >({
      mutation: UPDATE_LOCATION_TYPE_BY_ID_MUTATION,
      variables: { _id, record },
      errorPolicy: "all"
    });

    const { error, payload } = updateLocationTypeById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Cập nhật loại địa điểm");
      this.updateData(_id, payload);
      this.toggleModal();
    }
  };

  @action public toggleModal = (item = null) => {
    this.selectedItem = item;
    this.visible = !this.visible;
  };

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action private fetchData = async () => {
    let filter: IFilter;

    if (!isAuthorized(PERMISSION_SUPERADMIN)) {
      filter = { type: { nin: [WAREHOUSE_TYPE] } };
    }

    const {
      data: { findManyLocationType }
    } = await client.query<FindManyLocationTypeQuery>({
      query: FIND_MANY_LOCATION_TYPE_QUERY,
      variables: {
        where: {
          _operators: {
            ...filter
          }
        }
      },
      fetchPolicy: "network-only"
    });

    runInAction(() => {
      this.data = removeTypename(findManyLocationType);
    });
  };

  @action private updateData = (
    _id: string,
    record?: FullLocationTypeFragment
  ) => {
    if (!_id) {
      this.data = this.data.concat(record);
    } else {
      const index = this.data.findIndex(item => item._id === _id);

      this.data = [
        ...this.data.slice(0, index),
        record,
        ...this.data.slice(index + 1)
      ].filter(Boolean);
    }
  };
}

export default new LocationTypesStore();

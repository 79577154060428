import gql from 'graphql-tag';
import roleMapping from '../fragments/roleMapping';

export const CREATE_ROLE_MAPPING_MUTATION = gql`
  mutation CreateRoleMapping(
    $employeeId: String!
    $roleId: String!
    $locationId: [String!]!
  ) {
    createRoleMapping(
      employeeId: $employeeId
      roleId: $roleId
      locationId: $locationId
    ) {
      roleMapping {
        ...FullRoleMapping
      }
      error {
        message
      }
    }
  }
  ${roleMapping.full}
`;

import gql from 'graphql-tag';
import location from '../fragments/location';

export const CONFIRM_ASBUILD_LOCATION_MUTATION = gql`
  mutation ConfirmAsBuiltLocation($_id: ID!) {
    confirmAsBuiltLocation(_id: $_id) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${location.full}
`;

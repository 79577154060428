import gql from 'graphql-tag';
import employee from '~graphql/fragments/employee';

import menuMapping from '../fragments/menuMapping';
import roleMapping from '../fragments/roleMapping';

export const FIND_MANY_PERMISSION_MAPPING_QUERY = gql`
  query FindManyPermissionMapping($where: EmployeeBaseFilter) {
    findManyEmployee(where: $where) {
      ...FullEmployee
    }
    findManyMenuMapping {
      ...FullMenuMapping
    }
    findManyRoleMapping {
      ...FullRoleMapping
    }
  }
  ${employee.full}
  ${menuMapping.full}
  ${roleMapping.full}
`;

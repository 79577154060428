import { Icon, Layout, Menu } from 'antd';
import { css } from 'emotion';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import slug from 'slug';
import SearchEquipment from '~components/SearchEquipment/SearchEquipment';
import authStore from '~stores/authStore';

import { PERMISSION_SUPERADMIN } from '@mgn/common';

const { Sider } = Layout;
const { SubMenu, Divider, Item } = Menu;

const siderStyle = css({
  height: '100vh',
  '& .search-equipment': {
    backgroundColor: 'transparent !important',
  },
  backgroundColor: '#fff',
});

@observer
class Sidebar extends Component<RouteComponentProps> {
  public onLogout = () => {
    authStore.logout();
  };

  public handleSearchEquipment = (serial: string) => {
    if (serial) {
      const { history } = this.props;
      history.push(`/equipment/${serial}`);
    }
  };

  public render() {
    const { currentEmployee, menu, permissions } = authStore;
    const arrPermissions = Object.keys(permissions);
    const checkSu = arrPermissions.indexOf(PERMISSION_SUPERADMIN) >= 0;

    return (
      <Sider
        width='13em'
        breakpoint='lg'
        collapsedWidth='0'
        className={siderStyle}
      >
        <Menu style={{ height: '100%' }} defaultSelectedKeys={['bang-chung']}>
          <SubMenu title={currentEmployee.fullName}>
            <Item key='nguoi-dung'>
              <Link to='/info'>
                <Icon type='user' />
                <span>Thông tin cá nhân</span>
              </Link>
            </Item>
            <Item key='thoat' onClick={this.onLogout}>
              <Icon type='logout' />
              <span>Thoát</span>
            </Item>
          </SubMenu>
          <Item key='chi-tiet-thiet-bi' className='search-equipment'>
            <SearchEquipment handleSelect={this.handleSearchEquipment} />
          </Item>
          <Divider />
          {menu.menu.map((item, index) =>
            item.route ? (
              <Item key={slug(item.name, { lower: true })}>
                <Link to={item.route}>
                  <Icon type={item.icon} />
                  <span>{item.name}</span>
                </Link>
              </Item>
            ) : (
              <SubMenu key={index} title={item.name}>
                {item.children &&
                  !isEmpty(item.children) &&
                  item.children.map(children => (
                    <Item key={slug(children.name, { lower: true })}>
                      <Link to={children.route}>
                        <Icon type={children.icon} />
                        <span>{children.name}</span>
                      </Link>
                    </Item>
                  ))}
              </SubMenu>
            )
          )}
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(Sidebar);

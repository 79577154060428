import { action, observable } from 'mobx';
import { FullLocationFragment } from '~graphql/types';

export class LocationStore {
  @observable
  public currentLocation?: FullLocationFragment;

  @action
  public setLocation = location => {
    this.currentLocation = { ...location.selectedItem };
  };
}

export const locationStore = new LocationStore();

const AUTH_TOKEN = "token";
const API_GRAPHQL_URL = "http://localhost:4000/graphql";
const API_SUBSCRIPTIONS_URL = "ws://localhost:4000/";
const CODE_GOODSISSUE = "PX";
const CODE_GOODSRECEIPT = "PN";
const ADDRESS_COMPANY = "D2-D6 Thất Sơn, Phường 15, Quận 10, TP.HCM";
const NAME_OPENNET = "CÔNG TY TNHH SX TM DV THỜI ĐẠI MỞ";
const NAME_MEGANET = "CÔNG TY CỔ PHẦN CÔNG NGHỆ NGUỒN MỞ THẾ HỆ MỚI";
const ROLE_TYPE =
  "Ban hành theo Thông tư số 200/2014/TT-BTC Ngày 22/12/2014 của Bộ Tài Chính";

export {
  AUTH_TOKEN,
  API_GRAPHQL_URL,
  API_SUBSCRIPTIONS_URL,
  CODE_GOODSISSUE,
  CODE_GOODSRECEIPT,
  ADDRESS_COMPANY,
  NAME_OPENNET,
  NAME_MEGANET,
  ROLE_TYPE
};

import gql from 'graphql-tag';
import permission from '../fragments/permission';

export const FIND_MANY_PERMISSION_QUERY = gql`
  query FindManyPermission(
    $skip: Int
    $take: Int
    $where: PermissionBaseFilter
    $order: PermissionBaseSort
  ) {
    findManyPermission(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullPermission
    }
  }
  ${permission.full}
`;

import gql from 'graphql-tag';
import proposedEquipment from '../fragments/proposedEquipment';

export const PASS_TO_MANAGER_PROPOSED_EQUIPMENT_MUTATION = gql`
  mutation PassToManagerProposedEquipment($_id: ID!) {
    passToManagerProposedEquipment(_id: $_id) {
      proposedEquipment {
        ...FullProposedEquipment
      }
      error {
        message
      }
    }
  }
  ${proposedEquipment.full}
`;

import gql from 'graphql-tag';
import equipmentCategory from './equipmentCategory';
import equipmentModel from './equipmentModel';
import location from './location';
import supplier from './supplier';

const basic = gql`
  fragment BasicEquipmentTemp on EquipmentTemp {
    _id
    serial
    status
    equipmentModelId
    equipmentModelCode
    equipmentCategoryId
    supplierCode
    locationId
    unitPrice
    warranty
    createdAt
    updatedAt
    issueInvoiceDay
    createdAt
    updatedAt
  }
`;

const resolved = gql`
  fragment ResolvedEquipmentTemp on EquipmentTemp {
    equipmentCategory {
      ...FullEquipmentCategory
    }
    equipmentModel {
      ...BasicEquipmentModel
    }
    location {
      ...BasicLocation
    }
    supplier {
      ...BasicSupplier
    }
  }
  ${equipmentCategory.full}
  ${equipmentModel.basic}
  ${location.basic}
  ${supplier.basic}
`;

const full = gql`
  fragment FullEquipmentTemp on EquipmentTemp {
    ...BasicEquipmentTemp
    ...ResolvedEquipmentTemp
  }
  ${basic}
  ${resolved}
`;

export default { basic, full };

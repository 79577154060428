import location from '../fragments/location';
import gql from 'graphql-tag';

export const UPDATE_DETAIL_LOCATION_MUTATION = gql`
  mutation UpdateDetailLocation(
    $_id: ID!
    $inspectionResult: InspectionResultInput
    $drawingPaths: [FileInput!]
    $picturePaths: [FileInput!]
    $recordPaths: [FileInput!]
  ) {
    updateDetailLocation(
      _id: $_id
      inspectionResult: $inspectionResult
      drawingPaths: $drawingPaths
      picturePaths: $picturePaths
      recordPaths: $recordPaths
    ) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${location.full}
`;

import gql from 'graphql-tag';
import goodsReceipt from '../fragments/goodsReceipt';
import goodsIssue from '../fragments/goodsIssue';

export const FIND_GOODS_RECEIPT_ISSUE_BY_PROPOSED_EQUIPMENT = gql`
  query FindGoodsReceiptIssueByProposedEquipment(
    $proposedEquipmentId: String!
  ) {
    findManyGoodsReceipt(where: { proposedEquipmentId: $proposedEquipmentId }) {
      ...FullGoodsReceipt
    }
    findManyGoodsIssue(where: { proposedEquipmentId: $proposedEquipmentId }) {
      ...FullGoodsIssue
    }
  }
  ${goodsReceipt.full}
  ${goodsIssue.full}
`;

import { Button } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { isAuthorized } from '~components/Authorized/Authorized';
import { PERMISSION_SUPERADMIN } from '@mgn/common';

import store from './store';

@observer
export default class MenuAction extends Component {
  public render() {
    const { handleShow } = store;

    return (
      <Button
        type='primary'
        icon='plus'
        onClick={handleShow}
        disabled={!isAuthorized(PERMISSION_SUPERADMIN)}
      >
        Tạo danh mục mới
      </Button>
    );
  }
}

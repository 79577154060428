import gql from "graphql-tag";

import employee from "./employee";

const basic = gql`
  fragment BasicWarehouse on Location {
    _id
    name
    address
    status
    area
    district
    locationType
    typeCode
    type {
      _id
      name
      display
      description
    }
    maintainerId
    createdEmployeeId
    createdAt
    updatedAt
  }
`;

const full = gql`
  fragment FullWarehouse on Location {
    ...BasicWarehouse
    maintainer {
      ...FullEmployee
    }
    createdEmployee {
      ...FullEmployee
    }
  }
  ${basic}
  ${employee.full}
`;

export default { basic, full };

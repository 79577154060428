import { Col, Row } from "antd";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import React, { Component } from "react";
import LocationDropdown from "~components/Dropdown/LocationDropdown";
import { UIField } from "~components/UI/UIField";

import store from "./store";
import { LOCATION_TYPE, WAREHOUSE_TYPE } from "@mgn/common";

interface IFormValues {
  locationId?: string;
}

export type FilterValue = IFormValues;

@observer
export default class WarehouseFilter extends Component {
  public onSubmit = (values: IFormValues) => {
    store.setFilter(values);
  };

  public render() {
    const { type, filter } = store;

    const renderForm = () => {
      return (
        <Form>
          <Row gutter={8}>
            <Col
              span={24}
              md={{ span: 4 }}
              style={{ minWidth: 380, maxWidth: 400 }}
            >
              <Field
                name="locationId"
                component={UIField}
                nativeInput={false}
                fieldComponent={LocationDropdown}
                fieldProps={{
                  typeCode: type === LOCATION_TYPE ? undefined : type,
                  locationType:
                    type === LOCATION_TYPE ? LOCATION_TYPE : WAREHOUSE_TYPE,
                  placeholder: "Tìm kiếm kho...",
                  allowClear: true
                }}
              />
            </Col>
          </Row>
        </Form>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{ ...filter }}
        onSubmit={this.onSubmit}
        validate={this.onSubmit}
        validateOnBlur={false}
        render={renderForm}
      />
    );
  }
}

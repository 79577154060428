import location from '../fragments/location';
import gql from 'graphql-tag';

export const UPDATE_MAINTAINER_LOCATION_MUTATION = gql`
  mutation UpdateMaintainerLocation($_id: ID!, $maintainerId: String!) {
    updateMaintainerLocation(_id: $_id, maintainerId: $maintainerId) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${location.full}
`;

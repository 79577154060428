import { ColDef, ColGroupDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { EmployeeInfo, Loading, LocationInfo } from '~components/UI';
import AgGridServer from '~components/UI/AgGridTable/AgGridServer';
import { GridCellDateRenderer } from '~components/UI/AgGridTable/CellDateRenderer';
import { GridCellStatusRenderer } from '~components/UI/AgGridTable/CellStatusRenderer';
import { FIND_MANY_BASIC_LOCATION_QUERY } from '~graphql/queries';
import { LocationBaseFilter } from '~graphql/types';
import { GridCellActionRenderer } from '~screens/Warehouse/GridCellActionRenderer';
import store from '~screens/Warehouse/store';

@observer
class WarehouseTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 90,
      maxWidth: 90,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      headerName: 'Tên',
      field: 'name',
      minWidth: 180,
      suppressSorting: true,
      cellRendererFramework: ({ data }) => <LocationInfo location={data} />,
    },
    {
      headerName: 'NV vận hành',
      field: 'maintainer',
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: ({ value }) => <EmployeeInfo employee={value} />,
    },
    {
      headerName: 'Ngày tạo',
      field: 'createdAt',
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Trạng thái',
      field: 'status',
      minWidth: 130,
      maxWidth: 130,
      cellRendererFramework: GridCellStatusRenderer,
    },
  ];

  public render() {
    const { loading, handleGridReady, whereFilter } = store;
    if (loading) return <Loading />;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height: number) => (
            <AgGridServer<'findManyLocation', LocationBaseFilter>
              height={height - 8}
              columnDefs={this.columnDefs}
              query={FIND_MANY_BASIC_LOCATION_QUERY}
              queryKey='findManyLocation'
              where={whereFilter}
              onGridReady={handleGridReady}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

export default WarehouseTable;

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { areas } from '~documents/province';

const { Option } = Select;

interface IProps extends SelectProps {
  permission?: string;
}

export type AreaDropdownProps = IProps;

@observer
export default class AreaDropdown extends Component<IProps> {
  // @observable private loading: boolean;
  @observable private data: string[];

  constructor(props) {
    super(props);
    runInAction(() => {
      // this.loading = true;
      this.data = areas;
    });
  }

  public render() {
    const { permission, ...props } = this.props;
    return (
      <Select
        {...props}
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        // loading={this.loading}
      >
        {this.data.map((e, index) => {
          return (
            <Option key={String(index)} value={e}>
              {e}
            </Option>
          );
        })}
      </Select>
    );
  }
}

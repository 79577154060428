import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { Loading } from '~components/UI';

import Action from './Action';
import Filter from './Filter';
import MenuGrid from './Grid';
import MenuForm from './MenuForm';
import store from './store';

@observer
class Created extends React.Component {
  public componentWillMount() {
    store.init();
  }

  public render() {
    const { loading } = store;
    if (loading) return <Loading />;

    return (
      <Fragment>
        <Row
          type='flex'
          style={{
            flexFlow: 'column',
            height: '100vh',
          }}
        >
          <h2 style={{ marginTop: 8 }}>Danh sách menu</h2>
          <div style={{ display: 'flex' }}>
            <Col style={{ flex: 1 }}>
              <Filter />
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Action />
            </Col>
          </div>
          <div style={{ flex: 1, margin: '8px 0' }}>
            <MenuGrid />
          </div>
        </Row>

        <MenuForm />
      </Fragment>
    );
  }
}

export default Created;

import client from '~graphql/client';
import { CountLocationQuery, LocationBaseFilter } from '~graphql/types';
import { COUNT_LOCATION_QUERY } from '~graphql/queries/countLocation';

export const countLocation = async (filter: LocationBaseFilter) => {
  const { data } = await client.query<CountLocationQuery>({
    query: COUNT_LOCATION_QUERY,
    variables: { where: { ...filter } },
    fetchPolicy: 'network-only',
  });
  const count = data.countLocation;
  return count;
};

import { css } from 'emotion';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import authStore from '~stores/authStore';

const tableStyle = css({
  display: 'table',
  '> div': {
    display: 'table-row',
  },
  '> div > div': {
    display: 'table-cell',
    padding: '8px',
  },
});

@observer
class UserInfo extends Component {
  public render() {
    const { currentEmployee } = authStore;

    return (
      <div className={tableStyle} style={{ fontSize: '14px', margin: 'auto' }}>
        <div>
          <div>Tên:</div>
          <div>
            <b>{get(currentEmployee, 'fullName', 'Chưa có')}</b>
          </div>
        </div>
        <div>
          <div>Email:</div>
          <div>
            <b>{get(currentEmployee, 'email', 'Chưa có')}</b>
          </div>
        </div>
        <div>
          <div>Số điện thoại:</div>
          <div>
            <b>{get(currentEmployee, 'phone', 'Chưa có')}</b>
          </div>
        </div>
      </div>
    );
  }
}

export default UserInfo;

import { Col, Row } from "antd";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { UIRangePicker } from "~components/UI/UIDateRangePicker";
import { UIField } from "~components/UI/UIField";
import LocationDropdown from "~components/Dropdown/LocationDropdown";

import { LOCATION_TYPE } from "@mgn/common";
import store from "./store";

interface IFormValues {
  locationId?: string;
  createAt?: string[];
}

interface IProps<T> {
  onChange: (values: T) => any;
  reset?: boolean;
}
@observer
export default class ProposedEquipmentFilter extends Component<
  IProps<IFormValues>
> {
  public reload = () => undefined;
  public componentWillReceiveProps(nextProps) {
    if (this.props.reset !== nextProps.reset) {
      this.reload();
    }
  }
  public onSubmit = (values: IFormValues) => {
    const { onChange } = this.props;

    if (typeof onChange === "function") {
      onChange(values);
    }
  };

  public render() {
    const { filter } = store;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{ ...filter }}
        onSubmit={this.onSubmit}
        validate={this.onSubmit}
        validateOnBlur={false}
        render={this.renderForm}
      />
    );
  }

  private renderForm = () => {
    return (
      <Form>
        <Row gutter={8}>
          <Col
            span={24}
            md={{ span: 4 }}
            style={{ minWidth: 380, maxWidth: 800 }}
          >
            <Field
              name="locationId"
              component={UIField}
              nativeInput={false}
              fieldComponent={LocationDropdown}
              fieldProps={{
                placeholder: "Chọn địa điểm",
                locationType: LOCATION_TYPE,
                allowClear: true
              }}
            />
          </Col>
          <Col
            span={24}
            md={{ span: 6 }}
            style={{ minWidth: 250, maxWidth: 400 }}
          >
            <Field
              name="createdAt"
              component={UIField}
              nativeInput={false}
              fieldComponent={UIRangePicker}
              fieldProps={{
                placeholder: ["Từ ngày", "Đến ngày"],
                allowClear: true
              }}
            />
          </Col>
        </Row>
      </Form>
    );
  };
}

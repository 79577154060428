import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import LocationDropdown from '~components/Dropdown/LocationDropdown';
import { UIRangePicker } from '~components/UI/UIDateRangePicker';
import { UIField } from '~components/UI/UIField';

interface IFormValues {
  locationId?: string;
  createdAt?: string[];
  code?: string;
}

interface IProps<T> {
  filter: IFormValues;
  onChange: (values: T) => any;
}

export type FilterValue = IFormValues;

export default class GoodsRIFilter extends Component<IProps<IFormValues>> {
  public onSubmit = (values: IFormValues) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(values);
    }
  };

  public render() {
    const { filter } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...filter,
        }}
        onSubmit={this.onSubmit}
        validate={this.onSubmit}
        validateOnBlur={false}
        render={this.renderForm}
      />
    );
  }

  private renderForm = () => {
    return (
      <Form>
        <Row gutter={8}>
          <Col md={4}>
            <Field
              name='code'
              component={UIField}
              fieldProps={{
                placeholder: 'Nhập mã phiếu',
                allowClear: true,
              }}
            />
          </Col>
          <Col md={8}>
            <Field
              name='locationId'
              component={UIField}
              nativeInput={false}
              fieldComponent={LocationDropdown}
              fieldProps={{
                placeholder: 'Kho nguồn / đích',
                allowClear: true,
              }}
            />
          </Col>
          <Col md={6}>
            <Field
              name='createdAt'
              component={UIField}
              nativeInput={false}
              fieldComponent={UIRangePicker}
              fieldProps={{
                placeholder: ['Từ ngày', 'Đến ngày'],
                allowClear: true,
              }}
            />
          </Col>
        </Row>
      </Form>
    );
  };
}

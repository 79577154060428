import { observer } from 'mobx-react';
import React, { Component } from 'react';
import store from './store';
import { SERIAL, OTHER } from '@mgn/common';
import { UIButton } from '~components/UI';
import { Row, Col } from 'antd';
import { get } from 'lodash';

@observer
class UpdateEquipmentActions extends Component {
  public render() {
    const { setOption, equipment } = store;

    return (
      <Row gutter={8} style={{ width: '100%' }}>
        <Col md={12}>
          <UIButton
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
            onClick={() => setOption(SERIAL)}
            disabled={!Boolean(get(equipment, 'serial'))}
          >
            Cập nhật Serial
          </UIButton>
        </Col>
        <Col md={12}>
          <UIButton
            type='info'
            htmlType='submit'
            style={{ width: '100%' }}
            onClick={() => setOption(OTHER)}
            disabled={!Boolean(get(equipment, 'serial'))}
          >
            Cập nhật thông tin
          </UIButton>
        </Col>
      </Row>
    );
  }
}

export default UpdateEquipmentActions;

import gql from 'graphql-tag';

import employeeDevice from '../fragments/employeeDevice';

export const REMOVE_EMPLOYEE_DEVICE_BY_ID_MUTATION = gql`
  mutation RemoveEmployeeDeviceById($_id: ID!) {
    removeEmployeeDeviceById(_id: $_id) {
      payload {
        ...BasicEmployeeDevice
      }
      error {
        message
      }
    }
  }
  ${employeeDevice.basic}
`;

import { ColDef } from "ag-grid-community";
import { Alert, Row } from "antd";
import classname from "classname";
import { get, groupBy } from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { flexCenterX, textCenter } from "~assets/styles/utils";
import { GridCellNumberRenderer } from "~components/UI/AgGridTable/CellNumberRenderer";
import client from "~graphql/client";
import {
  FIND_MANY_EQUIPMENT_HISTORY_QUERY,
  FIND_ONE_GOODS_ISSUE_QUERY
} from "~graphql/queries";
import {
  FindManyEquipmentHistoryQuery,
  FindManyEquipmentHistoryVariables,
  FindOneGoodsIssueQuery,
  FindOneGoodsIssueVariables,
  FullEquipmentFragment,
  FullGoodsIssueFragment
} from "~graphql/types";

import {
  ADDRESS_COMPANY,
  NAME_OPENNET,
  NAME_MEGANET
} from "../../configs/config";
import { AgGridTable, Loading, UIButton } from "../UI";
import {
  h1Title,
  printButton,
  printContainer,
  textFullRowWithDot
} from "./style";

interface IRowData extends FullEquipmentFragment {
  index?: number;
  amount?: number;
  totalPrice?: number;
}

@observer
class GoodsIssueDetail extends Component<
  RouteComponentProps<{ code: string }>
> {
  private columnDefs: ColDef[] = [
    {
      headerName: "STT",
      field: "index",
      minWidth: 60,
      maxWidth: 60,
      width: 50
    },
    {
      headerName: "Nhà cung cấp",
      field: "supplier.name",
      minWidth: 120,
      width: 160,
      rowSpan: ({ data }) => data.amount || 1
      // cellClass: 'cell-wrap-text ag-cell-span',
    },
    {
      headerName: "Tên hàng",
      field: "equipmentModel.name",
      minWidth: 80,
      width: 120,
      rowSpan: ({ data }) => data.amount || 1
      // cellClass: 'cell-wrap-text ag-cell-span',
    },
    {
      headerName: "ĐVT",
      field: "equipmentModel.unit",
      minWidth: 60,
      maxWidth: 60,
      width: 50,
      rowSpan: ({ data }) => data.amount || 1
      // cellClass: 'ag-cell-span',
    },
    {
      headerName: "Số lượng",
      field: "amount",
      minWidth: 80,
      maxWidth: 80,
      width: 80,
      cellRendererFramework: GridCellNumberRenderer,
      cellRendererParams: { decimal: 0 },
      rowSpan: ({ data }) => data.amount || 1
      // cellClass: 'right ag-cell-span',
    },
    {
      headerName: "Đơn giá",
      field: "unitPrice",
      minWidth: 80,
      maxWidth: 80,
      width: 80,
      cellRendererFramework: GridCellNumberRenderer,
      cellRendererParams: { decimal: 0 },
      rowSpan: ({ data }) => data.amount || 1
      // cellClass: 'right ag-cell-span',
    },
    {
      headerName: "Thành tiền",
      field: "totalPrice",
      minWidth: 100,
      maxWidth: 100,
      width: 100,
      cellRendererFramework: GridCellNumberRenderer,
      cellRendererParams: { decimal: 0 },
      rowSpan: ({ data }) => data.amount || 1
      // cellClass: 'right ag-cell-span',
    },
    {
      headerName: "Serial/MAC",
      field: "serial",
      minWidth: 130,
      maxWidth: 130,
      width: 130
    },
    {
      headerName: "Ghi chú",
      field: "note",
      minWidth: 70,
      maxWidth: 70,
      width: 70,
      rowSpan: ({ data }) => data.amount || 1
      //  cellClass: 'ag-cell-span',
    }
  ];

  @observable private goodsIssue: FullGoodsIssueFragment;
  @observable.ref private equipments: FullEquipmentFragment[];
  @observable private loading: boolean;

  constructor(props) {
    super(props);
    runInAction(() => {
      this.goodsIssue = null;
      this.equipments = [];
      this.loading = true;
    });

    this.fetchData(this.props.match.params.code);
  }

  @action public fetchData = async code => {
    this.loading = true;
    const [
      {
        data: { findOneGoodsIssue }
      },
      {
        data: { findManyEquipmentHistory }
      }
    ] = await Promise.all([
      await client.query<FindOneGoodsIssueQuery, FindOneGoodsIssueVariables>({
        query: FIND_ONE_GOODS_ISSUE_QUERY,
        variables: { where: { code } },
        fetchPolicy: "network-only"
      }),
      await client.query<
        FindManyEquipmentHistoryQuery,
        FindManyEquipmentHistoryVariables
      >({
        query: FIND_MANY_EQUIPMENT_HISTORY_QUERY,
        variables: { where: { goodsIssueCode: code } },
        fetchPolicy: "network-only"
      })
    ]);
    runInAction(() => {
      this.goodsIssue = findOneGoodsIssue;
      this.equipments = findManyEquipmentHistory.map(item => item.equipment);
      this.loading = false;
    });
  };

  @computed public get formatedCreationDate() {
    if (!this.goodsIssue) return null;
    const createdAt = moment(this.goodsIssue.createdAt);
    return [
      "Ngày",
      createdAt.format("DD"),
      "tháng",
      createdAt.format("MM"),
      "năm",
      createdAt.format("YYYY")
    ].join(" ");
  }

  @computed public get formatedEquipments() {
    const grouped = groupBy(this.equipments, "equipmentModelCode");
    const data = Object.keys(grouped).reduce<IRowData[]>(
      (all, supplierCode) => {
        all.push(
          ...grouped[supplierCode].map((item, idx) => {
            const obj: IRowData = { ...item };
            obj.index = all.length + idx + 1;
            if (!idx) {
              obj.amount = grouped[supplierCode].length;
              obj.totalPrice = obj.amount * item.unitPrice;
            } else {
              obj.equipmentModel = null;
              obj.supplier = null;
              obj.unitPrice = undefined;
            }
            return obj;
          })
        );
        return all;
      },
      []
    );

    data.push({
      ...data[0],
      index: null,
      serial: "-",
      equipmentModel: null,
      supplier: null,
      unitPrice: undefined,
      amount: undefined,
      totalPrice: undefined
    });

    return data;
  }

  public render() {
    if (this.loading) return <Loading />;
    if (!this.goodsIssue) {
      return (
        <Alert
          style={{ marginTop: 8 }}
          message="Error"
          description={`Không có phiếu xuất ${this.props.match.params.code}!`}
          type="error"
          showIcon={true}
        />
      );
    }

    return (
      <Fragment>
        <UIButton
          icon="printer"
          type="info"
          className={printButton}
          onClick={this.handlePrint}
        >
          In Phiếu Xuất
        </UIButton>
        <Row type="flex" className={printContainer}>
          <div className="header">
            <p className={classname(h1Title, "header--left")}>{NAME_MEGANET}</p>
            <p className={classname(h1Title, "header--right")}>
              Mẫu số 02 - VT
            </p>
          </div>
          <div className="header">
            <p className="header--left">{ADDRESS_COMPANY}</p>
            <p className="header--right">
              <i>( Ban hành theo Thông tư số 200/2014/TT-BTC</i>
              <br />
              <i>Ngày 22/12/2014 của Bộ Tài Chính )</i>
            </p>
          </div>
          <div className={textCenter}>
            <p className={h1Title}>PHIẾU XUẤT KHO</p>
            <p>{this.formatedCreationDate}</p>
            <div className={flexCenterX}>
              <p style={{ width: 100 }}>
                <span>{`Số: ${this.goodsIssue.code}`}</span>
                <span />
              </p>
            </div>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Row>
              <p className={textFullRowWithDot}>
                <span>
                  {`Họ và tên người nhận hàng:
                  ${
                    this.goodsIssue.proposedEquipment &&
                    this.goodsIssue.proposedEquipment.createdEmployee
                      ? this.goodsIssue.proposedEquipment.createdEmployee
                          .fullName
                      : ""
                  }
                  `}
                </span>
                <span />
              </p>
            </Row>
            <Row>
              <p className={textFullRowWithDot}>
                <span>Bộ phận:</span>
                <span />
              </p>
            </Row>
            <Row>
              <p className={textFullRowWithDot}>
                <span>Lý do xuất kho:</span>
                <span style={{ marginLeft: 8 }}>
                  {this.goodsIssue.description}
                </span>
                <span />
              </p>
            </Row>
            <Row>
              <p>
                <span>
                  {`Kho nguồn: ${get(
                    this.goodsIssue,
                    "fromLocation.type.display",
                    ""
                  )} -
                  ${get(this.goodsIssue, "fromLocation.name", "")} - ${get(
                    this.goodsIssue,
                    "fromLocation.address",
                    ""
                  )}`}
                </span>
                <span />
              </p>
            </Row>
            <Row>
              {this.goodsIssue.toLocation && (
                <p>
                  <span>
                    {`Kho đích: ${get(
                      this.goodsIssue,
                      "toLocation.type.display",
                      ""
                    )}-
                  ${get(this.goodsIssue, "toLocation.name", "")} - ${get(
                      this.goodsIssue,
                      "toLocation.address",
                      ""
                    )}`}
                  </span>
                  <span />
                </p>
              )}
            </Row>
            <Row>
              <p className={textFullRowWithDot}>
                <span>{`Ghi chú: ${
                  this.goodsIssue.note ? this.goodsIssue.note : ""
                }`}</span>
                <span />
              </p>
            </Row>
            <Row>
              <p className={textFullRowWithDot}>
                <span style={{ paddingTop: 20 }} />
                <span />
              </p>
            </Row>
            <Row>
              <p className={textFullRowWithDot}>
                <span style={{ paddingTop: 20 }} />
                <span />
              </p>
            </Row>
            <Row>
              <p>
                <span>{`Vật tư: ${
                  this.goodsIssue.proposedEquipment &&
                  this.goodsIssue.proposedEquipment.materialDetail
                    ? this.goodsIssue.proposedEquipment.materialDetail
                    : ""
                }`}</span>
                <span />
              </p>
            </Row>
            <Row>
              <p className={textFullRowWithDot}>
                <span style={{ paddingTop: 20 }} />
                <span />
              </p>
            </Row>
          </div>
          <div style={{ paddingTop: 20 }} className="printing">
            <AgGridTable
              defaultColDef={{
                suppressFilter: true,
                suppressSorting: true,
                suppressToolPanel: true,
                suppressMenu: true
              }}
              columnDefs={this.columnDefs}
              rowData={this.formatedEquipments}
              height="auto"
              gridOptions={{ domLayout: "print" }}
              suppressRowTransform={true}
            />
          </div>
          <div style={{ paddingTop: 20 }} className="no-page-break">
            <p className={textFullRowWithDot}>
              <span>Tổng cộng số tiền (Viết bằng chữ):</span>
              <span />
            </p>
            <p className={textFullRowWithDot}>
              <span>Số chứng từ gốc kèm theo:</span>
              <span />
            </p>
          </div>
          <div
            className="footer no-page-break"
            style={{ padding: "20px 0 80px 0" }}
          >
            <div className={textCenter}>
              <p>
                <strong>Người lập phiếu</strong>
              </p>
              <i>(Ký, họ tên)</i>
            </div>
            <div className={textCenter}>
              <p>
                <strong>Người nhận hàng</strong>
              </p>
              <i>(Ký, họ tên)</i>
            </div>
            <div className={textCenter}>
              <p>
                <strong>Thủ Kho</strong>
              </p>
              <i>(Ký, họ tên)</i>
            </div>
          </div>
        </Row>
      </Fragment>
    );
  }

  public handlePrint = () => {
    window.print();
  };
}

export default withRouter(GoodsIssueDetail);

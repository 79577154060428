import { ICellRendererParams } from 'ag-grid-community';
import React, { Component } from 'react';

import { EquipmentDetailLocation } from '~screens/Routes';

export class GridSerialCellRenderer extends Component<ICellRendererParams> {
  public render() {
    const { value, data } = this.props;

    if (!data) return '';
    return data.code
      ? value
      : EquipmentDetailLocation.toLink(value, { serial: value });
  }
}

import { ColDef } from 'ag-grid-community';
import { Alert, Button, Col, Popconfirm, Row } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Authorized from '~components/Authorized/Authorized';
import ConfirmModal from '~components/ConfirmModal/ConfirmModal';
import EquipmentTable from '~components/Equipment/EquipmentTable';
import EquipmentDetail from '~components/EquipmentDetail/EquipmentDetail';
import { Loading, UIButton } from '~components/UI';
import { removeTypename } from '~utils';

import {
  AGREED,
  CANCELLED,
  CREATED,
  PERMISSION_EQUIPMENT_TEMP_GOODS_ISSUE_ADD,
  PERMISSION_GOODS_ISSUE_CONFIRM_FROM_MANAGER,
  WAITING_AGREEMENT
} from '@mgn/common';

import CardDetail from './CardDetail';
import FormEquipmentTemp from './FormEquipmentTemp';
import { GridEquipmentActionCellRenderer } from './GridEquipmentActionCellRenderer';
import store from './store';

@observer
class GoodsIssueDetail extends Component<RouteComponentProps> {
  private actionColDef: ColDef = {
    field: '_id',
    headerName: '',
    minWidth: 60,
    maxWidth: 60,
    cellRendererFramework: GridEquipmentActionCellRenderer,
  };

  public componentWillMount = async () => {
    const { code } = this.props.match.params as any;
    store.setCode(code);
  };

  public notYetAgreedTable = () => {
    const {
      confirmVisible,
      toggleForm,
      toggleConfirm,
      selectedItem: goodsIssue,
      equipmentTemps,
      handlePassToManager,
      handleConfirmFromManager,
    } = store;

    const actions = (
      <Fragment>
        {goodsIssue.status === CREATED && (
          <Authorized
            permission={PERMISSION_EQUIPMENT_TEMP_GOODS_ISSUE_ADD}
            locationId={goodsIssue.fromLocationId}
          >
            <Button type='primary' icon='plus' onClick={toggleForm}>
              Tạo thiết bị
            </Button>
            <Popconfirm title='Trình duyệt?' onConfirm={handlePassToManager}>
              <UIButton
                type='success'
                icon='to-top'
                disabled={isEmpty(equipmentTemps)}
                style={{ marginLeft: 8 }}
              >
                Trình duyệt
              </UIButton>
            </Popconfirm>
          </Authorized>
        )}
        {goodsIssue.status === WAITING_AGREEMENT && (
          <Authorized
            permission={PERMISSION_GOODS_ISSUE_CONFIRM_FROM_MANAGER}
            locationId={goodsIssue.fromLocationId}
          >
            <Fragment>
              <UIButton type='success' icon='check' onClick={toggleConfirm}>
                Duyệt
              </UIButton>
              <ConfirmModal
                toggleModal={toggleConfirm}
                handleConfirm={handleConfirmFromManager}
                visible={confirmVisible}
                values={[
                  { text: 'ĐỒNG Ý', value: AGREED, color: 'green' },
                  { text: 'KIỂM TRA LẠI', value: CREATED, color: 'blue' },
                  { text: 'HỦY', value: CANCELLED, color: 'red' },
                ]}
              />
            </Fragment>
          </Authorized>
        )}
      </Fragment>
    );

    return (
      <EquipmentTable
        data={removeTypename(equipmentTemps)}
        extraColumn={['unitPrice']}
        actionComponent={actions}
        actionColDef={this.actionColDef}
      />
    );
  };

  public agreedTable = () => {
    const { equipments } = store;

    return (
      <EquipmentTable
        extraColumn={['location', 'unitPrice']}
        data={removeTypename(equipments)}
      />
    );
  };

  public render() {
    const { loading, selectedItem: goodsIssue } = store;

    if (loading) return <Loading />;
    if (!goodsIssue) {
      return (
        <Alert
          style={{ marginTop: 8 }}
          message='Error'
          description={'Không có phiếu nhập kho!'}
          type='error'
          showIcon={true}
        />
      );
    }

    const { proposedEquipmentId } = goodsIssue;
    return (
      <Fragment>
        <Row type='flex' style={{ flexFlow: 'column', height: '100vh' }}>
          <div style={{ flex: 0 }}>
            <CardDetail />
          </div>
          <Row type='flex' style={{ flex: 1, marginTop: 8 }} gutter={16}>
            <Col span={proposedEquipmentId ? 16 : 24}>
              {goodsIssue.status === AGREED || goodsIssue.status === CANCELLED
                ? this.agreedTable()
                : this.notYetAgreedTable()}
            </Col>
            {proposedEquipmentId && (
              <Col span={8}>
                <EquipmentDetail pe={goodsIssue.proposedEquipment} />
              </Col>
            )}
          </Row>
        </Row>

        <FormEquipmentTemp />
      </Fragment>
    );
  }
}

export default GoodsIssueDetail;

import React, { Component } from 'react';
import { Spin } from 'antd';
import { css } from 'emotion';
// import classname from 'classname';

// const loadingClassname = css`
//   text-align: center;
//   width: 100%;
//   background: transparent;
//   border-radius: 4px;
//   margin-bottom: 20px;
//   padding: 30px 50px;
//   margin: 20px 0;
// `;

export default class Loading extends Component<any> {
  public render() {
    // const { className, ...props } = this.props;
    // const newClassName = classname(loadingClassname, className);

    return (
      <div style={{ textAlign: 'center', padding: 8 }}>
        <Spin tip='Đang tải...' {...this.props} />
      </div>
    );
  }
}

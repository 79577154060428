export function formatDataToExport(equipment) {
  const data = [['STT', 'Tên thiết bị', ['Số lượng'], 'Ghi chú']];
  equipment.forEach((item, index) => {
    data.push([
      index,
      item.equipmentModelId.equipmentCategoryId.name,
      item.equipmentModelId.name,
      item.quantity,
      item.description,
    ]);
  });
  return data;
}

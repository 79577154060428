import { Col, Row } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import LocationDropdown from '~components/Dropdown/LocationDropdown';
import EquipmentCategoryDropdown from '~components/Dropdown/EquipmentCategoryDropdown';
import { UIButton } from '~components/UI';
import { UIRangePicker } from '~components/UI/UIDateRangePicker';
import { UIField } from '~components/UI/UIField';
import { englishify } from '~utils';

import store from './store';

interface IFormValue {
  locationId?: string;
  date?: string[];
  equipmentCategoryId?: string;
}

interface IProps<T> {
  onChange: (value: T) => any;
}

export type FilterValue = IFormValue;

@observer
export default class IOInventoryFilter extends Component<IProps<IFormValue>> {
  public onSubmit = (values: IFormValue) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(values);
    }
  };

  public render() {
    const initialValues = {
      ...store.filter,
    };
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validate={this.onSubmit}
        validateOnBlur={false}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (props: FormikProps<IFormValue>) => {
    const { values } = props;
    const onBtnExport = () => {
      const { gridApi } = store;

      const fileName = [
        'Bao_cao_XNT',
        values.locationId ? englishify(store.location.name) : 'Kho_Tong',
      ]
        .filter(Boolean)
        .join('-');

      const params = { fileName, columnGroups: true };
      gridApi.exportDataAsExcel(params);
    };
    return (
      <Form>
        <Row type='flex' gutter={8} style={{ marginTop: 8 }}>
          <Col span={24} md={{ span: 6 }} style={{ maxWidth: 300 }}>
            <Field
              name='date'
              component={UIField}
              nativeInput={false}
              fieldComponent={UIRangePicker}
              fieldProps={{
                placeholder: ['Từ ngày', 'Đến ngày'],
                allowClear: true,
              }}
            />
          </Col>
          <Col span={24} md={{ span: 6 }}>
            <Field
              name='locationId'
              component={UIField}
              nativeInput={false}
              fieldComponent={LocationDropdown}
              fieldProps={{
                placeholder: 'Kho / Địa điểm',
                allowClear: true,
              }}
            />
          </Col>
          <Col span={24} md={{ span: 6 }}>
            <Field
              name='equipmentCategoryId'
              component={UIField}
              nativeInput={false}
              fieldComponent={EquipmentCategoryDropdown}
              fieldProps={{
                placeholder: 'Loại thiết bị',
                allowClear: true,
              }}
            />
          </Col>
          <Col style={{ marginLeft: 'auto', marginTop: 4, flex: 0 }}>
            <UIButton type='primary' icon='file-excel' onClick={onBtnExport}>
              Xuất báo cáo
            </UIButton>
          </Col>
        </Row>
      </Form>
    );
  };
}

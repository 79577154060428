import gql from 'graphql-tag';
import locationFragment from '../fragments/location';

export const CONFIRM_FROM_INSTALLER_LOCATION_MUTATION = gql`
  mutation ConfirmFromInstallerLocation($_id: ID!) {
    confirmFromInstallerLocation(_id: $_id) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${locationFragment.full}
`;

import gql from 'graphql-tag';
import equipmentCategory from '../fragments/equipmentCategory';

export const CREATE_EQUIPMENT_CATEGORY_MUTATION = gql`
  mutation CreateEquipmentCategory($name: String!, $description: String) {
    createEquipmentCategory(
      record: { name: $name, description: $description }
    ) {
      payload {
        ...FullEquipmentCategory
      }
      error{
        message
      }
    }
  }
  ${equipmentCategory.full}
`;

import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import { isAuthorized } from '~components/Authorized/Authorized';
import { UIButton } from '~components/UI';
import {
  CREATED,
  PERMISSION_GOODS_ISSUE_REMOVE,
  PERMISSION_GOODS_ISSUE_UPDATE,
  AGREED,
} from '@mgn/common';

import store from './store';
import { GoodsIssuePrintLocation } from '~screens/Routes';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public handlePrint = () => {
    const code = get(this.props.data, 'code');
    window.open(GoodsIssuePrintLocation.toUrl({ code }));
  };

  public render() {
    const { data } = this.props;
    const { toggleModal, handleDelete } = store;

    return (
      <Fragment>
        {get(data, 'status') === CREATED && (
          <Button.Group>
            <UIButton
              icon='edit'
              size='small'
              type='primary'
              disabled={
                !isAuthorized(
                  PERMISSION_GOODS_ISSUE_UPDATE,
                  get(data, 'fromLocationId')
                )
              }
              onClick={() => toggleModal(data)}
            />
            <Popconfirm
              title='Xóa?'
              onConfirm={() => handleDelete(get(data, 'code'))}
            >
              <UIButton
                type='danger'
                icon='delete'
                size='small'
                disabled={
                  !isAuthorized(
                    PERMISSION_GOODS_ISSUE_REMOVE,
                    get(data, 'fromLocationId')
                  )
                }
              />
            </Popconfirm>
          </Button.Group>
        )}
        {get(data, 'status') === AGREED && (
          <Fragment>
            <UIButton
              icon='printer'
              size='small'
              type='info'
              onClick={this.handlePrint}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Button, Col, Row, Select } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import * as Yup from 'yup';
import { AgGridTable, UIButton } from '~components/UI';
import { UIField } from '~components/UI/UIField';
import { BasicLocationContact, ContactInput } from '~graphql/types';
import { trimObject } from '~utils';
import { TECHNICAL, CONTACT } from '@mgn/common';

const { Option } = Select;

const validationSchema = Yup.object().shape<ContactInput>({
  name: Yup.string().nullable(true),
  phone: Yup.string().required('Bắt buộc nhập'),
  email: Yup.string().nullable(true),
  position: Yup.string().nullable(true),
});

interface IProps {
  value: ContactInput[];
  onChange: (value: ContactInput[]) => any;
}

@observer
class ContactTable extends Component<IProps> {
  @observable.shallow public selectedItem: BasicLocationContact;
  @observable public index: number;

  private colDef: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      minWidth: 90,
      maxWidth: 90,
      cellRendererFramework: ({ data, rowIndex }) => (
        <ButtonGroup>
          <UIButton
            icon='delete'
            type='danger'
            size='small'
            onClick={() => this.handleRemove(data)}
          />
          <UIButton
            icon='edit'
            type='primary'
            size='small'
            onClick={() => this.setSelectedItem(data, rowIndex)}
          />
        </ButtonGroup>
      ),
    },
    {
      headerName: 'Tên',
      field: 'name',
      minWidth: 100,
    },
    {
      headerName: 'SĐT',
      field: 'phone',
      minWidth: 100,
    },
    {
      headerName: 'Chức vụ',
      field: 'position',
      minWidth: 100,
      cellRenderer: data => {
        if (get(data, 'value') === TECHNICAL) {
          return 'Kỹ thuật viên';
        }
        if (get(data, 'value') === CONTACT) {
          return 'Người liên hệ';
        }
        return data.value;
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 100,
    },
  ];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.selectedItem = null;
      this.index = -1;
    });
  }

  @action public setSelectedItem(
    contact: BasicLocationContact,
    rowIndex: number = null
  ) {
    this.selectedItem = contact;
    this.index = rowIndex;
  }

  @action public handleSubmit = (
    values: BasicLocationContact,
    actions: FormikActions<ContactInput>
  ) => {
    const { value, onChange } = this.props;
    const { resetForm, setSubmitting } = actions;
    trimObject(values);

    this.index > -1
      ? onChange([
          ...value.slice(0, this.index),
          values,
          ...value.slice(this.index + 1),
        ])
      : onChange([...(value || []), values]);

    this.index = -1;

    setSubmitting(false);
    resetForm({ phone: null });
  };

  public handleRemove = (contact: ContactInput) => {
    const { value, onChange } = this.props;
    onChange(value.filter(item => item !== contact));
  };

  public render() {
    const initialValues = {
      name: get(this.selectedItem, 'name'),
      phone: get(this.selectedItem, 'phone'),
      position: get(this.selectedItem, 'position'),
      email: get(this.selectedItem, 'email'),
    };
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (props: FormikProps<ContactInput>) => {
    const { value } = this.props;
    const { isValid, isSubmitting } = props;
    const options = [
      {
        key: TECHNICAL,
        value: TECHNICAL,
        children: 'Kỹ thuật viên',
      },
      {
        key: CONTACT,
        value: CONTACT,
        children: 'Người liên hệ',
      },
    ];

    return (
      <Fragment>
        <Form>
          <Row gutter={8}>
            <Col md={6}>
              <Field
                name='name'
                component={UIField}
                fieldProps={{ placeholder: 'Tên' }}
              />
            </Col>
            <Col md={4}>
              <Field
                name='phone'
                component={UIField}
                fieldProps={{ placeholder: 'Số điện thoại' }}
              />
            </Col>
            <Col md={4}>
              <Field
                name='position'
                component={UIField}
                fieldComponent={Select}
                fieldProps={{
                  placeholder: 'Chức vụ',
                  children: options.map(({ key, ...prop }) => (
                    <Option key={key} {...prop} />
                  )),
                  allowClear: true,
                }}
                nativeInput={false}
              />
            </Col>
            <Col md={7}>
              <Field
                name='email'
                component={UIField}
                fieldProps={{ placeholder: 'Email' }}
              />
            </Col>
            <Col md={3}>
              <Button
                type='primary'
                htmlType='submit'
                disabled={!isValid || (this.index <= -1 && value.length >= 2)}
                loading={isSubmitting}
                style={{ marginTop: 4, width: '100%' }}
              >
                {this.index > -1 ? 'Sửa' : 'Tạo'}
              </Button>
            </Col>
          </Row>
        </Form>
        <AgGridTable
          height={150}
          columnDefs={this.colDef}
          rowData={value || []}
        />
      </Fragment>
    );
  };
}

export default ContactTable;

import { Button, Col, Divider, Modal, Popconfirm, Row } from 'antd';
import { Field, Form, Formik, FormikProps, FormikActions } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import * as Yup from 'yup';
import AreaDropdown from '~components/Dropdown/AreaDropdown';
import DistrictDropdown from '~components/Dropdown/DistrictDropdown';
import EmployeeDropDown from '~components/Dropdown/EmployeeDropdown';
import { UIButton } from '~components/UI';
import { UIField } from '~components/UI/UIField';
import {
  ContactInput,
  CreateLocationVariables,
  DetailedLocationFragment,
  FullLocationFragment
} from '~graphql/types';
import { removeTypename } from '~utils';

import {
  AGREED,
  CREATED,
  INSTALLED,
  PERMISSION_EMPLOYEE_FREEWIFI_INSTALLER,
  PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER,
  PERMISSION_EMPLOYEE_FREEWIFI_SURVEYOR,
  STOPPED,
  WORKING,
  PERMISSION_LOCATION_CANCEL
} from '@mgn/common';

import ContactTable from '../ContactTable/ContactTable';

import { UpdateContentLocationStore } from './store';
import { isAuthorized } from '~components/Authorized/Authorized';
import LocationTypeDropdown from '~components/Dropdown/LocationTypeDropdown';

interface IFormValues extends CreateLocationVariables {
  _id: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),
  name: Yup.string().required('Bắt buộc nhập'),
  address: Yup.string()
    .matches(/., ./, 'Sai định dạng địa chỉ')
    .required('Bắt buộc nhập'),
  district: Yup.string().required('Bắt buộc chọn'),
  area: Yup.string().required('Bắt buộc chọn'),
  typeCode: Yup.string().required('Bắt buộc nhập'),
  contact: Yup.array<ContactInput>().min(1, ''),
  surveyorId: Yup.string().nullable(true),
  installerId: Yup.string().nullable(true),
  maintainerId: Yup.string().nullable(true),
});

interface IProps {
  location: DetailedLocationFragment;
  onChange: (location: FullLocationFragment) => void;
  visible: boolean;
  toggleModal: () => void;
  toggleViewDrawer?: () => void;
  toggleUpdateDetail?: () => void;
}

export type UpdateContentLocationProps = IProps;

class UpdateContentLocation extends Component<IProps> {
  private store = new UpdateContentLocationStore(this.props);

  public componentWillReceiveProps(props) {
    this.store.setProps(props);
  }

  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = this.store;
    const { setSubmitting } = actions;
    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate({ _id, ...record });
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
  };

  public render() {
    const {
      passToSurveyor,
      handleDelete,
      passToInstaller,
      passToMaintainer,
      stoppedLocation,
      workingLocation,
      cancelledLocation,
    } = this.store;
    const { visible, toggleModal, location } = this.props;

    const initialValues = {
      _id: get(location, '_id'),
      name: get(location, 'name'),
      address: get(location, 'address'),
      district: get(location, 'district'),
      area: get(location, 'area'),
      typeCode: get(location, 'typeCode') || undefined,
      contact: removeTypename(get(location, 'contact', [])),
      surveyorId: get(location, 'surveyorId') || undefined,
      installerId: get(location, 'installerId') || undefined,
      maintainerId: get(location, 'maintainerId') || undefined,
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const {
        isValid,
        isSubmitting,
        values,
        setFieldValue,
        setTouched,
        submitForm,
      } = props;

      let functionButton;

      switch (get(location, 'status')) {
        case CREATED:
          functionButton = (
            <Fragment>
              <Popconfirm
                title={`Giao cho ${get(location, 'surveyor.fullName')}`}
                okText='Giao'
                cancelText='Không'
                onConfirm={passToSurveyor}
              >
                <UIButton
                  type='warning'
                  disabled={!Boolean(get(location, 'surveyorId'))}
                >
                  Giao khảo sát
                </UIButton>
              </Popconfirm>
              <Popconfirm
                title='Xóa?'
                okText='Xóa'
                cancelText='Không'
                onConfirm={handleDelete}
              >
                <UIButton type='danger' disabled={!Boolean(values._id)}>
                  Xóa điểm
                </UIButton>
              </Popconfirm>
            </Fragment>
          );

          break;

        case AGREED:
          functionButton = (
            <Fragment>
              <Popconfirm
                title={`Giao cho ${get(location, 'installer.fullName')}`}
                okText='Giao'
                cancelText='Không'
                onConfirm={passToInstaller}
              >
                <UIButton
                  type='warning'
                  disabled={!Boolean(get(location, 'installerId'))}
                >
                  Giao thi công
                </UIButton>
              </Popconfirm>
            </Fragment>
          );

          break;

        case INSTALLED:
          functionButton = (
            <Popconfirm
              title={`Giao cho ${get(location, 'maintainer.fullName')}`}
              okText='Giao'
              cancelText='Không'
              onConfirm={passToMaintainer}
            >
              <UIButton
                type='warning'
                disabled={!Boolean(get(location, 'maintainerId'))}
              >
                Giao vận hành
              </UIButton>
            </Popconfirm>
          );

          break;
        case WORKING:
          functionButton = isEmpty(get(location, 'equipmentDetail', [])) && (
            <Popconfirm
              title={`Ngưng hoạt dộng ${get(location, 'name')}`}
              okText='Ok'
              cancelText='Không'
              onConfirm={stoppedLocation}
            >
              <UIButton
                type='warning'
                disabled={!Boolean(get(location, 'maintainerId'))}
              >
                Chuyển sang ngưng hoạt dộng
              </UIButton>
            </Popconfirm>
          );

          break;
        case STOPPED:
          functionButton = (
            <Popconfirm
              title={`Quay lại hoạt động ${get(location, 'name')}`}
              okText='Ok'
              cancelText='Không'
              onConfirm={workingLocation}
            >
              <UIButton
                type='warning'
                disabled={!Boolean(get(location, 'maintainerId'))}
              >
                Chuyển sang hoạt dộng
              </UIButton>
            </Popconfirm>
          );

          break;
      }

      const footer = (
        <Row>
          {isAuthorized(PERMISSION_LOCATION_CANCEL) &&
            get(location, 'status') &&
            get(location, 'status') !== CREATED &&
            get(location, 'status') !== STOPPED && (
              <Popconfirm
                title='Hủy điểm'
                okText='Có'
                cancelText='Không'
                onConfirm={() => cancelledLocation()}
              >
                <UIButton type='danger'>Huỷ điểm</UIButton>
              </Popconfirm>
            )}
          {functionButton}
          <UIButton
            type='success'
            onClick={submitForm}
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </UIButton>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      const handleContactSubmit = (contact: ContactInput[]) => {
        setFieldValue('contact', contact);
      };

      const onAreaChange = () => {
        setFieldValue('district', undefined, false);
        setTouched({ district: false });
      };

      return (
        <Modal
          closable={false}
          visible={visible}
          onCancel={toggleModal}
          footer={footer}
          className='biggerModal'
          style={{ top: 16 }}
        >
          <Form id={values._id}>
            <Row gutter={8}>
              <Col md={8}>
                <Field
                  label='Tên địa điểm'
                  name='name'
                  component={UIField}
                  fieldProps={{ placeholder: 'Nhập tên địa điểm' }}
                />
              </Col>
              <Col md={16}>
                <Field
                  label='Địa chỉ'
                  name='address'
                  component={UIField}
                  fieldProps={{
                    placeholder:
                      'Nhập địa chỉ theo dạng: Số nhà[dấu phẩy][dấu cách]tên đường',
                  }}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col md={8}>
                <Field
                  label='Tỉnh/Thành phố'
                  name='area'
                  component={UIField}
                  fieldComponent={AreaDropdown}
                  fieldProps={{
                    placeholder: 'Chọn Tỉnh/Thành phố',
                    allowClear: true,
                    onChange: onAreaChange,
                  }}
                  nativeInput={false}
                />
              </Col>
              <Col md={8}>
                <Field
                  label='Quận/Huyện'
                  name='district'
                  component={UIField}
                  fieldComponent={DistrictDropdown}
                  fieldProps={{
                    allowClear: true,
                    placeholder: 'Chọn Quận/Huyện',
                    selectedArea: get(values, 'area'),
                    disabled: get(values, 'area') ? false : true,
                  }}
                  nativeInput={false}
                />
              </Col>
              <Col md={8}>
                <Field
                  label='Loại địa điểm'
                  name='typeCode'
                  component={UIField}
                  fieldComponent={LocationTypeDropdown}
                  fieldProps={{
                    placeholder: 'Chọn loại địa điểm',
                    allowClear: true,
                  }}
                  nativeInput={false}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col md={8}>
                <Field
                  name='surveyorId'
                  label='Khảo sát'
                  nativeInput={false}
                  component={UIField}
                  fieldComponent={EmployeeDropDown}
                  fieldProps={{
                    permission: PERMISSION_EMPLOYEE_FREEWIFI_SURVEYOR,
                    placeholder: 'Chọn khảo sát',
                  }}
                />
              </Col>
              <Col md={8}>
                <Field
                  name='installerId'
                  label='Thi công'
                  nativeInput={false}
                  component={UIField}
                  fieldComponent={EmployeeDropDown}
                  fieldProps={{
                    permission: PERMISSION_EMPLOYEE_FREEWIFI_INSTALLER,
                    placeholder: 'Chọn thi công',
                  }}
                />
              </Col>
              <Col md={8}>
                <Field
                  name='maintainerId'
                  label='Vận hành'
                  nativeInput={false}
                  component={UIField}
                  fieldComponent={EmployeeDropDown}
                  fieldProps={{
                    permission: PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER,
                    placeholder: 'Chọn vận hành',
                  }}
                />
              </Col>
            </Row>
          </Form>
          <Divider orientation='left'>Liên hệ</Divider>
          <ContactTable
            value={get(values, 'contact')}
            onChange={handleContactSubmit}
          />
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default UpdateContentLocation;

import gql from 'graphql-tag';
import equipmentCategory from './equipmentCategory';

const basic = gql`
  fragment BasicEquipmentModel on EquipmentModel {
    _id
    name
    code
    unit
    description
    equipmentCategoryId
    createdAt
    updatedAt
  }
`;

const full = gql`
  fragment FullEquipmentModel on EquipmentModel {
    ...BasicEquipmentModel
    equipmentCategory {
      ...FullEquipmentCategory
    }
  }
  ${basic}
  ${equipmentCategory.full}
`;

export default { basic, full };

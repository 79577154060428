import { ColDef } from 'ag-grid-community';
import { Alert, Button, Col, Popconfirm, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Authorized from '~components/Authorized/Authorized';
import ConfirmModal from '~components/ConfirmModal/ConfirmModal';
import EquipmentTable from '~components/Equipment/EquipmentTable';
import EquipmentDetail from '~components/EquipmentDetail/EquipmentDetail';
import { Loading, UIButton } from '~components/UI';
import { removeTypename } from '~utils';

import {
  AGREED,
  CANCELLED,
  CREATED,
  PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_ADD,
  PERMISSION_GOODS_RECEIPT_CONFIRM_FROM_MANAGER,
  WAITING_AGREEMENT
} from '@mgn/common';

import GoodsReceiptCardDetail from './CardDetail';
import FormEquipmentTemp from './FormEquipmentTemp';
import ActionCellRenderer from './GridEquipmentTempActionCellRenderer';
import store from './store';

@observer
class GoodsIssueDetail extends Component<RouteComponentProps> {
  private actionColDef: ColDef = {
    field: '_id',
    headerName: '',
    minWidth: 70,
    maxWidth: 70,
    cellRendererFramework: ActionCellRenderer,
  };

  public componentWillMount = async () => {
    const { code } = this.props.match.params as any;
    store.setCode(code);
  };

  public notYetAgreedTable = () => {
    const {
      confirmVisible,
      toggleForm,
      toggleConfirm,
      selectedItem: goodsReceipt,
      equipmentTemps,
      handlePassToManager,
      handleConfirmFromManager,
    } = store;

    let functionButton;

    switch (get(goodsReceipt, 'status')) {
      case CREATED:
        functionButton = (
          <Authorized
            permission={PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_ADD}
            locationId={get(goodsReceipt, 'toLocationId')}
          >
            <Button type='primary' icon='plus' onClick={toggleForm}>
              Tạo thiết bị
            </Button>
            <Popconfirm title='Trình duyệt?' onConfirm={handlePassToManager}>
              <UIButton
                type='success'
                icon='to-top'
                disabled={isEmpty(equipmentTemps)}
                style={{ marginLeft: 8 }}
              >
                Trình duyệt
              </UIButton>
            </Popconfirm>
          </Authorized>
        );

        break;

      case WAITING_AGREEMENT:
        functionButton = (
          <Authorized
            permission={PERMISSION_GOODS_RECEIPT_CONFIRM_FROM_MANAGER}
            locationId={get(goodsReceipt, 'toLocationId')}
          >
            <UIButton type='success' icon='check' onClick={toggleConfirm}>
              Duyệt
            </UIButton>
          </Authorized>
        );

        break;
    }

    return (
      <Fragment>
        <EquipmentTable
          data={removeTypename(equipmentTemps)}
          extraColumn={['unitPrice']}
          actionComponent={functionButton}
          actionColDef={this.actionColDef}
        />
        <ConfirmModal
          toggleModal={toggleConfirm}
          handleConfirm={handleConfirmFromManager}
          visible={confirmVisible}
          values={[
            { text: 'ĐỒNG Ý', value: AGREED, color: 'green' },
            { text: 'KIỂM TRA LẠI', value: CREATED, color: 'blue' },
            { text: 'HỦY', value: CANCELLED, color: 'red' },
          ]}
        />
      </Fragment>
    );
  };

  public agreedTable = () => {
    const { equipments } = store;

    return (
      <EquipmentTable
        extraColumn={['location', 'unitPrice']}
        data={removeTypename(equipments)}
      />
    );
  };

  public render() {
    const { loading, selectedItem: goodsReceipt } = store;

    if (loading) return <Loading />;
    if (!goodsReceipt) {
      return (
        <Alert
          style={{ marginTop: 8 }}
          message='Error'
          description={'Không có phiếu nhập kho!'}
          type='error'
          showIcon={true}
        />
      );
    }

    const { proposedEquipmentId } = goodsReceipt;
    return (
      <Fragment>
        <Row type='flex' style={{ flexFlow: 'column', height: '100vh' }}>
          <div style={{ flex: 0 }}>
            <GoodsReceiptCardDetail />
          </div>
          <Row type='flex' style={{ flex: 1, marginTop: 8 }} gutter={16}>
            <Col span={proposedEquipmentId ? 16 : 24}>
              {goodsReceipt.status === AGREED ||
              goodsReceipt.status === CANCELLED
                ? this.agreedTable()
                : this.notYetAgreedTable()}
            </Col>
            {proposedEquipmentId && (
              <Col span={8}>
                <EquipmentDetail pe={goodsReceipt.proposedEquipment} />
              </Col>
            )}
          </Row>
        </Row>

        <FormEquipmentTemp />
      </Fragment>
    );
  }
}

export default GoodsIssueDetail;

import { ICellRendererParams } from 'ag-grid-community';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Icon, Tooltip } from 'antd';
import { css } from 'emotion';

const errorTooltip = css({
  '& .ant-tooltip-inner': {
    backgroundColor: '#ff7875',
    color: '#fff',
  },
  '& .ant-tooltip-arrow': {
    borderTopColor: '#ff7875',
  },
});

export default class GridPreTempSerialCellRenderer extends Component<
  ICellRendererParams
> {
  public render() {
    const { value, data } = this.props;

    if (!data) return '';
    if (data.code) return value;

    const link = (
      <Link
        style={{ color: data.error ? '#f5222d' : 'initial' }}
        to={`/equipment/${value}`}
      >
        {value}
      </Link>
    );

    return data.error ? (
      <Tooltip title={data.error} overlayClassName={errorTooltip}>
        <Icon
          type='info-circle'
          theme='twoTone'
          twoToneColor='#f5222d'
          style={{ marginRight: 8 }}
        />
        {link}
      </Tooltip>
    ) : (
      link
    );
  }
}

export { GridPreTempSerialCellRenderer };

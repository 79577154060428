import { css } from 'emotion';

export const flexCenterX = css`
  display: flex;
  justify-content: center;
`;

export const flexCenterY = css(flexCenterX, {
  flexFlow: 'column',
});

export const textCenter = css`
  text-align: center;
`;

export const divAsTable = css({
  display: 'table',
  '> div': {
    display: 'table-row',
  },
  '> div > div': {
    display: 'table-cell',
    padding: '8px',
  },
});

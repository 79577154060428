import gql from 'graphql-tag';
import locationFragment from '../fragments/location';

export const STOPPED_LOCATION = gql`
  mutation StoppedLocation($_id: ID!) {
    stoppedLocation(_id: $_id) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${locationFragment.full}
`;

import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { InputProps } from 'antd/lib/input';
import { FieldProps } from 'formik';
import React, { Component } from 'react';

interface IFieldHandler {
  onChange?: (value: any) => void;
  onBlur?: () => any;
}

// tslint:disable-next-line:interface-name
export interface UIFieldProps<T = InputProps> {
  label?: string;
  nativeInput?: boolean;
  fieldComponent?: new (props: T) => React.Component;
  fieldProps?: T;
}

export class UIField<T extends IFieldHandler> extends Component<
  FieldProps & UIFieldProps<T>
> {
  public render() {
    const {
      field,
      form,
      label,
      nativeInput = true,
      fieldComponent: FieldComponent,
      fieldProps = {},
    } = this.props;
    const { name } = field;
    const { touched, errors, setFieldValue, setFieldTouched } = form;
    const errorMsg = touched[name] && errors[name];
    const { onChange, onBlur } = fieldProps as IFieldHandler;

    const handleChange = value => {
      setFieldValue(name, value);
      if (typeof onChange === 'function') {
        onChange(value);
      }
    };
    const handleBlue = () => {
      setFieldTouched(name, true);
      if (typeof onBlur === 'function') {
        onBlur();
      }
    };

    return (
      <FormItem
        label={label}
        validateStatus={errorMsg ? 'error' : 'success'}
        help={errorMsg}
      >
        {FieldComponent ? (
          <FieldComponent
            {...field}
            {...fieldProps}
            {...(nativeInput
              ? {}
              : { onChange: handleChange, onBlur: handleBlue })}
          />
        ) : (
          <Input
            {...field}
            {...fieldProps}
            {...(nativeInput
              ? {}
              : { onChange: handleChange, onBlur: handleBlue })}
          />
        )}
      </FormItem>
    );
  }
}

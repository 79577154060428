import gql from 'graphql-tag';

import employeeDevice from '../fragments/employeeDevice';

export const UPDATE_EMPLOYEE_DEVICE_MUTATION = gql`
  mutation UpdateEmployeeDeviceById($_id: ID!, $record: EmployeeDeviceInput!) {
    updateEmployeeDeviceById(_id: $_id, record: $record) {
      payload {
        ...FullEmployeeDevice
      }
      error {
        message
      }
    }
  }
  ${employeeDevice.full}
`;

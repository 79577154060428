import gql from 'graphql-tag';
import goodsIssue from '../fragments/goodsIssue';

export const FIND_MANY_GOODS_ISSUE_QUERY = gql`
  query FindManyGoodsIssue(
    $skip: Int
    $take: Int
    $where: GoodsIssueBaseFilter
    $order: GoodsIssueBaseSort
  ) {
    findManyGoodsIssue(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullGoodsIssue
    }
  }
  ${goodsIssue.full}
`;

export const FIND_ONE_GOODS_ISSUE_QUERY = gql`
  query FindOneGoodsIssue(
    $where: GoodsIssueBaseFilter
    $order: GoodsIssueBaseSort
  ) {
    findOneGoodsIssue(where: $where, order: $order) {
      ...FullGoodsIssue
    }
  }
  ${goodsIssue.full}
`;

import location from '../fragments/location';
import gql from 'graphql-tag';

export const UPDATE_INSTALLER_LOCATION_MUTATION = gql`
  mutation UpdateInstallerLocation($_id: ID!, $installerId: String!) {
    updateInstallerLocation(_id: $_id, installerId: $installerId) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${location.full}
`;

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Button, Col, Row, Select } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import React, { Component, Fragment } from 'react';
import * as Yup from 'yup';

import { AgGridTable, UIButton } from '~components/UI';
import { UIField } from '~components/UI/UIField';
import { NetworkInput, FullLocationNetwork } from '~graphql/types';
import { observer } from 'mobx-react';
import { observable, action, runInAction } from 'mobx';
import TextArea from 'antd/lib/input/TextArea';
import { trimObject } from '~utils';

const { Option } = Select;

const validationSchema = Yup.object().shape<NetworkInput>({
  carrier: Yup.string().required('Bắt buộc nhập'),
  speed: Yup.string().required('Bắt buộc nhập'),
  note: Yup.string().nullable(true),
});

interface IProps {
  value: NetworkInput[];
  onChange: (value: NetworkInput[]) => any;
}

@observer
class NetworkTable extends Component<IProps> {
  @observable.shallow public selectedItem: FullLocationNetwork;
  @observable public index: number;

  private colDef: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      minWidth: 90,
      maxWidth: 90,
      cellRendererFramework: ({ data, rowIndex }) => (
        <ButtonGroup>
          <UIButton
            icon='delete'
            type='danger'
            size='small'
            onClick={() => this.handleRemove(data)}
          />
          <UIButton
            icon='edit'
            type='primary'
            size='small'
            onClick={() => this.setSelectedItem(data, rowIndex)}
          />
        </ButtonGroup>
      ),
    },
    {
      headerName: 'Nhà mạng',
      field: 'carrier',
      minWidth: 100,
    },
    {
      headerName: 'Tốc độ',
      field: 'speed',
      minWidth: 100,
    },
    {
      headerName: 'Ghi chú',
      field: 'note',
      minWidth: 100,
    },
  ];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.selectedItem = null;
      this.index = -1;
    });
  }

  @action public setSelectedItem(
    contact: FullLocationNetwork,
    rowIndex: number = null
  ) {
    this.selectedItem = contact;
    this.index = rowIndex;
  }

  @action public handleSubmit = (
    values: FullLocationNetwork,
    actions: FormikActions<NetworkInput>
  ) => {
    const { value, onChange } = this.props;
    const { resetForm, setSubmitting } = actions;
    trimObject(values);

    this.index > -1
      ? onChange([
          ...value.slice(0, this.index),
          values,
          ...value.slice(this.index + 1),
        ])
      : onChange([...(value || []), values]);

    this.index = -1;

    setSubmitting(false);
    resetForm();
  };

  public handleRemove = (network: NetworkInput) => {
    const { value, onChange } = this.props;
    onChange(value.filter(item => item !== network));
  };

  public render() {
    const initialValues = {
      carrier: get(this.selectedItem, 'carrier'),
      speed: get(this.selectedItem, 'speed'),
      note: get(this.selectedItem, 'note'),
    };
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (props: FormikProps<NetworkInput>) => {
    const { value } = this.props;
    const { isValid, isSubmitting } = props;

    const carrier = [
      'VIETTEL',
      'CMC',
      'FPT',
      'VNPT',
      'SPT',
      'VTC',
      'EVN',
      'NETNAM',
    ];

    return (
      <Fragment>
        <Form>
          <Row gutter={8}>
            <Col md={5}>
              <Field
                name='carrier'
                component={UIField}
                fieldComponent={Select}
                fieldProps={{
                  placeholder: 'Chọn NCC',
                  children: carrier.map((item, index) => (
                    <Option key={String(index)} value={item}>
                      {item}
                    </Option>
                  )),
                }}
                nativeInput={false}
              />
            </Col>
            <Col md={4}>
              <Field
                name='speed'
                component={UIField}
                fieldProps={{ placeholder: 'Tốc độ' }}
              />
            </Col>
            <Col md={12}>
              <Field
                name='note'
                component={UIField}
                fieldComponent={TextArea}
                fieldProps={{
                  autosize: { minRows: 1, maxRows: 2 },
                  placeholder: 'Ghi chú',
                }}
              />
            </Col>
            <Col md={3}>
              <Button
                type='primary'
                htmlType='submit'
                disabled={!isValid}
                loading={isSubmitting}
                style={{ marginTop: 4, width: '100%' }}
              >
                {this.index > -1 ? 'Sửa' : 'Tạo'}
              </Button>
            </Col>
          </Row>
        </Form>
        <AgGridTable
          height={150}
          columnDefs={this.colDef}
          rowData={value || []}
        />
      </Fragment>
    );
  };
}

export default NetworkTable;

import { Row, Tabs } from "antd";
import { observer } from "mobx-react";
import React, { ChangeEvent, Component, Fragment } from "react";
import { TabName } from "~components/UI";

import { PERMISSION_WAREHOUSE_CREATE } from "@mgn/common";

import WarehouseForm from "./Form";
import WarehouseTable from "./Grid";
import Header from "./Header";
import store from "./store";

const { TabPane } = Tabs;

@observer
class Warehouse extends Component {
  public onTabChange = activeKey => {
    store.setType(activeKey);
  };

  public handleQuickFilter = (event: ChangeEvent<HTMLInputElement>) => {
    store.setFilter(event.target.value);
  };

  public render() {
    const { type, toggleModal, visible } = store;

    const data = {
      warehouse: <TabName title="Danh sách kho" />,
      demo: <TabName title="Danh sách kho demo" />,
      buyer: <TabName title="Danh sách kho bán" />,
      other: <TabName title="Danh sách kho khác" />,
      location: <TabName title="Danh sách điểm" />
    };

    const tabs = (
      <Tabs onChange={this.onTabChange} activeKey={type}>
        {Object.keys(data).map(item => (
          <TabPane key={item} tab={data[item]} />
        ))}
      </Tabs>
    );

    return (
      <Fragment>
        <Row type="flex" style={{ flexFlow: "column", height: "100vh" }}>
          <Row>{tabs}</Row>
          <Header
            text="kho"
            handeFilter={this.handleQuickFilter}
            toggleModal={toggleModal}
            permission={PERMISSION_WAREHOUSE_CREATE}
            type={type}
          />
          <div style={{ flex: 1, marginTop: 8 }}>
            <WarehouseTable />
          </div>
        </Row>
        <WarehouseForm visible={visible} />
      </Fragment>
    );
  }
}
export default Warehouse;

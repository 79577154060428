import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { Component, Fragment } from 'react';

import { FullLocationFragment } from '~graphql/types';

import { DescriptionItem, EmployeeInfo } from './';

interface IProps {
  location: FullLocationFragment;
}

class LocationEmployee extends Component<IProps> {
  public render() {
    const { location } = this.props;
    return (
      <Fragment>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='NV tạo điểm'
              content={
                <EmployeeInfo employee={get(location, 'createdEmployee')} />}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='NV khảo sát'
              content={<EmployeeInfo employee={get(location, 'surveyor')} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title='NV thi công'
              content={<EmployeeInfo employee={get(location, 'installer')} />}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title='NV vận hành'
              content={<EmployeeInfo employee={get(location, 'maintainer')} />}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default LocationEmployee;

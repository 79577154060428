import gql from 'graphql-tag';
import role from '../fragments/role';

export const FIND_MANY_ROLE_QUERY = gql`
  query FindManyRole(
    $skip: Int
    $take: Int
    $where: RoleBaseFilter
    $order: RoleBaseSort
  ) {
    findManyRole(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullRole
    }
  }
  ${role.full}
`;

import { action, observable, runInAction } from 'mobx';

import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_EQUIPMENT_MODEL_MUTATION,
  REMOVE_EQUIPMENT_MODEL_BY_ID_MUTATION,
  UPDATE_EQUIPMENT_MODEL_MUTATION
} from '~graphql/mutations';
import { FIND_MANY_EQUIPMENT_MODEL_QUERY } from '~graphql/queries';
import {
  CreateEquipmentModelMutation,
  CreateEquipmentModelVariables,
  EquipmentModelInput,
  FindManyEquipmentModelQuery,
  FullEquipmentModelFragment,
  RemoveEquipmentModelByIdMutation,
  RemoveEquipmentModelByIdVariables,
  UpdateEquipmentModelByIdMutation,
  UpdateEquipmentModelByIdVariables
} from '~graphql/types';
import { removeTypename } from '~utils';

class EmployeeModelStore {
  @observable public quickFilter: string;
  @observable public visible: boolean;
  @observable public loading: boolean;
  @observable public selectedItem: FullEquipmentModelFragment;
  @observable.shallow public data: FullEquipmentModelFragment[];
  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.quickFilter = null;
      this.visible = false;
      this.loading = true;
      this.selectedItem = null;
      this.data = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action public showDrawer = () => {
    this.visible = false;
  };

  @action public toggleModal = (item = null) => {
    this.selectedItem = item;
    this.visible = !this.visible;
  };

  @action public handleRemove = async (_id: string) => {
    await client.mutate<
      RemoveEquipmentModelByIdMutation,
      RemoveEquipmentModelByIdVariables
    >({
      mutation: REMOVE_EQUIPMENT_MODEL_BY_ID_MUTATION,
      variables: { _id },
      errorPolicy: 'all',
      update: (_cache, { data: { removeEquipmentModelById } }) => {
        const { error } = removeEquipmentModelById;

        if (error) {
          ErrorNoti(error.message);
        } else {
          SuccessNoti('Xóa thiết bị');
          this.updateData(_id);
        }
      },
    });
  };

  @action public handleCreate = async (record: EquipmentModelInput) => {
    const {
      data: { createEquipmentModel },
    } = await client.mutate<
      CreateEquipmentModelMutation,
      CreateEquipmentModelVariables
    >({
      mutation: CREATE_EQUIPMENT_MODEL_MUTATION,
      variables: { record },
      errorPolicy: 'all',
    });
    const { error, payload } = createEquipmentModel;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo thiết bị');
      this.updateData(null, payload);
      this.toggleModal();
    }
  };

  @action public handleUpdate = async (
    _id: string,
    record: EquipmentModelInput
  ) => {
    const {
      data: { updateEquipmentModelById },
    } = await client.mutate<
      UpdateEquipmentModelByIdMutation,
      UpdateEquipmentModelByIdVariables
    >({
      mutation: UPDATE_EQUIPMENT_MODEL_MUTATION,
      variables: {
        _id,
        record,
      },
      errorPolicy: 'all',
    });
    const { error, payload } = updateEquipmentModelById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật thiết bị');
      this.updateData(_id, payload);
      this.toggleModal();
    }
  };

  @action private updateData = (
    _id: string,
    record?: FullEquipmentModelFragment
  ) => {
    if (!_id) {
      this.data = this.data.concat(record);
    } else {
      const index = this.data.findIndex(item => item._id === _id);
      this.data = [
        ...this.data.slice(0, index),
        record,
        ...this.data.slice(index + 1),
      ].filter(Boolean);
    }
  };

  @action private fetchData = async () => {
    const {
      data: { findManyEquipmentModel },
    } = await client.query<FindManyEquipmentModelQuery>({
      query: FIND_MANY_EQUIPMENT_MODEL_QUERY,
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.data = removeTypename(findManyEquipmentModel);
    });
  };
}

export default new EmployeeModelStore();

import supplier from '../fragments/supplier';
import gql from 'graphql-tag';

export const REMOVE_SUPPLIER_MUTATION = gql`
  mutation RemoveSupplierById($_id: ID!) {
    removeSupplierById(_id: $_id) {
      payload {
        ...BasicSupplier
      }
      error {
        message
      }
    }
  }
  ${supplier.basic}
`;

import gql from 'graphql-tag';
import employee from '../fragments/employee';

export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UpdateEmployeeById($_id: ID!, $record: EmployeeInput!) {
    updateEmployeeById(_id: $_id, record: $record) {
      payload {
        ...FullEmployee
      }
      error {
        message
      }
    }
  }
  ${employee.full}
`;

import {
  FullProposedEquipmentEquipmentDetail,
} from '~graphql/types';

export function mergeEquipment(
  oldEquipment: EquipmentDetailLocationEquipmentDetail[],
  newEquipment: FullProposedEquipmentEquipmentDetail[]
) {
  const newMap = newEquipment.reduce((all, item) => {
    if (!all[item.equipmentModelId]) {
      all[item.equipmentModelId] = {
        equipmentModelId: item.equipmentModelId,
        equipmentModel: item.equipmentModel,
      };

      if (item.quantity > 0) {
        Object.assign(all[item.equipmentModelId], {
          receiveQty: item.quantity,
          receiveNote: item.note,
        });
      } else {
        Object.assign(all[item.equipmentModelId], {
          issueQty: item.quantity,
          issueNote: item.note,
          serials: item.serials,
        });
      }
    } else {
      if (item.quantity > 0) {
        Object.assign(all[item.equipmentModelId], {
          receiveQty: item.quantity,
          receiveNote: item.note,
        });
      } else {
        Object.assign(all[item.equipmentModelId], {
          issueQty: item.quantity,
          issueNote: item.note,
          serials: item.serials,
        });
      }
    }

    return all;
  }, {});

  const data = {};

  oldEquipment.forEach(e => {
    if (newMap[e.equipmentModelId]) {
      data[e.equipmentModelId] = {
        equipmentModel: e.equipmentModel,
        equipmentModelId: e.equipmentModelId,
        oldQty: e.quantity,
        receiveQty: newMap[e.equipmentModelId].receiveQty,
        receiveNote: newMap[e.equipmentModelId].note,
        issueQty: newMap[e.equipmentModelId].issueQty,
        issueNote: newMap[e.equipmentModelId].issueNote,
        totalQty:
          Number(e.quantity || 0) +
          Number(newMap[e.equipmentModelId].receiveQty || 0) +
          Number(newMap[e.equipmentModelId].issueQty || 0),
        serials: newMap[e.equipmentModelId].serials,
      };
    } else {
      data[e.equipmentModelId] = {
        equipmentModel: e.equipmentModel,
        equipmentModelId: e.equipmentModelId,
        oldQty: e.quantity,
        receiveQty: 0,
        receiveNote: null,
        issueQty: 0,
        issueNote: null,
        totalQty: e.quantity,
      };
    }
  });

  newEquipment.forEach(e => {
    if (!data[e.equipmentModelId]) {
      data[e.equipmentModelId] = {
        equipmentModel: e.equipmentModel,
        equipmentModelId: e.equipmentModelId,
        oldQty: 0,
        receiveQty: e.quantity > 0 ? e.quantity : 0,
        receiveNote: e.quantity > 0 ? e.note : null,
        issueQty: e.quantity < 0 ? e.quantity : 0,
        issueNote: e.quantity < 0 ? e.note : null,
        totalQty: e.quantity,
        serials: e.serials,
      };
    }
  });

  return Object.values(data);
}

import gql from 'graphql-tag';
import equipmentTemp from '../fragments/equipmentTemp';

export const ADD_EQUIPMENT_TEMP_GOODS_RECEIPT_MUTATION = gql`
  mutation AddEquipmentTempGoodsReceipt(
    $goodsReceiptCode: String!
    $equipmentTemps: [EquipmentInputType!]!
  ) {
    addEquipmentTempGoodsReceipt(
      goodsReceiptCode: $goodsReceiptCode
      equipmentTemps: $equipmentTemps
    ) {
      error {
        message
      }
      equipmentTemps {
        ...FullEquipmentTemp
      }
      invalidEquipmentTemps {
        serial
        message
      }
    }
  }
  ${equipmentTemp.full}
`;

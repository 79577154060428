import { ColDef, ColGroupDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { AgGridTable, Loading } from '~components/UI';

import { GridCellActionRenderer } from './GridCellActionRenderer';
import store from './store';

@observer
class SupplierTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      minWidth: 90,
      maxWidth: 90,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      headerName: 'Tên',
      field: 'name',
      minWidth: 200,
    },
    {
      headerName: 'Mã',
      field: 'code',
      minWidth: 70,
      maxWidth: 70,
    },
    {
      headerName: 'Địa chỉ',
      field: 'address',
      suppressSorting: true,
      minWidth: 250,
    },
    {
      headerName: 'Số điện thoại',
      field: 'phone',
      minWidth: 120,
      maxWidth: 120,
    },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 200,
      maxWidth: 200,
    },
  ];

  public render() {
    const { loading, supplier, quickFilter } = store;
    if (loading) return <Loading />;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height: number) => (
            <AgGridTable
              context={this}
              columnDefs={this.columnDefs}
              height={height - 8}
              rowData={supplier}
              quickFilterText={quickFilter}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

export default SupplierTable;

import gql from 'graphql-tag';
import employee from '../fragments/employee';

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation CreateEmployee($fullName: String!, $email: String!, $phone: String) {
    createEmployee(fullName: $fullName, email: $email, phone: $phone) {
      employee {
        ...FullEmployee
      }
      error {
        message
      }
    }
  }
  ${employee.full}
`;

import gql from 'graphql-tag';
import goodsIssue from '../fragments/goodsIssue';

export const UPDATE_GOODS_ISSUE_MUTATION = gql`
  mutation UpdateGoodsIssueByCode(
    $code: String!
    $record: UpdateGoodsIssueInput!
  ) {
    updateGoodsIssueByCode(code: $code, record: $record) {
      goodsIssue {
        ...FullGoodsIssue
      }
      error {
        message
      }
    }
  }
  ${goodsIssue.full}
`;

import { Button, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { get } from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import * as Yup from "yup";
import { UIField } from "~components/UI/UIField";
import { LocationTypeInput } from "~graphql/types";

import store from "./store";
import TypeDropdown from "~components/Dropdown/TypeDropdown";
import { Authorized, isAuthorized } from "~components/Authorized/Authorized";
import { PERMISSION_SUPERADMIN, LOCATION_TYPE } from "@mgn/common";

interface IFormValues extends LocationTypeInput {
  _id: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),
  type: Yup.string().required("Bắt buộc nhập"),
  name: Yup.string().required("Bắt buộc nhập"),
  display: Yup.string().required("Bắt buộc nhập"),
  description: Yup.string().nullable(true)
});

@observer
class LocationTypeForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm, setSubmitting } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { visible, toggleModal, selectedItem } = store;

    const formInitialValues = {
      _id: get(selectedItem, "_id"),
      type: isAuthorized(PERMISSION_SUPERADMIN)
        ? get(selectedItem, "type")
        : LOCATION_TYPE,
      name: get(selectedItem, "name"),
      display: get(selectedItem, "display"),
      description: get(selectedItem, "description")
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            htmlType="submit"
            form="ltFm"
            disabled={!isValid}
            loading={isSubmitting}
            type="primary"
          >
            {values._id ? "Cập nhật" : "Tạo mới"}
          </Button>
          <Button type="danger" onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id="ltFm">
            <Authorized permission={PERMISSION_SUPERADMIN}>
              <Field
                name="type"
                label="Loại"
                component={UIField}
                fieldComponent={TypeDropdown}
                fieldProps={{ placeholder: "Chọn loại", required: true }}
                nativeInput={false}
              />
            </Authorized>
            <Field
              name="name"
              label="Tên loại địa điểm"
              component={UIField}
              fieldProps={{ placeholder: "Nhập tên loại địa điểm" }}
            />
            <Field
              name="display"
              label="Tên viết tắt"
              component={UIField}
              fieldProps={{ placeholder: "Nhập tên viết tắt" }}
            />
            <Field
              name="description"
              label="Mô tả"
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                placeholder: "Nhập mô tả",
                autosize: { minRows: 2, maxRows: 6 }
              }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default LocationTypeForm;

import React, { Component, ReactNode } from 'react';
import { Row } from 'antd';
import { css } from 'emotion';

interface IProps {
  tableName: ReactNode;
}

class TableTitle extends Component<IProps> {
  public render() {
    const titleClass = css`
      font-size: 1.5em;
      font-weight: 600;
      margin-right: auto;
    `;
    const { tableName, children } = this.props;

    return (
      <Row type='flex'>
        <span className={titleClass} style={{ flex: 1 }}>
          {tableName}
        </span>
        <div>{children}</div>
      </Row>
    );
  }
}

export default TableTitle;

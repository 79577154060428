import gql from "graphql-tag";

const full = gql`
  fragment FullLocationType on LocationType {
    _id
    type
    name
    code
    display
    description
    createdAt
    updatedAt
  }
`;

export default { full };

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import client from '~graphql/client';
import { FIND_MANY_MENU_QUERY } from '~graphql/queries';
import {
  FindManyMenuQuery,
  FindManyMenuVariables,
  FullMenuFragment,
} from '~graphql/types';

const { Option } = Select;

@observer
export default class MenuDropdown extends Component<SelectProps> {
  @observable public loading: boolean;
  @observable public data: FullMenuFragment[];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = false;
      this.data = [];
    });
  }

  public componentDidMount() {
    this.loadData().then(
      action('setLoading', () => {
        this.loading = false;
      })
    );
  }

  @action public loadData = async () => {
    this.loading = true;
    const { data } = await client.query<
      FindManyMenuQuery,
      FindManyMenuVariables
    >({
      query: FIND_MANY_MENU_QUERY,
      fetchPolicy: 'network-only',
    });
    runInAction(() => {
      this.loading = false;
      this.data = data ? data.findManyMenu : [];
    });
  };

  public render() {
    return (
      <Select
        allowClear={true}
        style={{ width: '100%' }}
        {...this.props}
        loading={this.loading}
      >
        {this.data.map(item => {
          return (
            <Option key={item._id} value={item._id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

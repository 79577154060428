import gql from 'graphql-tag';
import goodsReceipt from '../fragments/goodsReceipt';

export const CONFIRM_FROM_MANAGER_GOODS_RECEIPT = gql`
  mutation ConfirmFromManagerGoodsReceipt(
    $goodsReceiptCode: String!
    $note: String
    $confirm: ConfirmEnum!
  ) {
    confirmFromManagerGoodsReceipt(
      goodsReceiptCode: $goodsReceiptCode
      note: $note
      confirm: $confirm
    ) {
      goodsReceipt {
        ...FullGoodsReceipt
      }
      error {
        message
      }
    }
  }
  ${goodsReceipt.full}
`;

import './Button.less';

import { Button } from 'antd';
import { ButtonType, NativeButtonProps } from 'antd/lib/button/button';
import classname from 'classname';
import { Omit } from 'lodash';
import React, { Component } from 'react';

interface IUIButtonProps extends Omit<NativeButtonProps, 'type'> {
  type?: ButtonType | 'success' | 'warning' | 'info';
  outline?: 'outline' | 'none';
}

export class UIButton extends Component<IUIButtonProps> {
  public render() {
    let { type: btnType } = this.props;

    let typeClassName: string;

    switch (btnType) {
      case 'success':
      case 'warning':
      case 'info':
        typeClassName = `ant-btn-${btnType}`;
        btnType = 'default';
        break;
      default:
        break;
    }
    // const buttonColor = css`
    //   background-color: 'linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%)';
    // `;
    const { className, type, outline, ...props } = this.props;

    return (
      <Button
        className={classname(
          'ui-btn',
          typeClassName,
          outline && 'ui-btn-outline',
          outline === 'none' && 'ui-btn-outline-none',
          className
        )}
        {...props}
        type={btnType}
      >
        {this.props.children}
      </Button>
    );
  }
}

import gql from 'graphql-tag';
import supplier from '../fragments/supplier';

export const FIND_MANY_SUPPLIER_QUERY = gql`
  query FindManySupplier(
    $skip: Int
    $take: Int
    $where: SupplierBaseFilter
    $order: SupplierBaseSort
  ) {
    findManySupplier(skip: $skip, take: $take, where: $where, order: $order) {
      ...FullSupplier
    }
  }
  ${supplier.full}
`;

import {
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsRequest
} from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import { Row } from "antd";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import ReactResizeDetector from "react-resize-detector";
import { AgGridTable } from "~components/UI";
import client from "~graphql/client";
import { FIND_MANY_LOCATION_QUERY } from "~graphql/queries";
import {
  FindManyLocationQuery,
  FindManyLocationVariables,
  LocationBaseFilter
} from "~graphql/types";

import {
  IOrderArgs,
  mapReduce,
  SortDirection,
  WORKING,
  LOCATION_TYPE
} from "@mgn/common";

interface IProps extends AgGridReactProps {
  columnsDefs: any;
  where?: LocationBaseFilter;
  reset?: boolean;
}

export interface ISortModel {
  colId: string;
  sort: SortDirection;
}

@observer
export class LocationTableServer extends Component<IProps> {
  public onFilterChanged = () => undefined;

  public componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.reset) !== JSON.stringify(nextProps.reset) ||
      JSON.stringify(this.props.where) !== JSON.stringify(nextProps.where)
    ) {
      this.onFilterChanged();
    }
  }

  public loadLocation = async (request: IServerSideGetRowsRequest) => {
    const { where } = this.props;

    where.locationType = LOCATION_TYPE;

    const { ...filter } = where;

    const { startRow, endRow, sortModel } = request;
    const take = endRow - startRow;
    const orderCreated: IOrderArgs = { updatedAt: SortDirection.DESC };
    const order = mapReduce<ISortModel, "colId", SortDirection>(
      sortModel,
      "colId",
      ({ sort }) => sort.toUpperCase() as any
    );

    const {
      data: { findManyLocation }
    } = await client.query<FindManyLocationQuery, FindManyLocationVariables>({
      variables: {
        take: filter._operators.status.in[0] === WORKING ? 1000 : take,
        order: isEmpty(order) ? orderCreated : order,
        skip: startRow,
        where: {
          ...filter
        }
      },
      query: FIND_MANY_LOCATION_QUERY,
      fetchPolicy: "network-only"
    });

    return findManyLocation;
  };

  public onGridReady = async (event: GridReadyEvent) => {
    const { api } = event;
    this.onFilterChanged = () => {
      api.onFilterChanged();
    };
    const dataSource: IServerSideDatasource = {
      getRows: async ({ request, successCallback }) => {
        const { startRow, endRow } = request;
        const take = endRow - startRow;
        const data = await this.loadLocation(request);
        successCallback(
          data,
          data.length === take ? null : startRow + data.length
        );
      }
    };
    api.setServerSideDatasource(dataSource);
  };

  public render() {
    const { columnsDefs } = this.props;
    return (
      <Row type="flex" style={{ flexFlow: "column", height: "100%" }}>
        <div style={{ flex: 1, marginTop: 8 }}>
          <ReactResizeDetector handleHeight={true}>
            {(_width, height) => (
              <AgGridTable
                height={height - 8}
                columnDefs={columnsDefs}
                onGridReady={this.onGridReady}
                rowModelType="serverSide"
                cacheBlockSize={50}
                infiniteInitialRowCount={0}
              />
            )}
          </ReactResizeDetector>
        </div>
      </Row>
    );
  }
}

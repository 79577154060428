import { Popover } from 'antd';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { divAsTable } from '~assets/styles/utils';
import { BasicLocationFragment } from '~graphql/types';
import { acronymArea, acronymDistrict } from '~utils/acronymLocation';

interface IProps {
  location: BasicLocationFragment;
}

class LocationInfo extends Component<IProps> {
  public render() {
    const { location } = this.props;
    if (!location) return null;

    const content = (
      <div className={divAsTable}>
        <div>
          <div>Tên:</div>
          <div>{location.name}</div>
        </div>
        <div>
          <div>Địa chỉ:</div>
          <div>
            <a>{location.address}</a>
          </div>
        </div>
      </div>
    );

    return (
      <Popover title={null} content={content}>
        <Link to={`/warehouse/${get(location, '_id')}`}>
          {get(location, 'type.display')} - {acronymArea(get(location, 'area'))}
          {acronymDistrict(get(location, 'area'), get(location, 'district'))}
          {get(location, 'name')}
        </Link>
      </Popover>
    );
  }
}

export default LocationInfo;

import { Button, Card, Col, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { DescriptionItem, EmployeeInfo, LocationInfo } from '~components/UI';
import ViewDrawer from '~components/ViewDrawer/ViewDrawer';
import { formatStatus, formatTime } from '~utils';
import { LOCATION_TYPE } from '@mgn/common';
import store from './store';

@observer
export default class WarehouseCardDetail extends Component {
  public render() {
    const { selectedItem: warehouse, visibleView, toggleView } = store;

    return (
      <Fragment>
        <Card size='small' style={{ marginTop: 8 }}>
          <Row>
            <Col md={8}>
              <DescriptionItem
                title='Kho'
                content={
                  <Fragment>
                    <LocationInfo location={warehouse} />
                    {get(warehouse, 'locationType') === LOCATION_TYPE && (
                      <Button
                        shape='circle'
                        ghost={true}
                        size='small'
                        style={{ marginLeft: 8 }}
                        type='primary'
                        icon='info'
                        onClick={() => toggleView()}
                      />
                    )}
                  </Fragment>
                }
              />
            </Col>
            <Col md={6}>
              <DescriptionItem
                title='Người quản lý'
                content={
                  <EmployeeInfo employee={get(warehouse, 'maintainer')} />}
              />
            </Col>
            <Col md={6}>
              <DescriptionItem
                title='Ngày tạo'
                content={formatTime(get(warehouse, 'createdAt'))}
              />
            </Col>
            <Col md={4}>
              <DescriptionItem
                title='Trạng thái'
                content={formatStatus(get(warehouse, 'status'))}
              />
            </Col>
          </Row>
        </Card>

        <ViewDrawer
          visible={visibleView}
          toggleDrawer={toggleView}
          locationId={get(warehouse, '_id')}
        />
      </Fragment>
    );
  }
}

export { WarehouseCardDetail };

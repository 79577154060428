import { GridReadyEvent } from 'ag-grid-community';
import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  CONFIRM_FROM_MANAGER_PROPOSED_EQUIPMENT_MUTATION,
  CONFIRM_FROM_WAREHOUSE_PROPOSED_EQUIPMENT_MUTATION,
} from '~graphql/mutations';
import {
  ConfirmFromManagerProposedEquipmentMutation,
  ConfirmFromManagerProposedEquipmentVariables,
  ConfirmFromWarehouseProposedEquipmentMutation,
  ConfirmFromWarehouseProposedEquipmentVariables,
  FullProposedEquipmentFragment,
  ProposedEquipmentBaseFilter,
} from '~graphql/types';
import { FilterValue } from '~screens/GoodsIssue/Filter';
import { countProposed } from '~utils/countProposedEquipment';

import {
  AGREED,
  CANCELLED,
  FULFILLED,
  FULFILLING,
  WAITING_AGREEMENT,
  WAITING_SURVEYOR,
} from '@mgn/common';

interface INotification {
  agreedTab?: number;
  fulfillingTab?: number;
  waitingAgreementTab?: number;
  doneTab?: number;
  cancelledTab?: number;
}

class ProposedEquipmentStore {
  @observable public status: string;
  @observable public reset: boolean;
  @observable public noti: INotification;
  @observable public filter: FilterValue;
  @observable public visibleView: boolean;
  @observable public visibleModal: boolean;
  @observable.ref public selectedItem: FullProposedEquipmentFragment;

  constructor() {
    runInAction(() => {
      this.status = WAITING_AGREEMENT;
      this.reset = false;
      this.noti = null;
      this.filter = null;
      this.visibleView = false;
      this.visibleModal = false;
      this.selectedItem = null;
    });
  }

  @computed public get whereFilter() {
    const filter: ProposedEquipmentBaseFilter = { status: this.status };

    if (this.filter) {
      const { locationId, createdAt } = this.filter;
      if (locationId) {
        filter.OR = [{ locationId }];
      }
      if (Array.isArray(createdAt) && createdAt.length) {
        const [from, to] = createdAt;
        filter._operators = {
          createdAt: {
            ...(from && {
              gte: moment(from)
                .startOf('day')
                .toISOString(),
            }),
            ...(to && {
              lte: moment(to)
                .endOf('day')
                .toISOString(),
            }),
          },
        };
      }
    }

    return filter;
  }

  @action public setNotification = async () => {
    const notification: INotification = {};
    Object.assign(notification, {
      waitingAgreementTab: await countProposed(WAITING_AGREEMENT),
      agreedTab: await countProposed(AGREED),
      fulfillingTab: await countProposed(FULFILLING),
      doneTab: await countProposed(FULFILLED),
      cancelledTab: await countProposed(CANCELLED),
    });
    runInAction(() => {
      this.noti = notification;
    });
  };

  @action public setStatus = (status: string) => {
    runInAction(() => {
      this.status = status;
      this.reset = !this.reset;
    });
  };

  @action public setFilter = (filter: FilterValue) => {
    this.filter = filter;
  };

  @action public handleGridReady = ({ api }: GridReadyEvent) => {
    this.reload = () => {
      api.onFilterChanged();
    };
  };

  @action public toggleView = (item = null) => {
    this.visibleView = !this.visibleView;
    this.selectedItem = item;
  };

  @action public toggleModal = () => {
    this.visibleModal = !this.visibleModal;
  };

  @action public handleConfirmFromManager = async (confirm, note) => {
    const {
      data: { confirmFromManagerProposedEquipment },
    } = await client.mutate<
      ConfirmFromManagerProposedEquipmentMutation,
      ConfirmFromManagerProposedEquipmentVariables
    >({
      mutation: CONFIRM_FROM_MANAGER_PROPOSED_EQUIPMENT_MUTATION,
      variables: { confirm, note, _id: this.selectedItem._id },
    });

    const { error } = confirmFromManagerProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      switch (confirm) {
        case AGREED:
          SuccessNoti('Duyệt');
          break;
        case WAITING_SURVEYOR:
          SuccessNoti('Trả lại');
          break;
        case CANCELLED:
          SuccessNoti('Hủy');
          break;
      }

      this.toggleModal();
      this.toggleView();
      this.setNotification();
      this.reload();
    }
  };

  @action public handleConfirmFromWarehouse = async (confirm, note) => {
    const {
      data: { confirmFromWarehouseProposedEquipment },
    } = await client.mutate<
      ConfirmFromWarehouseProposedEquipmentMutation,
      ConfirmFromWarehouseProposedEquipmentVariables
    >({
      mutation: CONFIRM_FROM_WAREHOUSE_PROPOSED_EQUIPMENT_MUTATION,
      variables: { confirm, note, _id: this.selectedItem._id },
    });

    const { error } = confirmFromWarehouseProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      switch (confirm) {
        case AGREED:
          SuccessNoti('Duyệt');
          break;
        case WAITING_SURVEYOR:
          SuccessNoti('Trả lại');
          break;
        case CANCELLED:
          SuccessNoti('Hủy');
          break;
      }
      this.toggleModal();
      this.toggleView();
      this.setNotification();
      this.reload();
    }
  };

  @action private reload = () => undefined;
}
export default new ProposedEquipmentStore();

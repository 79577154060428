import { observer } from 'mobx-react';
import React, { Component } from 'react';
import store from './store';
import { FormikProps, Form, Field, Formik, FormikActions } from 'formik';
import { UIField } from '~components/UI/UIField';
import { UpdateContentEquipmentVariables } from '~graphql/types';
import { Button, Row, Modal } from 'antd';
import EquipmentModelDropdown from '~components/Dropdown/EquipmentModelDropdown';
import SupplierDropdown from '~components/Dropdown/SupplierDropdown';
import { get } from 'lodash';
import { SERIAL, OTHER } from '@mgn/common';
import { DescriptionItem } from '~components/UI';
import { EquipmentDetailLocation } from '~screens/Routes';
import { UIInputNumber } from '~components/UI/UIInputNumber';

interface IFormValues extends UpdateContentEquipmentVariables {
  newSerial?: string;
}

interface IProps {
  visible: boolean;
}

@observer
class SelectOption extends Component<IProps> {
  public hanndleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { updateContentEquipment, updateSerialEquipment, option } = store;
    const { serial, newSerial, ...remains } = values;
    const { setSubmitting, resetForm } = actions;

    if (option === SERIAL) {
      await updateSerialEquipment({ serial, newSerial });
    }
    if (option === OTHER) {
      await updateContentEquipment({ serial, ...remains });
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { equipment } = store;
    const initialValues = {
      serial: get(equipment, 'serial'),
      equipmentModelId: get(equipment, 'equipmentModelId'),
      unitPrice: get(equipment, 'unitPrice'),
      warranty: get(equipment, 'warranty'),
      supplierCode: get(equipment, 'supplierCode'),
      note: get(equipment, 'note'),
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={this.hanndleSubmit}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (props: FormikProps<IFormValues>) => {
    const { isSubmitting } = props;
    const { toggleModal, option, equipment } = store;
    const { visible } = this.props;

    const footer = (
      <Row>
        <Button
          type='primary'
          htmlType='submit'
          form='eFm'
          loading={isSubmitting}
        >
          Cập nhật
        </Button>
        <Button type='danger' onClick={() => toggleModal()}>
          Đóng
        </Button>
      </Row>
    );

    return option === SERIAL ? (
      <Modal closable={false} visible={visible} footer={footer}>
        <Form id='eFm'>
          <DescriptionItem
            title='Serial hiện tại'
            content={EquipmentDetailLocation.toLink(get(equipment, 'serial'), {
              serial: get(equipment, 'serial'),
            })}
          />
          <Field
            name='newSerial'
            label='Serial mới'
            component={UIField}
            fieldProps={{ placeholder: 'Nhập SERIAL mới' }}
          />
        </Form>
      </Modal>
    ) : (
      <Modal closable={false} visible={visible} footer={footer}>
        <Form id='eFm'>
          <Field
            name='equipmentModelId'
            label='Mẫu thiết bị'
            component={UIField}
            fieldComponent={EquipmentModelDropdown}
            fieldProps={{
              placeholder: 'Chọn mẫu thiết bị',
            }}
            nativeInput={false}
          />
          <Field
            name='supplierCode'
            label='Nhà cung cấp'
            component={UIField}
            fieldComponent={SupplierDropdown}
            fieldProps={{
              useCode: true,
              placeholder: 'Chọn nhà cung cấp',
            }}
            nativeInput={false}
          />
          <Field
            name='unitPrice'
            label='Đơn giá (VNĐ)'
            component={UIField}
            fieldComponent={UIInputNumber}
            fieldProps={{ placeholder: 'Đơn giá' }}
            nativeInput={false}
          />
          <Field
            name='warranty'
            label='Bảo hành (T)'
            component={UIField}
            fieldComponent={UIInputNumber}
            fieldProps={{ placeholder: 'Thời gian bảo hành' }}
            nativeInput={false}
          />
          <Field
            name='note'
            label='Ghi chú'
            component={UIField}
            fieldProps={{ placeholder: 'Ghi chú' }}
          />
        </Form>
      </Modal>
    );
  };
}

export default SelectOption;

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { LocationInfo } from '~components/UI';
import AgGridServer from '~components/UI/AgGridTable/AgGridServer';
import { GridCellDateRenderer } from '~components/UI/AgGridTable/CellDateRenderer';
import { GridCellLinkRenderer } from '~components/UI/AgGridTable/CellLinkRenderer';
import { GridCellStatusRenderer } from '~components/UI/AgGridTable/CellStatusRenderer';
import { FIND_MANY_GOODS_ISSUE_QUERY } from '~graphql/queries';
import { GoodsIssueBaseFilter } from '~graphql/types';
import authStore from '~stores/authStore';
import { compareEmployees, sortEmployee, sortTime } from '~utils';

import { GridCellActionRenderer } from './GridCellActionRenderer';
import store from './store';

const sortLocation = (a, b, currentEmployeeId) => {
  if (
    !compareEmployees(
      a.createdEmployeeId,
      b.createdEmployeeId,
      currentEmployeeId
    )
  ) {
    return sortEmployee(
      a.createdEmployeeId,
      b.createdEmployeeId,
      currentEmployeeId
    );
  }
  return sortTime(a.updatedAt, b.updatedAt);
};

@observer
class GoodsIssueTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      maxWidth: 90,
      minWidth: 90,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      headerName: 'Mã phiếu',
      field: 'code',
      maxWidth: 110,
      minWidth: 110,
      sort: 'desc',
      comparator: (_valueA, _valueB, a, b) => {
        const currentEmployeeId = authStore.currentEmployee._id;
        return sortLocation(a.data, b.data, currentEmployeeId);
      },
      cellRendererFramework: GridCellLinkRenderer,
      cellRendererParams: { route: '/goodsIssue' },
    },
    {
      colId: 'fromLocationId',
      headerName: 'Kho nguồn',
      field: 'fromLocation',
      cellRendererFramework: ({ value }) => <LocationInfo location={value} />,
      minWidth: 130,
      width: 130,
    },
    {
      colId: 'toLocationId',
      headerName: 'Kho đích',
      field: 'toLocation',
      cellRendererFramework: ({ value }) => <LocationInfo location={value} />,
      minWidth: 300,
      width: 300,
    },
    {
      headerName: 'Mô tả',
      field: 'description',
      minWidth: 200,
      width: 200,
      suppressSorting: true,
      cellClass: 'cell-wrap-text',
    },
    {
      headerName: 'Ngày tạo',
      field: 'createdAt',
      maxWidth: 150,
      minWidth: 150,
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Ngày duyệt',
      field: 'approvedAt',
      maxWidth: 150,
      minWidth: 150,
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Trạng thái',
      field: 'status',
      maxWidth: 130,
      minWidth: 130,
      cellRendererFramework: GridCellStatusRenderer,
    },
  ];

  public render() {
    const { whereFilter, handleGridReady } = store;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height) => {
            return (
              <AgGridServer<'findManyGoodsIssue', GoodsIssueBaseFilter>
                height={height - 8}
                columnDefs={this.columnDefs}
                query={FIND_MANY_GOODS_ISSUE_QUERY}
                queryKey='findManyGoodsIssue'
                where={whereFilter}
                onGridReady={handleGridReady}
              />
            );
          }}
        </ReactResizeDetector>
      </div>
    );
  }
}

export default GoodsIssueTable;

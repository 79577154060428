import role from '../fragments/role';
import gql from 'graphql-tag';

export const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRoleById($_id: ID!, $record: RoleInput!) {
    updateRoleById(_id: $_id, record: $record) {
      payload {
        ...FullRole
      }
      error {
        message
      }
    }
  }
  ${role.full}
`;

import gql from 'graphql-tag';

import equipment from '../fragments/equipment';

export const UPDATE_CONTENT_EQUIPMENT = gql`
  mutation UpdateContentEquipment(
    $serial: String!
    $equipmentModelId: String
    $supplierCode: String
    $status: String
    $note: String
    $unitPrice: Float
    $warranty: Float
  ) {
    updateContentEquipment(
      serial: $serial
      equipmentModelId: $equipmentModelId
      supplierCode: $supplierCode
      status: $status
      note: $note
      unitPrice: $unitPrice
      warranty: $warranty
    ) {
      equipment {
        ...FullEquipment
      }
      error {
        message
      }
    }
  }
  ${equipment.full}
`;

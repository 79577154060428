import gql from 'graphql-tag';

const basic = gql`
  fragment BasicSupplier on Supplier {
    _id
    name
    code
    phone
    email
    address
    createdAt
    updatedAt
  }
`;

const full = gql`
  fragment FullSupplier on Supplier {
    ...BasicSupplier
  }
  ${basic}
`;

export default { basic, full };

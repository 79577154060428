import { observable, action, runInAction, computed } from 'mobx';
import {
  FullProposedEquipmentFragment,
  FindOneProposedEquipmentQuery,
  FindOneProposedEquipmentVariables,
  FullGoodsReceiptFragment,
  FullGoodsIssueFragment,
  FindGoodsReceiptIssueByProposedEquipmentQuery,
  FindGoodsReceiptIssueByProposedEquipmentVariables,
  RemoveGoodsReceiptByCodeMutation,
  RemoveGoodsIssueByCodeMutation,
  RemoveGoodsReceiptByCodeVariables,
  RemoveGoodsIssueByCodeVariables,
  CreateGoodsReceiptMutation,
  CreateGoodsReceiptVariables,
  UpdateGoodsReceiptInput,
  UpdateGoodsReceiptByCodeMutation,
  UpdateGoodsReceiptByCodeVariables,
  CreateGoodsIssueVariables,
  CreateGoodsIssueMutation,
  UpdateGoodsIssueInput,
  UpdateGoodsIssueByCodeMutation,
  UpdateGoodsIssueByCodeVariables,
  ConfirmFromWarehouseProposedEquipmentMutation,
  ConfirmFromWarehouseProposedEquipmentVariables,
  PassToFulfilledProposedEquipmentMutation,
  PassToFulfilledProposedEquipmentVariables
} from '~graphql/types';
import client from '~graphql/client';
import {
  FIND_ONE_PROPOSED_EQUIPMENT_QUERY,
  FIND_GOODS_RECEIPT_ISSUE_BY_PROPOSED_EQUIPMENT
} from '~graphql/queries';
import {
  GOODSRECEIPT,
  GOODSISSUE,
  WAITING_SURVEYOR,
  CANCELLED
} from '@mgn/common';
import {
  REMOVE_GOODS_RECEIPT_MUTATION,
  REMOVE_GOODS_ISSUE_MUTATION,
  CREATE_GOODS_RECEIPT_MUTATION,
  UPDATE_GOODS_RECEIPT_MUTATION,
  CREATE_GOODS_ISSUE_MUTATION,
  UPDATE_GOODS_ISSUE_MUTATION,
  CONFIRM_FROM_WAREHOUSE_PROPOSED_EQUIPMENT_MUTATION,
  PASS_TO_FULFILLED_PROPOSED_EQUIPMENT_MUTATION
} from '~graphql/mutations';
import { ErrorNoti, SuccessNoti } from '~components/UI';
import { get } from 'lodash';

interface IGoodsReceipt extends FullGoodsReceiptFragment {
  type: string;
}

interface IGoodsIssue extends FullGoodsIssueFragment {
  type: string;
}

export class ProposedEquipmentDetailStore {
  @computed public get canAddGoodsIssue() {
    if (!this.selectedItem) return false;
    return (
      this.selectedItem.equipmentDetail.filter(ed => ed.quantity > 0).length &&
      this.selectedItem.equipmentDetail
        .filter(ed => ed.quantity > 0)
        .some(e => e.issuedQuantity < Math.abs(e.quantity))
    );
  }

  @computed public get canAddGoodsReceipt() {
    if (!this.selectedItem) return false;
    return (
      this.selectedItem.equipmentDetail.filter(ed => ed.quantity < 0).length &&
      this.selectedItem.equipmentDetail
        .filter(ed => ed.quantity < 0)
        .some(e => e.issuedRecovery < Math.abs(e.quantity))
    );
  }

  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public visibleConfirmModal: boolean;
  @observable.ref public selectedItem: FullProposedEquipmentFragment;
  @observable public loadingGRI: boolean;
  @observable public selectedGRI: IGoodsReceipt & IGoodsIssue;
  @observable public quickFilter: string;

  @observable.shallow public goodsReceipt: IGoodsReceipt[];
  @observable.shallow public goodsIssue: IGoodsIssue[];

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.visible = false;
      this.visibleConfirmModal = false;
      this.selectedItem = null;
      this.selectedGRI = null;
      this.loadingGRI = true;
      this.quickFilter = null;
      this.goodsIssue = [];
      this.goodsReceipt = [];
    });
  }

  @action public setId(_id: string) {
    this.loading = true;
    this.selectedItem = null;

    this.fetchData(_id).then(
      action(() => {
        this.loading = false;
      })
    );

    this.setPEId(_id);
  }

  // PE : ProposedEquipment
  @action public setPEId(peId: string) {
    this.loadingGRI = true;
    this.goodsIssue = [];
    this.goodsReceipt = [];
    this.fetchGRIData(peId).then(
      action(() => {
        this.loadingGRI = false;
      })
    );
  }

  @action public toggleModal = (item: IGoodsReceipt & IGoodsIssue = null) => {
    this.visible = !this.visible;
    this.selectedGRI = item;
  };

  @action public toggleConfirmModal = () => {
    this.visibleConfirmModal = !this.visibleConfirmModal;
  };

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action public handleDelete = async (code: string, type: string) => {
    const mutation =
      type === GOODSRECEIPT
        ? REMOVE_GOODS_RECEIPT_MUTATION
        : REMOVE_GOODS_ISSUE_MUTATION;

    const { data } = await client.mutate<
      RemoveGoodsReceiptByCodeMutation & RemoveGoodsIssueByCodeMutation,
      RemoveGoodsReceiptByCodeVariables & RemoveGoodsIssueByCodeVariables
    >({
      mutation,
      variables: { code },
    });

    const { error } =
      data.removeGoodsIssueByCode || data.removeGoodsReceiptByCode;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Xóa phiếu');
    }

    if (type === GOODSRECEIPT) {
      this.updateGoodsReceipt(code);
    } else {
      this.updateGoodsIssue(code);
    }
  };

  // GoodsReceipt
  @action public handleCreateGoodsReceipt = async (
    record: CreateGoodsReceiptVariables
  ) => {
    const { data } = await client.mutate<
      CreateGoodsReceiptMutation,
      CreateGoodsReceiptVariables
    >({
      mutation: CREATE_GOODS_RECEIPT_MUTATION,
      variables: record,
      errorPolicy: 'all',
    });

    const { error, goodsReceipt } = data.createGoodsReceipt;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo phiếu nhập kho');
    }

    this.updateGoodsReceipt(null, goodsReceipt);
    this.toggleModal();
  };

  @action public handleUpdateGoodsReceipt = async (
    code: string,
    record: UpdateGoodsReceiptInput
  ) => {
    const {
      data: { updateGoodsReceiptByCode },
    } = await client.mutate<
      UpdateGoodsReceiptByCodeMutation,
      UpdateGoodsReceiptByCodeVariables
    >({
      mutation: UPDATE_GOODS_RECEIPT_MUTATION,
      variables: {
        code,
        record,
      },
      errorPolicy: 'all',
    });
    const { error, goodsReceipt } = updateGoodsReceiptByCode;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật phiếu nhập kho');
      this.updateGoodsReceipt(code, goodsReceipt);
      this.toggleModal();
    }
  };

  // GoodsIssue
  @action public handleCreateGoodsIssue = async (
    record: CreateGoodsIssueVariables
  ) => {
    const {
      data: { createGoodsIssue },
    } = await client.mutate<
      CreateGoodsIssueMutation,
      CreateGoodsIssueVariables
    >({
      mutation: CREATE_GOODS_ISSUE_MUTATION,
      variables: record,
      errorPolicy: 'all',
    });

    const { error, goodsIssue } = createGoodsIssue;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo phiếu nhập kho');
      this.updateGoodsIssue(null, goodsIssue);
      this.toggleModal();
    }
  };

  @action public handleUpdateGoodsIssue = async (
    code: string,
    record: UpdateGoodsIssueInput
  ) => {
    const {
      data: { updateGoodsIssueByCode },
    } = await client.mutate<
      UpdateGoodsIssueByCodeMutation,
      UpdateGoodsIssueByCodeVariables
    >({
      mutation: UPDATE_GOODS_ISSUE_MUTATION,
      variables: {
        code,
        record,
      },
      errorPolicy: 'all',
    });

    const { error, goodsIssue } = updateGoodsIssueByCode;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật phiếu nhập kho');
      this.updateGoodsIssue(code, goodsIssue);
      this.toggleModal();
    }
  };

  @action public handleConfirmFromWarehouse = async (confirm, note) => {
    const {
      data: { confirmFromWarehouseProposedEquipment },
    } = await client.mutate<
      ConfirmFromWarehouseProposedEquipmentMutation,
      ConfirmFromWarehouseProposedEquipmentVariables
    >({
      mutation: CONFIRM_FROM_WAREHOUSE_PROPOSED_EQUIPMENT_MUTATION,
      variables: { confirm, note, _id: this.selectedItem._id },
    });

    const { error, proposedEquipment } = confirmFromWarehouseProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      switch (confirm) {
        case WAITING_SURVEYOR:
          SuccessNoti('Trả lại');
          break;
        case CANCELLED:
          SuccessNoti('Hủy');
          break;
      }

      this.toggleConfirmModal();

      runInAction(() => {
        this.selectedItem = proposedEquipment;
      });
    }
  };

  @action public handlePassToFulfilled = async () => {
    const {
      data: { passToFulfilledProposedEquipment },
    } = await client.mutate<
      PassToFulfilledProposedEquipmentMutation,
      PassToFulfilledProposedEquipmentVariables
    >({
      mutation: PASS_TO_FULFILLED_PROPOSED_EQUIPMENT_MUTATION,
      variables: { _id: get(this.selectedItem, '_id') },
    });

    const { error, proposedEquipment } = passToFulfilledProposedEquipment;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Chuyển sang hoàn thành');
      this.fetchGRIData(get(proposedEquipment, '_id'));

      runInAction(() => {
        this.selectedItem = proposedEquipment;
      });
    }
  };

  // fetching
  @action private async fetchData(_id: string) {
    this.loading = true;
    const { data } = await client.query<
      FindOneProposedEquipmentQuery,
      FindOneProposedEquipmentVariables
    >({
      query: FIND_ONE_PROPOSED_EQUIPMENT_QUERY,
      variables: { where: { _id } },
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.selectedItem = data.findOneProposedEquipment;
    });
  }

  @action private async fetchGRIData(proposedEquipmentId: string) {
    const { data } = await client.query<
      FindGoodsReceiptIssueByProposedEquipmentQuery,
      FindGoodsReceiptIssueByProposedEquipmentVariables
    >({
      query: FIND_GOODS_RECEIPT_ISSUE_BY_PROPOSED_EQUIPMENT,
      variables: { proposedEquipmentId },
      fetchPolicy: 'network-only',
    });
    const goodsReceipt = data.findManyGoodsReceipt.map(gr => ({
      ...gr,
      type: GOODSRECEIPT,
    }));

    const goodsIssue = data.findManyGoodsIssue.map(gi => ({
      ...gi,
      type: GOODSISSUE,
    }));

    runInAction(() => {
      this.goodsReceipt = goodsReceipt || [];
      this.goodsIssue = goodsIssue || [];
    });
  }

  @action private updateGoodsReceipt = (
    code: string,
    record?: FullGoodsReceiptFragment
  ) => {
    const newRecord = { ...record, type: GOODSRECEIPT };
    if (!code) {
      this.goodsReceipt = this.goodsReceipt.concat(newRecord);
    } else {
      const index = this.goodsReceipt.findIndex(item => item.code === code);

      this.goodsReceipt = [
        ...this.goodsReceipt.slice(0, index),
        record && newRecord,
        ...this.goodsReceipt.slice(index + 1),
      ].filter(Boolean);
    }
  };

  @action private updateGoodsIssue = (
    code: string,
    record?: FullGoodsIssueFragment
  ) => {
    const newRecord = { ...record, type: GOODSISSUE };
    if (!code) {
      this.goodsIssue = this.goodsIssue.concat(newRecord);
    } else {
      const index = this.goodsIssue.findIndex(item => item.code === code);
      this.goodsIssue = [
        ...this.goodsIssue.slice(0, index),
        record && newRecord,
        ...this.goodsIssue.slice(index + 1),
      ].filter(Boolean);
    }
  };
}

export default new ProposedEquipmentDetailStore();

import gql from 'graphql-tag';
import permission from './permission';

const basic = gql`
  fragment BasicRole on Role {
    _id
    name
    display
    description
    createdAt
    updatedAt
  }
`;

const full = gql`
  fragment FullRole on Role {
    ...BasicRole
    permissions {
      ...FullPermission
    }
  }
  ${permission.full}
  ${basic}
`;

export default { basic, full };

import React, { Component } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

export class GroupRowInnerRenderer extends Component<ICellRendererParams> {
  constructor(props) {
    super(props);
    props.reactContainer.style.display = 'inline-block';
  }

  public render() {
    const { data } = this.props;
    return `${data.equipmentModel.name} (${data.quantity})`;
  }
}

import { Button, Col, Row, Select } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { UIField } from '~components/UI/UIField';
import { MenuChildInput } from '~graphql/types';
import { trimObject } from '~utils';

const { Option } = Select;

const validationSchema = Yup.object().shape<IFormValues>({
  icon: Yup.string().nullable(true),
  route: Yup.string().nullable(true),
  name: Yup.string().required('Bắt buộc nhập'),
});

type IFormValues = MenuChildInput;

interface IProps {
  data: MenuChildInput[];
  handleSubmit: (values: MenuChildInput) => any;
}

@observer
export default class FormMenuInput extends Component<IProps> {

  public onSubmit = (
    values: IFormValues,
    props: FormikActions<IFormValues>
  ) => {
    const { handleSubmit } = this.props;
    const { resetForm } = props;
    trimObject(values);

    handleSubmit(values);
    resetForm();
  };

  public render() {
    return (
      <Formik
        initialValues={{}}
        enableReinitialize={true}
        onSubmit={this.onSubmit}
        render={this.renderForm}
        validationSchema={validationSchema}
        validateOnBlur={false}
      />
    );
  }
  private renderForm = (props: FormikProps<IFormValues>) => {
    const { setFieldValue, values, resetForm, isValid } = props;
    const { data } = this.props;

    if (values.route && !data.find(item => item.route === values.route)) {
      resetForm();
    }

    const options = data.map(item => {
      const text = `${item.name} [${item.route}]`;
      return (
        <Option key={item.route} value={item.route}>
          {text}
        </Option>
      );
    });

    const handleChange = (value: string) => {
      const selectedItem = data.find(item => item.route === value);
      setFieldValue('icon', selectedItem.icon);
      setFieldValue('name', selectedItem.name);
    };

    return (
      <Form>
        <Row type='flex' gutter={8}>
          <Col style={{ flex: 1 }}>
            <Field
              name='route'
              component={UIField}
              nativeInput={false}
              fieldComponent={Select}
              fieldProps={{
                placeholder: 'Chọn menu',
                children: options,
                onChange: handleChange,
              }}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Field
              name='name'
              component={UIField}
              fieldProps={{
                placeholder: 'Nhập tên hiển thị',
              }}
            />
          </Col>
          <Button
            type='primary'
            icon='plus'
            htmlType='submit'
            style={{ marginTop: 4 }}
            disabled={!isValid}
          />
        </Row>
      </Form>
    );
  };
}

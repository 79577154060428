import gql from 'graphql-tag';

const child = gql`
  fragment MenuChild on MenuChild {
    name
    route
    icon
    children {
      name
      route
      icon
    }
  }
`;

const full = gql`
  fragment FullMenu on Menu {
    _id
    name
    menu {
      ...MenuChild
    }
    createdAt
    updatedAt
  }
  ${child}
`;

export default { full };

import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import store from './store';
import { UIButton } from '~components/UI';

@observer
export default class GridActionCellRenderer extends Component<
  ICellRendererParams
> {
  public handleEdit = () => {
    const { handleShow } = store;
    const { data } = this.props;

    handleShow(data);
  };

  public handleDelete = () => {
    const { handleDelete } = store;
    const { value } = this.props;

    handleDelete(value);
  };

  public render() {
    return (
      <Button.Group>
        <UIButton
          type='primary'
          icon='edit'
          size='small'
          onClick={this.handleEdit}
        />
        <Popconfirm
          title='Bạn có chắc muốn xóa không?'
          onConfirm={this.handleDelete}
        >
          <UIButton type='danger' icon='delete' size='small' />
        </Popconfirm>
      </Button.Group>
    );
  }
}

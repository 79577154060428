import { ICellRendererParams } from 'ag-grid-community';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

interface IProps extends ICellRendererParams {
  route: string;
}

export class GridCellLinkRenderer extends Component<IProps> {
  public render() {
    const { value, route } = this.props;

    return <Link to={`${route}/${value}`}>{value}</Link>;
  }
}

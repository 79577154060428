import gql from 'graphql-tag';
import proposedEquipment from '../fragments/proposedEquipment';

export const CREATE_PROPOSED_EQUIPMENT_MUTATION = gql`
  mutation CreateProposedEquipment(
    $locationId: String!
    $type: TypeEnum!
    $equipmentDetail: [EquipmentDetailInput!]!
    $proposalReason: String!
    $materialDetail: String
  ) {
    createProposedEquipment(
      locationId: $locationId
      type: $type
      equipmentDetail: $equipmentDetail
      proposalReason: $proposalReason
      materialDetail: $materialDetail
    ) {
      proposedEquipment {
        ...FullProposedEquipment
      }
      error {
        message
      }
    }
  }
  ${proposedEquipment.full}
`;

import gql from 'graphql-tag';
import menuMapping from '../fragments/menuMapping';

export const FIND_MANY_MENU_MAPPING_QUERY = gql`
  query FindManyMenuMapping(
    $skip: Int
    $take: Int
    $where: MenuMappingBaseFilter
    $order: MenuMappingBaseSort
  ) {
    findManyMenuMapping(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullMenuMapping
    }
  }
  ${menuMapping.full}
`;

import { GridReadyEvent } from 'ag-grid-community';
import { Button, Col, Icon, Modal, Row, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { Field, Form, Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import XLSX from 'xlsx';
import * as Yup from 'yup';

import EquipmentModelDropdown from '~components/Dropdown/EquipmentModelDropdown';
import SearchEquipment from '~components/SearchEquipment/SearchEquipment';
import SupplierDropdown from '~components/Dropdown/SupplierDropdown';
import { ErrorNoti, UIButton } from '~components/UI';
import { UIField } from '~components/UI/UIField';
import { UIInputNumber } from '~components/UI/UIInputNumber';

import excelTemplateFile from './GoodsReceiptExcelTemplate.xlsx';
import GridEquipmentPreTemp from './GridEquipmentPreTemp';
import { GridPreTempSerialCellRenderer } from './GridPreTempSerialCellRenderer';
import store, { IFormValues } from './store';
import { trimObject } from '~utils';

const validationSchema = Yup.object().shape<IFormValues>({
  serial: Yup.string().required('Bắt buộc nhập'),
  equipmentModelCode: Yup.string().required('Bắt buộc nhập'),
  supplierCode: Yup.string().required('Bắt buộc nhập'),
  unitPrice: Yup.number()
    .min(0, 'Đơn giá phải lớn hơn 0')
    .required('Bắt buộc nhập'),
  warranty: Yup.number(),
});

@observer
export default class FormEquipmentTemp extends Component {
  public handleFile = async (file: UploadFile): Promise<IFormValues[]> => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    return new Promise(resolve => {
      reader.onload = e => {
        const bstr = (e.target as any).result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        return resolve(
          data
            .slice(1)
            .map(
              ([
                equipmentModelCode,
                serial,
                supplierCode,
                unitPrice,
                warranty,
              ]) => ({
                serial,
                equipmentModelCode,
                supplierCode,
                unitPrice,
                warranty,
              })
            )
            .filter(item => item.serial)
        );
      };
      if (rABS) reader.readAsBinaryString(file.originFileObj);
      else reader.readAsArrayBuffer(file.originFileObj);
    });
  };

  public handleBeforeUpload = () => false;

  public handleFileChange = async (info: UploadChangeParam) => {
    const serials = await Promise.all(info.fileList.map(this.handleFile))
      .then(allData => {
        return Array.prototype.concat([], ...allData);
      })
      .catch(err => {
        console.error(err);
        ErrorNoti('Không đọc được file excel');
      });

    if (!serials || !serials.length) return;

    const { handleAddPreTemps } = store;
    handleAddPreTemps(...serials);
  };

  public handleGridReady = (event: GridReadyEvent) => {
    const serialCol = event.api.getColumnDef('serial');
    serialCol.cellRendererFramework = GridPreTempSerialCellRenderer;
  };

  public render() {
    const {
      selectedItem,
      preTempVisible: formVisible,
      toggleForm,
      equipmentPreTemps,
      handleAddPreTemps,
      handleAddPreTempSerials,
      handleCreateEquipmentTemps,
    } = store;

    const handleSubmit = (values: IFormValues) => {
      trimObject(values);
      handleAddPreTemps(values);
    };

    const footer = (
      <Row>
        <Button
          icon='plus'
          type='primary'
          disabled={!equipmentPreTemps.length}
          onClick={handleCreateEquipmentTemps}
        >
          Tạo thiết bị
        </Button>

        <Button
          type='danger'
          style={{ marginLeft: 8 }}
          onClick={() => toggleForm()}
        >
          Đóng
        </Button>
      </Row>
    );

    return (
      <Modal closable={false} visible={formVisible} footer={footer}>
        {selectedItem.proposedEquipmentId ? (
          <Row gutter={8}>
            <Col md={12}>
              <SearchEquipment
                locationId={selectedItem.fromLocationId}
                handleSelect={handleAddPreTempSerials}
              />
            </Col>
            <Col md={4}>
              <Upload
                fileList={[]}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                beforeUpload={this.handleBeforeUpload}
                onChange={this.handleFileChange}
              >
                <UIButton type='info' icon='file-excel'>
                  Excel
                </UIButton>
              </Upload>
            </Col>
            <Col style={{ textAlign: 'right', margin: '8px 0' }}>
              <a href={excelTemplateFile} target='_blank'>
                <Icon type='download' style={{ marginRight: 8 }} />
                Tải file excel mẫu
              </a>
            </Col>
          </Row>
        ) : (
          <Formik
            initialValues={{}}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            render={this.renderForm}
          />
        )}

        <div style={{ height: 250, marginTop: 16 }}>
          <GridEquipmentPreTemp />
        </div>
      </Modal>
    );
  }

  private renderForm = (props: FormikProps<IFormValues>) => {
    const { isValid } = props;

    return (
      <Form id='goodsReceiptEquipTempFm'>
        <Field
          name='serial'
          component={UIField}
          fieldProps={{ placeholder: 'Nhập SERIAL/MAC' }}
        />
        <Row gutter={8}>
          <Col span={24} md={{ span: 12 }}>
            <Field
              name='equipmentModelCode'
              component={UIField}
              fieldComponent={EquipmentModelDropdown}
              fieldProps={{ useCode: true }}
              nativeInput={false}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <Field
              name='supplierCode'
              component={UIField}
              fieldComponent={SupplierDropdown}
              fieldProps={{ useCode: true }}
              nativeInput={false}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24} md={{ span: 12 }}>
            <Field
              name='unitPrice'
              component={UIField}
              fieldComponent={UIInputNumber}
              fieldProps={{ placeholder: 'Đơn giá' }}
              nativeInput={false}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <Field
              name='warranty'
              component={UIField}
              fieldComponent={UIInputNumber}
              fieldProps={{ placeholder: 'Thời gian bảo hành' }}
              nativeInput={false}
            />
          </Col>
        </Row>
        <Field
          name='note'
          component={UIField}
          fieldProps={{ placeholder: 'Ghi chú' }}
        />
        <Row type='flex' gutter={8} style={{ alignItems: 'baseline' }}>
          <Col>
            <Button
              icon='plus'
              type='primary'
              htmlType='submit'
              disabled={!isValid}
            >
              Tạo
            </Button>
          </Col>
          <Col>hoặc</Col>
          <Col>
            <Upload
              fileList={[]}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              beforeUpload={this.handleBeforeUpload}
              onChange={this.handleFileChange}
            >
              <UIButton type='info' icon='file-excel'>
                Excel
              </UIButton>
            </Upload>
          </Col>
          <Col style={{ textAlign: 'right', margin: '8px 0' }}>
            <a href={excelTemplateFile} target='_blank'>
              <Icon type='download' style={{ marginRight: 8 }} />
              Tải file excel mẫu
            </a>
          </Col>
        </Row>
      </Form>
    );
  };
}

export { FormEquipmentTemp };

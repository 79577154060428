import gql from 'graphql-tag';
import employee from './employee';

const basic = gql`
  fragment BasicEmployeeDevice on EmployeeDevice {
    _id
    mac
    device
    employeeId
    description
  }
`;

const full = gql`
  fragment FullEmployeeDevice on EmployeeDevice {
    ...BasicEmployeeDevice
    employee {
      ...FullEmployee
    }
  }
  ${basic}
  ${employee.full}
`;

export default { basic, full };

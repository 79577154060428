import gql from 'graphql-tag';

const REMOVE_FILE_LOCATION_MUTATION = gql`
  mutation RemoveFileLocation($id: String!) {
    removeFileLocation(id: $id) {
      id
    }
  }
`;

export { REMOVE_FILE_LOCATION_MUTATION };

import gql from 'graphql-tag';

const full = gql`
  fragment FullMaterial on Material {
    _id
    name
    code
    unit
    description
    createdAt
    updatedAt
  }
`;

export default { full };

import gql from 'graphql-tag';
import menu from '../fragments/menu';

export const CREATE_MENU_MUTATION = gql`
  mutation CreateMenu($record: MenuInput!) {
    createMenu(record: $record) {
      payload {
        ...FullMenu
      }
      error {
        message
      }
    }
  }
  ${menu.full}
`;

export const REMOVE_MENU_BY_ID_MUTATION = gql`
  mutation RemoveMenuById($_id: ID!) {
    removeMenuById(_id: $_id) {
      payload {
        ...FullMenu
      }
      error {
        message
      }
    }
  }
  ${menu.full}
`;

export const UPDATE_MENU_BY_ID_MUTATION = gql`
  mutation UpdateMenuById($_id: ID!, $record: MenuInput!) {
    updateMenuById(_id: $_id, record: $record) {
      payload {
        ...FullMenu
      }
      error {
        message
      }
    }
  }
  ${menu.full}
`;

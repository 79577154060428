import { ICellRendererParams } from 'ag-grid-community';
import { Icon, Tree } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { FullMenuFragment, MenuChildInput } from '~graphql/types';
import { AntTreeNodeProps } from 'antd/lib/tree';

export const renderMenuTree = (
  data: MenuChildInput[],
  TreeNodeCls = Tree.TreeNode,
  treeNodeProps: AntTreeNodeProps = {}
) => {
  return data.map(item => {
    const key = `${item.name}${item.route || ''}`;
    const icon = item.icon ? (
      <Icon type={item.icon} />
    ) : (
      <Icon type='folder' theme='twoTone' twoToneColor='#52c41a' />
    );
    const title = `${item.name} ${item.route ? `[${item.route}]` : ''}`;

    return (
      <TreeNodeCls {...treeNodeProps} key={key} icon={icon} title={title}>
        {item.children &&
          item.children.length &&
          renderMenuTree(item.children, TreeNodeCls, treeNodeProps)}
      </TreeNodeCls>
    );
  });
};

@observer
export default class GridMenuTreeCellRenderer extends Component<
  ICellRendererParams
> {
  public render() {
    const { data } = this.props;

    return (
      <Tree showIcon={true} defaultExpandAll={true} selectable={false}>
        {renderMenuTree((data as FullMenuFragment).menu)}
      </Tree>
    );
  }
}

import { Row, Tabs } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { TabName } from '~components/UI';
import { FullGoodsIssueFragment } from '~graphql/types';

import {
  AGREED,
  CANCELLED,
  CREATED,
  PERMISSION_GOODS_ISSUE_CREATE,
  WAITING_AGREEMENT,
} from '@mgn/common';

import GoodsIssueForm from './Form';
import GoodsIssueTable from './Grid';
import Header from './Hearder';
import store from './store';

const { TabPane } = Tabs;

interface IProps {
  data: FullGoodsIssueFragment;
}

@observer
class GoodsIssue extends Component<IProps> {
  public componentWillMount() {
    store.setNotification();
  }

  public onTabChange = activeKey => {
    store.setStatus(activeKey);
  };

  public render() {
    const { status, noti, toggleModal, setFilter, filter } = store;

    const tabs = (
      <Tabs onChange={this.onTabChange} activeKey={status}>
        <TabPane
          key={CREATED}
          tab={<TabName title='Mới' count={get(noti, 'createdTab', 0)} />}
        />
        <TabPane
          key={WAITING_AGREEMENT}
          tab={
            <TabName
              title='Chờ duyệt'
              count={get(noti, 'waitingAgreementTab', 0)}
            />
          }
        />
        <TabPane
          key={AGREED}
          tab={<TabName title='Đã duyệt' count={get(noti, 'agreedTab', 0)} />}
        />
        <TabPane
          key={CANCELLED}
          tab={<TabName title='Huỷ' count={get(noti, 'cancelledTab', 0)} />}
        />
        <TabPane
          key=''
          tab={<TabName title='Tất cả' count={get(noti, 'allTab', 0)} />}
        />
      </Tabs>
    );

    return (
      <Fragment>
        <Row
          type='flex'
          style={{
            flexFlow: 'column',
            height: '100vh',
          }}
        >
          <h2 style={{ marginTop: 8 }}>Danh sách phiếu xuất</h2>
          <Row>{tabs}</Row>
          <Header
            text='phiếu xuất'
            handeFilter={setFilter}
            toggleModal={toggleModal}
            permission={PERMISSION_GOODS_ISSUE_CREATE}
            status={status}
            filter={filter}
          />
          <div style={{ flex: 1, marginTop: 8 }}>
            <GoodsIssueTable />
          </div>
        </Row>
        <GoodsIssueForm />
      </Fragment>
    );
  }
}

export default GoodsIssue;

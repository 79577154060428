import { Col, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import store from './store';

@observer
export default class MenuFilter extends Component {
  public handleQuickFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { setQuickFilter } = store;
    setQuickFilter(e.target.value);
  };

  public render() {
    return (
      <Row>
        <Col
          span={24}
          md={{ span: 6 }}
          style={{ minWidth: 200, maxWidth: 400 }}
        >
          <Input
            type='text'
            onChange={this.handleQuickFilter}
            placeholder='Tìm kiếm...'
          />
        </Col>
      </Row>
    );
  }
}

import gql from 'graphql-tag';

import employee from '../fragments/employee';
import menu from '../fragments/menu';

export const ME_QUERY = gql`
  query Me {
    me {
      employee {
        ...FullEmployee
      }
      menu {
        ...FullMenu
      }
      permissionsMapping
      error {
        message
      }
    }
  }
  ${employee.full}
  ${menu.full}
`;

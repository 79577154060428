import gql from "graphql-tag";

import employee from "./employee";
import locationType from "./locationType";
import equipmentDetail from "./equipmentDetail";

const search = gql`
  fragment SearchLocation on Location {
    _id
    name
    locationType
    area
    typeCode
    type {
      ...FullLocationType
    }
    district
  }
  ${locationType.full}
`;

const basic = gql`
  fragment BasicLocation on Location {
    _id
    name
    locationType
    typeCode
    type {
      ...FullLocationType
    }
    address
    district
    area
    contact {
      phone
      name
      email
      position
    }
    maintainerId
    maintainer {
      ...FullEmployee
    }
    status
    createdAt
    updatedAt
  }
  ${locationType.full}
  ${employee.full}
`;

const detailed = gql`
  fragment DetailedLocation on Location {
    ...BasicLocation
    surveyorId
    surveyor {
      ...FullEmployee
    }
    installerId
    installer {
      ...FullEmployee
    }
    equipmentDetail {
      ...FullEquipmentDetail
    }
  }
  ${basic}
  ${equipmentDetail.full}
  ${employee.full}
`;

const equipment = gql`
  fragment EquipmentDetailLocation on Location {
    _id
    name
    equipmentDetail {
      ...FullEquipmentDetail
    }
  }
  ${equipmentDetail.full}
`;

const full = gql`
  fragment FullLocation on Location {
    ...DetailedLocation
    inspectionResult {
      area
      capacity
      description
      network {
        carrier
        speed
        note
      }
    }
    drawingPaths {
      id
      name
      url
    }
    picturePaths {
      id
      name
      url
    }
    recordPaths {
      id
      name
      url
    }
    createdEmployeeId
    createdEmployee {
      ...FullEmployee
    }
  }
  ${detailed}
  ${employee.full}
`;

export default { search, basic, detailed, equipment, full };

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import client from '~graphql/client';
import { FIND_MANY_EQUIPMENT_MODEL_QUERY } from '~graphql/queries';
import {
  FindManyEquipmentModelQuery,
  FindManyEquipmentModelVariables,
  FullEquipmentModelFragment,
} from '~graphql/types';

const { Option } = Select;

interface IProps extends SelectProps {
  equipmentCategoryId?: string;
  useCode?: boolean;
  all?: boolean;
}

@observer
export default class EquipmentModelDropdown extends Component<IProps> {
  @observable public loading: boolean;
  @observable public data: FullEquipmentModelFragment[];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.loading = true;
      this.data = [];
    });
  }

  @computed public get equipmentModels() {
    const { equipmentCategoryId } = this.props;
    return this.data.filter(item => {
      if (
        equipmentCategoryId &&
        item.equipmentCategoryId !== equipmentCategoryId
      ) {
        return false;
      }
      return true;
    });
  }

  public componentDidMount() {
    this.loadData().then(
      action('setLoading', () => {
        this.loading = false;
      })
    );
  }

  @action public loadData = async () => {
    this.loading = true;

    const { data } = await client.query<
      FindManyEquipmentModelQuery,
      FindManyEquipmentModelVariables
    >({
      query: FIND_MANY_EQUIPMENT_MODEL_QUERY,
    });

    runInAction(() => {
      this.data = data.findManyEquipmentModel;
    });
  };

  public render() {
    const {
      equipmentCategoryId,
      useCode = false,
      all = false,
      ...props
    } = this.props;

    return (
      <Select
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        allowClear={true}
        placeholder='Chọn model'
        style={{ width: '100%' }}
        {...props}
      >
        {all
          ? this.data.map(item => {
              return (
                <Option key={item._id} value={useCode ? item.code : item._id}>
                  {item.name}
                </Option>
              );
            })
          : this.equipmentModels.map(item => {
              return (
                <Option key={item._id} value={useCode ? item.code : item._id}>
                  {item.name}
                </Option>
              );
            })}
      </Select>
    );
  }
}

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import {
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsRequest
} from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import client from '~graphql/client';

import { IFindManyFilter, mapReduce, SortDirection } from '@mgn/common';

import AgGridTable from './AgGridTable';

export interface ISortModel {
  colId: string;
  sort: SortDirection;
}

interface IProps<TKey, TWhere> extends AgGridReactProps {
  query: any;
  queryKey: TKey;
  height?: number;
  where?: TWhere;
}

type QueryShape<TKey extends string> = { [key in TKey]: any[] };

@observer
export default class AgGridServer<
  TKey extends string,
  TWhere
> extends Component<IProps<TKey, TWhere>> {
  public reload = () => undefined;

  public componentWillReceiveProps(nextProps: IProps<TKey, TWhere>) {
    if (JSON.stringify(this.props.where) !== JSON.stringify(nextProps.where)) {
      this.reload();
    }
  }

  public loadData = async (request: IServerSideGetRowsRequest) => {
    const { query, queryKey, where = {} } = this.props;
    const { startRow, endRow, sortModel } = request;

    const order = mapReduce<ISortModel, 'colId', SortDirection>(
      sortModel,
      'colId',
      ({ sort }) => sort.toUpperCase() as any
    );

    const take = endRow - startRow;
    const { data } = await client.query<QueryShape<TKey>, IFindManyFilter>({
      query,
      variables: {
        take,
        where,
        order,
        skip: startRow,
      },
      fetchPolicy: 'network-only',
    });

    return data[queryKey];
  };

  public onGridReady = async (event: GridReadyEvent) => {
    const { api } = event;
    this.reload = () => {
      api.onFilterChanged();
    };
    const dataSource: IServerSideDatasource = {
      getRows: async ({ request, successCallback }) => {
        const { startRow, endRow } = request;
        const take = endRow - startRow;
        const data = await this.loadData(request);
        successCallback(
          data,
          data.length === take ? null : startRow + data.length
        );
      },
    };
    api.setServerSideDatasource(dataSource);

    const { onGridReady } = this.props;
    if (typeof onGridReady === 'function') {
      onGridReady(event);
    }
  };

  public render() {
    const { query, height = 300, ...gridOptions } = this.props;

    return (
      <AgGridTable
        height={height}
        rowModelType='serverSide'
        infiniteInitialRowCount={0}
        {...gridOptions}
        onGridReady={this.onGridReady}
      />
    );
  }
}

export { AgGridServer };

import React, { Component } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import { css } from 'emotion';
import classname from 'classname';

const whiteBoldText = css`
  border: solid 0.05em;
  width: fit-content;
  font-weight: bold;
  font-size: larger;
  color: white;
  margin: 0 0.5em;
`;

interface ICustomInput extends InputProps {
  color?: string;
  title?: string;
}

export default class CustomInput extends Component<ICustomInput> {
  public render() {
    // let color;
    // let textColor;

    // switch (this.props.color) {
    //   case 'red':
    //     color = 'linear-gradient(141deg, #ff5959 0%, #f72a2a 51%, #ff0000 75%)';
    //     textColor = '#ff0000';
    //     break;
    //   case 'green':
    //     color = 'linear-gradient(141deg, #08e1ae 0%, #008000 51%, #008000 75%)';
    //     textColor = '#008000';
    //     break;
    //   case 'yellow':
    //     color = ' linear-gradient(orange,#ff8c00)';
    //     textColor = '#ff8c00';
    //     break;
    //   default:
    //     color = 'linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%)';
    //     textColor = '#2cb5e8';
    // }

    // if (this.props.title === 'Đóng') {
    //   color = 'linear-gradient(141deg, #ff5959 0%, #f72a2a 51%, #ff0000 75%)';
    //   textColor = '#ff0000';
    // }

    // const buttonColor = css`
    //   ${whiteBoldText};
    //   z-index: 1;
    //   float: right;
    //   background: ${color};
    //   &:focus {
    //     background: ${color};
    //     color: white;
    //     border-color: white;
    //   }
    //   &:hover,
    //   &:active {
    //     background: white;
    //     color: ${textColor};
    //     border-color: ${textColor};
    //   }
    //   &[disabled] {
    //     background: #f5f5f5;
    //   }
    // `;
    const groupAddonWidth = css`
      .ant-input-group-addon {
        width: 10em !important;
      }
    `;
    const { className, ...props } = this.props;
    const newClassName = classname(groupAddonWidth, className, 'customInput');

    return <Input className={newClassName} {...props} />;
  }
}

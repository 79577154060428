import { ColDef, ColGroupDef } from 'ag-grid-community';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { from } from 'zen-observable';

import { AgGridTable, Loading } from '~components/UI';

import { GridCellActionRenderer } from './GridCellActionRenderer';
import { GridCellRoleRenderer } from './GridCellRoleRenderer';
import store from './store';

@observer
class RoleTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      maxWidth: 90,
      minWidth: 90,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      headerName: 'Tên hiển thị',
      field: 'display',
      minWidth: 200,
    },
    {
      headerName: 'Chức danh',
      field: 'name',
      minWidth: 220,
    },
    {
      headerName: 'Mô tả',
      field: 'description',
      suppressSorting: true,
      minWidth: 200,
    },
    {
      headerName: 'Danh sách quyền',
      field: 'permissions',
      minWidth: 400,
      cellClass: 'cell-wrap-text',
      suppressSorting: true,
      cellRendererFramework: GridCellRoleRenderer,
    },
  ];

  public getRowHeight = ({ data }) => {
    const roleData = get(data, 'permissions', []);
    const heightWithPadding = roleData.length * 17;
    const heightWhenNoData = 40;
    return Math.max(heightWhenNoData, heightWithPadding);
  };

  public render() {
    const { loading, role, quickFilter } = store;
    if (loading) return <Loading />;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height: number) => (
            <AgGridTable
              context={this}
              columnDefs={this.columnDefs}
              height={height - 8}
              rowData={role}
              quickFilterText={quickFilter}
              getRowHeight={this.getRowHeight}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}
export default RoleTable;

import gql from 'graphql-tag';
import employeeDevice from '../fragments/employeeDevice';

export const CREATE_EMPLOYEE_DEVICE_MUTATION = gql`
  mutation CreateEmployeeDevice($record: EmployeeDeviceInput!) {
    createEmployeeDevice(record: $record) {
      payload {
        ...FullEmployeeDevice
      }
      error {
        message
      }
    }
  }
  ${employeeDevice.full}
`;

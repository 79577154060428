import { Button, Modal, Row } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import * as Yup from 'yup';

import { UIField } from '~components/UI/UIField';
import { MenuChildInput, MenuInput } from '~graphql/types';

import MenuTree from './MenuTree';
import store from './store';
import { observable, runInAction } from 'mobx';
import { trimObject } from '~utils';

const menuItemSchema = Yup.object<MenuChildInput>({
  name: Yup.string().required('Bắt buộc nhập'),
  route: Yup.string().nullable(true),
  icon: Yup.string().nullable(true),
});
menuItemSchema.shape({
  children: Yup.array<MenuChildInput>(menuItemSchema).nullable(true),
});

const validationSchema = Yup.object<IFormValues>({
  name: Yup.string().required('Bắt buộc nhập'),
  menu: Yup.array<MenuChildInput>(menuItemSchema).min(1, 'Bắt buộc chọn'),
});

interface IFormValues extends MenuInput {
  _id?: string;
}

@observer
class MenuForm extends Component {
  @observable public isValid: boolean;

  constructor(props) {
    super(props);
    runInAction(() => {
      this.isValid = false;
    });
  }

  public render() {
    const { selectedItem, visible, handleClose, menuTree } = store;

    const initialValues = {
      _id: get(selectedItem, '_id'),
      name: get(selectedItem, 'name'),
      menu: menuTree,
    };

    const footer = (
      <Row type='flex' gutter={8} style={{ justifyContent: 'flex-end' }}>
        <Button
          type='primary'
          htmlType='submit'
          form='menuFm'
          disabled={!this.isValid}
        >
          {initialValues._id ? 'Cập nhật' : 'Tạo mới'}
        </Button>
        <Button type='danger' onClick={() => handleClose()}>
          Đóng
        </Button>
      </Row>
    );

    return (
      <Modal closable={false} visible={visible} footer={footer}>
        <Formik
          initialValues={initialValues}
          isInitialValid={validationSchema.isValidSync(initialValues)}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </Modal>
    );
  }

  public onSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm, setSubmitting } = actions;
    trimObject(values);

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  private renderForm = (props: FormikProps<IFormValues>) => {
    const { values, isValid, touched, setFieldValue, setFieldTouched } = props;

    runInAction(() => {
      this.isValid = isValid && (touched.name || !!touched.menu);
    });

    const onMenuChange = (value: MenuChildInput[]) => {
      setFieldValue('menu', value);
      setFieldTouched('menu', true);
    };

    return (
      <Fragment>
        <Form id='menuFm'>
          <Field
            name='name'
            component={UIField}
            label='Tên MENU'
            fieldProps={{
              placeholder: 'Nhập tên MENU',
            }}
          />
        </Form>
        <MenuTree value={values.menu} onChange={onMenuChange} />
      </Fragment>
    );
  };
}

export default MenuForm;

import { Button, Col, Row } from "antd";
import React, { Component, Fragment } from "react";

import Authorized from "~components/Authorized/Authorized";
import { LOCATION_TYPE } from "@mgn/common";

import WarehouseFilter from "./Filter";

interface IProps {
  text: string;
  handeFilter: (values) => void;
  toggleModal: () => void;
  permission: string;
  type: string;
}

export default class Header extends Component<IProps> {
  public render() {
    const { text, toggleModal, permission, type } = this.props;

    return (
      <Fragment>
        <Row gutter={8}>
          <Col md={8}>
            <WarehouseFilter />
          </Col>
          <Col md={{ span: 4, offset: 12 }}>
            {type !== LOCATION_TYPE && (
              <Authorized permission={permission}>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  icon="plus"
                  onClick={() => toggleModal()}
                >
                  {`Tạo ${text}`}
                </Button>
              </Authorized>
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

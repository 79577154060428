export const formatNumber = (
  value: number,
  digits: number = 2,
  delim: string = '.',
  separator: string = ','
) => {
  const s = value < 0 ? '-' : '';
  const n = Math.abs(Number(value) || 0).toFixed(digits);
  const i = parseInt(n, 10).toString();
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + separator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${separator}`) +
    (digits
      ? delim +
        Math.abs(value - +i)
          .toFixed(digits)
          .slice(2)
      : '')
  );
};

import React from 'react';
import { pick } from 'lodash';
import { TECHNICAL, CONTACT } from '@mgn/common';
import { ContactInput } from '~graphql/types';

const Contact = ({ contact }) =>
  contact
    ? contact.map((data, index) => <Person key={index} info={data} />)
    : '';

const Person = ({ info }) => {
  if (info.position === TECHNICAL) {
    info.position = 'Kỹ thuật viên';
  } else if (info.position === CONTACT) {
    info.position = 'Người liên hệ';
  } else {
    info.position = info.position;
  }
  const allowed = ['name', 'phone', 'position'];
  return (
    <span>
      {Object.values(pick(info, allowed))
        .filter(Boolean)
        .join(' | ')}
      <br />
    </span>
  );
};

export default Contact;

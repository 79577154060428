import client from '~graphql/client';
import { CountGoodsIssueQuery } from '~graphql/types';
import { COUNT_GOODSISSUE_QUERY } from '~graphql/queries/countGoodsIssue';

export const countGoodsIssue = async (status = null) => {
  const { data } = await client.query<CountGoodsIssueQuery>({
    query: COUNT_GOODSISSUE_QUERY,
    variables: { where: { status } },
    fetchPolicy: 'network-only',
  });
  const count = data.countGoodsIssue;
  return count;
};

import supplier from '../fragments/supplier';
import gql from 'graphql-tag';

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation CreateSupplier(
    $name: String!
    $code: String!
    $phone: String!
    $address: String!
    $email: String
  ) {
    createSupplier(
      record: {
        name: $name
        code: $code
        phone: $phone
        email: $email
        address: $address
      }
    ) {
      payload {
        ...FullSupplier
      }
      error {
        message
      }
    }
  }
  ${supplier.full}
`;

import gql from 'graphql-tag';
import equipmentCategory from './equipmentCategory';
import equipmentModel from './equipmentModel';
import location from './location';
import equipment from './equipment';

const basic = gql`
  fragment BasicEquipmentHistory on EquipmentHistory {
    _id
    equipmentModelCode
    serial
    equipmentId
    currentLocationId
    oldLocationId
    equipmentCategoryId
    goodsReceiptCode
    goodsIssueCode
    unitPrice
    createdAt
    updatedAt
  }
`;

const equipmentOnly = gql`
  fragment EquipmentOnlyEquipmentHistory on EquipmentHistory {
    ...BasicEquipmentHistory
    equipment {
      ...FullEquipment
    }
  }
  ${basic}
  ${equipment.full}
`;

const historyOnly = gql`
  fragment HistoryOnlyEquipmentHistory on EquipmentHistory {
    ...BasicEquipmentHistory
    currentLocation {
      ...BasicLocation
    }
    oldLocation {
      ...BasicLocation
    }
  }
  ${basic}
  ${location.basic}
`;

const full = gql`
  fragment FullEquipmentHistory on EquipmentHistory {
    ...BasicEquipmentHistory
    equipment {
      ...FullEquipment
    }
    equipmentCategory {
      ...FullEquipmentCategory
    }
    equipmentModel {
      ...BasicEquipmentModel
    }
    currentLocation {
      ...BasicLocation
    }
    oldLocation {
      ...BasicLocation
    }
  }
  ${basic}
  ${equipment.full}
  ${equipmentCategory.full}
  ${equipmentModel.basic}
  ${location.basic}
`;

export default { basic, equipmentOnly, historyOnly, full };

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { AgGridTable } from '~components/UI';
import { formatPrice } from '~utils';

import store from './store';

const gridStyle = css({
  '.ag-theme-material .ag-ltr': {
    '.ag-row-group-leaf-indent': {
      margin: 0,
    },
    '.ag-cell, .ag-header-cell, .ag-header-group-cell': {
      borderLeft: 'solid 1px #e0e0e0',
    },
    '.ag-floating-top-container .ag-cell': {
      borderBottom: 'solid 1px #e0e0e0',
    },
    '.ag-floating-bottom-container .ag-cell': {
      borderTop: 'solid 1px #e0e0e0',
    },
  },
});

@observer
class IOInventoryTable extends Component {
  private gridOptions = {
    autoGroupColumnDef: {
      headerName: 'Mã thiết bị',
      minWidth: 150,
      maxWidth: 150,
      cellRendererParams: {
        suppressCount: true,
      },
    },
    suppressAggFuncInHeader: true,
  };
  private excelStyles = [
    {
      id: 'twoDecimalPlaces',
      numberFormat: { format: '#,##0.00' },
    },
    {
      id: 'thousandsSeparator',
      numberFormat: { format: '###,##0,000' },
    },
  ];
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'TỔNG HỢP TỒN KHO',
      headerClass: 'center',
      children: [
        {
          headerName: 'Model',
          field: 'equipmentModelCode',
          rowGroup: true,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: 'Tên hàng',
          field: 'equipmentModel.name',
          minWidth: 300,
          aggFunc: values => values[0],
        },
        {
          headerName: 'Đơn vị',
          field: 'equipmentModel.unit',
          aggFunc: values => values[0],
          minWidth: 90,
          maxWidth: 90,
        },
        {
          headerName: 'Đầu kỳ',
          headerClass: 'center',
          children: [
            {
              headerName: 'Số lượng',
              field: 'openingAmount',
              cellClass: 'right twoDecimalPlaces',
              headerClass: 'right',
              aggFunc: 'sum',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: ({ value }) => (value !== 0 ? value : '-'),
            },
            {
              headerName: 'Giá trị',
              field: 'openingTotalPrice',
              cellClass: 'right thousandsSeparator',
              headerClass: 'right',
              aggFunc: 'sum',
              cellRenderer: ({ value }) =>
                value !== 0 ? formatPrice(value) : '-',
              minWidth: 120,
              maxWidth: 120,
            },
          ],
        },
        {
          headerName: 'Nhập mới',
          headerClass: 'center',
          columnGroupShow: 'open',
          children: [
            {
              headerName: 'Số lượng',
              field: 'newInAmount',
              cellClass: 'right twoDecimalPlaces',
              headerClass: 'right',
              aggFunc: 'sum',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: ({ value }) => (value !== 0 ? value : '-'),
            },
            {
              headerName: 'Giá trị',
              field: 'newInTotalPrice',
              cellClass: 'right thousandsSeparator',
              headerClass: 'right',
              aggFunc: 'sum',
              cellRenderer: ({ value }) =>
                value !== 0 ? formatPrice(value) : '-',
              minWidth: 120,
              maxWidth: 120,
            },
          ],
        },
        {
          headerName: 'Nhập từ kho khác',
          headerClass: 'center',
          columnGroupShow: 'open',
          children: [
            {
              headerName: 'Số lượng',
              field: 'changeInAmount',
              cellClass: 'right twoDecimalPlaces',
              headerClass: 'right',
              aggFunc: 'sum',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: ({ value }) => (value !== 0 ? value : '-'),
            },
            {
              headerName: 'Giá trị',
              field: 'changeInTotalPrice',
              headerClass: 'right',
              cellClass: 'right thousandsSeparator',
              aggFunc: 'sum',
              cellRenderer: ({ value }) =>
                value !== 0 ? formatPrice(value) : '-',
              minWidth: 120,
              maxWidth: 120,
            },
          ],
        },
        {
          headerName: 'Xuất',
          headerClass: 'center',
          columnGroupShow: 'open',
          children: [
            {
              headerName: 'Số lượng',
              field: 'outAmount',
              cellClass: 'right twoDecimalPlaces',
              aggFunc: 'sum',
              headerClass: 'right',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: ({ value }) => (value !== 0 ? value : '-'),
            },
            {
              headerName: 'Giá trị',
              field: 'outTotalPrice',
              headerClass: 'right',
              cellClass: 'right thousandsSeparator',
              aggFunc: 'sum',
              cellRenderer: ({ value }) =>
                value !== 0 ? formatPrice(value) : '-',
              minWidth: 120,
              maxWidth: 120,
            },
          ],
        },
        {
          headerName: 'Tồn cuối kỳ',
          headerClass: 'center',
          children: [
            {
              headerName: 'Số lượng',
              field: 'closingAmount',
              cellClass: 'right twoDecimalPlaces',
              headerClass: 'right',
              aggFunc: 'sum',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: ({ value }) => (value !== 0 ? value : '-'),
            },
            {
              headerName: 'Giá trị',
              field: 'closingTotalPrice',
              headerClass: 'right',
              cellClass: 'right thousandsSeparator',
              aggFunc: 'sum',
              cellRenderer: ({ value }) =>
                value !== 0 ? formatPrice(value) : '-',
              minWidth: 120,
              maxWidth: 120,
            },
          ],
        },
      ],
    },
  ];

  public render() {
    const { handleGridReady, getFooter, getHeader, reportInventory } = store;

    return (
      <div style={{ height: '100%' }} className={gridStyle}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height) => (
            <AgGridTable
              onGridReady={handleGridReady}
              gridOptions={this.gridOptions}
              columnDefs={this.columnDefs}
              autoGroupColumnDef={{
                headerName: 'Mã thiết bị',
                minWidth: 150,
              }}
              pinnedBottomRowData={getFooter}
              pinnedTopRowData={getHeader}
              height={height - 8}
              rowData={reportInventory}
              suppressAggFuncInHeader={true}
              pivotMode={true}
              excelStyles={this.excelStyles}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

export default IOInventoryTable;

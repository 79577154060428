import gql from 'graphql-tag';
import warehouse from '../fragments/warehouse';

export const UPDATE_CONTENT_WAREHOUSE_MUTATION = gql`
  mutation UpdateContentWarehouse(
    $_id: ID!
    $record: WarehouseContentInput!
  ) {
    updateContentWarehouse(
      _id: $_id
      record: $record
    ) {
      warehouse {
        ...FullWarehouse
      }
      error {
        message
      }
    }
  }
  ${warehouse.full}
`;

import { DatePicker } from 'antd';
import {
  RangePickerProps,
  RangePickerValue
} from 'antd/lib/date-picker/interface';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';

import { Without } from '~utils/types';

const { RangePicker } = DatePicker;
// tslint:disable-next-line
interface UIRangePickerProps
  extends Without<RangePickerProps, 'value' | 'onChange'> {
  value?: string[];
  onChange?: (values: string[]) => any;
}

export class UIRangePicker extends Component<UIRangePickerProps> {
  public render() {
    const { value, onChange, ...props } = this.props;

    const newValues = value
      ? value.map(date => {
          return date && moment(date);
        })
      : [];

    return (
      <RangePicker
        style={{ width: '100%' }}
        ranges={{
          'Hôm nay': [moment().startOf('day'), moment().endOf('day')],
          'Hôm qua': [
            moment()
              .startOf('day')
              .subtract(1, 'day'),
            moment()
              .endOf('day')
              .subtract(1, 'day'),
          ],
          'Tuần này': [moment().startOf('week'), moment().endOf('week')],
          'Tháng này': [moment().startOf('month'), moment().endOf('month')],
          'Tháng trước': [
            moment()
              .subtract(1, 'months')
              .startOf('month'),
            moment()
              .subtract(1, 'months')
              .endOf('month'),
          ],
        }}
        {...props}
        value={newValues as RangePickerValue}
        onChange={this.onChange}
      />
    );
  }

  private onChange = (dates: Moment[]) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      const newValues = dates
        ? dates.map(date => date && date.toISOString())
        : [];
      onChange(newValues);
    }
  };
}

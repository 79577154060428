import { ColDef, ColGroupDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { EmployeeInfo, LocationInfo } from '~components/UI';
import { AgGridServer } from '~components/UI/AgGridTable/AgGridServer';
import { GridCellDateRenderer } from '~components/UI/AgGridTable/CellDateRenderer';
import { GridCellStatusRenderer } from '~components/UI/AgGridTable/CellStatusRenderer';
import { FIND_MANY_PROPOSED_EQUIPMENT_QUERY } from '~graphql/queries';
import { ProposedEquipmentBaseFilter } from '~graphql/types';

import { GridCellActionRenderer } from './GridCellActionRenderer';
import { GridCellTypeProposedEquipmentRenderer } from './GridCellTypeRenderer';
import store from './store';

@observer
class ProposedEquipmentTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      field: '_id',
      maxWidth: 60,
      minWidth: 60,
      pinned: 'left',
      suppressSorting: true,
      cellRendererFramework: GridCellActionRenderer,
    },
    {
      colId: 'locationId',
      headerName: 'Tên địa điểm',
      field: 'location',
      minWidth: 380,
      cellRendererFramework: ({ value }) => <LocationInfo location={value} />,
    },
    {
      colId: 'createdEmployeeId',
      headerName: 'NV đề xuất',
      field: 'createdEmployee',
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: ({ value }) => <EmployeeInfo employee={value} />,
    },
    {
      headerName: 'Lý do',
      field: 'proposalReason',
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      suppressSorting: true,
      minWidth: 200,
    },
    {
      headerName: 'Loại đề xuất',
      field: 'type',
      minWidth: 130,
      maxWidth: 130,
      cellRendererFramework: GridCellTypeProposedEquipmentRenderer,
    },
    {
      headerName: 'Ngày cập nhật',
      field: 'updatedAt',
      minWidth: 150,
      maxWidth: 150,
      sort: 'desc',
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Ngày tạo',
      field: 'createdAt',
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Trạng thái',
      field: 'status',
      minWidth: 130,
      maxWidth: 130,
      cellRendererFramework: GridCellStatusRenderer,
    },
  ];

  public onQuickFilterText = event => {
    this.setState({ quickFilterText: event.target.value });
  };

  public render() {
    const { whereFilter, handleGridReady } = store;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height) => (
            <AgGridServer<
              'findManyProposedEquipment',
              ProposedEquipmentBaseFilter
            >
              height={height - 8}
              columnDefs={this.columnDefs}
              query={FIND_MANY_PROPOSED_EQUIPMENT_QUERY}
              queryKey='findManyProposedEquipment'
              where={whereFilter}
              onGridReady={handleGridReady}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

export default ProposedEquipmentTable;

import { ICellRendererParams } from "ag-grid-community";
import { Button, Popconfirm } from "antd";
import { get } from "lodash";
import { observer } from "mobx-react";
import React, { Component, Fragment } from "react";

import { isAuthorized } from "~components/Authorized/Authorized";
import { UIButton } from "~components/UI";
import {
  DELETED,
  PERMISSION_WAREHOUSE_REMOVE,
  PERMISSION_WAREHOUSE_UPDATE,
  WAREHOUSE_TYPE
} from "@mgn/common";

import store from "./store";

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const { toggleModal, handleDelete } = store;
    return (
      <Fragment>
        {get(data, "locationType") === WAREHOUSE_TYPE &&
          get(data, "status") !== DELETED && (
            <Fragment>
              <Button.Group>
                <UIButton
                  icon="edit"
                  size="small"
                  type="primary"
                  onClick={() => toggleModal(data)}
                  disabled={!isAuthorized(PERMISSION_WAREHOUSE_UPDATE)}
                />
                <Popconfirm
                  title="Xoá ?"
                  onConfirm={() => handleDelete(get(data, "_id"))}
                >
                  <UIButton
                    icon="delete"
                    size="small"
                    type="danger"
                    disabled={!isAuthorized(PERMISSION_WAREHOUSE_REMOVE)}
                  />
                </Popconfirm>
              </Button.Group>
            </Fragment>
          )}
      </Fragment>
    );
  }
}

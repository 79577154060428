import React, { Component } from 'react';
import { InputNumber } from 'antd';

const formatter = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const parser = (value: string) => {
  const v = value.replace(/[^\d]|(,*)/g, '');
  return v ? parseInt(v, 10) : 0;
};

export class UIInputNumber extends Component<InputNumber> {
  public render() {
    return (
      <InputNumber
        formatter={formatter}
        parser={parser}
        step='1000'
        min={0}
        style={{ width: '100%' }}
        {...this.props}
      />
    );
  }
}

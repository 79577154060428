import { ICellRendererParams } from 'ag-grid-community';
import { Component } from 'react';

import { formatStatus } from '~utils';

export class GridCellStatusRenderer extends Component<ICellRendererParams> {
  public render() {
    const { value } = this.props;

    return value ? formatStatus(value) : '';
  }
}

import gql from 'graphql-tag';

import goodsReceipt from '../fragments/goodsReceipt';

export const FIND_MANY_GOODS_RECEIPT_QUERY = gql`
  query FindManyGoodsReceipt(
    $skip: Int
    $take: Int
    $where: GoodsReceiptBaseFilter
    $order: GoodsReceiptBaseSort
  ) {
    findManyGoodsReceipt(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullGoodsReceipt
    }
  }
  ${goodsReceipt.full}
`;

export const FIND_ONE_GOODS_RECEIPT_QUERY = gql`
  query FindOneGoodsReceipt(
    $where: GoodsReceiptBaseFilter
    $order: GoodsReceiptBaseSort
  ) {
    findOneGoodsReceipt(where: $where, order: $order) {
      ...FullGoodsReceipt
    }
  }
  ${goodsReceipt.full}
`;

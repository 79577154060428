import { Button, Icon } from 'antd';
import classname from 'classname';
import { css } from 'emotion';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
import { flexCenterX, flexCenterY } from '~assets/styles/utils';
import { UIField } from '~components/UI/UIField';
import authStore from '~stores/authStore';
import { trimObject } from '~utils';

const styles = css`
  form {
    width: 40%;
    max-width: 300px;
    min-width: 250px;
    text-align: center;
  }

  .title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.75em;
    font-weight: bold;
    color: #2cb5e8;
  }
`;

interface IFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  email: Yup.string().required('Bắt buộc nhập'),

  password: Yup.string().required('Bắt buộc nhập'),
});

@observer
class LoginForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { setSubmitting } = actions;
    trimObject(values);

    const { email, password } = values;
    authStore.login({ email, password });

    setSubmitting(false);
  };

  public render() {
    const { isAuthenticated, flattedMenu } = authStore;

    if (isAuthenticated) {
      return <Redirect to={get(flattedMenu, '[0].route', '/info')} />;
    }

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting } = props;

      return (
        <div style={{ height: '100vh' }} className={flexCenterY}>
          <div className={classname(flexCenterX, styles)}>
            <Form id='loginFm'>
              <h1 className='title'>Quản lý nội bộ</h1>
              <Field
                name='email'
                component={UIField}
                fieldProps={{
                  placeholder: 'Email',
                  prefix: (
                    <Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                }}
              />
              <Field
                name='password'
                component={UIField}
                fieldProps={{
                  type: 'password',
                  placeholder: 'Mật khẩu',
                  prefix: (
                    <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                }}
              />
              <Button
                type='primary'
                htmlType='submit'
                form='loginFm'
                disabled={!isValid}
                icon='login'
                loading={isSubmitting}
              >
                Đăng nhập
              </Button>
            </Form>
          </div>
        </div>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default LoginForm;

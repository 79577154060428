import React, { Component, Fragment } from 'react';

import PermissionTable from './Grid';
import MenuMappingForm from './MenuMappingForm';
import MenuStore from './MenuStore';

class Permission extends Component {
  public componentWillMount() {
    MenuStore.init();
  }

  public render() {
    return (
      <Fragment>
        <PermissionTable />
        <MenuMappingForm />
      </Fragment>
    );
  }
}

export default Permission;

import gql from 'graphql-tag';
import proposedEquipment from '../fragments/proposedEquipment';

export const UPDATE_DETAIL_PROPOSED_EQUIPMENT_MUTATION = gql`
  mutation UpdateDetailProposedEquipment(
    $_id: ID!
    $type: TypeEnum!
    $equipmentDetail: [EquipmentDetailInput!]!
    $proposalReason: String!
    $materialDetail: String
  ) {
    updateDetailProposedEquipment(
      _id: $_id
      type: $type
      proposalReason: $proposalReason
      equipmentDetail: $equipmentDetail
      materialDetail: $materialDetail
    ) {
      proposedEquipment {
        ...FullProposedEquipment
      }
      error {
        message
      }
    }
  }
  ${proposedEquipment.full}
`;

import { action, computed, observable, runInAction } from 'mobx';

import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  CREATE_EMPLOYEE_MUTATION,
  REMOVE_EMPLOYEE_MUTATION,
  RESET_PASSWORD_EMPLOYEE,
  UPDATE_EMPLOYEE_MUTATION
} from '~graphql/mutations';
import { FIND_MANY_EMPLOYEE_QUERY } from '~graphql/queries';
import {
  CreateEmployeeMutation,
  CreateEmployeeVariables,
  EmployeeInput,
  FindManyEmployeeQuery,
  FullEmployeeFragment,
  RemoveEmployeeByIdMutation,
  RemoveEmployeeByIdVariables,
  ResetPasswordEmployeeMutation,
  ResetPasswordEmployeeVariables,
  UpdateEmployeeByIdMutation,
  UpdateEmployeeByIdVariables
} from '~graphql/types.d';
import { CREATED } from '@mgn/common';
import { removeTypename } from '~utils';

class EmployeeStore {
  @computed public get employee() {
    if (!this.status) return this.data;
    return this.data.filter(item => item.status === this.status);
  }

  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public status: string;
  @observable public selectedItem: FullEmployeeFragment;
  @observable public quickFilter: string;

  @observable.ref private data: FullEmployeeFragment[];
  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.visible = false;
      this.status = CREATED;
      this.selectedItem = null;
      this.quickFilter = null;
      this.data = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public handleDelete = async (_id: string) => {
    await client.mutate<
      RemoveEmployeeByIdMutation,
      RemoveEmployeeByIdVariables
    >({
      mutation: REMOVE_EMPLOYEE_MUTATION,
      variables: { _id },
      errorPolicy: 'all',
      update: (_cache, { data: { removeEmployeeById } }) => {
        const { error } = removeEmployeeById;
        if (error) {
          ErrorNoti(error.message);
        } else {
          SuccessNoti('Xóa nhân viên');
          this.updateData(_id);
        }
      },
    });
  };

  @action public handleCreate = async (record: CreateEmployeeVariables) => {
    const {
      data: { createEmployee },
    } = await client.mutate<CreateEmployeeMutation, CreateEmployeeVariables>({
      mutation: CREATE_EMPLOYEE_MUTATION,
      variables: record,
      errorPolicy: 'all',
    });

    const { error, employee } = createEmployee;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo nhân viên');
      this.updateData(null, employee);
      this.toggleModal();
    }
  };

  @action public handleUpdate = async (_id: string, record: EmployeeInput) => {
    const {
      data: { updateEmployeeById },
    } = await client.mutate<
      UpdateEmployeeByIdMutation,
      UpdateEmployeeByIdVariables
    >({
      mutation: UPDATE_EMPLOYEE_MUTATION,
      variables: { _id, record },
      errorPolicy: 'all',
    });

    const { error, payload } = updateEmployeeById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật nhân viên');
      this.updateData(_id, payload);
      this.toggleModal();
    }
  };

  @action public handleResetPassword = async (_id: string) => {
    const {
      data: { resetPasswordEmployee },
    } = await client.mutate<
      ResetPasswordEmployeeMutation,
      ResetPasswordEmployeeVariables
    >({ mutation: RESET_PASSWORD_EMPLOYEE, variables: { _id } });

    const { error, employee } = resetPasswordEmployee;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Đặt lại mật khẩu');
      this.updateData(_id, employee);
    }
  };

  @action public toggleModal = (item = null) => {
    this.selectedItem = item;
    this.visible = !this.visible;
  };

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action private fetchData = async () => {
    const {
      data: { findManyEmployee },
    } = await client.query<FindManyEmployeeQuery>({
      query: FIND_MANY_EMPLOYEE_QUERY,
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.data = removeTypename(findManyEmployee);
    });
  };

  @action private updateData = (_id: string, record?: FullEmployeeFragment) => {
    if (!_id) {
      this.data = this.data.concat(record);
    } else {
      const index = this.data.findIndex(item => item._id === _id);

      this.data = [
        ...this.data.slice(0, index),
        record,
        ...this.data.slice(index + 1),
      ].filter(Boolean);
    }
  };
}

export default new EmployeeStore();

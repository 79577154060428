import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Col, Input, Popover, Row } from 'antd';
import { get } from 'lodash';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { AgGridTable } from '~components/UI';
import { FullProposedEquipmentFragment } from '~graphql/types';

import store from './store';
import { divAsTable } from '~assets/styles/utils';
import { ellipsis } from '~assets/styles/typepo';

interface IProps {
  pe: FullProposedEquipmentFragment;
}

const PopoverContent = data => (
  <div className={divAsTable}>
    {data.serials && (
      <div>
        <div>Serials:</div>
        <div>{(data.serials || []).join(', ')}</div>
      </div>
    )}
    <div>
      <div>Ghi chú:</div>
      <div>{get(data, 'note')}</div>
    </div>
  </div>
);

@observer
class EquipmentDetail extends Component<IProps> {
  @observable public quickFilter: string;

  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Loại',
      field: 'equipmentModel.equipmentCategory.name',
      minWidth: 70,
      maxWidth: 70,
    },
    {
      headerName: 'Tên',
      field: 'equipmentModel.name',
      minWidth: 120,
      maxWidth: 120,
      cellRendererFramework: ({ value, data }) => {
        return (
          <Popover title={null} content={PopoverContent(data)}>
            <span
              className={ellipsis}
              style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}
            >
              {value}
            </span>
          </Popover>
        );
      },
    },
    {
      headerName: 'SL',
      field: 'quantity',
      minWidth: 60,
      maxWidth: 60,
    },
    {
      headerName: 'Xuất',
      field: 'issuedQuantity',
      minWidth: 60,
      maxWidth: 60,
    },
    {
      headerName: 'Thu',
      field: 'issuedRecovery',
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  constructor(props) {
    super(props);
    runInAction(() => {
      this.quickFilter = null;
    });
  }

  public onQuickFilterText = (event: ChangeEvent<HTMLInputElement>) => {
    store.setQuickFilter(event.target.value);
  };

  public render() {
    const { quickFilter } = store;
    const { pe } = this.props;
    return (
      <Row
        type='flex'
        style={{
          flexFlow: 'column',
          height: '100%',
        }}
      >
        <h2>Thiết bị yêu cầu</h2>
        <Row style={{ flex: 0 }}>
          <Col span={24} md={{ span: 6 }} style={{ maxWidth: 300 }}>
            <Input
              type='text'
              onChange={this.onQuickFilterText}
              placeholder='Tìm kiếm...'
            />
          </Col>
        </Row>
        <div style={{ flex: 1, marginTop: 8 }}>
          <ReactResizeDetector handleHeight={true}>
            {(_width, height) => (
              <AgGridTable
                height={height - 8}
                rowData={pe.equipmentDetail}
                columnDefs={this.columnDefs}
                quickFilterText={quickFilter}
              />
            )}
          </ReactResizeDetector>
        </div>
      </Row>
    );
  }
}

export default EquipmentDetail;

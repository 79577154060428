import gql from 'graphql-tag';

import employee from './employee';
import equipmentDetail from './equipmentDetail';
import location from './location';

const basic = gql`
  fragment BasicProposedEquipment on ProposedEquipment {
    _id
    locationId
    createdEmployeeId
    firstApproverId
    secondApproverId
    firstApprovedDate
    secondApprovedDate
    type
    status
    firstApprovedNote
    secondApprovedNote
    createdAt
    updatedAt
    proposalReason
    locationStatus
    materialDetail
  }
`;

const full = gql`
  fragment FullProposedEquipment on ProposedEquipment {
    ...BasicProposedEquipment
    location {
      ...DetailedLocation
    }
    createdEmployee {
      ...FullEmployee
    }
    firstApprover {
      ...FullEmployee
    }
    secondApprover {
      ...FullEmployee
    }
    equipmentDetail {
      ...FullEquipmentDetail
      serials
      issuedQuantity
      issuedRecovery
    }
  }
  ${basic}
  ${location.detailed}
  ${employee.full}
  ${equipmentDetail.full}
`;

export default { basic, full };

import { Row } from 'antd';
import { observer } from 'mobx-react';
import React, { ChangeEvent, Component, Fragment } from 'react';
import { Loading } from '~components/UI';
import Header from '~screens/Employee/Header';

import { PERMISSION_EQUIPMENT_CATEGORY_CREATE } from '@mgn/common';

import EquipmentCategoryForm from './Form';
import EquipmentCategoryTable from './Grid';
import store from './store';

@observer
class EquipmentCategory extends Component {
  public componentWillMount() {
    store.init();
  }

  public handleQuickFilter = (event: ChangeEvent<HTMLInputElement>) => {
    store.setQuickFilter(event.target.value);
  };

  public render() {
    const { toggleModal, loading, quickFilter } = store;

    if (loading) return <Loading />;

    return (
      <Fragment>
        <Row
          type='flex'
          style={{
            flexFlow: 'column',
            height: '100vh',
          }}
        >
          <Header
            text='loại thiết bị'
            filter={quickFilter}
            handeFilter={this.handleQuickFilter}
            toggleModal={toggleModal}
            permission={PERMISSION_EQUIPMENT_CATEGORY_CREATE}
          />
          <div style={{ flex: 1, marginTop: 8 }}>
            <EquipmentCategoryTable />
          </div>
        </Row>
        <EquipmentCategoryForm />
      </Fragment>
    );
  }
}
export default EquipmentCategory;

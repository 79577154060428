import { ICellRendererParams } from 'ag-grid-community';
import { get } from 'lodash';
import React, { Component } from 'react';

import { LocationInfo } from '~components/UI';

export class GridCellContentRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;

    if (!get(data, 'oldLocationId')) {
      return (
        <span>
          Nhập mới vào <LocationInfo location={get(data, 'currentLocation')} />.
        </span>
      );
    }

    return (
      <span>
        Xuất từ <LocationInfo location={get(data, 'oldLocation')} /> sang{' '}
        <LocationInfo location={get(data, 'currentLocation')} />.
      </span>
    );
  }
}

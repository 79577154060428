import { get } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { ErrorNoti, InfoNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  ADD_EQUIPMENT_TEMP_GOODS_RECEIPT_MUTATION,
  CONFIRM_FROM_MANAGER_GOODS_RECEIPT,
  PASS_TO_MANAGER_GOODS_RECEIPT,
  REMOVE_EQUIPMENT_TEMP_GOODS_RECEIPT_MUTATION
} from '~graphql/mutations';
import {
  FIND_MANY_EQUIPMENT_GOODS_RI_QUERY,
  FIND_MANY_EQUIPMENT_HISTORY_EQUIPMENT_ONLY_QUERY,
  FIND_MANY_EQUIPMENT_QUERY,
  FIND_MANY_EQUIPMENT_TEMP_QUERY,
  FIND_ONE_GOODS_RECEIPT_QUERY
} from '~graphql/queries';
import {
  AddEquipmentTempGoodsReceiptMutation,
  AddEquipmentTempGoodsReceiptVariables,
  ConfirmEnum,
  ConfirmFromManagerGoodsReceiptMutation,
  ConfirmFromManagerGoodsReceiptVariables,
  FindEquipmentByGoodsRiCodeQuery,
  FindEquipmentByGoodsRiCodeVariables,
  FindManyEquipmentHistoryEquipmentOnlyQuery,
  FindManyEquipmentHistoryEquipmentOnlyVariables,
  FindManyEquipmentQuery,
  FindManyEquipmentTempQuery,
  FindManyEquipmentTempVariables,
  FindManyEquipmentVariables,
  FindOneGoodsReceiptQuery,
  FindOneGoodsReceiptVariables,
  FullEquipmentTempFragment,
  FullGoodsReceiptFragment,
  FullProposedEquipmentFragment,
  PassToManagerGoodsReceiptMutation,
  PassToManagerGoodsReceiptVariables,
  RemoveEquipmentTempGoodsReceiptMutation,
  RemoveEquipmentTempGoodsReceiptVariables,
  SharedEquipmentFragment
} from '~graphql/types';

import { AGREED, CANCELLED, mapReduce } from '@mgn/common';

export interface IFormValues {
  serial: string;
  equipmentModelCode: string;
  supplierCode: string;
  unitPrice: number;
  warranty: number;
  note?: string;
}

interface IEquipmentPreTemp extends IFormValues {
  error?: string;
}

class GoodsReceiptDetailStore {
  @observable public loading: boolean;
  @observable public preTempVisible: boolean;
  @observable public confirmVisible: boolean;
  @observable.ref public selectedItem: FullGoodsReceiptFragment;
  @observable public selectedPe: FullProposedEquipmentFragment;
  @observable.shallow
  public equipments: SharedEquipmentFragment[];
  @observable
  public equipmentTemps: FullEquipmentTempFragment[];
  @observable.shallow
  public equipmentPreTemps: IEquipmentPreTemp[];
  @computed
  private get equipmentPreTempSerials() {
    return this.equipmentPreTemps.reduce((all, item) => {
      all[item.serial] = true;
      return all;
    }, {});
  }

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.preTempVisible = false;
      this.confirmVisible = false;
      this.selectedItem = null;
      this.selectedPe = null;
      this.equipments = [];
      this.equipmentTemps = [];
      this.equipmentPreTemps = [];
    });
  }

  @action public setCode(code: string) {
    this.loading = true;
    this.selectedItem = null;
    this.equipments = [];
    this.equipmentTemps = [];
    this.equipmentPreTemps = [];

    this.fetchData(code).then(
      action(() => {
        this.loading = false;
      })
    );
  }

  @action public toggleForm = () => {
    this.preTempVisible = !this.preTempVisible;
  };

  @action public toggleConfirm = () => {
    this.confirmVisible = !this.confirmVisible;
  };

  @action public handleAddPreTemps = async (...equipments: IFormValues[]) => {
    const newSerials = equipments.filter(({ serial }) => {
      return !this.equipmentPreTempSerials[serial];
    });
    if (!newSerials.length) {
      return InfoNoti('SERIAL đã được thêm');
    }

    this.equipmentPreTemps = this.equipmentPreTemps.concat(newSerials);
  };
  @action public handleAddPreTempSerials = async (...serials: string[]) => {
    const newSerials = serials.filter(serial => {
      return !this.equipmentPreTempSerials[serial];
    });
    if (!newSerials.length) {
      return InfoNoti('SERIAL đã được thêm');
    }

    const {
      data: { findManyEquipment },
    } = await client.query<FindManyEquipmentQuery, FindManyEquipmentVariables>({
      query: FIND_MANY_EQUIPMENT_QUERY,
      variables: {
        where: {
          locationId: this.selectedItem.fromLocationId,
          _operators: { serial: { in: newSerials } },
        },
      },
    });

    if (findManyEquipment.length) {
      runInAction(() => {
        this.equipmentPreTemps = this.equipmentPreTemps.concat(
          findManyEquipment
        );
      });
    } else {
      return ErrorNoti('Không tìm thấy SERIAL nào trong kho');
    }
  };

  @action public handleDeletePreTemps = async (serial: string) => {
    this.equipmentPreTemps = this.equipmentPreTemps.filter(
      item => item.serial !== serial
    );
  };

  @action public handleCreateEquipmentTemps = async () => {
    const createEquipmentTemps = this.equipmentPreTemps.map(
      ({
        serial,
        equipmentModelCode,
        supplierCode,
        unitPrice,
        warranty,
        note,
      }) => ({
        serial,
        equipmentModelCode,
        supplierCode,
        unitPrice,
        warranty,
        note,
      })
    );

    const { errors } = await client.mutate<
      AddEquipmentTempGoodsReceiptMutation,
      AddEquipmentTempGoodsReceiptVariables
    >({
      mutation: ADD_EQUIPMENT_TEMP_GOODS_RECEIPT_MUTATION,
      variables: {
        goodsReceiptCode: this.selectedItem.code,
        equipmentTemps: createEquipmentTemps,
      },
      errorPolicy: 'all',
      update: (_cache, { data: { addEquipmentTempGoodsReceipt } }) => {
        const {
          error,
          equipmentTemps,
          invalidEquipmentTemps,
        } = addEquipmentTempGoodsReceipt;

        if (error) {
          return ErrorNoti(error.message);
        }

        const invalidEquipmentTempSerials = mapReduce(
          invalidEquipmentTemps,
          'serial',
          ({ message }) => message
        );

        if (equipmentTemps.length) {
          this.fetchEquipmentTemp(this.selectedItem.code);
        }

        runInAction(() => {
          this.equipmentPreTemps = this.equipmentPreTemps
            .filter(item => invalidEquipmentTempSerials[item.serial])
            .map(item => ({
              ...item,
              error: invalidEquipmentTempSerials[item.serial],
            }));

          if (this.equipmentPreTemps.length) {
            ErrorNoti(`Không thể nhập ${this.equipmentPreTemps.length} SERIAL`);
          } else {
            SuccessNoti('Tạo thiết bị vào phiếu');
            this.toggleForm();
          }
        });
      },
    });

    if (errors) {
      ErrorNoti(errors[0].message);
    }
  };

  @action public handleDeleteEquipmentTemp = async (_id: string) => {
    const { errors } = await client.mutate<
      RemoveEquipmentTempGoodsReceiptMutation,
      RemoveEquipmentTempGoodsReceiptVariables
    >({
      mutation: REMOVE_EQUIPMENT_TEMP_GOODS_RECEIPT_MUTATION,
      variables: { _id },
      update: (_cache, { data: { removeEquipmentTempGoodsReceipt } }) => {
        const { error } = removeEquipmentTempGoodsReceipt;

        if (error) {
          ErrorNoti(error.message);
        } else {
          SuccessNoti('Xóa thiết bị');
          this.updateEquipmentTemp(_id);
        }
      },
    });

    if (errors) {
      ErrorNoti(errors[0].message);
    }
  };

  @action public handlePassToManager = async () => {
    await client.mutate<
      PassToManagerGoodsReceiptMutation,
      PassToManagerGoodsReceiptVariables
    >({
      mutation: PASS_TO_MANAGER_GOODS_RECEIPT,
      variables: { goodsReceiptCode: this.selectedItem.code },
      update: (_cache, { data: { passToManagerGoodsReceipt } }) => {
        const { error, goodsReceipt } = passToManagerGoodsReceipt;

        if (error) {
          ErrorNoti(error.message);
        } else {
          runInAction(() => {
            this.selectedItem = goodsReceipt;
          });

          SuccessNoti('Trình duyệt');
        }
      },
    });
  };

  @action public handleConfirmFromManager = async (
    confirm: ConfirmEnum,
    note: string
  ) => {
    const {
      data: { confirmFromManagerGoodsReceipt },
    } = await client.mutate<
      ConfirmFromManagerGoodsReceiptMutation,
      ConfirmFromManagerGoodsReceiptVariables
    >({
      mutation: CONFIRM_FROM_MANAGER_GOODS_RECEIPT,
      variables: { confirm, note, goodsReceiptCode: this.selectedItem.code },
    });

    const { error, goodsReceipt } = confirmFromManagerGoodsReceipt;
    if (error) {
      ErrorNoti(error.message);
    } else {
      runInAction(() => {
        this.selectedItem = goodsReceipt;
      });
      if (goodsReceipt.status === AGREED || goodsReceipt.status === CANCELLED) {
        this.fetchEquipmentHistory(goodsReceipt.code);
      }
      this.toggleConfirm();
      SuccessNoti('Duyệt');
    }
  };

  @action private async fetchData(code: string) {
    this.loading = true;
    const {
      data: { findOneGoodsReceipt },
    } = await client.query<
      FindOneGoodsReceiptQuery,
      FindOneGoodsReceiptVariables
    >({
      query: FIND_ONE_GOODS_RECEIPT_QUERY,
      fetchPolicy: 'network-only',
      variables: { where: { code } },
    });

    runInAction(() => {
      this.selectedItem = findOneGoodsReceipt;
    });

    get(findOneGoodsReceipt, 'status') === AGREED ||
    get(findOneGoodsReceipt, 'status') === CANCELLED
      ? this.fetchEquipmentHistory(code)
      : this.fetchEquipmentTemp(code);
  }

  @action private async fetchEquipmentTemp(code: string) {
    const {
      data: { findManyEquipmentTemp },
    } = await client.query<
      FindManyEquipmentTempQuery,
      FindManyEquipmentTempVariables
    >({
      query: FIND_MANY_EQUIPMENT_TEMP_QUERY,
      fetchPolicy: 'network-only',
      variables: { where: { code } },
    });

    runInAction(() => {
      this.equipmentTemps = findManyEquipmentTemp || [];
    });
  }

  // @action private async fetchEquipmentHistory(code: string) {
  //   const {
  //     data: { findManyEquipmentHistory },
  //   } = await client.query<
  //     FindManyEquipmentHistoryEquipmentOnlyQuery,
  //     FindManyEquipmentHistoryEquipmentOnlyVariables
  //   >({
  //     query: FIND_MANY_EQUIPMENT_HISTORY_EQUIPMENT_ONLY_QUERY,
  //     fetchPolicy: 'network-only',
  //     variables: { where: { goodsReceiptCode: code } },
  //   });

  //   runInAction(() => {
  //     this.equipments = (findManyEquipmentHistory || []).map(
  //       item => item.equipment
  //     );
  //   });
  // }

  @action private async fetchEquipmentHistory(code: string) {
    const {
      data: { findEquipmentByGoodsRICode },
    } = await client.query<
      FindEquipmentByGoodsRiCodeQuery,
      FindEquipmentByGoodsRiCodeVariables
    >({
      query: FIND_MANY_EQUIPMENT_GOODS_RI_QUERY,
      fetchPolicy: 'network-only',
      variables: { code },
    });

    runInAction(() => {
      this.equipments = findEquipmentByGoodsRICode || [];
    });
  }

  @action private updateEquipmentTemp = (
    _id: string,
    record?: FullEquipmentTempFragment
  ) => {
    if (!_id) {
      this.equipmentTemps.push(record);
    } else {
      const index = this.equipmentTemps.findIndex(item => item._id === _id);
      if (!record) {
        this.equipmentTemps.splice(index, 1);
      } else {
        this.equipmentTemps.splice(index, 1, record);
      }
    }
  };
}

export default new GoodsReceiptDetailStore();

import gql from 'graphql-tag';
import locationType from '../fragments/locationType';

export const FIND_MANY_LOCATION_TYPE_QUERY = gql`
  query FindManyLocationType(
    $skip: Int
    $take: Int
    $where: LocationTypeBaseFilter
    $order: LocationTypeBaseSort
  ) {
    findManyLocationType(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      ...FullLocationType
    }
  }
  ${locationType.full}
`;

import proposedEquipment from '../fragments/proposedEquipment';
import gql from 'graphql-tag';

export const REMOVE_PROPOSED_EQUIPMENT_MUTATION = gql`
  mutation RemoveProposedEquipment($_id: ID!) {
    removeProposedEquipment(_id: $_id) {
      proposedEquipment {
        ...BasicProposedEquipment
      }
      error {
        message
      }
    }
  }
  ${proposedEquipment.basic}
`;

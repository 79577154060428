import gql from 'graphql-tag';

import equipmentCategory from './equipmentCategory';
import equipmentModel from './equipmentModel';
import location from './location';
import supplier from './supplier';

const common = gql`
  fragment CommonEquipment on Equipment {
    _id
    serial
    status
    equipmentModelId
    equipmentModelCode
    equipmentCategoryId
    supplierCode
    locationId
    unitPrice
    warranty
    note
    createdAt
    updatedAt
  }
`;

const resolved = gql`
  fragment ResolvedEquipment on Equipment {
    equipmentCategory {
      ...FullEquipmentCategory
    }
    equipmentModel {
      ...BasicEquipmentModel
    }
    location {
      ...BasicLocation
    }
    supplier {
      ...BasicSupplier
    }
  }
  ${equipmentCategory.full}
  ${equipmentModel.basic}
  ${location.basic}
  ${supplier.basic}
`;

const shared = gql`
  fragment SharedEquipment on Equipment {
    ...CommonEquipment
    ...ResolvedEquipment
  }
  ${common}
  ${resolved}
`;

const basic = gql`
  fragment BasicEquipment on Equipment {
    ...CommonEquipment
    goodsReceiptCode
    issueInvoiceDay
    goodsIssueCode
  }
  ${common}
`;

const full = gql`
  fragment FullEquipment on Equipment {
    ...BasicEquipment
    ...ResolvedEquipment
  }
  ${basic}
  ${resolved}
`;

export default { common, shared, basic, full };

import { action, computed, observable, runInAction } from 'mobx';

import { ErrorNoti, SuccessNoti } from '~components/UI';
import client from '~graphql/client';
import {
  REMOVE_EQUIPMENT_CATEGORY_BY_ID_MUTATION,
  CREATE_EQUIPMENT_CATEGORY_MUTATION,
  UPDATE_EQUIPMENT_CATEGORY_BY_ID_MUTATION
} from '~graphql/mutations';
import { FIND_MANY_EQUIPMENT_CATEGORY_QUERY } from '~graphql/queries';
import {
  FullEquipmentCategoryFragment,
  RemoveEquipmentCategoryByIdMutation,
  RemoveEquipmentCategoryByIdVariables,
  CreateEquipmentCategoryVariables,
  CreateEquipmentCategoryMutation,
  FindManyEquipmentCategoryQuery,
  EquipmentCategoryInput,
  UpdateEquipmentCategoryByIdMutation,
  UpdateEquipmentCategoryByIdVariables
} from '~graphql/types.d';
import { CREATED } from '@mgn/common';
import { removeTypename } from '~utils';

class EquipmentCategoryStore {
  @computed public get equipmentCategory() {
    return this.data;
  }

  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public status: string;
  @observable public selectedItem: FullEquipmentCategoryFragment;
  @observable public quickFilter: string;

  @observable.ref private data: FullEquipmentCategoryFragment[];
  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.visible = false;
      this.status = CREATED;
      this.selectedItem = null;
      this.quickFilter = null;
      this.data = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchData().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @action public handleDelete = async (_id: string) => {
    await client.mutate<
      RemoveEquipmentCategoryByIdMutation,
      RemoveEquipmentCategoryByIdVariables
    >({
      mutation: REMOVE_EQUIPMENT_CATEGORY_BY_ID_MUTATION,
      variables: { _id },
      errorPolicy: 'all',
      update: (_cache, { data: { removeEquipmentCategoryById } }) => {
        const { error } = removeEquipmentCategoryById;
        if (error) {
          ErrorNoti(error.message);
        } else {
          SuccessNoti('Xóa loại thiết bị');
          this.updateData(_id);
        }
      },
    });
  };

  @action public handleCreate = async (
    record: CreateEquipmentCategoryVariables
  ) => {
    const {
      data: { createEquipmentCategory },
    } = await client.mutate<
      CreateEquipmentCategoryMutation,
      CreateEquipmentCategoryVariables
    >({
      mutation: CREATE_EQUIPMENT_CATEGORY_MUTATION,
      variables: record,
      errorPolicy: 'all',
    });

    const { error, payload } = createEquipmentCategory;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Tạo loại thiết bị');
      this.updateData(null, payload);
      this.toggleModal();
    }
  };

  @action public handleUpdate = async (
    _id: string,
    record: EquipmentCategoryInput
  ) => {
    const {
      data: { updateEquipmentCategoryById },
    } = await client.mutate<
      UpdateEquipmentCategoryByIdMutation,
      UpdateEquipmentCategoryByIdVariables
    >({
      mutation: UPDATE_EQUIPMENT_CATEGORY_BY_ID_MUTATION,
      variables: { _id, record },
      errorPolicy: 'all',
    });

    const { error, payload } = updateEquipmentCategoryById;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti('Cập nhật loại thiết bị');
      this.updateData(_id, payload);
      this.toggleModal();
    }
  };

  @action public toggleModal = (item = null) => {
    this.selectedItem = item;
    this.visible = !this.visible;
  };

  @action public setQuickFilter = filter => {
    this.quickFilter = filter;
  };

  @action private fetchData = async () => {
    const {
      data: { findManyEquipmentCategory },
    } = await client.query<FindManyEquipmentCategoryQuery>({
      query: FIND_MANY_EQUIPMENT_CATEGORY_QUERY,
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.data = removeTypename(findManyEquipmentCategory);
    });
  };

  @action private updateData = (
    _id: string,
    record?: FullEquipmentCategoryFragment
  ) => {
    // debugger;
    if (!_id) {
      this.data = this.data.concat(record);
    } else {
      const index = this.data.findIndex(item => item._id === _id);

      this.data = [
        ...this.data.slice(0, index),
        record,
        ...this.data.slice(index + 1),
      ].filter(Boolean);
    }
  };
}

export default new EquipmentCategoryStore();

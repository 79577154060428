import client from '~graphql/client';

const createMutation = (record, mutation, ...query) => {
  return client.mutate({
    mutation,
    variables: { record },
    refetchQueries: { ...query },
    errorPolicy: 'all',
  });
};

const deleteMutation = (_id, mutation, query) => {
  return client.mutate({
    mutation,
    variables: { _id },
    refetchQueries: [{ query }],
    errorPolicy: 'all',
  });
};

const updateMutation = (record, mutation, query = null) => {
  return client.mutate({
    mutation,
    variables: { ...record },
    refetchQueries: [{ query }],
    errorPolicy: 'all',
  });
};

const updateMutationWithQueryFilter = (props, record, mutation, query) => {
  return props.client.mutate({
    mutation,
    variables: { ...record },
    refetchQueries: [{ query }],
    errorPolicy: 'all',
  });
};

export {
  createMutation,
  deleteMutation,
  updateMutation,
  updateMutationWithQueryFilter,
};

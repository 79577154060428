import gql from 'graphql-tag';
import location from '../fragments/location';

export const REMOVE_BEFORE_AGREED_LOCATION_MUTATION = gql`
  mutation RemoveBeforeAgreedLocation($_id: ID!) {
    removeBeforeAgreedLocation(_id: $_id) {
      location {
        ...BasicLocation
      }
      error {
        message
      }
    }
  }
  ${location.basic}
`;

import React from 'react';
import { Col } from 'antd';

const Image = ({ name, url }) => (
  <Col md={{ span: 6 }} sm={{ span: 8 }} xs={{ span: 12 }}>
    <a target='_blank' rel='noopener noreferrer' href={url}>
      <img alt={url} src={url} height={128} width={128} />
      <p style={{ textAlign: 'center' }}>{name}</p>
    </a>
  </Col>
);

export default Image;

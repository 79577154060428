import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import Authorized from '~components/Authorized/Authorized';
import GoodsRIFilter, { FilterValue } from '~screens/GoodsIssue/Filter';

import { CREATED } from '@mgn/common';

interface IProps {
  text: string;
  handeFilter: (values) => void;
  toggleModal: () => void;
  permission: string;
  status: string;
  filter: FilterValue;
}

export default class Header extends Component<IProps> {
  public render() {
    const {
      text,
      handeFilter,
      toggleModal,
      permission,
      status,
      filter,
    } = this.props;

    return (
      <Row type='flex'>
        <Col style={{ flex: 1 }}>
          <GoodsRIFilter onChange={handeFilter} filter={filter} />
        </Col>
        <Col style={{ flex: 0 }}>
          {status === CREATED && (
            <Authorized permission={permission}>
              <Button type='primary' icon='plus' onClick={() => toggleModal()}>
                {`Tạo ${text}`}
              </Button>
            </Authorized>
          )}
        </Col>
      </Row>
    );
  }
}

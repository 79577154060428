import gql from 'graphql-tag';
import employee from './employee';
import menu from './menu';

export const basic = gql`
  fragment BasicMenuMapping on MenuMapping {
    _id
    employeeId
    menuId
    createdAt
    updatedAt
  }
`;

export const full = gql`
  fragment FullMenuMapping on MenuMapping {
    ...BasicMenuMapping
    employee {
      ...FullEmployee
    }
    menu {
      ...FullMenu
    }
  }
  ${basic}
  ${employee.full}
  ${menu.full}
`;

export default { basic, full };

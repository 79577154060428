import gql from 'graphql-tag';
import locationType from '../fragments/locationType';

export const UPDATE_LOCATION_TYPE_BY_ID_MUTATION = gql`
  mutation UpdateLocationTypeById($_id: ID!, $record: LocationTypeInput!) {
    updateLocationTypeById(_id: $_id, record: $record) {
      payload {
        ...FullLocationType
      }
      error {
        message
      }
    }
  }
  ${locationType.full}
`;

import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { Without } from '~utils/types';

// tslint:disable-next-line
interface UIDatePickerProps
  extends Without<DatePickerProps, 'value' | 'onChange'> {
  value?: string | number | Date | Moment;
  onChange?: (value: string) => any;
}

export class UIDatePicker extends Component<UIDatePickerProps> {
  public render() {
    const { value, onChange, ...props } = this.props;

    return (
      <DatePicker
        {...props}
        value={value && moment(value)}
        onChange={this.onChange}
      />
    );
  }

  private onChange = (date?: Moment) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(date && date.toISOString());
    }
  };
}

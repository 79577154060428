import { action, computed, flow, observable } from 'mobx';

import { ErrorNoti } from '~components/UI';
import apolloClient from '~graphql/client';
import { LOGIN_MUTATION } from '~graphql/mutations';
import { ME_QUERY } from '~graphql/queries';
import {
  FullEmployeeFragment,
  FullMenuFragment,
  LoginMutation,
  MeQuery
} from '~graphql/types';
import { IMenuItem, PERMISSION_SUPERADMIN } from '@mgn/common';

import { LoginVariables } from '../graphql/types.d';
import commonStore from './commonStore';

export class AuthStore {
  @observable public inProgress: boolean = false;
  @observable public isAuthenticated: boolean = false;
  @observable public currentEmployee: FullEmployeeFragment = null;
  @observable public menu: FullMenuFragment = null;
  @observable public permissions: {
    [permission: string]: string[];
  } = {};
  @observable public authError: string = null;

  @computed public get flattedMenu(): IMenuItem[] {
    if (!this.menu) return [];
    return this.menu.menu.reduce((all, m) => {
      const { name, route, icon, children } = m;
      if (route) {
        all.push({ name, route, icon });
      } else if (Array.isArray(children)) {
        all.push(...children);
      }
      return all;
    }, []);
  }

  @computed public get isSuperadmin(): boolean {
    return this.isAuthenticated && PERMISSION_SUPERADMIN in this.permissions;
  }

  public login = flow(function*(this: AuthStore, { email, password }) {
    this.authError = null;
    this.inProgress = true;

    const {
      data: { login },
    } = yield apolloClient
      .mutate<LoginMutation, LoginVariables>({
        mutation: LOGIN_MUTATION,
        variables: { email, password },
      })
      .then(i => i);

    const { error, authToken } = login;

    if (error) {
      ErrorNoti('Email hoặc mật khẩu không đúng!');
      this.authError = error;
    } else {
      commonStore.setToken(authToken);
      this.updateCurrentEmployee();
    }

    this.inProgress = false;
  });

  public updateCurrentEmployee = flow(function*(this: AuthStore) {
    this.inProgress = true;

    const {
      data: { me },
    } = yield apolloClient.query<MeQuery>({ query: ME_QUERY });
    const { error, employee, menu, permissionsMapping } = me;

    this.inProgress = false;
    if (error) {
      this.logout();
    } else {
      this.isAuthenticated = true;
      this.currentEmployee = employee;
      this.menu = menu;
      this.permissions = permissionsMapping;
    }
  });

  @action
  public logout() {
    commonStore.setToken(null);
    this.isAuthenticated = false;
    this.currentEmployee = null;
    this.menu = null;
    this.permissions = null;

    window.location.reload();
  }
}

export default new AuthStore();

import { action, observable, runInAction } from 'mobx';

class EquipmentViewDrawerStore {
  @observable public proposedEquipmentHistoryVisible: boolean;
  @observable public proposedEquipmentDrawerVisible: boolean;
  @observable public equipmentModalVisible: boolean;
  @observable public locationId: string;
  @observable public categoryIdFilter: string;
  @observable public modelIdFilter: string;

  constructor() {
    runInAction(() => {
      this.proposedEquipmentHistoryVisible = false;
      this.proposedEquipmentDrawerVisible = false;
      this.equipmentModalVisible = false;
      this.locationId = null;
      this.categoryIdFilter = null;
      this.modelIdFilter = null;
    });
  }

  @action public toggleProposedEquipmentHistory = () => {
    this.proposedEquipmentHistoryVisible = !this
      .proposedEquipmentHistoryVisible;
  };

  @action public toggleProposedEquipmentDrawer = () => {
    this.proposedEquipmentDrawerVisible = !this.proposedEquipmentDrawerVisible;
  };

  @action public toggleEquipmentModal = (filter = null) => {
    if (filter) {
      const { categoryIdFilter = null, modelIdFilter = null } = filter;

      this.categoryIdFilter = categoryIdFilter;
      this.modelIdFilter = modelIdFilter;
    }

    this.equipmentModalVisible = !this.equipmentModalVisible;
  };
}

export default new EquipmentViewDrawerStore();

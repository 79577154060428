import gql from 'graphql-tag';
import material from '../fragments/material';

export const CREATE_MATERIAL_MUTATION = gql`
  mutation CreateMaterial($record: MaterialInput!) {
    createMaterial(record: $record) {
      payload {
        ...FullMaterial
      }
      error {
        message
      }
    }
  }
  ${material.full}
`;

import { Button, Modal, Row, Select } from "antd";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { get } from "lodash";
import { observer } from "mobx-react";
import React, { Component } from "react";
import * as Yup from "yup";
import LocationDropdown from "~components/Dropdown/LocationDropdown";
import RoleDropdown from "~components/Dropdown/RoleDropdown";
import { UIField } from "~components/UI/UIField";

import { ALL_LOCATION, WAREHOUSE_TYPE } from "@mgn/common";

import { RoleStore } from "./RoleStore";

const { Option } = Select;

interface IFormValues {
  locationId: any[];
  roleId: string;
  employeeId: string;
}
const validationSchema = Yup.object().shape<IFormValues>({
  locationId: Yup.array().required("Bắt buộc chọn"),

  roleId: Yup.string().required("Bắt buộc chọn"),

  employeeId: Yup.string()
});

interface IProps {
  store: RoleStore;
  roleId: string;
  employeeId: string;
  visible: boolean;
}

@observer
class RoleMappingForm extends Component<IProps> {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleRoleMappingCreate } = this.props.store;
    const { resetForm } = actions;

    const { ...record } = values;

    await handleRoleMappingCreate(record);
    resetForm();
  };

  public render() {
    const { employeeId, store, visible, roleId } = this.props;
    const { selectedRoleMapping, toggleModal } = store;

    const initialValues: IFormValues = {
      employeeId,
      locationId: get(selectedRoleMapping, "locationId"),
      roleId: get(selectedRoleMapping, "roleId")
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const {
        isValid,
        isSubmitting,
        submitForm,
        values,
        setFieldValue
      } = props;

      const onChange = () => {
        setFieldValue("locationId", []);
      };
      const footer = (
        <Row>
          <Button
            type="primary"
            disabled={!isValid}
            loading={isSubmitting}
            onClick={submitForm}
          >
            {selectedRoleMapping ? "Cập nhật" : "Tạo mới"}
          </Button>
          <Button type="danger" onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form>
            <Field
              label="Danh sách chức danh"
              name="roleId"
              fieldProps={{
                onChange,
                roleId,
                disabled: selectedRoleMapping && true,
                placeholder: "Chọn chức danh"
              }}
              component={UIField}
              fieldComponent={RoleDropdown}
              nativeInput={false}
            />
            {["FREEWIFI", "VIEWER"].some(item =>
              get(values, "roleId", "").includes(item)
            ) ? (
              <Field
                label="Danh sách địa điểm"
                name="locationId"
                component={UIField}
                fieldComponent={Select}
                fieldProps={{
                  mode: "multiple",
                  children: <Option value={ALL_LOCATION}>Tất cả điểm</Option>,
                  placeholder: "Chọn địa điểm quản lý"
                }}
                nativeInput={false}
              />
            ) : (
              <Field
                label="Danh sách địa điểm"
                name="locationId"
                component={UIField}
                fieldComponent={LocationDropdown}
                fieldProps={{
                  mode: "multiple",
                  locationType: WAREHOUSE_TYPE,
                  allLocation: true,
                  placeholder: "Chọn địa điểm quản lý"
                }}
                nativeInput={false}
              />
            )}
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default RoleMappingForm;

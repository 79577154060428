import { Card, Col, Popover, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { DescriptionItem } from '~components/UI';
import { EmployeeInfo, LocationInfo } from '~components/UI/';
import { formatPrice, formatStatus, formatTime } from '~utils';

import store from './store';

const layoutProps = {
  span: 24,
  md: { span: 6 },
};

const layoutProps2 = {
  span: 24,
  md: { span: 12 },
};

@observer
export default class GoodsIssueCardDetail extends Component {
  public render() {
    const { selectedItem: goodsIssue } = store;

    return (
      <Card size='small' style={{ marginTop: 8 }}>
        <Row>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Mã phiếu xuất'
              content={get(goodsIssue, 'code')}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Trạng thái'
              content={formatStatus(get(goodsIssue, 'status'))}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Người tạo'
              content={
                <EmployeeInfo employee={get(goodsIssue, 'createdEmployee')} />}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Ngày tạo'
              content={formatTime(get(goodsIssue, 'createdAt'))}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Kho nguồn'
              content={
                <LocationInfo location={get(goodsIssue, 'fromLocation')} />}
            />
          </Col>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Kho đích'
              content={
                <LocationInfo location={get(goodsIssue, 'toLocation')} />}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Tổng số lượng (thiết bị)'
              content={get(goodsIssue, 'totalQuantity')}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Tổng giá trị (VNĐ)'
              content={formatPrice(get(goodsIssue, 'totalPrice'))}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Người duyệt'
              content={<EmployeeInfo employee={get(goodsIssue, 'approver')} />}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Ngày duyệt'
              content={formatTime(get(goodsIssue, 'approvedAt'))}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Mô tả'
              content={get(goodsIssue, 'description')}
            />
          </Col>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Ghi chú'
              content={get(goodsIssue, 'note')}
            />
          </Col>
        </Row>
        <Row>
          <DescriptionItem
            title='Yêu cầu vật tư'
            content={
              <Popover
                title={null}
                content={
                  <pre>
                    {get(goodsIssue, 'proposedEquipment.materialDetail')}
                  </pre>
                }
              >
                <span style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}>
                  Xem chi tiết
                </span>
              </Popover>
            }
          />
        </Row>
      </Card>
    );
  }
}

export { GoodsIssueCardDetail };

import { observer } from 'mobx-react';
import React, { Component } from 'react';
import StackTrace from 'stacktrace-js/dist/stacktrace';
import client from '~graphql/client';
import { CREATE_ERROR_LOG_MUTATION } from '~graphql/mutations';
import {
  CreateErrorLogMutation,
  CreateErrorLogVariables,
} from '~graphql/types';
import authStore from '~stores/authStore';

interface IState {
  hasError: boolean;
}

@observer
class ErrorBoundary extends Component<any, IState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public handleErrorLogging = async errorLog => {
    await client.mutate<CreateErrorLogMutation, CreateErrorLogVariables>({
      mutation: CREATE_ERROR_LOG_MUTATION,
      variables: {
        record: {
          employeeId: authStore.currentEmployee._id,
          error: errorLog,
        },
      },
      errorPolicy: 'all',
    });
  };

  public componentDidCatch(error) {
    this.setState({
      hasError: true,
    });

    const callback = stackframes => {
      const stringifiedStack = stackframes.map(sf => sf.toString()).join('\n');
      this.handleErrorLogging(stringifiedStack);
    };

    const errback = err => {
      console.info(err.message);
    };

    StackTrace.fromError(error)
      .then(callback)
      .catch(errback);
  }
  public render() {
    if (this.state.hasError) {
      return <h2>Lỗi.</h2>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

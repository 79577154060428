import { Card, Col, Row } from 'antd';
import { get } from 'lodash';
import React, { Component } from 'react';
import { DescriptionItem, LocationInfo } from '~components/UI';
import { FullEquipmentFragment } from '~graphql/types';
import { formatPrice, formatTime } from '~utils';

interface IProps {
  data: FullEquipmentFragment;
}

export default class CardEquipmentHistory extends Component<IProps> {
  public render() {
    const { data: equipment } = this.props;

    return (
      <Card style={{ marginTop: 8 }}>
        <Row>
          <Col span={8}>
            <DescriptionItem
              title='Serial'
              content={get(equipment, 'serial', 'Chưa có')}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Loại thiết bị'
              content={get(equipment, 'equipmentCategory.name', 'Chưa có')}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Model'
              content={get(equipment, 'equipmentModel.name', 'Chưa có')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem
              title='Ngày nhập'
              content={formatTime(get(equipment, 'createdAt'))}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Nhà cung cấp'
              content={get(equipment, 'supplier.name', 'Chưa có')}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Đơn giá (VNĐ)'
              content={formatPrice(get(equipment, 'unitPrice', ''))}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem
              title='Thời gian bảo hành'
              content={`${get(equipment, 'warranty') || 0} Tháng`}
            />
          </Col>
          <Col span={16}>
            <DescriptionItem
              title='Kho hiện tại'
              content={<LocationInfo location={get(equipment, 'location')} />}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
export { CardEquipmentHistory };

import gql from 'graphql-tag';
import equipmentCategory from '../fragments/equipmentCategory';

export const REMOVE_EQUIPMENT_CATEGORY_BY_ID_MUTATION = gql`
  mutation RemoveEquipmentCategoryById($_id: ID!) {
    removeEquipmentCategoryById(_id: $_id) {
      payload {
        ...FullEquipmentCategory
      }
      error {
        message
      }
    }
  }
  ${equipmentCategory.full}
`;

import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import store from './store';
import {
  CREATED,
  GOODSRECEIPT,
  PERMISSION_GOODS_RECEIPT_UPDATE,
  PERMISSION_GOODS_ISSUE_UPDATE,
  PERMISSION_GOODS_RECEIPT_REMOVE,
  PERMISSION_GOODS_ISSUE_REMOVE
} from '@mgn/common';
import { UIButton } from '~components/UI';
import { isAuthorized } from '~components/Authorized/Authorized';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public render() {
    const { data } = this.props;
    const { toggleModal, handleDelete } = store;
    const status = get(data, 'status');
    const type = get(data, 'type');
    const code = get(data, 'code');
    return (
      status === CREATED && (
        <Fragment>
          <Button.Group>
            <UIButton
              onClick={() => toggleModal(data)}
              icon='edit'
              type='primary'
              disabled={
                !isAuthorized(
                  type === GOODSRECEIPT
                    ? PERMISSION_GOODS_RECEIPT_UPDATE
                    : PERMISSION_GOODS_ISSUE_UPDATE,
                  type === GOODSRECEIPT
                    ? get(data, 'toLocationId')
                    : get(data, 'fromLocationId')
                )
              }
            />
            <Popconfirm title='Xóa?' onConfirm={() => handleDelete(code, type)}>
              <UIButton
                icon='delete'
                type='danger'
                disabled={
                  !isAuthorized(
                    type === GOODSRECEIPT
                      ? PERMISSION_GOODS_RECEIPT_REMOVE
                      : PERMISSION_GOODS_ISSUE_REMOVE,
                    type === GOODSRECEIPT
                      ? get(data, 'toLocationId')
                      : get(data, 'fromLocationId')
                  )
                }
              />
            </Popconfirm>
          </Button.Group>
        </Fragment>
      )
    );
  }
}

import { Card, Col, Icon, Popover, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { divAsTable } from '~assets/styles/utils';
import {
  Contact,
  DescriptionItem,
  EmployeeInfo,
  LocationInfo,
} from '~components/UI';
import { formatStatus, formatTime } from '~utils';

import store from './store';

const approvedContent = params => {
  return (
    <div className={divAsTable}>
      <div>
        <div>Thời gian duyệt:</div>
        <div>{params.date}</div>
      </div>
      <div>
        <div>Ghi chú:</div>
        <div>{params.note}</div>
      </div>
    </div>
  );
};

@observer
export default class ProposedEquipmentCardDetail extends Component {
  public render() {
    const { selectedItem: pe } = store;

    return (
      <Card style={{ marginTop: 8 }}>
        <Row>
          <Col span={16}>
            <Row>
              <DescriptionItem
                title='Tên điểm'
                content={<LocationInfo location={get(pe, 'location')} />}
              />
            </Row>
            <Row>
              <DescriptionItem
                title='Yêu cầu vật tư'
                content={
                  <Popover
                    title={null}
                    content={<pre>{get(pe, 'materialDetail')}</pre>}
                  >
                    <span
                      style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}
                    >
                      Xem chi tiết
                    </span>
                  </Popover>
                }
              />
            </Row>
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Người liên hệ'
              content={<Contact contact={get(pe, 'location.contact')} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem
              title='Lý do đề xuất'
              content={get(pe, 'proposalReason')}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Free-Wifi duyệt'
              content={
                get(pe, 'firstApproverId') && (
                  <span style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}>
                    <EmployeeInfo employee={get(pe, 'firstApprover')} />
                    <Popover
                      content={approvedContent({
                        date: formatTime(get(pe, 'firstApprovedDate')),
                        note: get(pe, 'firstApprovedNote'),
                      })}
                    >
                      <Icon type='info-circle' style={{ marginLeft: 8 }} />
                    </Popover>
                  </span>
                )
              }
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Kho duyệt'
              content={
                get(pe, 'secondApproverId') && (
                  <span style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}>
                    <EmployeeInfo employee={get(pe, 'secondApprover')} />
                    <Popover
                      content={approvedContent({
                        date: formatTime(get(pe, 'secondApprovedDate')),
                        note: get(pe, 'secondApprovedNote'),
                      })}
                    >
                      <Icon type='info-circle' style={{ marginLeft: 8 }} />
                    </Popover>
                  </span>
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem
              title='Người tạo'
              content={<EmployeeInfo employee={get(pe, 'createdEmployee')} />}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Ngày tạo'
              content={formatTime(get(pe, 'createdAt'))}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title='Trạng thái'
              content={formatStatus(get(pe, 'status'))}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

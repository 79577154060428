import { Card, Col, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import UserInfo from './UserInfo';
import store from './store';
import UpdatePhoneForm from './UpdatePhoneForm';
import UpdatePasswordForm from './UpdatePasswordForm';

const { Option } = Select;

@observer
class Info extends Component {
  public render() {
    const { handleSelect, type } = store;

    return (
      <Row>
        <Col lg={{ span: 10, offset: 6 }} sm={{ span: 14, offset: 5 }}>
          <Card
            title='Thông tin cá nhân'
            style={{ marginTop: '1em' }}
            headStyle={{ textAlign: 'center' }}
          >
            <div style={{ width: 300, margin: 'auto' }}>
              <UserInfo />

              <div style={{ margin: '1em auto', textAlign: 'center' }}>
                <Select
                  style={{ width: 200 }}
                  onChange={handleSelect}
                  placeholder='Cập nhật thông tin'
                >
                  <Option value='phone'>Cập nhật SĐT</Option>
                  <Option value='password'>Cập nhật mật khẩu</Option>
                </Select>
              </div>

              {type && (
                <Fragment>
                  {type === 'phone' ? (
                    <UpdatePhoneForm />
                  ) : (
                    <UpdatePasswordForm />
                  )}
                </Fragment>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Info;

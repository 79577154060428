import { Popover } from 'antd';
import React, { FunctionComponent } from 'react';

import { ellipsis } from '~assets/styles/typepo';
import { divAsTable } from '~assets/styles/utils';
import { FullEmployeeFragment } from '~graphql/types';

interface IProps {
  employee: FullEmployeeFragment;
}

const EmployeeInfo: FunctionComponent<IProps> = props => {
  const { employee } = props;
  if (!employee) return null;

  const content = (
    <div className={divAsTable}>
      <div>
        <div>Họ tên:</div>
        <div>{employee.fullName}</div>
      </div>
      <div>
        <div>Điện thoại:</div>
        <div>
          <a href={`tel:${employee.phone}`}>{employee.phone}</a>
        </div>
      </div>
      <div>
        <div>Email:</div>
        <div>
          <a href={`mailto:${employee.email}`}>{employee.email}</a>
        </div>
      </div>
    </div>
  );

  return (
    <Popover title={null} content={content}>
      <span
        className={ellipsis}
        style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}
      >
        {employee.fullName}
      </span>
    </Popover>
  );
};

export default EmployeeInfo;

import { Button, Col, Input, InputNumber, Popconfirm, Row } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { SelectProps } from 'antd/lib/select';
import { get } from 'lodash';
import React, { Component, Fragment } from 'react';
import { UIButton } from '~components/UI';
import { FullProposedEquipmentFragment } from '~graphql/types';

import EquipmentDropdown from './EquipmentDropdown';
import { TypeEnum } from './ProposedEquipmentDrawer';

interface IPropsCreateEquipmentInput extends FormComponentProps {
  alreadyProposedEquipmentModelId: string[];
  handleOK: (e: any) => void;
}

export class CreateEquipmentInput extends Component<
  IPropsCreateEquipmentInput
> {
  public render() {
    const { form, handleOK } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <Row gutter={8}>
          <Col md={16}>
            <EquipmentDropdown
              form={form}
              formField='equipment'
              alreadyProposedEquipmentModelId={
                this.props.alreadyProposedEquipmentModelId
              }
            />
          </Col>
          <Col md={8}>
            {getFieldDecorator('quantity')(
              <InputNumber
                placeholder='Số lượng'
                min={0}
                step={1}
                style={{ width: '100%' }}
              />
            )}
          </Col>
        </Row>
        <Row gutter={8}>
          <Col md={16}>
            {getFieldDecorator('note')(
              <Input.TextArea
                placeholder='Ghi chú'
                autosize={{ minRows: 1, maxRows: 2 }}
                style={{ marginTop: '0.5em' }}
              />
            )}
          </Col>
          <Col md={8}>
            <UIButton
              type='primary'
              onClick={handleOK}
              style={{ margin: '0.5em 0 0 0', width: '100%' }}
            >
              Thêm
            </UIButton>
          </Col>
        </Row>
      </Form>
    );
  }
}

interface IPropsMaterials extends FormComponentProps {
  currentForm: FullProposedEquipmentFragment | null;
  prevFormNotCompleted: boolean;
}

export class CreateMaterialDetail extends Component<IPropsMaterials, any> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      form: { getFieldDecorator },
      currentForm,
      prevFormNotCompleted,
    } = this.props;
    return (
      !prevFormNotCompleted && (
        <FormItem>
          {getFieldDecorator('materialDetail', {
            initialValue: currentForm ? currentForm.materialDetail : null,
          })(
            <Input.TextArea
              placeholder='Yêu cầu vật tư'
              autosize={{ minRows: 2, maxRows: 6 }}
            />
          )}
        </FormItem>
      )
    );
  }
}

interface IPropsFormType extends FormComponentProps {
  currentForm: FullProposedEquipmentFragment | null;
  prevFormNotCompleted: boolean;
  currentFormType: TypeEnum;
}

export class CreateFormTypeAndReasonInput extends Component<
  IPropsFormType,
  any
> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      form: { getFieldDecorator },
      currentFormType,
      currentForm,
      prevFormNotCompleted,
    } = this.props;
    return prevFormNotCompleted ? (
      <h3>
        Không được tạo phiếu đề xuất mới vì có phiếu đề xuất cũ chưa hoàn thành
      </h3>
    ) : (
      <Fragment>
        <Row gutter={8}>
          {currentFormType && (
            <Col md={8}>
              <Input
                addonBefore={'Loại'}
                disabled={true}
                style={{ width: '100%' }}
                defaultValue={
                  currentFormType === TypeEnum.AddEquipment
                    ? 'Xuất thêm'
                    : 'Thay đổi'
                }
              />
            </Col>
          )}
          <Col md={16}>
            <FormItem>
              {getFieldDecorator('proposalReason', {
                rules: [
                  {
                    required: true,
                    message: 'Phải nhập lý do!',
                  },
                ],
                initialValue: currentForm ? currentForm.proposalReason : null,
              })(
                <Input
                  addonBefore='Lý do'
                  placeholder='Nhập lý do tạo đề xuất thiết bị'
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

interface IPropsFooter extends SelectProps {
  currentForm: FullProposedEquipmentFragment | null;
  prevFormNotCompleted: boolean;
  currentFormType: TypeEnum;
  closeProposedEquipment: () => void;
  isFormEmpty: boolean;
  handleOK: (e: any) => void;
  handleDelete: (_id: any) => Promise<void>;
  handlePassToManager: () => Promise<void>;
}

export class CreateFooter extends Component<IPropsFooter, any> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      currentFormType,
      currentForm,
      prevFormNotCompleted,
      closeProposedEquipment,
      handleOK,
      isFormEmpty,
      handleDelete,
      handlePassToManager,
    } = this.props;
    return (
      <div className='drawer-footer'>
        {currentFormType && (
          <Row
            type='flex'
            gutter={8}
            style={{
              justifyContent: 'flex-end',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Col>
              <Popconfirm
                title='Xóa đề xuất?'
                okText='Xóa'
                cancelText='Không'
                onConfirm={() => handleDelete(get(currentForm, '_id'))}
              >
                <UIButton type='danger' disabled={!get(currentForm, '_id')}>
                  Xóa 
                </UIButton>
              </Popconfirm>
            </Col>
            <Col>
              <Popconfirm
                title='Trình duyệt đề xuất?'
                okText='Trình duyệt'
                cancelText='Không'
                onConfirm={handlePassToManager}
              >
                <UIButton type='warning' disabled={isFormEmpty}>
                  Trình duyệt
                </UIButton>
              </Popconfirm>
            </Col>
            <Col>
              <UIButton
                type='success'
                onClick={handleOK}
                disabled={prevFormNotCompleted}
              >
                Lưu
              </UIButton>
            </Col>
            <Col>
              <Button type='danger' onClick={() => closeProposedEquipment()}>
                Đóng
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

import gql from 'graphql-tag';
import goodsReceipt from '../fragments/goodsReceipt';

export const CREATE_GOODS_RECEIPT_MUTATION = gql`
  mutation CreateGoodsReceipt(
    $fromLocationId: String
    $toLocationId: String!
    $proposedEquipmentId: String
    $description: String
    $issueInvoiceDay: DateTime
  ) {
    createGoodsReceipt(
      fromLocationId: $fromLocationId
      toLocationId: $toLocationId
      proposedEquipmentId: $proposedEquipmentId
      description: $description
      issueInvoiceDay: $issueInvoiceDay
    ) {
      goodsReceipt {
        ...FullGoodsReceipt
      }
      error {
        message
      }
    }
  }
  ${goodsReceipt.full}
`;

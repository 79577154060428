import gql from 'graphql-tag';
import menuMapping from '../fragments/menuMapping';

export const UPDATE_MENU_MAPPING_MUTATION = gql`
  mutation UpdateMenuMapping($_id: ID!, $menuId: String!) {
    updateMenuMapping(_id: $_id, menuId: $menuId) {
      menuMapping {
        ...FullMenuMapping
      }
      error {
        message
      }
    }
  }
  ${menuMapping.full}
`;

import { Button, Col, Icon, Input, Modal, Row, Select } from "antd";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { get } from "lodash";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import * as Yup from "yup";
import LocationDropdown from "~components/Dropdown/LocationDropdown";
import { UIField } from "~components/UI/UIField";
import { CreateGoodsIssueVariables } from "~graphql/types";

import { GOODSISSUE, GOODSRECEIPT, WAREHOUSE_TYPE } from "@mgn/common";

import store from "./store";

const { TextArea } = Input;
const { Option } = Select;

interface IProps {
  visible: boolean;
}

interface IFormValues extends CreateGoodsIssueVariables {
  code: string;
  type?: string;
  locationName?: string;
  fromLocationNane?: string;
  toLocationName?: string;
}

const validationSchema = Yup.object().shape<IFormValues>({
  code: Yup.string().nullable(true),
  fromLocationId: Yup.string().required("Bắt buộc chọn"),
  toLocationId: Yup.string().required("Bắt buộc chọn")
});

@observer
class GoodsReceiptIssueForm extends Component<IProps> {
  @observable public type: string;
  @action public handleTypeChange = value => {
    this.type = value;
  };

  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const {
      handleCreateGoodsIssue,
      handleUpdateGoodsIssue,
      handleUpdateGoodsReceipt,
      handleCreateGoodsReceipt
    } = store;
    const { resetForm } = actions;

    const { code, type, locationName, ...record } = values;

    if (type === GOODSRECEIPT) {
      code
        ? await handleUpdateGoodsReceipt(code, record)
        : await handleCreateGoodsReceipt(record);
    } else {
      code
        ? await handleUpdateGoodsIssue(code, record)
        : await handleCreateGoodsIssue(record);
    }

    resetForm();
  };

  public render() {
    const { selectedGRI, selectedItem } = store;
    const initialValues: IFormValues = {
      code: get(selectedGRI, "code"),
      type: get(selectedGRI, "type"),
      description: get(selectedGRI, "description"),
      fromLocationId: get(selectedGRI, "fromLocationId"),
      toLocationId: get(selectedGRI, "toLocationId"),
      fromLocationNane: get(selectedGRI, "fromLocation.name"),
      toLocationName: get(selectedGRI, "toLocation.name"),
      proposedEquipmentId: get(selectedItem, "_id"),
      locationName: get(selectedItem, "location.name")
    };

    if (!initialValues.code) {
      initialValues.type = this.type;
      const locationField =
        initialValues.type === GOODSRECEIPT ? "fromLocationId" : "toLocationId";
      initialValues[locationField] = get(selectedItem, "locationId");
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }

  private renderForm = (props: FormikProps<IFormValues>) => {
    const { visible } = this.props;
    const {
      toggleModal,
      canAddGoodsIssue,
      canAddGoodsReceipt,
      selectedGRI
    } = store;
    const { isValid, isSubmitting, values } = props;
    const { type } = values;

    const footer = (
      <Row>
        <Button
          type="primary"
          htmlType="submit"
          form="gRIFm"
          disabled={!isValid}
          loading={isSubmitting}
        >
          {values.code ? "Cập nhật" : "Tạo mới"}
        </Button>

        <Button type="danger" onClick={() => toggleModal()}>
          Đóng
        </Button>
      </Row>
    );

    const options = [
      {
        key: GOODSISSUE,
        value: GOODSISSUE,
        disabled: !canAddGoodsIssue,
        children: "Phiếu xuất"
      },
      {
        key: GOODSRECEIPT,
        value: GOODSRECEIPT,
        disabled: !canAddGoodsReceipt,
        children: "Phiếu nhập"
      }
    ];

    return (
      <Modal closable={false} visible={visible} footer={footer}>
        <Form id="gRIFm">
          <Row>
            <Col>
              <Field
                name="type"
                component={UIField}
                nativeInput={false}
                fieldComponent={Select}
                fieldProps={{
                  style: { width: "100%" },
                  placeholder: "Loại phiếu",
                  onChange: this.handleTypeChange,
                  children: options.map(({ key, ...prop }) => (
                    <Option key={key} {...prop} />
                  )),
                  disabled: Boolean(selectedGRI && get(selectedGRI, "code"))
                }}
              />
            </Col>
          </Row>
          {type && (
            <Fragment>
              <Row gutter={8} type="flex">
                <Col span={24} md={{ span: 10 }}>
                  {type === GOODSRECEIPT ? (
                    <Field
                      label="Kho nguồn"
                      name="locationName"
                      component={UIField}
                      nativeInput={false}
                      fieldProps={{
                        disabled: true
                      }}
                    />
                  ) : (
                    <Field
                      name="fromLocationId"
                      label="Kho nguồn"
                      component={UIField}
                      fieldComponent={LocationDropdown}
                      fieldProps={{
                        locationType: WAREHOUSE_TYPE,
                        disabled: Boolean(get(selectedGRI, "_id")),
                        placeholder: "Chọn kho nguồn"
                      }}
                      nativeInput={false}
                    />
                  )}
                </Col>
                <Col
                  span={4}
                  style={{
                    alignSelf: "center",
                    fontSize: "2em",
                    textAlign: "center"
                  }}
                >
                  <Icon type="arrow-right" />
                </Col>
                <Col span={24} md={{ span: 10 }}>
                  {type === GOODSISSUE ? (
                    <Field
                      label="Kho đích"
                      name="locationName"
                      component={UIField}
                      nativeInput={false}
                      fieldProps={{
                        disabled: true
                      }}
                    />
                  ) : (
                    <Field
                      name="toLocationId"
                      label="Kho đích"
                      fieldComponent={LocationDropdown}
                      fieldProps={{
                        locationType: WAREHOUSE_TYPE,
                        disabled: Boolean(get(selectedGRI, "_id")),
                        placeholder: "Chọn kho đích"
                      }}
                      component={UIField}
                      nativeInput={false}
                    />
                  )}
                </Col>
              </Row>
              <Field
                name="description"
                label="Mô tả"
                component={UIField}
                fieldComponent={TextArea}
                fieldProps={{ autosize: { minRows: 2, maxRows: 6 } }}
              />
            </Fragment>
          )}
        </Form>
      </Modal>
    );
  };
}

export default GoodsReceiptIssueForm;

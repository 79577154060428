import { action, observable, runInAction } from "mobx";

import { ErrorNoti, SuccessNoti } from "~components/UI";
import client from "~graphql/client";
import {
  CONFIRM_ASBUILD_LOCATION_MUTATION,
  UPDATE_DETAIL_LOCATION_MUTATION
} from "~graphql/mutations";
import { FIND_ONE_LOCATION_QUERY } from "~graphql/queries";
import { FullLocationFragment, InspectionResultInput } from "~graphql/types";
import {
  ConfirmAsBuiltLocationMutation,
  ConfirmAsBuiltLocationVariables,
  FindOneLocationQuery,
  FindOneLocationVariables,
  UpdateDetailLocationMutation,
  UpdateDetailLocationVariables
} from "~graphql/types.d";
import HomeScreenStore from "~screens/HomeScreen/store";
import { countLocation } from "~utils/countLocation";

class UpdateDetailLocationStore {
  @observable.shallow public selectedItem: FullLocationFragment;
  @observable public proposedEquipmentHistoryVisible: boolean;
  @observable public proposedEquipmentDrawerVisible: boolean;
  @observable public updateContentVisible: boolean;
  @observable public equipmentModalVisible: boolean;
  @observable public locationId: string;
  @observable public loading: boolean;
  @observable public categoryIdFilter: string;
  @observable public modelIdFilter: string;
  @observable public isValid: boolean;
  @observable public isSubmitting: boolean;

  constructor() {
    runInAction(() => {
      this.selectedItem = null;
      this.proposedEquipmentHistoryVisible = false;
      this.proposedEquipmentDrawerVisible = false;
      this.updateContentVisible = false;
      this.equipmentModalVisible = false;
      this.locationId = null;
      this.loading = true;
      this.categoryIdFilter = null;
      this.modelIdFilter = null;
      this.isValid = false;
      this.isSubmitting = false;
    });
  }

  @action public setLocationId = (locationId: string) => {
    this.locationId = locationId;

    if (locationId) {
      this.fetchData();
    } else {
      this.selectedItem = null;
    }

    this.loading = false;
  };

  @action public validateForm = (isValid, isSubmitting) => {
    this.isValid = isValid;
    this.isSubmitting = isSubmitting;
  };

  @action public setLocation = (location: FullLocationFragment) => {
    this.selectedItem = location;
  };

  @action public toggleUpdateContentModal = () => {
    this.updateContentVisible = !this.updateContentVisible;
  };

  @action public toggleProposedEquipmentHistory = () => {
    this.proposedEquipmentHistoryVisible = !this
      .proposedEquipmentHistoryVisible;
  };

  @action public toggleProposedEquipmentDrawer = () => {
    this.proposedEquipmentDrawerVisible = !this.proposedEquipmentDrawerVisible;
  };

  @action public toggleEquipmentModal = (filter = null) => {
    if (filter) {
      const { categoryIdFilter = null, modelIdFilter = null } = filter;

      this.categoryIdFilter = categoryIdFilter;
      this.modelIdFilter = modelIdFilter;
    }

    this.equipmentModalVisible = !this.equipmentModalVisible;
  };

  @action public handleUpdateDetail = async (record: InspectionResultInput) => {
    const { area, capacity, description, network } = record;

    const inspectionResult = {
      capacity,
      area,
      description,
      network
    };

    const {
      data: { updateDetailLocation }
    } = await client.mutate<
      UpdateDetailLocationMutation,
      UpdateDetailLocationVariables
    >({
      mutation: UPDATE_DETAIL_LOCATION_MUTATION,
      variables: {
        inspectionResult,
        _id: this.locationId
      }
    });
    const { error, location } = updateDetailLocation;
    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Cập nhật địa điểm");

      runInAction(() => {
        this.selectedItem = location;
      });
    }
  };

  @action public handleConfirmSuccessAfterInstalledLocation = async () => {
    const {
      data: { confirmAsBuiltLocation }
    } = await client.mutate<
      ConfirmAsBuiltLocationMutation,
      ConfirmAsBuiltLocationVariables
    >({
      mutation: CONFIRM_ASBUILD_LOCATION_MUTATION,
      variables: { _id: this.locationId }
    });

    const { error } = confirmAsBuiltLocation;

    if (error) {
      ErrorNoti(error.message);
    } else {
      SuccessNoti("Chuyển sang hoàn công");
      HomeScreenStore.toggleUpdateDetail();
      HomeScreenStore.update();
      await countLocation(HomeScreenStore.whereFilter).then(value => {
        HomeScreenStore.setCount(value);
      });
    }
  };

  @action public fetchData = async () => {
    const _id = this.locationId;

    if (_id) {
      const {
        data: { findOneLocation }
      } = await client.query<FindOneLocationQuery, FindOneLocationVariables>({
        query: FIND_ONE_LOCATION_QUERY,
        fetchPolicy: "network-only",
        variables: { where: { _id } }
      });

      runInAction(() => {
        this.selectedItem = findOneLocation;
      });
    }
  };
}

export default new UpdateDetailLocationStore();

import { ICellRendererParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { UIButton } from '~components/UI';

import store from './store';

@observer
export class GridPreTempActionCellRenderer extends Component<
  ICellRendererParams
> {
  public onClick = () => {
    const { data } = this.props;
    const { handleDeletePreTemps } = store;

    handleDeletePreTemps(data.serial);
  };

  public render() {
    const { node } = this.props;
    if (node.group) return null;

    return (
      <UIButton
        icon='delete'
        size='small'
        type='danger'
        onClick={this.onClick}
      />
    );
  }
}

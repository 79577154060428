import gql from 'graphql-tag';
import location from './location';
import equipmentModel from './equipmentModel';

const basic = gql`
  fragment BasicReportInventory on ReportInventory {
    date
    equipmentModelCode
    equipmentModel {
      ...FullEquipmentModel
    }
    openingAmount
    newInAmount
    changeInAmount
    outAmount
    closingAmount
    openingTotalPrice
    newInTotalPrice
    changeInTotalPrice
    outTotalPrice
    closingTotalPrice
  }
  ${equipmentModel.full}
`;

const full = gql`
  fragment FullReportInventory on ReportInventory {
    ...BasicReportInventory
    locationId
    location {
      ...BasicLocation
    }
  }
  ${basic}
  ${location.basic}
`;

export default { basic, full };

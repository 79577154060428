import { Button, Col, Input, Modal, Row } from 'antd';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as Yup from 'yup';
import EquipmentCategoryDropdown from '~components/Dropdown/EquipmentCategoryDropdown';
import { UIField } from '~components/UI/UIField';
import { EquipmentModelInput } from '~graphql/types';

import store from './store';

interface IFormValues extends EquipmentModelInput {
  _id?: string;
}

const { TextArea } = Input;

const validationSchema = Yup.object().shape<IFormValues>({
  _id: Yup.string(),
  name: Yup.string().required('Bắt buộc nhập tên thiết bị!'),
  code: Yup.string().required('Bắt buộc nhập mã thiết bị!'),
  unit: Yup.string().required('Bắt buộc nhập đơn vị!'),
  description: Yup.string().nullable(true),
  equipmentCategoryId: Yup.string().required('Bắt buộc chọn'),
});

@observer
class EquipmentModelForm extends Component {
  public handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const { handleCreate, handleUpdate } = store;
    const { resetForm, setSubmitting } = actions;

    const { _id, ...record } = values;

    if (_id) {
      await handleUpdate(_id, record);
    } else {
      await handleCreate(record);
    }

    setSubmitting(false);
    resetForm();
  };

  public render() {
    const { selectedItem, visible, toggleModal } = store;

    const formInitialValues = {
      _id: get(selectedItem, '_id'),
      name: get(selectedItem, 'name'),
      code: get(selectedItem, 'code'),
      unit: get(selectedItem, 'unit'),
      description: get(selectedItem, 'description'),
      equipmentCategoryId: get(selectedItem, 'equipmentCategoryId'),
    };

    const renderForm = (props: FormikProps<IFormValues>) => {
      const { isValid, isSubmitting, values } = props;

      const footer = (
        <Row>
          <Button
            type='primary'
            htmlType='submit'
            form='equipModelFm'
            disabled={!isValid}
            loading={isSubmitting}
          >
            {values._id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          <Button type='danger' onClick={() => toggleModal()}>
            Đóng
          </Button>
        </Row>
      );

      return (
        <Modal closable={false} visible={visible} footer={footer}>
          <Form id='equipModelFm'>
            <Row gutter={8}>
              <Col md={12} xs={24}>
                <Field
                  name='name'
                  label='Tên thiết bị'
                  component={UIField}
                  fieldProps={{ placeholder: 'Nhập tên thiết bị' }}
                />
              </Col>
              <Col md={12} xs={24}>
                <Field
                  name='code'
                  label='Mã'
                  component={UIField}
                  fieldProps={{ placeholder: 'Nhập mã thiết bị' }}
                />
              </Col>
            </Row>
            <Field
              name='equipmentCategoryId'
              label='Danh sách loại thiết bị'
              component={UIField}
              fieldComponent={EquipmentCategoryDropdown}
              fieldProps={{ placeholder: 'Chọn loại thiết bị' }}
              nativeInput={false}
            />
            <Field
              name='unit'
              label='Đơn vị'
              component={UIField}
              fieldProps={{ placeholder: 'Nhập đơn vị' }}
            />
            <Field
              name='description'
              label='Mô tả'
              component={UIField}
              fieldComponent={TextArea}
              fieldProps={{
                autosize: { minRows: 2, maxRows: 6 },
                placeholder: 'Nhập mô tả',
              }}
            />
          </Form>
        </Modal>
      );
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={renderForm}
      />
    );
  }
}

export default EquipmentModelForm;

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { AgGridTable, Loading } from '~components/UI';
import { GridCellDateRenderer } from '~components/UI/AgGridTable/CellDateRenderer';

import { GridCellContentRenderer } from './GridCellContentRenderer';
import { GridCellLinkRenderer } from './GridCellLinkRenderer';
import store from './store';

@observer
class EquipmentHistoryTable extends Component {
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Ngày giờ',
      field: 'createdAt',
      minWidth: 150,
      maxWidth: 150,
      suppressSorting: true,
      cellRendererFramework: GridCellDateRenderer,
    },
    {
      headerName: 'Nội dung thay đổi',
      suppressSorting: true,
      cellRendererFramework: GridCellContentRenderer,
    },
    {
      headerName: 'Phiếu nhập xuất',
      minWidth: 130,
      maxWidth: 130,
      suppressSorting: true,
      cellRendererFramework: GridCellLinkRenderer,
    },
  ];

  public render() {
    const { loading, equipmentHistory } = store;
    if (loading) return <Loading />;

    return (
      <div style={{ height: '100%' }}>
        <ReactResizeDetector handleHeight={true}>
          {(_width, height) => (
            <AgGridTable
              height={height - 8}
              rowData={equipmentHistory}
              columnDefs={this.columnDefs}
            />
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}
export default EquipmentHistoryTable;

import { removeTypename } from '~utils';
import { CommonEquipmentFragment } from '~graphql/types';

export const formatEquipmentData = (
  data: CommonEquipmentFragment[],
  categoryIdFilter?: string | null,
  modelIdFilter?: string | null
) => {
  if (!data) return null;

  return removeTypename(data).filter(e => {
    if (categoryIdFilter && e.equipmentCategoryId !== categoryIdFilter) {
      return false;
    }
    if (modelIdFilter && e.equipmentModelId !== modelIdFilter) {
      return false;
    }
    return true;
  });
};

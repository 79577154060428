import {
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsRequest
} from 'ag-grid-community';
import { Col, Input, Row } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { debounce, get, uniqBy } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { ISortModel } from '~components/UI/AgGridTable/AgGridServer';
import { UIField } from '~components/UI/UIField';
import client from '~graphql/client';
import {
  FIND_EQUIPMENT_DETAIL_QUERY,
  FIND_MANY_EQUIPMENT_QUERY
} from '~graphql/queries';
import {
  EquipmentBaseFilter,
  EquipmentDetailLocationEquipmentDetail,
  FindEquipmentDetailQuery,
  FindEquipmentDetailVariables,
  FindManyEquipmentQuery,
  FindManyEquipmentVariables
} from '~graphql/types';

import { mapReduce } from '@mgn/common';

import EquipmentCategoryDropdown from '../Dropdown/EquipmentCategoryDropdown';
import EquipmentModelDropdown from '../Dropdown/EquipmentModelDropdown';
import { columnDefs, EquipmentExtraColumn } from './EquipmentTable';
import { GroupRowInnerRenderer } from './GridGroupRowRenderer';
import { AgGridTable } from '~components/UI';

interface IFormValues {
  serial?: string;
  equipmentCategoryId?: string;
  equipmentModelCode?: string;
}

interface IProps {
  title?: string;
  where?: EquipmentBaseFilter;
  locationId?: string;
  extraColumn?: EquipmentExtraColumn[];
  rowGroup?: boolean;
}

@observer
export class EquipmentTableServer extends Component<IProps> {
  @observable private formValues: IFormValues;
  @observable private equipmentDetail: EquipmentDetailLocationEquipmentDetail[];
  private initialValues: IFormValues = {
    serial: undefined,
    equipmentCategoryId: undefined,
    equipmentModelCode: undefined,
  };

  constructor(props) {
    super(props);
    runInAction(() => {
      this.formValues = {};
      this.equipmentDetail = null;
    });
  }

  public onFilterChanged = () => undefined;

  @action public setFormValues = (values: IFormValues) => {
    if (JSON.stringify(this.formValues) !== JSON.stringify(values)) {
      this.formValues = values;
      this.onFilterChanged();
    }
  };

  public loadEquipmentDetail = async (request: IServerSideGetRowsRequest) => {
    const { locationId } = this.props;

    const { startRow, endRow } = request;

    const take = endRow - startRow;

    const { equipmentModelCode, equipmentCategoryId, serial } = this.formValues;

    const { data } = await client.query<
      FindEquipmentDetailQuery,
      FindEquipmentDetailVariables
    >({
      query: FIND_EQUIPMENT_DETAIL_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        take,
        skip: startRow,
        where: {
          serial,
          equipmentModelCode,
          equipmentCategoryId,
          locationId,
        },
      },
    });

    runInAction(() => {
      this.equipmentDetail = get(data, 'findEquipmentDetail', []);
    });

    return this.equipmentDetail.filter(item => {
      if (
        equipmentCategoryId &&
        item.equipmentModel.equipmentCategoryId !== equipmentCategoryId
      ) {
        return false;
      }
      if (
        equipmentModelCode &&
        item.equipmentModel.code !== equipmentModelCode
      ) {
        return false;
      }

      return true;
    });
  };

  public loadEquipment = async (request: IServerSideGetRowsRequest) => {
    const { where = {}, locationId } = this.props;
    const {
      startRow,
      endRow,
      sortModel,
      groupKeys: [equipmentModelId],
    } = request;

    const order = mapReduce<ISortModel, 'colId', string>(
      sortModel,
      'colId',
      ({ sort }) => sort.toUpperCase()
    );

    const take = endRow - startRow;
    const {
      data: { findManyEquipment },
    } = await client.query<FindManyEquipmentQuery, FindManyEquipmentVariables>({
      variables: {
        take,
        order,
        skip: startRow,
        where: {
          ...this.formValues,
          ...where,
          equipmentModelId,
          locationId,
        },
      },
      query: FIND_MANY_EQUIPMENT_QUERY,
      fetchPolicy: 'network-only',
    });

    return findManyEquipment;
  };

  public onGridReady = async ({ api }: GridReadyEvent) => {
    const { rowGroup = true } = this.props;

    this.onFilterChanged = debounce(
      () => {
        api.onFilterChanged();
      },
      300,
      { leading: false, trailing: true }
    );

    const dataSource: IServerSideDatasource = {
      getRows: async ({ request, successCallback }) => {
        const { groupKeys, startRow, endRow } = request;
        const take = endRow - startRow;
        if (rowGroup && !groupKeys.length) {
          const data = await this.loadEquipmentDetail(request);
          successCallback(data, data.length);
        } else {
          const data = await this.loadEquipment(request);
          successCallback(
            data,
            data.length === take ? null : startRow + data.length
          );
        }
      },
    };
    api.setServerSideDatasource(dataSource);
  };

  public render() {
    const {
      title = 'Danh sách thiết bị',
      extraColumn = [],
      rowGroup = true,
    } = this.props;

    const columns = [
      ...(rowGroup ? [columnDefs.idGroup] : [columnDefs.id]),
      ...[columnDefs.serial, columnDefs.equipmentCategoryId],
      {
        ...columnDefs.equipmentModelId,
        ...(rowGroup && { rowGroup, hide: true }),
      },
      ...extraColumn.map(col => columnDefs[col]),
      columnDefs.status,
    ].filter(Boolean);

    return (
      <Row type='flex' style={{ flexFlow: 'column', height: '100%' }}>
        {title && <h2>{title}</h2>}
        <Formik
          enableReinitialize={true}
          initialValues={this.initialValues}
          onSubmit={this.setFormValues}
          validate={this.setFormValues}
          render={this.renderForm}
        />
        <div style={{ flex: 1, marginTop: 8 }}>
          <ReactResizeDetector handleHeight={true}>
            {(_width, height) => (
              <AgGridTable
                height={height - 8}
                columnDefs={columns}
                onGridReady={this.onGridReady}
                rowModelType='serverSide'
                cacheBlockSize={150}
                infiniteInitialRowCount={0}
                groupUseEntireRow={true}
                frameworkComponents={{
                  groupRowInnerRenderer: GroupRowInnerRenderer,
                }}
                groupRowInnerRenderer={'groupRowInnerRenderer'}
                suppressAggFuncInHeader={true}
                rememberGroupStateWhenNewData={true}
              />
            )}
          </ReactResizeDetector>
        </div>
      </Row>
    );
  }

  private renderForm = (props: FormikProps<IFormValues>) => {
    const { values, setFieldValue, setTouched } = props;

    const onEquipmentModelCodeChange = () => {
      setFieldValue('equipmentModelCode', undefined, false);
      setTouched({ equipmentModelCode: false });
    };

    return (
      <Form>
        <Row gutter={8}>
          <Col md={6}>
            <Field
              name='equipmentCategoryId'
              component={UIField}
              nativeInput={false}
              fieldComponent={EquipmentCategoryDropdown}
              fieldProps={{
                onChange: onEquipmentModelCodeChange,
              }}
            />
          </Col>
          <Col md={6}>
            <Field
              name='equipmentModelCode'
              component={UIField}
              nativeInput={false}
              fieldComponent={EquipmentModelDropdown}
              fieldProps={{
                equipmentCategoryId: values.equipmentCategoryId,
                useCode: true,
              }}
            />
          </Col>
          <Col md={6}>
            <Field
              name='serial'
              component={UIField}
              fieldComponent={Input}
              fieldProps={{
                placeholder: 'Tìm theo SERIAL...',
                allowClear: true,
              }}
            />
          </Col>
        </Row>
      </Form>
    );
  };
}

export default EquipmentTableServer;

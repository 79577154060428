import gql from 'graphql-tag';
import locationFragment from '../fragments/location';

export const CONFIRM_FROM_SURVEYOR_LOCATION_MUTATION = gql`
  mutation ConfirmFromSurveyorLocation($_id: ID!) {
    confirmFromSurveyorLocation(_id: $_id) {
      location {
        ...FullLocation
      }
      error {
        message
      }
    }
  }
  ${locationFragment.full}
`;

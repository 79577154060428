import { action, observable, runInAction } from 'mobx';
import client from '~graphql/client';
import {
  FullEquipmentFragment,
  FindManyEquipmentHistoryQuery,
  FindManyEquipmentHistoryVariables,
  FindOneEquipmentQuery,
  FindOneEquipmentVariables,
  FullEquipmentHistoryFragment
} from '~graphql/types';
import {
  FIND_MANY_EQUIPMENT_HISTORY_QUERY,
  FIND_ONE_EQUIPMENT_QUERY
} from '~graphql/queries';

class EquipmentHistoryStore {
  @observable public loading: boolean;
  @observable public selectedItem: FullEquipmentFragment;
  @observable.shallow
  public equipmentHistory: FullEquipmentHistoryFragment[];

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.selectedItem = null;
      this.equipmentHistory = [];
    });
  }

  @action public setSerial(serial: string) {
    this.loading = true;
    this.selectedItem = null;
    this.equipmentHistory = [];

    this.fetchEquipment(serial).then(
      action(() => {
        this.loading = false;
      })
    );
  }

  @action private async fetchEquipment(serial: string) {
    const {
      data: { findOneEquipment },
    } = await client.query<FindOneEquipmentQuery, FindOneEquipmentVariables>({
      query: FIND_ONE_EQUIPMENT_QUERY,
      variables: { where: { serial } },
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.selectedItem = findOneEquipment;
    });
    return this.fetchEquipmentHistory(serial);
  }

  @action private async fetchEquipmentHistory(serial: string) {
    const {
      data: { findManyEquipmentHistory },
    } = await client.query<
      FindManyEquipmentHistoryQuery,
      FindManyEquipmentHistoryVariables
    >({
      query: FIND_MANY_EQUIPMENT_HISTORY_QUERY,
      variables: { where: { serial } },
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.equipmentHistory = findManyEquipmentHistory || [];
    });
  }
}

export default new EquipmentHistoryStore();

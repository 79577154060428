import { action, computed, observable, runInAction } from 'mobx';
import client from '~graphql/client';
import { removeTypename, formatTimeForIOReport } from '~utils';
import { GridReadyEvent, GridApi } from 'ag-grid-community';
import {
  BasicReportInventoryFragment,
  GetReportInventoryQuery,
  GetReportInventoryVariables,
  ReportInventoryBaseFilter,
  BasicLocationFragment,
  FindOneLocationQuery,
  FindOneLocationVariables,
  FullEquipmentCategoryFragment,
  FindOneEquipmentCategoryQuery,
  FindOneEquipmentCategoryVariables
} from '~graphql/types';
import {
  GET_REPORT_INVENTORY_QUERY,
  FIND_ONE_LOCATION_QUERY,
  FIND_ONE_EQUIPMENT_CATEGORY
} from '~graphql/queries';
import moment from 'moment';
import { get } from 'lodash';
import { FilterValue } from './Filter';

class IOInventoryStore {
  @observable public loading: boolean;
  @observable public visible: boolean;
  @observable public filter: FilterValue;
  @observable public gridApi: GridApi;
  @observable.ref public location: BasicLocationFragment;
  @observable.ref
  public equipmentCategory: FullEquipmentCategoryFragment;
  @observable.ref public reportInventory: BasicReportInventoryFragment[];
  private initialized: boolean;

  constructor() {
    runInAction(() => {
      this.loading = true;
      this.visible = false;
      this.filter = {
        locationId: undefined,
        equipmentCategoryId: undefined,
        date: [
          moment()
            .subtract(1, 'day')
            .startOf('day')
            .toISOString(),
          moment()
            .subtract(1, 'day')
            .endOf('day')
            .toISOString(),
        ],
      };
      this.gridApi = null;
      this.location = null;
      this.equipmentCategory = null;
      this.reportInventory = [];
      this.initialized = false;
    });
  }

  @action public init(forceReinit: boolean = false) {
    if (this.initialized && !forceReinit) return;
    this.loading = true;
    this.fetchReportInventory().finally(
      action(() => {
        this.loading = false;
        this.initialized = true;
      })
    );
  }

  @computed public get whereFilter() {
    const filter: ReportInventoryBaseFilter = {};
    if (this.filter) {
      const { locationId, date, equipmentCategoryId } = this.filter;
      const [from, to] = date;
      Object.assign(filter, {
        locationId,
        equipmentCategoryId,
        _operators: {
          date: {
            ...(from && {
              gte: moment(from)
                .startOf('day')
                .toISOString(),
            }),
            ...(to && {
              lte: moment(to)
                .endOf('day')
                .toISOString(),
            }),
          },
        },
      });
    }

    return filter;
  }

  @computed public get getFooter() {
    const reportInventory = this.reportInventory;
    const numberOfRows = [];
    const footerData = [
      {
        equipmentModel: {
          name: `Số dòng = ${numberOfRows.length}`,
        },
        openingAmount: 0,
        openingTotalPrice: 0,
        newInAmount: 0,
        newInTotalPrice: 0,
        changeInAmount: 0,
        changeInTotalPrice: 0,
        outAmount: 0,
        outTotalPrice: 0,
        closingAmount: 0,
        closingTotalPrice: 0,
      },
    ];

    if (reportInventory) {
      reportInventory.forEach(item => {
        if (
          !numberOfRows.some(smallItem => smallItem === item.equipmentModelCode)
        ) {
          numberOfRows.push(item.equipmentModelCode);
          footerData[0].equipmentModel.name = `Số dòng = ${
            numberOfRows.length
          }`;
        }

        footerData[0].openingAmount += item.openingAmount;
        footerData[0].openingTotalPrice += item.openingTotalPrice;
        footerData[0].newInAmount += item.newInAmount;
        footerData[0].newInTotalPrice += item.newInTotalPrice;
        footerData[0].changeInAmount += item.changeInAmount;
        footerData[0].changeInTotalPrice += item.changeInTotalPrice;
        footerData[0].outAmount += item.outAmount;
        footerData[0].outTotalPrice += item.outTotalPrice;
        footerData[0].closingAmount += item.closingAmount;
        footerData[0].closingTotalPrice += item.closingTotalPrice;
      });
    }
    return footerData;
  }

  @computed public get getHeader() {
    const reportInventory = this.reportInventory;
    const numberOfRows = [];
    const { date } = this.filter || { date: null };
    const from = date && get(date, '0');
    const to = date && get(date, '1');
    const fromDate = from ? formatTimeForIOReport(from) : 'từ đầu tới hiện tại';
    const toDate = to ? formatTimeForIOReport(to) : '';
    const timeStr = moment(from).isSame(to)
      ? `${fromDate}`
      : `${fromDate} - ${toDate}`;

    const locationStr = this.location
      ? get(this.location, 'name', 'không có')
      : 'Kho tổng';
    const equip = this.equipmentCategory
      ? get(this.equipmentCategory, 'name', '')
      : null;
    const equipStr = equip ? ` (${equip})` : '';

    const footerData = [
      {
        equipmentModel: {
          name: '',
        },
        openingAmount: 0,
        openingTotalPrice: 0,
        newInAmount: 0,
        newInTotalPrice: 0,
        changeInAmount: 0,
        changeInTotalPrice: 0,
        outAmount: 0,
        outTotalPrice: 0,
        closingAmount: 0,
        closingTotalPrice: 0,
      },
    ];
    if (reportInventory) {
      reportInventory.forEach(item => {
        if (
          !numberOfRows.some(smallItem => smallItem === item.equipmentModelCode)
        ) {
          numberOfRows.push(item.equipmentModelCode);
          footerData[0].equipmentModel.name = `${locationStr} (${
            numberOfRows.length
          })${equipStr}, \n ${timeStr}`;
        }

        footerData[0].openingAmount += item.openingAmount;
        footerData[0].openingTotalPrice += item.openingTotalPrice;
        footerData[0].newInAmount += item.newInAmount;
        footerData[0].newInTotalPrice += item.newInTotalPrice;
        footerData[0].changeInAmount += item.changeInAmount;
        footerData[0].changeInTotalPrice += item.changeInTotalPrice;
        footerData[0].outAmount += item.outAmount;
        footerData[0].outTotalPrice += item.outTotalPrice;
        footerData[0].closingAmount += item.closingAmount;
        footerData[0].closingTotalPrice += item.closingTotalPrice;
      });
    }
    return footerData;
  }

  @action public setFilter = (filter: FilterValue) => {
    this.filter = filter;
    if (filter.locationId) {
      this.fetchLocation(filter.locationId);
    } else {
      this.location = null;
    }
    if (filter.equipmentCategoryId) {
      this.fetchEquipmentCategory(filter.equipmentCategoryId);
    }

    this.fetchReportInventory();
  };

  @action public handleGridReady = ({ api }: GridReadyEvent) => {
    this.gridApi = api;
    // this.reload = () => {
    //   api.onFilterChanged();
    // };
    api.addEventListener('displayedColumnsChanged', () => {
      api.sizeColumnsToFit();
    });
  };

  @action public fetchLocation = async (locationId: string) => {
    const { data } = await client.query<
      FindOneLocationQuery,
      FindOneLocationVariables
    >({
      query: FIND_ONE_LOCATION_QUERY,
      variables: { where: { _id: locationId } },
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.location = data.findOneLocation;
    });
  };

  @action public fetchEquipmentCategory = async (
    equipmentCategoryId: string
  ) => {
    const { data } = await client.query<
      FindOneEquipmentCategoryQuery,
      FindOneEquipmentCategoryVariables
    >({
      query: FIND_ONE_EQUIPMENT_CATEGORY,
      variables: { where: { _id: equipmentCategoryId } },
      fetchPolicy: 'network-only',
    });

    runInAction(() => {
      this.equipmentCategory = data.findOneEquipmentCategory;
    });
  };

  @action private fetchReportInventory = async () => {
    const filter = this.whereFilter;
    const { data } = await client.query<
      GetReportInventoryQuery,
      GetReportInventoryVariables
    >({ query: GET_REPORT_INVENTORY_QUERY, variables: { where: filter } });

    runInAction(() => {
      this.reportInventory = removeTypename(data.getReportInventory);
    });
  };

  // @action private reload = () => undefined;
}

export default new IOInventoryStore();

import gql from 'graphql-tag';
import goodsIssue from '../fragments/goodsIssue';

export const CONFIRM_FROM_MANAGER_GOODS_ISSUE = gql`
  mutation ConfirmFromManagerGoodsIssue(
    $goodsIssueCode: String!
    $confirm: ConfirmEnum!
    $note: String
  ) {
    confirmFromManagerGoodsIssue(
      goodsIssueCode: $goodsIssueCode
      confirm: $confirm
      note: $note
    ) {
      goodsIssue {
        ...FullGoodsIssue
      }
      error {
        message
      }
    }
  }
  ${goodsIssue.full}
`;

import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

export class MacInput extends Component<InputProps> {
  public render() {
    return (
      <InputMask
        mask='aa:aa:aa:aa:aa:aa'
        formatChars={{ a: '[a-fA-F0-9]' }}
        permanents={[2, 5, 8, 11, 14]}
        {...this.props}
        disabled={!!this.props.disabled}
      >
        {({ permanents, ...props }) => {
          return <Input {...props} />;
        }}
      </InputMask>
    );
  }
}

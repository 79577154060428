type ReturnType<T, R> = ((item: T) => R);

export const mapReduce = <
  T extends { [key in F]: any },
  F extends string,
  R = boolean
>(
  data: T[],
  field: F,
  value?: ReturnType<T, R>
) => {
  const getValue = value || (() => true as any);

  return data.reduce<{ [key: string]: R }>((all, item) => {
    all[item[field]] = getValue(item);
    return all;
  }, {});
};

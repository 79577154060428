import { action, observable, runInAction } from 'mobx';
import client from '~graphql/client';
import {
  FullLocationFragment,
  FindOneLocationQuery,
  FindOneLocationVariables
} from '~graphql/types';
import { FIND_ONE_LOCATION_QUERY } from '~graphql/queries';

class WarehouseDetailStore {
  @observable public visibleView: boolean;
  @observable public loading: boolean;
  @observable public selectedItem: FullLocationFragment;

  constructor() {
    runInAction(() => {
      this.visibleView = false;
      this.loading = true;
      this.selectedItem = null;
    });
  }

  @action public setName(_id: string) {
    this.loading = true;
    this.selectedItem = null;
    this.fetchData(_id).then(
      action(() => {
        this.loading = false;
      })
    );
  }

  @action public toggleView = () => {
    this.visibleView = !this.visibleView;
  };

  @action private async fetchData(_id: string) {
    this.loading = true;
    const { data } = await client.query<
      FindOneLocationQuery,
      FindOneLocationVariables
    >({
      query: FIND_ONE_LOCATION_QUERY,
      fetchPolicy: 'network-only',
      variables: { where: { _id } },
    });
    runInAction(() => {
      this.selectedItem = data.findOneLocation;
    });
  }
}

export default new WarehouseDetailStore();

import { Card, Col, Popover, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { DescriptionItem, EmployeeInfo, LocationInfo } from '~components/UI';
import { formatPrice, formatStatus, formatTime } from '~utils';

import store from './store';

const layoutProps = {
  span: 24,
  md: { span: 6 },
};

const layoutProps2 = {
  span: 24,
  md: { span: 12 },
};

@observer
export default class GoodsReceiptCardDetail extends Component {
  public render() {
    const { selectedItem: goodsReceipt } = store;

    return (
      <Card size='small' style={{ marginTop: 8 }}>
        <Row>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Mã phiếu nhập'
              content={get(goodsReceipt, 'code')}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Trạng thái'
              content={formatStatus(get(goodsReceipt, 'status'))}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Người tạo'
              content={
                <EmployeeInfo employee={get(goodsReceipt, 'createdEmployee')} />}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Ngày tạo'
              content={formatTime(get(goodsReceipt, 'createdAt'))}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Kho nguồn'
              content={
                <LocationInfo location={get(goodsReceipt, 'fromLocation')} />}
            />
          </Col>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Kho đích'
              content={
                <LocationInfo location={get(goodsReceipt, 'toLocation')} />}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Tổng số lượng (thiết bị)'
              content={get(goodsReceipt, 'totalQuantity')}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Tổng giá trị (VNĐ)'
              content={formatPrice(get(goodsReceipt, 'totalPrice'))}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Người duyệt'
              content={
                <EmployeeInfo employee={get(goodsReceipt, 'approver')} />}
            />
          </Col>
          <Col {...layoutProps}>
            <DescriptionItem
              title='Ngày duyệt'
              content={formatTime(get(goodsReceipt, 'approvedAt'))}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Ngày xuất hóa đơn'
              content={formatTime(get(goodsReceipt, 'issueInvoiceDay'))}
            />
          </Col>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Mô tả'
              content={get(goodsReceipt, 'description')}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Ghi chú'
              content={get(goodsReceipt, 'note')}
            />
          </Col>
          <Col {...layoutProps2}>
            <DescriptionItem
              title='Yêu cầu vật tư'
              content={
                <Popover
                  title={null}
                  content={
                    <pre>
                      {get(goodsReceipt, 'proposedEquipment.materialDetail')}
                    </pre>
                  }
                >
                  <span style={{ color: 'rgb(36,131,255)', cursor: 'pointer' }}>
                    Xem chi tiết
                  </span>
                </Popover>
              }
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

export { GoodsReceiptCardDetail };

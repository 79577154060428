import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import { UIButton } from '~components/UI';
import {
  CREATED,
  AGREED,
  PERMISSION_GOODS_RECEIPT_UPDATE,
  PERMISSION_GOODS_RECEIPT_REMOVE
} from '@mgn/common';

import store from './store';
import { isAuthorized } from '~components/Authorized/Authorized';
import { GoodsReceiptPrintLocation } from '~screens/Routes';

@observer
export class GridCellActionRenderer extends Component<ICellRendererParams> {
  public handlePrint = () => {
    const code = get(this.props.data, 'code');
    window.open(GoodsReceiptPrintLocation.toUrl({ code }));
  };

  public render() {
    const { data } = this.props;
    const { toggleModal, handleDelete } = store;

    return (
      <Fragment>
        <Button.Group>
          {get(data, 'status') === AGREED &&
            !get(data, 'issueInvoiceDay') &&
            !get(data, 'proposedEquipmentId') && (
              <UIButton
                icon='edit'
                size='small'
                type='primary'
                disabled={
                  !isAuthorized(
                    PERMISSION_GOODS_RECEIPT_UPDATE,
                    get(data, 'toLocationId')
                  )
                }
                onClick={() => toggleModal(data)}
              />
            )}
          {get(data, 'status') === CREATED && (
            <Fragment>
              <UIButton
                icon='edit'
                size='small'
                type='primary'
                disabled={
                  !isAuthorized(
                    PERMISSION_GOODS_RECEIPT_UPDATE,
                    get(data, 'fromLocationId')
                  )
                }
                onClick={() => toggleModal(data)}
              />
              <Popconfirm
                title='Xóa?'
                onConfirm={() => handleDelete(get(data, 'code'))}
              >
                <UIButton
                  type='danger'
                  icon='delete'
                  size='small'
                  disabled={
                    !isAuthorized(
                      PERMISSION_GOODS_RECEIPT_REMOVE,
                      get(data, 'toLocationId')
                    )
                  }
                />
              </Popconfirm>
            </Fragment>
          )}
          {get(data, 'status') === AGREED && (
            <Fragment>
              <UIButton
                icon='printer'
                size='small'
                type='info'
                disabled={
                  !isAuthorized(
                    PERMISSION_GOODS_RECEIPT_UPDATE,
                    get(data, 'toLocationId')
                  )
                }
                onClick={this.handlePrint}
              />
            </Fragment>
          )}
        </Button.Group>
      </Fragment>
    );
  }
}

import gql from 'graphql-tag';
import goodsReceipt from '../fragments/goodsReceipt';

export const PASS_TO_MANAGER_GOODS_RECEIPT = gql`
  mutation PassToManagerGoodsReceipt($goodsReceiptCode: String!) {
    passToManagerGoodsReceipt(goodsReceiptCode: $goodsReceiptCode) {
      goodsReceipt {
        ...FullGoodsReceipt
      }
      error {
        message
      }
    }
  }
  ${goodsReceipt.full}
`;

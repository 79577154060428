const allPermissions = [];

export const PERMISSION_SUPERADMIN = 'su';
allPermissions.push({
  name: PERMISSION_SUPERADMIN,
  description: 'Đấng tối cao',
});

export const PERMISSION_UNAUTHENTICATED = '$unauthenticated';
allPermissions.push({
  name: PERMISSION_UNAUTHENTICATED,
  description: 'Người dùng chưa đăng nhập',
});

export const PERMISSION_AUTHENTICATED = '$authenticated';
allPermissions.push({
  name: PERMISSION_AUTHENTICATED,
  description: 'Người dùng đăng nhập',
});

export const PERMISSION_EVERYONE = '$everyone';
allPermissions.push({
  name: PERMISSION_EVERYONE,
  description: 'Tất cả mọi người',
});

export const PERMISSION_READ = 'permission.read';
allPermissions.push({
  name: PERMISSION_READ,
  description: 'Xem quyền hạn',
});

export const PERMISSION_ADD_ALL = 'permission.addAll';
allPermissions.push({
  name: PERMISSION_ADD_ALL,
  description: 'Thêm tất cả quyền',
});

export const PERMISSION_ROLE_ADD_ALL = 'role.addAll';
allPermissions.push({
  name: PERMISSION_ROLE_ADD_ALL,
  description: 'Thêm tất cả chức vụ',
});

export const PERMISSION_ROLE_READ = 'role.read';
allPermissions.push({
  name: PERMISSION_ROLE_READ,
  description: 'Xem chức vụ',
});

export const PERMISSION_ROLE_CREATE = 'role.create';
allPermissions.push({
  name: PERMISSION_ROLE_CREATE,
  description: 'Tạo chức vụ',
});

export const PERMISSION_ROLE_UPDATE = 'role.update';
allPermissions.push({
  name: PERMISSION_ROLE_UPDATE,
  description: 'Cập nhật chức vụ',
});

export const PERMISSION_ROLE_REMOVE = 'role.remove';
allPermissions.push({
  name: PERMISSION_ROLE_REMOVE,
  description: 'Xóa chức vụ',
});

export const PERMISSION_EMPLOYEE_READ = 'employee.read';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_READ,
  description: 'Xem nhân viên',
});

export const PERMISSION_EMPLOYEE_CREATE = 'employee.create';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_CREATE,
  description: 'Tạo nhân viên',
});

export const PERMISSION_EMPLOYEE_UPDATE = 'employee.update';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_UPDATE,
  description: 'Cập nhật nhân viên',
});

export const PERMISSION_EMPLOYEE_UPDATE_PASSWORD = 'employee.updatePassword';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_UPDATE_PASSWORD,
  description: 'Cập nhật mật khẩu',
});

export const PERMISSION_EMPLOYEE_RESET_PASSWORD = 'employee.resetPassword';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_RESET_PASSWORD,
  description: 'Đặt lại mật khẩu nhân viên',
});

export const PERMISSION_EMPLOYEE_REMOVE = 'employee.remove';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_REMOVE,
  description: 'Xóa nhân viên',
});

export const PERMISSION_EMPLOYEE_FREEWIFI_SURVEYOR =
  'employee.freeWifiSurveyor';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_FREEWIFI_SURVEYOR,
  description: 'N/V KHẢO SÁT',
});

export const PERMISSION_EMPLOYEE_FREEWIFI_INSTALLER =
  'employee.freeWifiInstaller';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_FREEWIFI_INSTALLER,
  description: 'N/V THI CÔNG',
});

export const PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER =
  'employee.freeWifiMaintainer';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_FREEWIFI_MAINTAINER,
  description: 'N/V VẬN HÀNH',
});

export const PERMISSION_EMPLOYEE_FREEWIFI_ADMIN = 'employee.freeWifiAdmin';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_FREEWIFI_ADMIN,
  description: 'ADMIN FREE-WIFI',
});

export const PERMISSION_EMPLOYEE_FREEWIFI_MANAGER = 'employee.freeWifiManager';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_FREEWIFI_MANAGER,
  description: 'QUẢN LÝ FREE-WIFI',
});

export const PERMISSION_EMPLOYEE_WAREHOUSE_MANAGER =
  'employee.warehouseManager';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_WAREHOUSE_MANAGER,
  description: 'QUẢN LÝ KHO',
});

export const PERMISSION_EMPLOYEE_WAREHOUSE_EMPLOYEE =
  'employee.warehouseEmployee';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_WAREHOUSE_EMPLOYEE,
  description: 'NHÂN VIÊN KHO',
});

export const PERMISSION_EQUIPMENT_CATEGORY_READ = 'equipmentCategory.read';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_CATEGORY_READ,
  description: 'Xem loại thiết bị',
});

export const PERMISSION_EQUIPMENT_CATEGORY_CREATE = 'equipmentCategory.create';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_CATEGORY_CREATE,
  description: 'Tạo loại thiết bị',
});

export const PERMISSION_EQUIPMENT_CATEGORY_UPDATE = 'equipmentCategory.update';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_CATEGORY_UPDATE,
  description: 'Cập nhât loại thiết bị',
});

export const PERMISSION_EQUIPMENT_CATEGORY_REMOVE = 'equipmentCategory.remove';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_CATEGORY_REMOVE,
  description: 'Xóa loại thiết bị',
});

export const PERMISSION_EQUIPMENT_MODEL_READ = 'equipmentModel.read';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_MODEL_READ,
  description: 'Xem mẫu thiết bị',
});

export const PERMISSION_EQUIPMENT_MODEL_CREATE = 'equipmentModel.create';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_MODEL_CREATE,
  description: 'Tạo mẫu thiết bị',
});

export const PERMISSION_EQUIPMENT_MODEL_UPDATE = 'equipmentModel.update';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_MODEL_UPDATE,
  description: 'Cập nhật mẫu thiết bị',
});

export const PERMISSION_EQUIPMENT_MODEL_REMOVE = 'equipmentModel.remove';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_MODEL_REMOVE,
  description: 'Xóa mẫu thiết bị',
});

export const PERMISSION_EQUIPMENT_HISTORY_READ = 'equipmentHistory.read';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_HISTORY_READ,
  description: 'Xem lịch sử thiết bị',
});

export const PERMISSION_LOCATION_TYPE_READ = 'locationType.read';
allPermissions.push({
  name: PERMISSION_LOCATION_TYPE_READ,
  description: 'Xem loại địa điểm',
});

export const PERMISSION_LOCATION_TYPE_CREATE = 'locationType.create';
allPermissions.push({
  name: PERMISSION_LOCATION_TYPE_CREATE,
  description: 'Tạo loại địa điểm',
});

export const PERMISSION_LOCATION_TYPE_UPDATE = 'locationType.update';
allPermissions.push({
  name: PERMISSION_LOCATION_TYPE_UPDATE,
  description: 'Cập nhât loại địa điểm',
});

export const PERMISSION_LOCATION_TYPE_REMOVE = 'locationType.remove';
allPermissions.push({
  name: PERMISSION_LOCATION_TYPE_REMOVE,
  description: 'Xóa loại địa điểm',
});

export const PERMISSION_LOCATION_READ = 'location.read';
allPermissions.push({
  name: PERMISSION_LOCATION_READ,
  description: 'Xem địa điểm',
});

export const PERMISSION_LOCATION_CREATE = 'location.create';
allPermissions.push({
  name: PERMISSION_LOCATION_CREATE,
  description: 'Tạo điểm',
});

export const PERMISSION_LOCATION_REMOVE_BEFORE_AGREED =
  'location.removeBeforeAgreed';
allPermissions.push({
  name: PERMISSION_LOCATION_REMOVE_BEFORE_AGREED,
  description: 'Xóa điểm trước khi được duyệt',
});

export const PERMISSION_LOCATION_UPDATE_CONTENT = 'location.updateContent';
allPermissions.push({
  name: PERMISSION_LOCATION_UPDATE_CONTENT,
  description: 'Cập nhật thông tin địa điểm',
});

export const PERMISSION_LOCATION_CREATE_UPLOAD_LINK =
  'location.createUploadLink';
allPermissions.push({
  name: PERMISSION_LOCATION_CREATE_UPLOAD_LINK,
  description: 'Tạo link upload',
});

export const PERMISSION_LOCATION_REMOVE_FILE = 'location.removeFile';
allPermissions.push({
  name: PERMISSION_LOCATION_REMOVE_FILE,
  description: 'Xóa file',
});

export const PERMISSION_LOCATION_PASS_TO_SURVEYOR = 'location.passToSurveyor';
allPermissions.push({
  name: PERMISSION_LOCATION_PASS_TO_SURVEYOR,
  description: 'Giao địa điểm cho N/V KHẢO SÁT',
});

export const PERMISSION_LOCATION_CONFIRM_FROM_SURVEYOR =
  'location.confirmFromSurveyor';
allPermissions.push({
  name: PERMISSION_LOCATION_CONFIRM_FROM_SURVEYOR,
  description: 'N/V KHẢO SÁT xác nhận địa điểm được giao từ ',
});

export const PERMISSION_LOCATION_UPDATE_DETAIL = 'location.updateDetail';
allPermissions.push({
  name: PERMISSION_LOCATION_UPDATE_DETAIL,
  description: 'Cập nhật chi tiết địa điểm',
});

export const PERMISSION_LOCATION_UPDATE_INSTALLER = 'location.updateInstaller';
allPermissions.push({
  name: PERMISSION_LOCATION_UPDATE_INSTALLER,
  description: 'N/V THI CÔNG cập nhật địa điểm ',
});

export const PERMISSION_LOCATION_PASS_TO_INSTALLER = 'location.passToInstaller';
allPermissions.push({
  name: PERMISSION_LOCATION_PASS_TO_INSTALLER,
  description: 'Giao địa điểm cho N/V THI CÔNG',
});

export const PERMISSION_LOCATION_CONFIRM_FROM_INSTALLER =
  'location.confirmFromInstaller';
allPermissions.push({
  name: PERMISSION_LOCATION_CONFIRM_FROM_INSTALLER,
  description: 'N/V THI CÔNG xác nhận địa điểm được giao ',
});

export const PERMISSION_LOCATION_CONFIRM_ASBUILT = 'location.confirmAsBuilt';
allPermissions.push({
  name: PERMISSION_LOCATION_CONFIRM_ASBUILT,
  description: 'N/V THI CÔNG xác nhận hoàn thành thi công',
});

export const PERMISSION_LOCATION_UPDATE_MAINTAINER =
  'location.updateMaintainer';
allPermissions.push({
  name: PERMISSION_LOCATION_UPDATE_MAINTAINER,
  description: 'N/V VẬN HÀNH cập nhật địa điểm',
});

export const PERMISSION_LOCATION_PASS_TO_MAINTAINER =
  'location.passToMaintainer';
allPermissions.push({
  name: PERMISSION_LOCATION_PASS_TO_MAINTAINER,
  description: 'Giao địa điểm cho N/V THI CÔNG',
});

export const PERMISSION_LOCATION_CONFIRM_FROM_MAINTAINER =
  'location.confirmFromMaintainer';
allPermissions.push({
  name: PERMISSION_LOCATION_CONFIRM_FROM_MAINTAINER,
  description: 'N/V THI CÔNG xác nhận địa điểm được giao',
});

export const PERMISSION_LOCATION_UPDATE_STATUS = 'location.updateStatus';
allPermissions.push({
  name: PERMISSION_LOCATION_UPDATE_STATUS,
  description: 'Cập nhật trạng thái địa điểm',
});

export const PERMISSION_LOCATION_CANCEL = 'location.cancel';
allPermissions.push({
  name: PERMISSION_LOCATION_CANCEL,
  description: 'Huỷ điểm trước khi thi công',
});

export const PERMISSION_LOCATION_HISTORY_READ = 'locationHistory.read';
allPermissions.push({
  name: PERMISSION_LOCATION_HISTORY_READ,
  description: 'Xem lịch sử địa điểm',
});

export const PERMISSION_PROPOSED_EQUIPMENT_READ = 'proposedEquipment.read';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_READ,
  description: 'Xem đề xuất thiết bị',
});

export const PERMISSION_PROPOSED_EQUIPMENT_CREATE = 'proposedEquipment.create';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_CREATE,
  description: 'Tạo đề xuất thiết bị',
});

export const PERMISSION_PROPOSED_EQUIPMENT_UPDATE_DETAIL =
  'proposedEquipment.updateDetail';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_UPDATE_DETAIL,
  description: 'Cập nhật chi tiết đề xuất thiết bị',
});

export const PERMISSION_PROPOSED_EQUIPMENT_PASS_TO_MANAGER =
  'proposedEquipment.passToManager';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_PASS_TO_MANAGER,
  description: 'Chuyển đề xuất thiết bị cho QUẢN LÝ FREE-WIFI',
});

export const PERMISSION_PROPOSED_EQUIPMENT_PASS_TO_FULFILLED =
  'proposedEquipment.passToFulfiled';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_PASS_TO_FULFILLED,
  description:
    'Chuyển đề xuất thiết bị sang trạng thái hoàn thành khi không đủ thiết bị yêu cầu',
});

export const PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_MANAGER =
  'proposedEquipment.confirmFromManager';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_MANAGER,
  description: 'QUẢN LÝ FREE-WIFI duyệt đề xuất thiết bị',
});

export const PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_WAREHOUSE =
  'proposedEquipment.confirmFromWarehouse';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_WAREHOUSE,
  description: 'QUẢN LÝ KHO duyệt đề xuất thiết bị',
});

export const PERMISSION_PROPOSED_EQUIPMENT_REMOVE = 'proposedEquipment.remove';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_REMOVE,
  description: 'Xóa đề xuất thiết bị',
});

export const PERMISSION_MENU_READ = 'menu.read';
allPermissions.push({
  name: PERMISSION_MENU_READ,
  description: 'Xem danh mục',
});

export const PERMISSION_MENU_CREATE = 'menu.create';
allPermissions.push({
  name: PERMISSION_MENU_CREATE,
  description: 'Tạo danh mục',
});

export const PERMISSION_MENU_UPDATE = 'menu.update';
allPermissions.push({
  name: PERMISSION_MENU_UPDATE,
  description: 'Cập nhật danh mục',
});

export const PERMISSION_MENU_REMOVE = 'menu.remove';
allPermissions.push({
  name: PERMISSION_MENU_REMOVE,
  description: 'Xóa danh mục',
});

export const PERMISSION_MATERIAL_READ = 'material.read';
allPermissions.push({
  name: PERMISSION_MATERIAL_READ,
  description: 'Xem loại vật tư',
});

export const PERMISSION_MATERIAL_CREATE = 'material.create';
allPermissions.push({
  name: PERMISSION_MATERIAL_CREATE,
  description: 'Tạo loại vật tư',
});

export const PERMISSION_MATERIAL_UPDATE = 'material.update';
allPermissions.push({
  name: PERMISSION_MATERIAL_UPDATE,
  description: 'Cập nhât loại vật tư',
});

export const PERMISSION_MATERIAL_REMOVE = 'material.remove';
allPermissions.push({
  name: PERMISSION_MATERIAL_REMOVE,
  description: 'Xóa loại vật tư',
});

export const PERMISSION_EQUIPMENT_TEMP_READ = 'equipmentTemp.read';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_TEMP_READ,
  description: 'Xem thiết bị lưu tạm',
});

export const PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_ADD =
  'equipmentTemp.addFromGoodsReceipt';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_ADD,
  description: 'Thêm thiết bị lưu tạm từ phiếu nhập kho',
});

export const PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_REMOVE =
  'equipmentTemp.removeFromGoodsReceipt';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_TEMP_GOODS_RECEIPT_REMOVE,
  description: 'Xóa thiết bị lưu tạm từ phiếu nhập kho',
});

export const PERMISSION_EQUIPMENT_TEMP_GOODS_ISSUE_ADD =
  'equipmentTemp.addFromGoodsIssue';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_TEMP_GOODS_ISSUE_ADD,
  description: 'Thêm thiết bị lưu tạm từ phiếu xuất kho',
});

export const PERMISSION_EQUIPMENT_TEMP_GOODS_ISSUE_REMOVE =
  'equipmentTemp.removeFromGoodsIssue';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_TEMP_GOODS_ISSUE_REMOVE,
  description: 'Xóa thiết bị lưu tạm từ phiếu xuất kho',
});

export const PERMISSION_EQUIPMENT_READ = 'equipment.read';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_READ,
  description: 'Xem thiết bị',
});

export const PERMISSION_WAREHOUSE_READ = 'warehouse.read';
allPermissions.push({
  name: PERMISSION_WAREHOUSE_READ,
  description: 'Xem kho',
});

export const PERMISSION_WAREHOUSE_CREATE = 'warehouse.create';
allPermissions.push({
  name: PERMISSION_WAREHOUSE_CREATE,
  description: 'Tạo kho',
});

export const PERMISSION_WAREHOUSE_UPDATE = 'warehouse.update';
allPermissions.push({
  name: PERMISSION_WAREHOUSE_UPDATE,
  description: 'Cập nhật kho',
});

export const PERMISSION_WAREHOUSE_REMOVE = 'warehouse.remove';
allPermissions.push({
  name: PERMISSION_WAREHOUSE_REMOVE,
  description: 'Xóa kho',
});

export const PERMISSION_GOODS_RECEIPT_READ = 'goodsReceipt.read';
allPermissions.push({
  name: PERMISSION_GOODS_RECEIPT_READ,
  description: 'Xem phiếu nhập kho',
});

export const PERMISSION_GOODS_RECEIPT_CREATE = 'goodsReceipt.create';
allPermissions.push({
  name: PERMISSION_GOODS_RECEIPT_CREATE,
  description: 'Tạo phiếu nhập kho',
});

export const PERMISSION_GOODS_RECEIPT_UPDATE = 'goodsReceipt.update';
allPermissions.push({
  name: PERMISSION_GOODS_RECEIPT_UPDATE,
  description: 'Cập nhât phiếu nhập kho',
});

export const PERMISSION_GOODS_RECEIPT_REMOVE = 'goodsReceipt.remove';
allPermissions.push({
  name: PERMISSION_GOODS_RECEIPT_REMOVE,
  description: 'Xóa phiếu nhập kho',
});

export const PERMISSION_GOODS_RECEIPT_PASS_TO_MANAGER =
  'equipments.passToManagerGoodsReceipt';
allPermissions.push({
  name: PERMISSION_GOODS_RECEIPT_PASS_TO_MANAGER,
  description: 'Chuyển phiếu nhập kho cho QUẢN LÝ KHO',
});

export const PERMISSION_GOODS_RECEIPT_CONFIRM_FROM_MANAGER =
  'goodsReceipt.confirmFromManager';
allPermissions.push({
  name: PERMISSION_GOODS_RECEIPT_CONFIRM_FROM_MANAGER,
  description: 'Duyệt phiếu nhập kho',
});

export const PERMISSION_GOODS_ISSUE_READ = 'goodsIssue.read';
allPermissions.push({
  name: PERMISSION_GOODS_ISSUE_READ,
  description: 'Xem phiếu xuất kho',
});

export const PERMISSION_GOODS_ISSUE_CREATE = 'goodsIssue.create';
allPermissions.push({
  name: PERMISSION_GOODS_ISSUE_CREATE,
  description: 'Tạo phiếu xuất kho',
});

export const PERMISSION_GOODS_ISSUE_UPDATE = 'goodsIssue.update';
allPermissions.push({
  name: PERMISSION_GOODS_ISSUE_UPDATE,
  description: 'Cập nhật phiếu xuất kho',
});

export const PERMISSION_GOODS_ISSUE_REMOVE = 'goodsIssue.remove';
allPermissions.push({
  name: PERMISSION_GOODS_ISSUE_REMOVE,
  description: 'Xóa phiếu xuất kho',
});

export const PERMISSION_GOODS_ISSUE_PASS_TO_MANAGER =
  'equipments.passToManagerGoodsIssue';
allPermissions.push({
  name: PERMISSION_GOODS_ISSUE_PASS_TO_MANAGER,
  description: 'Chuyển phiếu xuất kho cho QUẢN LÝ KHO',
});

export const PERMISSION_GOODS_ISSUE_CONFIRM_FROM_MANAGER =
  'goodsIssue.confirmFromManager';
allPermissions.push({
  name: PERMISSION_GOODS_ISSUE_CONFIRM_FROM_MANAGER,
  description: 'Duyệt phiếu xuất kho',
});

export const PERMISSION_MENU_MAPPING_READ = 'menuMapping.read';
allPermissions.push({
  name: PERMISSION_MENU_MAPPING_READ,
  description: 'Xem danh mục - người dùng',
});

export const PERMISSION_MENU_MAPPING_CREATE = 'menuMapping.create';
allPermissions.push({
  name: PERMISSION_MENU_MAPPING_CREATE,
  description: 'Tạo danh mục - người dùng',
});

export const PERMISSION_MENU_MAPPING_UPDATE = 'menuMapping.update';
allPermissions.push({
  name: PERMISSION_MENU_MAPPING_UPDATE,
  description: 'Cập nhật danh mục - người dùng',
});

export const PERMISSION_MENU_MAPPING_REMOVE = 'menuMapping.remove';
allPermissions.push({
  name: PERMISSION_MENU_MAPPING_REMOVE,
  description: 'Xóa danh mục - người dùng',
});

export const PERMISSION_ROLE_MAPPING_READ = 'roleMapping.read';
allPermissions.push({
  name: PERMISSION_ROLE_MAPPING_READ,
  description: 'Xem chức danh - người dùng',
});

export const PERMISSION_ROLE_MAPPING_CREATE = 'roleMapping.create';
allPermissions.push({
  name: PERMISSION_ROLE_MAPPING_CREATE,
  description: 'Tạo chức danh - người dùng',
});

export const PERMISSION_ROLE_MAPPING_UPDATE = 'roleMapping.update';
allPermissions.push({
  name: PERMISSION_ROLE_MAPPING_UPDATE,
  description: 'Cập nhật chức danh - người dùng',
});

export const PERMISSION_ROLE_MAPPING_REMOVE = 'roleMapping.remove';
allPermissions.push({
  name: PERMISSION_ROLE_MAPPING_REMOVE,
  description: 'Xóa chức danh - người dùng',
});

export const PERMISSION_SUPPLIER_REMOVE = 'supplier.remove';
allPermissions.push({
  name: PERMISSION_SUPPLIER_REMOVE,
  description: 'Xóa nhà cung cấp',
});

export const PERMISSION_SUPPLIER_CREATE = 'supplier.create';
allPermissions.push({
  name: PERMISSION_SUPPLIER_CREATE,
  description: 'Tạo nhà cung cấp',
});

export const PERMISSION_SUPPLIER_UPDATE = 'supplier.update';
allPermissions.push({
  name: PERMISSION_SUPPLIER_UPDATE,
  description: 'Cập nhật nhà cung cấp',
});

export const PERMISSION_SUPPLIER_READ = 'supplier.read';
allPermissions.push({
  name: PERMISSION_SUPPLIER_READ,
  description: 'Xem nhà cung cấp',
});

// export const PERMISSION_ERROR_LOG_CREATE = 'errorLog.create';
// allPermissions.push({
//   name: PERMISSION_ERROR_LOG_CREATE,
//   description: 'Can create Error Log',
// });

export const PERMISSION_EMPLOYEE_DEVICE_READ = 'employeeDevice.read';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_DEVICE_READ,
  description: 'Xem thiết bị của nhân viên',
});

export const PERMISSION_EMPLOYEE_DEVICE_CREATE = 'employeeDevice.create';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_DEVICE_CREATE,
  description: 'Tạo thiết bị của nhân viên',
});

export const PERMISSION_EMPLOYEE_DEVICE_UPDATE = 'employeeDevice.update';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_DEVICE_UPDATE,
  description: 'Cập nhât thiết bị của nhân viên',
});

export const PERMISSION_EMPLOYEE_DEVICE_REMOVE = 'employeeDevice.remove';
allPermissions.push({
  name: PERMISSION_EMPLOYEE_DEVICE_REMOVE,
  description: 'Xóa thiết bị của nhân viên',
});

export const PERMISSION_PRINT_GOODSRECEIPT = 'goodsReceipt.print';
allPermissions.push({
  name: PERMISSION_PRINT_GOODSRECEIPT,
  description: 'In phiếu nhập',
});

export const PERMISSION_PRINT_GOODSISSUE = 'goodsIssue.print';
allPermissions.push({
  name: PERMISSION_PRINT_GOODSISSUE,
  description: 'In phiếu xuất',
});

export const PERMISSION_LOCATION_STOP = 'location.stop';
allPermissions.push({
  name: PERMISSION_LOCATION_STOP,
  description: 'Dừng hoạt động địa điểm',
});

export const PERMISSION_LOCATION_WORK = 'location.work';
allPermissions.push({
  name: PERMISSION_LOCATION_WORK,
  description: 'Hoạt dộng địa diểm',
});

export const PERMISSION_PROPOSED_EQUIPMENT_UPDATE_ALL =
  'proposedEquipment.updateAll';
allPermissions.push({
  name: PERMISSION_PROPOSED_EQUIPMENT_UPDATE_ALL,
  description: 'Cập nhật lại tất cả các phiếu đề xuất',
});
export const PERMISSION_EQUIPMENT_UPDATE_SERIAL = 'equipment.updateSerial';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_UPDATE_SERIAL,
  description: 'Cập nhật serial lại cho thiết bị',
});

export const PERMISSION_EQUIPMENT_UPDATE_CONTENT = 'equipment.content';
allPermissions.push({
  name: PERMISSION_EQUIPMENT_UPDATE_CONTENT,
  description: 'Cập nhật content lại cho thiết bị',
});

export const permissions = allPermissions;

import gql from 'graphql-tag';
import goodsIssue from '../fragments/goodsIssue';

export const PASS_TO_MANAGER_GOODS_ISSUE = gql`
  mutation PassToManagerGoodsIssue($goodsIssueCode: String!) {
    passToManagerGoodsIssue(goodsIssueCode: $goodsIssueCode) {
      goodsIssue {
        ...FullGoodsIssue
      }
      error {
        message
      }
    }
  }
  ${goodsIssue.full}
`;

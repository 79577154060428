export interface IProvince {
  type: string;
  key: string;
  value: string;
  meta: { district: string[] };
  prefix: string;
}

export const provinces: IProvince[] = [
  {
    type: 'state',
    key: 'thanh-pho-ho-chi-minh',
    value: 'Thành phố Hồ Chí Minh',
    prefix: 'HCM',
    meta: {
      district: [
        'Quận 1',
        'Quận 2',
        'Quận 3',
        'Quận 4',
        'Quận 5',
        'Quận 6',
        'Quận 7',
        'Quận 8',
        'Quận 9',
        'Quận 10',
        'Quận 11',
        'Quận 12',
        'Quận Thủ Đức',
        'Quận Gò Vấp',
        'Quận Bình Thạnh',
        'Quận Tân Bình',
        'Quận Tân Phú',
        'Quận Phú Nhuận',
        'Quận Bình Tân',
        'Quận Củ Chi',
        'Quận Hóc Môn',
        'Quận Bình Chánh',
        'Quận Nhà Bè',
        'Quận Cần Giờ',
      ],
    },
  },
  {
    type: 'state',
    key: 'ha-noi',
    value: 'Thành phố Hà Nội',
    prefix: 'HN',
    meta: {
      district: [
        'Quận Ba Đình',
        'Quận Hoàn Kiếm',
        'Quận Tây Hồ',
        'Quận Long Biên',
        'Quận Cầu Giấy',
        'Quận Đống Đa',
        'Quận Hai Bà Trưng',
        'Quận Hoàng Mai',
        'Quận Thanh Xuân',
        'Quận Sóc Sơn',
        'Quận Đông Anh',
        'Quận Gia Lâm',
        'Quận Bắc Từ Liêm',
        'Quận Nam Từ Liêm',
        'Quận Thanh Trì',
        'Quận Mê Linh',
        'Quận Hà Đông',
        'Quận Sơn Tây',
        'Quận Ba Vì',
        'Quận Phúc Thọ',
        'Quận Đan Phượng',
        'Quận Hoài Đức',
        'Quận Quốc Oai',
        'Quận Thạch Thất',
        'Quận Chương Mỹ',
        'Quận Thanh Oai',
        'Quận Thường Tín',
        'Quận Phú Xuyên',
        'Quận Ứng Hòa',
        'Quận Mỹ Đức',
      ],
    },
  },
  {
    type: 'state',
    key: 'lam-dong',
    value: 'Lâm Đồng',
    prefix: 'LDONG',
    meta: {
      district: [
        'Thành phố Bảo Lộc',
        'Huyện Đức Trọng',
        'Huyện Di Linh',
        'Huyện Đơn Dương',
        'Huyện Lạc Dương',
        'Huyện Đạ Huoai',
        'Huyện Đạ Tẻh',
        'Huyện Cát Tiên',
        'Huyện Lâm Hà',
        'Huyện Bảo Lâm',
        'Huyện Đam Rông',
        'Thành phố Đà Lạt',
      ],
    },
  },
  {
    type: 'state',
    key: 'khanh-hoa',
    prefix: 'KHOA',
    value: 'Khánh Hòa',
    meta: {
      district: [
        'Thành phố Nha Trang',
        'Huyện Vạn Ninh',
        'Huyện Ninh Hoà',
        'Huyện Diên Khánh',
        'Huyện Khánh Vĩnh',
        'Thành phố Cam Ranh',
        'Huyện Khánh Sơn',
        'Huyện Trường Sa',
        'Huyện Cam Lâm',
        'Huyện Lâm Hà',
        'Huyện Bảo Lâm',
        'Huyện Đam Rông',
      ],
    },
  },

  {
    type: 'state',
    key: 'dak-lak',
    prefix: 'DLAK',
    value: 'Đắk Lắk',
    meta: {
      district: [
        'Thành phố Buôn Ma Thuột',
        'Huyện Ea H Leo',
        'Huyện Krông Buk',
        'Huyện Krông Năng',
        'Huyện Ea Súp',
        'Huyện Cư M gar',
        'Huyện Krông Pắc',
        'Huyện Ea Kar',
        'Huyện M`Đrăk',
        'Huyện Krông Ana',
        'Huyện Krông Bông',
        'Huyện Lăk',
      ],
    },
  },

  {
    type: 'state',
    key: 'kien-giang',
    prefix: 'KGIANG',
    value: 'Kiên Giang',
    meta: {
      district: [
        'Thành phố Rạch Giá',
        'Thị xã Hà Tiên',
        'Huyện Kiên Lương',
        'Huyện Hòn Đất',
        'Huyện Tân Hiệp',
        'Huyện Châu Thành',
        'Huyện Giồng Riềng',
        'Huyện Gò Quao',
        'Huyện An Biên',
        'Huyện An Minh',
        'Huyện Vĩnh Thuận',
        'Huyện Phú Quốc',
        'Huyện Kiên Hải',
        'Huyện U minh Thượng',
      ],
    },
  },

  {
    type: 'state',
    prefix: 'CTHO',
    key: 'can-tho',
    value: 'Thành phố Cần Thơ',
    meta: {
      district: [
        'Quận Ninh Kiều',
        'Quận Bình Thuỷ',
        'Quận Cái Răng',
        'Quận Ô Môn',
        'Huyện Phong Điền',
        'Huyện Cờ Đỏ',
        'Huyện Vĩnh Thạnh',
        'Huyện Thốt Nốt',
        'Huyện Thớt Lai',
      ],
    },
  },

  {
    type: 'state',
    key: 'lao-cai',
    prefix: 'LCAI',
    value: 'Lào Cai',
    meta: {
      district: [
        'Thành phố Lào Cai',
        'Huyện Bát Xát',
        'Huyện Mường Khương',
        'Huyện Si Ma Cai',
        'Huyện Bắc Hà',
        'Huyện Bảo Thắng',
        'Huyện Bảo Yên',
        'Huyện Sa Pa',
        'Huyện Văn Bàn',
      ],
    },
  },

  {
    type: 'state',
    key: 'dien-bien',
    prefix: 'DBIEN',
    value: 'Điện Biên',
    meta: {
      district: [
        'Huyện Điện Biên',
        'Huyện Điện Biên Đông',
        'Huyện Mường Ảng',
        'Huyện Nậm Pồ',
        'Thành phố Điện Biên Phủ',
        'Thị xã Mường Lay',
        'Huyện Mường Nhé',
        'Huyện Mường Chà',
        'Huyện Tủa Chùa',
        'Huyện Tuần Giáo',
      ],
    },
  },

  {
    type: 'state',
    key: 'lai-chau',
    prefix: 'LCHAU',
    value: 'Lai Châu',
    meta: {
      district: [
        'Thành phố Lai Châu',
        'Huyện Tam Đường',
        'Huyện Mường Tè',
        'Huyện Sìn Hồ',
        'Huyện Phong Thổ',
        'Huyện Than Uyên',
        'Huyện Tân Uyên',
        'Huyện Nậm Nhùn',
      ],
    },
  },
  {
    type: 'state',
    key: 'son-la',
    prefix: 'SLA',
    value: 'Sơn La',
    meta: {
      district: [
        'Thành phố Sơn La',
        'Huyện Quỳnh Nhai',
        'Huyện Thuận Châu',
        'Huyện Mường La',
        'Huyện Bắc Yên',
        'Huyện Phù Yên',
        'Huyện Mộc Châu',
        'Huyện Yên Châu',
        'Huyện Mai Sơn',
        'Huyện Sông Mã',
        'Huyện Sốp Cộp',
        'Huyện Vân Hồ',
      ],
    },
  },
  {
    type: 'state',
    key: 'yen-bai',
    value: 'Yên Bái',
    prefix: 'YBAI',
    meta: {
      district: [
        'Thành phố Yên Bái',
        'Thị xã Nghĩa Lộ',
        'Huyện Lục Yên',
        'Huyện Văn Yên',
        'Huyện Mù Căng Chải',
        'Huyện Trấn Yên',
        'Huyện Trạm Tấu',
        'Huyện Văn Chấn',
        'Huyện Yên Bình',
      ],
    },
  },
  {
    type: 'state',
    key: 'hoa-binh',
    value: 'Hoà Bình',
    prefix: 'HBINH',
    meta: {
      district: [
        'Thành phố Hòa Bình',
        'Huyện Đà Bắc',
        'Huyện Kỳ Sơn',
        'Huyện Lương Sơn',
        'Huyện Kim Bôi',
        'Huyện Cao Phong',
        'Huyện Tân Lạc',
        'Huyện Mai Châu',
        'Huyện Lạc Sơn',
        'Huyện Yên Thủy',
        'Huyện Lạc Thủy',
      ],
    },
  },

  {
    type: 'state',
    key: 'thai-nguyen',
    value: 'Thái Nguyên',
    prefix: 'TNGUYEN',
    meta: {
      district: [
        'Thành phố Thái Nguyên',
        'Thành phố Sông Công',
        'Huyện Định Hóa',
        'Huyện Phú Lương',
        'Huyện Đồng Hỷ',
        'Huyện Võ Nhai',
        'Huyện Đại Từ',
        'Thị xã Phổ Yên',
        'Huyện Phú Bình',
      ],
    },
  },

  {
    type: 'state',
    key: 'lang-son',
    value: 'Lạng Sơn',
    prefix: 'LSON',
    meta: {
      district: [
        'Thành phố Lạng Sơn',
        'Huyện Tràng Định',
        'Huyện Bình Gia',
        'Huyện Văn Lãng',
        'Huyện Cao Lộc',
        'Huyện Văn Quan',
        'Huyện Bắc Sơn',
        'Huyện Hữu Lũng',
        'Huyện Chi Lăng',
        'Huyện Lộc Bình',
        'Huyện Đình Lập',
      ],
    },
  },

  {
    type: 'state',
    key: 'quang-ninh',
    value: 'Quảng Ninh',
    prefix: 'QNINH',
    meta: {
      district: [
        'Thành phố Hạ Long',
        'Thành phố Móng Cái',
        'Thành phố Cẩm Phả',
        'Thành phố Uông Bí',
        'Huyện Bình Liêu',
        'Huyện Tiên Yên',
        'Huyện Đầm Hà',
        'Huyện Hải Hà',
        'Huyện Ba Chẽ',
        'Huyện Vân Đồn',
        'Huyện Hoành Bồ',
        'Thị xã Đông Triều',
        'Thị xã Quảng Yên',
        'Huyện Cô Tô',
      ],
    },
  },

  {
    type: 'state',
    key: 'bac-giang',
    value: 'Bắc Giang',
    prefix: 'BGIANG',
    meta: {
      district: [
        'Thành phố Bắc Giang',
        'Huyện Yên Thế',
        'Huyện Tân Yên',
        'Huyện Lạng Giang',
        'Huyện Lục Nam',
        'Huyện Lục Ngạn',
        'Huyện Sơn Động',
        'Huyện Yên Dũng',
        'Huyện Việt Yên',
        'Huyện Hiệp Hòa',
      ],
    },
  },

  {
    type: 'state',
    key: 'phu-tho',
    value: 'Phú Thọ',
    prefix: 'PTHO',
    meta: {
      district: [
        'Thành phố Việt Trì',
        'Thị xã Phú Thọ',
        'Huyện Đoan Hùng',
        'Huyện Hạ Hoà',
        'Huyện Thanh Ba',
        'Huyện Phù Ninh',
        'Huyện Yên Lập',
        'Huyện Cẩm Khê',
        'Huyện Tam Nông',
        'Huyện Lâm Thao',
        'Huyện Thanh Sơn',
        'Huyện Thanh Thuỷ',
        'Huyện Tân Sơn',
      ],
    },
  },

  {
    type: 'state',
    key: 'vinh-phuc',
    value: 'Vĩnh Phúc',
    prefix: 'VPHUC',
    meta: {
      district: [
        'Thành phố Vĩnh Yên',
        'Thị xã Phúc Yên',
        'Huyện Lập Thạch',
        'Huyện Tam Dương',
        'Huyện Tam Đảo',
        'Huyện Bình Xuyên',
        'Huyện Yên Lạc',
        'Huyện Vĩnh Tường',
        'Huyện Sông Lô',
      ],
    },
  },

  {
    type: 'state',
    key: 'bac-ninh',
    value: 'Bắc Ninh',
    prefix: 'BNINH',
    meta: {
      district: [
        'Thành phố Bắc Ninh',
        'Huyện Yên Phong',
        'Huyện Quế Võ',
        'Huyện Tiên Du',
        'Thị xã Từ Sơn',
        'Huyện Thuận Thành',
        'Huyện Gia Bình',
        'Huyện Lương Tài',
      ],
    },
  },

  {
    type: 'state',
    key: 'hai-duong',
    value: 'Hải Dương',
    prefix: 'HDUONG',
    meta: {
      district: [
        'Thành phố Hải Dương',
        'Thị xã Chí Linh',
        'Huyện Nam Sách',
        'Huyện Kinh Môn',
        'Huyện Kim Thành',
        'Huyện Thanh Hà',
        'Huyện Cẩm Giàng',
        'Huyện Bình Giang',
        'Huyện Gia Lộc',
        'Huyện Tứ Kỳ',
        'Huyện Ninh Giang',
        'Huyện Thanh Miện',
      ],
    },
  },

  {
    type: 'state',
    key: 'thanh-pho-hai-phong',
    value: 'Thành phố Hải Phòng',
    prefix: 'HP',
    meta: {
      district: [
        'Quận Hồng Bàng',
        'Quận Ngô Quyền',
        'Quận Lê Chân',
        'Quận Hải An',
        'Quận Kiến An',
        'Quận Đồ Sơn',
        'Quận Dương Kinh',
        'Huyện Thuỷ Nguyên',
        'Huyện An Dương',
        'Huyện An Lão',
        'Huyện Kiến Thuỵ',
        'Huyện Tiên Lãng',
        'Huyện Vĩnh Bảo',
        'Huyện Cát Hải',
      ],
    },
  },

  {
    type: 'state',
    key: 'hung-yen',
    value: 'Hưng Yên',
    prefix: 'HYEN',
    meta: {
      district: [
        'Thành phố Hưng Yên',
        'Huyện Văn Lâm',
        'Huyện Văn Giang',
        'Huyện Yên Mỹ',
        'Huyện Mỹ Hào',
        'Huyện Ân Thi',
        'Huyện Khoái Châu',
        'Huyện Kim Động',
        'Huyện Tiên Lữ',
        'Huyện Phù Cừ',
      ],
    },
  },

  {
    type: 'state',
    key: 'thai-binh',
    value: 'Thái Bình',
    prefix: 'TBINH',
    meta: {
      district: [
        'Thành phố Thái Bình',
        'Huyện Quỳnh Phụ',
        'Huyện Hưng Hà',
        'Huyện Đông Hưng',
        'Huyện Thái Thụy',
        'Huyện Tiền Hải',
        'Huyện Kiến Xương',
        'Huyện Vũ Thư',
      ],
    },
  },
  {
    type: 'state',
    key: 'ha-nam',
    value: 'Hà Nam',
    prefix: 'HNAM',
    meta: {
      district: [
        'Thành phố Phủ Lý',
        'Huyện Duy Tiên',
        'Huyện Kim Bảng',
        'Huyện Thanh Liêm',
        'Huyện Bình Lục',
        'Huyện Lý Nhân',
      ],
    },
  },
  {
    type: 'state',
    key: 'nam-dinh',
    value: 'Nam Định',
    prefix: 'NDINH',
    meta: {
      district: [
        'Thành phố Nam Định',
        'Huyện Mỹ Lộc',
        'Huyện Vụ Bản',
        'Huyện Ý Yên',
        'Huyện Nghĩa Hưng',
        'Huyện Nam Trực',
        'Huyện Trực Ninh',
        'Huyện Xuân Trường',
        'Huyện Giao Thủy',
        'Huyện Hải Hậu',
      ],
    },
  },
  {
    type: 'state',
    key: 'ninh-binh',
    value: 'Ninh Bình',
    prefix: 'NBINH',
    meta: {
      district: [
        'Thành phố Ninh Bình',
        'Thành phố Tam Điệp',
        'Huyện Nho Quan',
        'Huyện Gia Viễn',
        'Huyện Hoa Lư',
        'Huyện Yên Khánh',
        'Huyện Kim Sơn',
        'Huyện Yên Mô',
      ],
    },
  },
  {
    type: 'state',
    key: 'thanh-hoa',
    value: 'Thanh Hóa',
    prefix: 'THOA',
    meta: {
      district: [
        'Thành phố Thanh Hóa',
        'Thị xã Bỉm Sơn',
        'Thành phố Sầm Sơn',
        'Huyện Mường Lát',
        'Huyện Quan Hóa',
        'Huyện Bá Thước',
        'Huyện Quan Sơn',
        'Huyện Lang Chánh',
        'Huyện Ngọc Lặc',
        'Huyện Cẩm Thủy',
        'Huyện Thạch Thành',
        'Huyện Hà Trung',
        'Huyện Vĩnh Lộc',
        'Huyện Yên Định',
        'Huyện Thọ Xuân',
        'Huyện Thường Xuân',
        'Huyện Triệu Sơn',
        'Huyện Thiệu Hóa',
        'Huyện Hoằng Hóa',
        'Huyện Hậu Lộc',
        'Huyện Nga Sơn',
        'Huyện Như Xuân',
        'Huyện Như Thanh',
        'Huyện Nông Cống',
        'Huyện Đông Sơn',
        'Huyện Quảng Xương',
        'Huyện Tĩnh Gia',
      ],
    },
  },
  {
    type: 'state',
    key: 'nghe-an',
    value: 'Nghệ An',
    prefix: 'NAN',
    meta: {
      district: [
        'Thành phố Vinh',
        'Thị xã Cửa Lò',
        'Thị xã Thái Hoà',
        'Huyện Quế Phong',
        'Huyện Quỳ Châu',
        'Huyện Kỳ Sơn',
        'Huyện Tương Dương',
        'Huyện Nghĩa Đàn',
        'Huyện Quỳ Hợp',
        'Huyện Quỳnh Lưu',
        'Huyện Con Cuông',
        'Huyện Tân Kỳ',
        'Huyện Anh Sơn',
        'Huyện Diễn Châu',
        'Huyện Yên Thành',
        'Huyện Đô Lương',
        'Huyện Thanh Chương',
        'Huyện Nghi Lộc',
        'Huyện Nam Đàn',
        'Huyện Hưng Nguyên',
        'Thị xã Hoàng Mai',
      ],
    },
  },
  {
    type: 'state',
    key: 'ha-tinh',
    value: 'Hà Tĩnh',
    prefix: 'HTINH',
    meta: {
      district: [
        'Thành phố Hà Tĩnh',
        'Thị xã Hồng Lĩnh',
        'Huyện Hương Sơn',
        'Huyện Đức Thọ',
        'Huyện Vũ Quang',
        'Huyện Nghi Xuân',
        'Huyện Can Lộc',
        'Huyện Hương Khê',
        'Huyện Thạch Hà',
        'Huyện Cẩm Xuyên',
        'Huyện Kỳ Anh',
        'Huyện Lộc Hà',
        'Thị xã Kỳ Anh',
      ],
    },
  },
  {
    type: 'state',
    key: 'quang-binh',
    value: 'Quảng Bình',
    prefix: 'QBINH',
    meta: {
      district: [
        'Thành Phố Đồng Hới',
        'Huyện Minh Hóa',
        'Huyện Tuyên Hóa',
        'Huyện Quảng Trạch',
        'Huyện Bố Trạch',
        'Huyện Quảng Ninh',
        'Huyện Lệ Thủy',
        'Thị xã Ba Đồn',
      ],
    },
  },
  {
    type: 'state',
    key: 'quang-tri',
    value: 'Quảng Trị',
    prefix: 'QTRI',
    meta: {
      district: [
        'Thành phố Đông Hà',
        'Thị xã Quảng Trị',
        'Huyện Vĩnh Linh',
        'Huyện Hướng Hóa',
        'Huyện Gio Linh',
        'Huyện Đa Krông',
        'Huyện Cam Lộ',
        'Huyện Triệu Phong',
        'Huyện Hải Lăng',
      ],
    },
  },
  {
    type: 'state',
    key: 'thua-thien-hue',
    value: 'Thừa Thiên Huế',
    prefix: 'TTHUE',
    meta: {
      district: [
        'Thành phố Huế',
        'Huyện Phong Điền',
        'Huyện Quảng Điền',
        'Huyện Phú Vang',
        'Thị xã Hương Thủy',
        'Thị xã Hương Trà',
        'Huyện A Lưới',
        'Huyện Phú Lộc',
        'Huyện Nam Đông',
      ],
    },
  },
  {
    type: 'state',
    key: 'thanh-pho-da-nang',
    value: 'Thành phố Đà Nẵng',
    prefix: 'DN',
    meta: {
      district: [
        'Quận Liên Chiểu',
        'Quận Thanh Khê',
        'Quận Hải Châu',
        'Quận Sơn Trà',
        'Quận Ngũ Hành Sơn',
        'Quận Cẩm Lệ',
        'Huyện Hòa Vang',
      ],
    },
  },
  {
    type: 'state',
    key: 'quang-nam',
    value: 'Quảng Nam',
    prefix: 'QNAM',
    meta: {
      district: [
        'Thành phố Tam Kỳ',
        'Thành phố Hội An',
        'Huyện Tây Giang',
        'Huyện Đông Giang',
        'Huyện Đại Lộc',
        'Thị xã Điện Bàn',
        'Huyện Duy Xuyên',
        'Huyện Quế Sơn',
        'Huyện Nam Giang',
        'Huyện Phước Sơn',
        'Huyện Hiệp Đức',
        'Huyện Thăng Bình',
        'Huyện Tiên Phước',
        'Huyện Bắc Trà My',
        'Huyện Nam Trà My',
        'Huyện Núi Thành',
        'Huyện Phú Ninh',
        'Huyện Nông Sơn',
      ],
    },
  },
  {
    type: 'state',
    key: 'quang-ngai',
    value: 'Quảng Ngãi',
    prefix: 'QNGAI',
    meta: {
      district: [
        'Thành phố Quảng Ngãi',
        'Huyện Bình Sơn',
        'Huyện Trà Bồng',
        'Huyện Tây Trà',
        'Huyện Sơn Tịnh',
        'Huyện Tư Nghĩa',
        'Huyện Sơn Hà',
        'Huyện Sơn Tây',
        'Huyện Minh Long',
        'Huyện Nghĩa Hành',
        'Huyện Mộ Đức',
        'Huyện Đức Phổ',
        'Huyện Ba Tơ',
        'Huyện Lý Sơn',
      ],
    },
  },
  {
    type: 'state',
    key: 'binh-dinh',
    value: 'Bình Định',
    prefix: 'BDINH',
    meta: {
      district: [
        'Thành phố Qui Nhơn',
        'Huyện An Lão',
        'Huyện Hoài Nhơn',
        'Huyện Hoài Ân',
        'Huyện Phù Mỹ',
        'Huyện Vĩnh Thạnh',
        'Huyện Tây Sơn',
        'Huyện Phù Cát',
        'Thị xã An Nhơn',
        'Huyện Tuy Phước',
        'Huyện Vân Canh',
      ],
    },
  },
  {
    type: 'state',
    key: 'phu-yen',
    value: 'Phú Yên',
    prefix: 'PYEN',
    meta: {
      district: [
        'Thành phố Tuy Hoà',
        'Thị xã Sông Cầu',
        'Huyện Đồng Xuân',
        'Huyện Tuy An',
        'Huyện Sơn Hòa',
        'Huyện Sông Hinh',
        'Huyện Tây Hoà',
        'Huyện Phú Hoà',
        'Huyện Đông Hòa',
      ],
    },
  },
  {
    type: 'state',
    key: 'ninh-thuan',
    value: 'Ninh Thuận',
    prefix: 'NTHUAN',
    meta: {
      district: [
        'Thành phố Phan Rang-Tháp Chàm',
        'Huyện Bác Ái',
        'Huyện Ninh Sơn',
        'Huyện Ninh Hải',
        'Huyện Ninh Phước',
        'Huyện Thuận Bắc',
        'Huyện Thuận Nam',
      ],
    },
  },
  {
    type: 'state',
    key: 'binh-thuan',
    value: 'Bình Thuận',
    prefix: 'BTHUAN',
    meta: {
      district: [
        'Thành phố Phan Thiết',
        'Thị xã La Gi',
        'Huyện Tuy Phong',
        'Huyện Bắc Bình',
        'Huyện Hàm Thuận Bắc',
        'Huyện Hàm Thuận Nam',
        'Huyện Tánh Linh',
        'Huyện Đức Linh',
        'Huyện Hàm Tân',
        'Huyện Phú Quí',
      ],
    },
  },
  {
    type: 'state',
    key: 'kon-tum',
    value: 'Kon Tum',
    prefix: 'KTUM',
    meta: {
      district: [
        'Thành phố Kon Tum',
        'Huyện Đắk Glei',
        'Huyện Ngọc Hồi',
        'Huyện Đắk Tô',
        'Huyện Kon Plông',
        'Huyện Kon Rẫy',
        'Huyện Đắk Hà',
        'Huyện Sa Thầy',
        'Huyện Tu Mơ Rông',
        "Huyện Ia H' Drai",
      ],
    },
  },
  {
    type: 'state',
    key: 'gia-lai',
    value: 'Gia Lai',
    prefix: 'GLAI',
    meta: {
      district: [
        'Thành phố Pleiku',
        'Thị xã An Khê',
        'Thị xã Ayun Pa',
        'Huyện KBang',
        'Huyện Đăk Đoa',
        'Huyện Chư Păh',
        'Huyện Ia Grai',
        'Huyện Mang Yang',
        'Huyện Kông Chro',
        'Huyện Đức Cơ',
        'Huyện Chư Prông',
        'Huyện Chư Sê',
        'Huyện Đăk Pơ',
        'Huyện Ia Pa',
        'Huyện Krông Pa',
        'Huyện Phú Thiện',
        'Huyện Chư Pưh',
      ],
    },
  },
  {
    type: 'state',
    key: 'dak-nong',
    value: 'Đắk Nông',
    prefix: 'DNONG',
    meta: {
      district: [
        'Thị xã Gia Nghĩa',
        'Huyện Đăk Glong',
        'Huyện Cư Jút',
        'Huyện Đắk Mil',
        'Huyện Krông Nô',
        'Huyện Đắk Song',
        "Huyện Đắk R'Lấp",
        'Huyện Tuy Đức',
      ],
    },
  },
  {
    type: 'state',
    key: 'binh-phuoc',
    value: 'Bình Phước',
    prefix: 'BPHUOC',
    meta: {
      district: [
        'Thị xã Phước Long',
        'Thị xã Đồng Xoài',
        'Thị xã Bình Long',
        'Huyện Bù Gia Mập',
        'Huyện Lộc Ninh',
        'Huyện Bù Đốp',
        'Huyện Hớn Quản',
        'Huyện Đồng Phú',
        'Huyện Bù Đăng',
        'Huyện Chơn Thành',
        'Huyện Phú Riềng',
      ],
    },
  },
  {
    type: 'state',
    key: 'tay-ninh',
    value: 'Tây Ninh',
    prefix: 'TNINH',
    meta: {
      district: [
        'Thành phố Tây Ninh',
        'Huyện Tân Biên',
        'Huyện Tân Châu',
        'Huyện Dương Minh Châu',
        'Huyện Châu Thành',
        'Huyện Hòa Thành',
        'Huyện Gò Dầu',
        'Huyện Bến Cầu',
        'Huyện Trảng Bàng',
      ],
    },
  },
  {
    type: 'state',
    key: 'binh-duong',
    value: 'Bình Dương',
    prefix: 'BDUONG',
    meta: {
      district: [
        'Thành phố Thủ Dầu Một',
        'Huyện Bàu Bàng',
        'Huyện Dầu Tiếng',
        'Thị xã Bến Cát',
        'Huyện Phú Giáo',
        'Thị xã Tân Uyên',
        'Thị xã Dĩ An',
        'Thị xã Thuận An',
        'Huyện Bắc Tân Uyên',
      ],
    },
  },
  {
    type: 'state',
    key: 'dong-nai',
    value: 'Đồng Nai',
    prefix: 'DNAI',
    meta: {
      district: [
        'Thành phố Biên Hòa',
        'Thị xã Long Khánh',
        'Huyện Tân Phú',
        'Huyện Vĩnh Cửu',
        'Huyện Định Quán',
        'Huyện Trảng Bom',
        'Huyện Thống Nhất',
        'Huyện Cẩm Mỹ',
        'Huyện Long Thành',
        'Huyện Xuân Lộc',
        'Huyện Nhơn Trạch',
      ],
    },
  },
  {
    type: 'state',
    key: 'ba-ria-vung-tau',
    value: 'Bà Rịa - Vũng Tàu',
    prefix: 'BR-VTAU',
    meta: {
      district: [
        'Thành phố Vũng Tàu',
        'Thành phố Bà Rịa',
        'Huyện Châu Đức',
        'Huyện Xuyên Mộc',
        'Huyện Long Điền',
        'Huyện Đất Đỏ',
        'Huyện Tân Thành',
      ],
    },
  },
  {
    type: 'state',
    key: 'long-an',
    value: 'Long An',
    prefix: 'LAN',
    meta: {
      district: [
        'Thành phố Tân An',
        'Thị xã Kiến Tường',
        'Huyện Tân Hưng',
        'Huyện Vĩnh Hưng',
        'Huyện Mộc Hóa',
        'Huyện Tân Thạnh',
        'Huyện Thạnh Hóa',
        'Huyện Đức Huệ',
        'Huyện Đức Hòa',
        'Huyện Bến Lức',
        'Huyện Thủ Thừa',
        'Huyện Tân Trụ',
        'Huyện Cần Đước',
        'Huyện Cần Giuộc',
        'Huyện Châu Thành',
      ],
    },
  },
  {
    type: 'state',
    key: 'tien-giang',
    value: 'Tiền Giang',
    prefix: 'TGIANG',
    meta: {
      district: [
        'Thành phố Mỹ Tho',
        'Thị xã Gò Công',
        'Thị xã Cai Lậy',
        'Huyện Tân Phước',
        'Huyện Cái Bè',
        'Huyện Cai Lậy',
        'Huyện Châu Thành',
        'Huyện Chợ Gạo',
        'Huyện Gò Công Tây',
        'Huyện Gò Công Đông',
        'Huyện Tân Phú Đông',
      ],
    },
  },
  {
    type: 'state',
    key: 'ben-tre',
    value: 'Bến Tre',
    prefix: 'BTRE',
    meta: {
      district: [
        'Thành phố Bến Tre',
        'Huyện Châu Thành',
        'Huyện Chợ Lách',
        'Huyện Mỏ Cày Nam',
        'Huyện Giồng Trôm',
        'Huyện Bình Đại',
        'Huyện Ba Tri',
        'Huyện Thạnh Phú',
        'Huyện Mỏ Cày Bắc',
      ],
    },
  },
  {
    type: 'state',
    key: 'tra-vinh',
    value: 'Trà Vinh',
    prefix: 'TVINH',
    meta: {
      district: [
        'Thành phố Trà Vinh',
        'Huyện Càng Long',
        'Huyện Cầu Kè',
        'Huyện Tiểu Cần',
        'Huyện Châu Thành',
        'Huyện Cầu Ngang',
        'Huyện Trà Cú',
        'Huyện Duyên Hải',
        'Thị xã Duyên Hải',
      ],
    },
  },
  {
    type: 'state',
    key: 'vinh-long',
    value: 'Vĩnh Long',
    prefix: 'VLONG',
    meta: {
      district: [
        'Thành phố Vĩnh Long',
        'Huyện Long Hồ',
        'Huyện Mang Thít',
        'Huyện  Vũng Liêm',
        'Huyện Tam Bình',
        'Thị xã Bình Minh',
        'Huyện Trà Ôn',
        'Huyện Bình Tân',
      ],
    },
  },
  {
    type: 'state',
    key: 'dong-thap',
    value: 'Đồng Tháp',
    prefix: 'DTHAP',
    meta: {
      district: [
        'Thành phố Cao Lãnh',
        'Thành phố Sa Đéc',
        'Thị xã Hồng Ngự',
        'Huyện Tân Hồng',
        'Huyện Hồng Ngự',
        'Huyện Tam Nông',
        'Huyện Tháp Mười',
        'Huyện Cao Lãnh',
        'Huyện Thanh Bình',
        'Huyện Lấp Vò',
        'Huyện Lai Vung',
        'Huyện Châu Thành',
      ],
    },
  },
  {
    type: 'state',
    key: 'an-giang',
    value: 'An Giang',
    prefix: 'AGIANG',
    meta: {
      district: [
        'Thành phố Long Xuyên',
        'Thành phố Châu Đốc',
        'Huyện An Phú',
        'Thị xã Tân Châu',
        'Huyện Phú Tân',
        'Huyện Châu Phú',
        'Huyện Tịnh Biên',
        'Huyện Tri Tôn',
        'Huyện Châu Thành',
        'Huyện Chợ Mới',
        'Huyện Thoại Sơn',
      ],
    },
  },
  {
    type: 'state',
    key: 'hau-giang',
    value: 'Hậu Giang',
    prefix: 'HGIANG',
    meta: {
      district: [
        'Thành phố Vị Thanh',
        'Thị xã Ngã Bảy',
        'Huyện Châu Thành A',
        'Huyện Châu Thành',
        'Huyện Phụng Hiệp',
        'Huyện Vị Thuỷ',
        'Huyện Long Mỹ',
        'Thị xã Long Mỹ',
      ],
    },
  },
  {
    type: 'state',
    key: 'soc-trang',
    value: 'Sóc Trăng',
    prefix: 'STRANG',
    meta: {
      district: [
        'Thành phố Sóc Trăng',
        'Huyện Châu Thành',
        'Huyện Kế Sách',
        'Huyện Mỹ Tú',
        'Huyện Cù Lao Dung',
        'Huyện Long Phú',
        'Huyện Mỹ Xuyên',
        'Thị xã Ngã Năm',
        'Huyện Thạnh Trị',
        'Thị xã Vĩnh Châu',
        'Huyện Trần Đề',
      ],
    },
  },
  {
    type: 'state',
    key: 'bac-lieu',
    value: 'Bạc Liêu',
    prefix: 'BLIEU',
    meta: {
      district: [
        'Thành phố Bạc Liêu',
        'Huyện Hồng Dân',
        'Huyện Phước Long',
        'Huyện Vĩnh Lợi',
        'Thị xã Giá Rai',
        'Huyện Đông Hải',
        'Huyện Hoà Bình',
      ],
    },
  },
  {
    type: 'state',
    key: 'ca-mau',
    value: 'Cà Mau',
    prefix: 'CMAU',
    meta: {
      district: [
        'Thành phố Cà Mau',
        'Huyện U Minh',
        'Huyện Thới Bình',
        'Huyện Trần Văn Thời',
        'Huyện Cái Nước',
        'Huyện Đầm Dơi',
        'Huyện Năm Căn',
        'Huyện Phú Tân',
        'Huyện Ngọc Hiển',
      ],
    },
  },
  {
    type: 'state',
    key: 'tuyen-quang',
    value: 'Tuyên Quang',
    prefix: 'TQUANG',
    meta: {
      district: [
        'Thành phố Tuyên Quang',
        'Huyện Lâm Bình',
        'Huyện Nà Hang',
        'Huyện Chiêm Hóa',
        'Huyện Hàm Yên',
        'Huyện Yên Sơn',
        'Huyện Sơn Dương',
      ],
    },
  },
  {
    type: 'state',
    key: 'ha-giang',
    value: 'Hà Giang',
    prefix: 'HGIANG',
    meta: {
      district: [
        'Thành phố Hà Giang',
        'Huyện Đồng Văn',
        'Huyện Mèo Vạc',
        'Huyện Yên Minh',
        'Huyện Quản Bạ',
        'Huyện Vị Xuyên',
        'Huyện Bắc Mê',
        'Huyện Hoàng Su Phì',
        'Huyện Xín Mần',
        'Huyện Bắc Quang',
        'Huyện Quang Bình',
      ],
    },
  },
  {
    type: 'state',
    key: 'cao-bang',
    value: 'Cao Bằng',
    prefix: 'CBANG',
    meta: {
      district: [
        'Thành phố Cao Bằng',
        'Huyện Bảo Lâm',
        'Huyện Bảo Lạc',
        'Huyện Thông Nông',
        'Huyện Hà Quảng',
        'Huyện Trà Lĩnh',
        'Huyện Trùng Khánh',
        'Huyện Hạ Lang',
        'Huyện Quảng Uyên',
        'Huyện Phục Hoà',
        'Huyện Hoà An',
        'Huyện Nguyên Bình',
        'Huyện Thạch An',
      ],
    },
  },
  {
    type: 'state',
    key: 'bac-kan',
    value: 'Bắc Kạn',
    prefix: 'BKAN',
    meta: {
      district: [
        'Thành Phố Bắc Kạn',
        'Huyện Pác Nặm',
        'Huyện Ba Bể',
        'Huyện Ngân Sơn',
        'Huyện Bạch Thông',
        'Huyện Chợ Đồn',
        'Huyện Chợ Mới',
        'Huyện Na Rì',
      ],
    },
  },
];

export const areas = provinces.map(item => item.value);

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

const { Option } = Select;

interface IProps extends SelectProps {
  status?: [string];
}

const statusData = {
  CREATED: 'Mới tạo',
  WAITING_SURVEYOR: 'Chờ khảo sát',
  SURVEYING: 'Đang khảo sát',
  WAITING_AGREEMENT: 'Chờ duyệt',
  AGREED: 'Duyệt thành công',
  CANCELLED: 'Huỷ',
  WAITING_INSTALLER: 'Chờ thi công',
  INSTALLING: 'Đang thi công',
  INSTALLED: 'Hoàn công',
  WAITING_MAINTAINER: 'Chờ vận hành',
  WORKING: 'Hoạt động',
};

@observer
export default class StatusDropdown extends Component<IProps> {
  public render() {
    const { status, ...props } = this.props;
    return (
      <Select
        {...props}
        showSearch={true}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {status &&
          status.map((s, index) => (
            <Option key={String(index)} value={s}>
              {statusData[s]}
            </Option>
          ))}
      </Select>
    );
  }
}

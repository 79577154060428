import { Row, Tabs } from "antd";
import { get } from "lodash";
import { observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import { Authorized } from "~components/Authorized/Authorized";
import ConfirmModal from "~components/ConfirmModal/ConfirmModal";
import { TabName, UIButton } from "~components/UI";
import ViewDrawer from "~components/ViewDrawer/ViewDrawer";
import { FullProposedEquipmentFragment } from "~graphql/types";

import {
  AGREED,
  CANCELLED,
  FULFILLED,
  FULFILLING,
  PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_MANAGER,
  PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_WAREHOUSE,
  WAITING_AGREEMENT,
  WAITING_SURVEYOR
} from "@mgn/common";

import ProposedEquipmentFilter from "./Filter";
import ProposedEquipmentTable from "./Grid";
import store from "./store";

const { TabPane } = Tabs;

interface IProps {
  data: FullProposedEquipmentFragment;
}

@observer
class ProposedEquipment extends Component<IProps> {
  public componentWillMount() {
    store.setNotification();
  }

  public onTabChange = activeKey => {
    store.setStatus(activeKey);
  };

  public onFilterChange = values => {
    store.setFilter(values);
  };

  public render() {
    const {
      noti,
      status,
      visibleView,
      toggleView,
      toggleModal,
      selectedItem,
      visibleModal,
      handleConfirmFromManager,
      handleConfirmFromWarehouse
    } = store;

    const tabs = (
      <Tabs onChange={this.onTabChange} activeKey={status}>
        <TabPane
          key={WAITING_AGREEMENT}
          tab={
            <TabName
              title="Chờ Free duyệt"
              count={get(noti, "waitingAgreementTab", 0)}
            />
          }
        />
        <TabPane
          key={AGREED}
          tab={
            <TabName title="Chờ kho duyệt" count={get(noti, "agreedTab", 0)} />
          }
        />
        <TabPane
          key={FULFILLING}
          tab={
            <TabName title="Đang xuất" count={get(noti, "fulfillingTab", 0)} />
          }
        />
        <TabPane
          key={FULFILLED}
          tab={<TabName title="Hoàn thành" count={get(noti, "doneTab", 0)} />}
        />
        <TabPane
          key={CANCELLED}
          tab={<TabName title="Hủy" count={get(noti, "cancelledTab", 0)} />}
        />
      </Tabs>
    );

    let functionButton;

    switch (status) {
      case WAITING_AGREEMENT:
        functionButton = (
          <Authorized
            permission={PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_MANAGER}
          >
            <UIButton type="success" onClick={() => toggleModal()}>
              Duyệt
            </UIButton>
            <ConfirmModal
              visible={visibleModal}
              toggleModal={toggleModal}
              handleConfirm={handleConfirmFromManager}
              values={[
                { text: "ĐỒNG Ý", value: AGREED, color: "green" },
                {
                  text: "TRẢ LẠI",
                  value: WAITING_SURVEYOR,
                  color: "blue"
                },
                { text: "HỦY", value: CANCELLED, color: "red" }
              ]}
            />
          </Authorized>
        );

        break;

      case AGREED:
        functionButton = (
          <Authorized
            permission={PERMISSION_PROPOSED_EQUIPMENT_CONFIRM_FROM_WAREHOUSE}
          >
            <UIButton type="success" onClick={() => toggleModal()}>
              Duyệt
            </UIButton>
            <ConfirmModal
              visible={visibleModal}
              toggleModal={toggleModal}
              handleConfirm={handleConfirmFromWarehouse}
              values={[
                { text: "ĐỒNG Ý", value: AGREED, color: "green" },
                {
                  text: "TRẢ LẠI",
                  value: WAITING_SURVEYOR,
                  color: "blue"
                },
                { text: "HỦY", value: CANCELLED, color: "red" }
              ]}
            />
          </Authorized>
        );

        break;
    }

    return (
      <Fragment>
        <Row type="flex" style={{ flexFlow: "column", height: "100vh" }}>
          <Row>{tabs}</Row>
          <ProposedEquipmentFilter onChange={this.onFilterChange} />
          <div style={{ flex: 1, marginTop: 8 }}>
            <ProposedEquipmentTable />
          </div>
        </Row>
        <ViewDrawer
          visible={visibleView}
          toggleDrawer={toggleView}
          locationId={get(selectedItem, "locationId")}
          proposedEquipment={selectedItem}
        >
          {functionButton}
        </ViewDrawer>
      </Fragment>
    );
  }
}
export default ProposedEquipment;

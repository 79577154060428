import moment from 'moment';

export const convertingTime = input => {
  if (!input) return 'Chưa có';
  const updatedTime: any = moment(input);
  const isItYesterday = updatedTime.diff(moment(new Date()), 'day') < 0;
  return isItYesterday
    ? updatedTime.format('dddd[, ngày] DD/MM/YYYY HH:MM')
    : updatedTime.fromNow() + updatedTime.format('[ (]HH:MM DD/MM/YYYY[)]');
};
